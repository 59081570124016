import { RJSFSchema } from '@rjsf/utils';
import { JSONSchema7Definition } from 'json-schema';

// todo - We can rely on backend to get this enum
export enum ContentTypes {
  Text = 'text',
  Document = 'document',
  Image = 'image',
  Video = 'video',
  Webview = 'webview',
}

export const messageTypeSchema: RJSFSchema = {
  type: 'string',
  enum: [
    ContentTypes.Text,
    ContentTypes.Image,
    ContentTypes.Document,
    ContentTypes.Video,
    ContentTypes.Webview,
  ],
};

export const commonMessageContent: { [key: string]: JSONSchema7Definition } = {
  id: {
    type: 'string',
  },
  lastUpdated: {
    type: 'string',
  },
};
