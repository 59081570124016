import {
  Machine,
  Typestate,
  ActorRef,
  Interpreter,
  StateMachine,
  Sender,
} from 'xstate';
import { assign } from '@xstate/immer';
import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';
import { SharedEvents } from './app.xstate-utils';

type WinWidthNames = 'small' | 'medium' | 'large';

const isUnitTesting = false;

function getWindowWidthName(width: number): WinWidthNames {
  switch (true) {
    case width >= 1024:
      return 'large';
    case width >= 640:
      return 'medium';
    default:
      return 'small';
  }
}

export interface UiState {
  states: {
    modal: {
      states: {
        closed: Record<string, unknown>;
        opened: {
          states: {
            userProfile: Record<string, unknown>;
            login: Record<string, unknown>;
            publish: Record<string, unknown>;
          };
        };
      };
    };
    drawer: {
      states: {
        closed: Record<string, unknown>;
        opened: {
          states: {
            nodeDetails: Record<string, unknown>;
            elements: Record<string, unknown>;
          };
        };
      };
    };
    overlay: {
      states: {
        hidden: Record<string, unknown>;
        visible: Record<string, unknown>;
      };
    };
    profile: {
      states: {
        closed: Record<string, unknown>;
        opened: Record<string, unknown>;
      };
    };
  };
}

export interface UiContext {
  isLoggedIn: boolean;
  windowWidthName: WinWidthNames;
}

export type UiEvents =
  | { type: 'modal.triggerClose' }
  | { type: 'modal.hasClosed' }
  | {
      type: `modal.${'userProfile' | 'login' | 'publish'}.triggerOpen`;
    }
  | {
      // decoupled using reactcomponet
      type: `drawer.${'nodeDetails'}.triggerOpen`;
    }
  | {
      type: `drawer.update.${'nodeDetails'}`;
    }
  | {
      type: 'drawer.elements.triggerToggle';
      expanded: boolean;
    }
  | { type: `drawer.hasClosed` }
  | { type: `windowWidth.is${'Small' | 'Medium' | 'Large'}` }
  | { type: 'userProfile.open' }
  | { type: 'userProfile.close' }
  | { type: `reset.context`; data: UiContext }
  | SharedEvents;

export interface UiTypestates extends Typestate<UiContext> {
  context: UiContext;
  value: MachineStateSchemaPaths<UiState['states']>;
}

export type UiSpawnedActorRef = ActorRef<UiEvents, SharedEvents>;
export type UiStateMachine = StateMachine<
  UiContext,
  UiState,
  UiEvents,
  UiTypestates
>;
export type UiInterpreter = Interpreter<
  UiContext,
  UiState,
  UiEvents,
  UiTypestates
>;

const { createInvokablePromise, createXstateHooks: createUiXstateHooks } =
  getXstateUtils<UiContext, UiEvents, UiTypestates, UiState>();

export { createUiXstateHooks };

function userIsLoggedIn(ctx: UiContext): boolean {
  return ctx.isLoggedIn;
}

function canUserPublish(ctx: UiContext): boolean {
  // return ctx.isLoggedIn;
  return true;
}

// function sendUiEvent(
//   event: UiEvents | UiEvents['type'] | SendExpr<UiContext, UiEvents, UiEvents>
// ): ReturnType<typeof send> {
//   return send(event);
// }

export const uiInitialContext: UiContext = {
  isLoggedIn: false,
  windowWidthName: isUnitTesting
    ? 'large'
    : getWindowWidthName(window.innerWidth),
};

export const uiMachine = Machine<UiContext, UiState, UiEvents>(
  {
    id: 'ui',
    type: 'parallel',
    strict: true,
    predictableActionArguments: true,
    context: uiInitialContext,
    invoke: {
      id: 'windowWidthWatcher',
      src: (ctx) => (sendEvent: Sender<UiEvents>) => {
        const delay = 1000;
        let timeoutId: NodeJS.Timeout;
        let currentWidthName: WinWidthNames = ctx.windowWidthName;

        function handleResize({
          currentTarget,
        }: WindowEventMap['resize']): void {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            const { innerWidth } = currentTarget as Window;
            const widthName = getWindowWidthName(innerWidth);
            if (widthName === currentWidthName) return;
            currentWidthName = widthName;
            switch (widthName) {
              case 'large':
                sendEvent('windowWidth.isLarge');
                break;
              case 'medium':
                sendEvent('windowWidth.isMedium');
                break;
              case 'small':
              default:
                sendEvent('windowWidth.isSmall');
                break;
            }
          }, delay);
        }

        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      },
    },
    on: {
      'reset.context': {
        actions: ['resetContext'],
        target: '#ui',
      },
      'user.loggedIn': {
        actions: [
          assign((ctx) => {
            ctx.isLoggedIn = true;
          }),
        ],
      },
      'user.loggedOut': {
        actions: [
          assign((ctx) => {
            ctx.isLoggedIn = false;
          }),
        ],
      },
      'windowWidth.isLarge': {
        actions: [
          assign((ctx) => {
            ctx.windowWidthName = 'large';
          }),
        ],
      },
      'windowWidth.isMedium': {
        actions: [
          assign((ctx) => {
            ctx.windowWidthName = 'medium';
          }),
        ],
      },
      'windowWidth.isSmall': {
        actions: [
          assign((ctx) => {
            ctx.windowWidthName = 'small';
          }),
        ],
      },
    },
    states: {
      modal: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              'modal.login.triggerOpen': {
                target: 'opened.login',
                cond: function isUserLoggedIn(ctx) {
                  return ctx.isLoggedIn === false;
                },
              },
              'modal.userProfile.triggerOpen': {
                target: 'opened.userProfile',
                cond: userIsLoggedIn,
              },
              'modal.publish.triggerOpen': {
                target: 'opened.publish',
                cond: canUserPublish,
              },
            },
          },
          opened: {
            on: {
              /**
               * this event transition (going from a modal close event being triggered to actually being
               * closed) should be handled inside the modal component itself
               */
              'modal.triggerClose': {
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                actions: () => {},
              },
              /**
               * once the modal has sent the modal.hasClosed event, we can fully transition back to
               * the closed state
               */
              'modal.hasClosed': 'closed',
            },
            states: {
              login: {},
              userProfile: {},
              publish: {},
            },
          },
        },
      },
      overlay: {
        initial: 'hidden',
        states: {
          hidden: {},
          visible: {},
        },
      },
      drawer: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              'drawer.nodeDetails.triggerOpen': {
                target: '#ui.drawer.opened.nodeDetails',
              },
            },
          },
          opened: {
            on: {
              'drawer.hasClosed': {
                target: '#ui.drawer.closed',
              },
              'drawer.update.nodeDetails': {
                target: '#ui.drawer.opened.nodeDetails',
              },
              'drawer.elements.triggerToggle': [
                {
                  target: '#ui.drawer.opened.nodeDetails',
                  cond: (_, event) => event.expanded,
                },
                {
                  target: '#ui.drawer.opened.elements',
                  cond: (_, event) => !event.expanded,
                },
              ],
            },
            states: {
              nodeDetails: {},
              elements: {},
            },
          },
        },
      },
      profile: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              'userProfile.open': {
                target: 'opened',
                cond: userIsLoggedIn,
              },
            },
          },
          opened: {
            on: {
              'userProfile.close': {
                target: 'closed',
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      resetContext: assign((ctx, event) => {
        if (event.type !== 'reset.context') {
          return ctx;
        }

        return event.data;
      }),
    },
  }
);
