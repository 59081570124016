import * as React from 'react';
import { createComponent } from '@lit/react';
import { TreeWC } from './tree.wc';
import { TreeItemWC } from '../tree-item/tree-item.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type OnSelectionChange = ({
  detail: { selection },
}: {
  detail: { selection: TreeItemWC[] };
}) => void;

export type TreeProps = GenerateComponentTypeWithChildren<TreeWC> & {
  onSelectionChange?: OnSelectionChange;
};

export type TreePropsWithRef = GeneratePropsWithRef<TreeProps>;

export const Tree = createComponent({
  tagName: TreeWC.tagname,
  elementClass: TreeWC,
  react: React,
  events: {
    onSelectionChange: 'pl-selection-change',
  },
}) as TreePropsWithRef;
