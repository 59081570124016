import * as React from 'react';
import { createComponent } from '@lit/react';
import { DividerWC } from './divider.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type DividerProps = GenerateComponentTypeWithChildren<DividerWC>;

export type DividerPropsWithRef = GeneratePropsWithRef<DividerProps>;

export const Divider = createComponent({
  tagName: DividerWC.tagname,
  elementClass: DividerWC,
  react: React,
}) as DividerPropsWithRef;
