import * as React from 'react';
import { createComponent } from '@lit/react';
import { TableCellWC } from './table-cell.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TableCellProps = GenerateComponentTypeWithChildren<TableCellWC> &
  TranslateInterface & {
    onClick?: React.MouseEventHandler<TableCellWC>;
  };

export type TableCellPropsWithRef = GeneratePropsWithRef<TableCellProps>;

export const TableCell = createComponent({
  tagName: TableCellWC.tagname,
  elementClass: TableCellWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as TableCellPropsWithRef;
