import * as React from 'react';
import { createComponent } from '@lit/react';
import { PageSectionWC } from './page-section.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PageSectionProps = GenerateComponentTypeWithChildren<PageSectionWC>;
export type PageSectionPropsWithRef = GeneratePropsWithRef<PageSectionProps>;

export const PageSection = createComponent({
  tagName: PageSectionWC.tagname,
  elementClass: PageSectionWC,
  react: React,
}) as PageSectionPropsWithRef;
