import * as React from 'react';
import { createComponent } from '@lit/react';
import { RadioWC } from './radio.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type RadioProps = GenerateComponentTypeWithChildren<RadioWC> & {
  onFocus?: React.FocusEventHandler<RadioWC>;
  onBlur?: React.FocusEventHandler<RadioWC>;
};

export type RadioPropsWithRef = GeneratePropsWithRef<RadioProps>;

export const Radio = createComponent({
  tagName: RadioWC.tagname,
  elementClass: RadioWC,
  react: React,
  events: {
    onBlur: 'ps-blur',
    onFocus: 'ps-focus',
  },
}) as RadioPropsWithRef;
