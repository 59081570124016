import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import styles from './widget.scss?inline';

@customElement('ps-widget')
export class WidgetWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html` <div class="c-widget" data-cy="widget">
      <slot></slot>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-widget': WidgetWC;
  }
  enum PSElementTagNameMap {
    'ps-widget' = 'ps-widget',
  }
}
