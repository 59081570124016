import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './grid-cell.scss?inline';

@customElement('ps-grid-cell')
export class GridCellWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** Number of columns the grid cell uses */
  @property({ reflect: true }) xsmall = '12';

  /** Number of columns the grid cell uses on small bp */
  @property({ reflect: true }) small: string;

  /** Number of columns the grid cell uses on medium bp */
  @property({ reflect: true }) medium: string;

  /** Number of columns the grid cell uses on large bp */
  @property({ reflect: true }) large: string;

  /** Number of columns the grid cell uses on xlarge bp */
  @property({ reflect: true }) xlarge: string;

  render() {
    const classes = classMap({
      'c-grid-cell': true,
      [`c-grid-cell--${this.xsmall}/12`]: this.xsmall,
      [`c-grid-cell--${this.small}/12@small`]: this.small,
      [`c-grid-cell--${this.medium}/12@medium`]: this.medium,
      [`c-grid-cell--${this.large}/12@large`]: this.large,
      [`c-grid-cell--${this.xlarge}/12@xlarge`]: this.xlarge,
    });

    return html`
      <div data-cy="grid-cell" class=${classes}>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-grid-cell': GridCellWC;
  }
  enum PSElementTagNameMap {
    'ps-grid-cell' = 'ps-grid-cell',
  }
}
