import { MutationController } from '@lit-labs/observers/mutation-controller.js';
import { PypestreamElement } from '../decorators/customElement';

const slotContentIsPresent = Symbol('slotContentIsPresent');

type Constructor<T = Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
  prototype: T;
};

export interface SlotPresenceObservingInterface {
  slotContentIsPresent: boolean;
  hasSlottedContent(selector: string): boolean;
  managePresenceObservedSlot(): void;
}

export function SlotObserver<T extends Constructor<PypestreamElement>>(
  constructor: T,
  lightDomSelector: string | string[]
): T & Constructor<SlotPresenceObservingInterface> {
  const lightDomSelectors = Array.isArray(lightDomSelector)
    ? lightDomSelector
    : [lightDomSelector];
  class SlotPresenceObservingElement
    extends constructor
    implements SlotPresenceObservingInterface
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any[]) {
      super(args);

      // eslint-disable-next-line no-new
      new MutationController(this, {
        config: {
          childList: true,
          subtree: true,
          attributes: true,
        },
        callback: () => {
          this.managePresenceObservedSlot();
        },
      });

      this.managePresenceObservedSlot();
    }

    /**
     *  @private
     */
    public get slotContentIsPresent(): boolean {
      if (lightDomSelectors.length === 1) {
        return this[slotContentIsPresent].get(lightDomSelectors[0]) || false;
      }
      throw new Error(
        'Multiple selectors provided to `ObserveSlotPresence` use `hasSlottedContent(selector: string)` instead.'
      );
    }

    private [slotContentIsPresent]: Map<string, boolean> = new Map();

    public hasSlottedContent(selector: string): boolean {
      if (this[slotContentIsPresent].has(selector)) {
        return this[slotContentIsPresent].get(selector) || false;
      }
      throw new Error(
        `The provided selector \`${selector}\` is not being observed.`
      );
    }

    public managePresenceObservedSlot = (): void => {
      let changes = false;
      lightDomSelectors.forEach((selector) => {
        const nextValue = !!this.querySelector(`:scope > ${selector}`);
        const previousValue = this[slotContentIsPresent].get(selector) || false;
        changes = changes || previousValue !== nextValue;
        this[slotContentIsPresent].set(
          selector,
          !!this.querySelector(`:scope > ${selector}`)
        );
      });
      if (changes) {
        this.updateComplete.then(() => {
          this.requestUpdate();
        });
      }
    };
  }

  return SlotPresenceObservingElement;
}
