import * as React from 'react';
import { createComponent } from '@lit/react';
import { MenuAvatarWC } from './menu-avatar.wc';
import { GenerateComponentTypeWithChildren } from '../../component-types-generics';

export type MenuAvatarProps = GenerateComponentTypeWithChildren<MenuAvatarWC>;

export const MenuAvatar = createComponent({
  tagName: MenuAvatarWC.tagname,
  elementClass: MenuAvatarWC,
  react: React,
});
