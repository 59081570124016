import * as React from 'react';
import { createComponent } from '@lit/react';
import { DrawerWC } from './drawer.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type DrawerProps = GenerateComponentTypeWithChildren<DrawerWC>;

export type DrawerPropsWithRef = GeneratePropsWithRef<DrawerProps>;

export const Drawer = createComponent({
  tagName: DrawerWC.tagname,
  elementClass: DrawerWC,
  react: React,
}) as DrawerPropsWithRef;
