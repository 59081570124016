import { Position, internalsSymbol, Node } from 'reactflow';
import { Buffer } from 'buffer';
import { parse } from 'csv-parse/browser/esm';
import { MessageContents, NodeData } from '../store/graph';
import { ContentTypes } from '../schemas/contents/common';
import { is } from 'superstruct';
import {
  TextContentStruct,
  DocumentContentStruct,
  ImageContentStruct,
  VideoContentStruct,
  WebviewContentStruct,
} from '../schemas/contents';

export const getEdgeId = (
  source: number | string,
  target: number | string
): string => `${source} -> ${target}`;

export const isValidMessageContent = (
  messageToValidate: MessageContents[number]
) => {
  switch (messageToValidate.messageType) {
    case ContentTypes.Text:
      return is(messageToValidate, TextContentStruct);
    case ContentTypes.Document:
      return is(messageToValidate, DocumentContentStruct);
    case ContentTypes.Image:
      return is(messageToValidate, ImageContentStruct);
    case ContentTypes.Video:
      return is(messageToValidate, VideoContentStruct);
    case ContentTypes.Webview:
      return is(messageToValidate, WebviewContentStruct);
    default:
      console.error(
        '**** Unknown type of message content ****',
        messageToValidate
      );
      return false;
  }
};

// This code is taken from reactflow docs --- this is pending on self-review
// returns the position (top,right,bottom or right) passed node compared to
function getParams(
  nodeA: Node,
  nodeB: Node
): [x: number, y: number, position: Position] {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(
  node: Node,
  handlePosition: Position
): [x: number, y: number] {
  const handle = node[internalsSymbol]?.handleBounds?.source?.find(
    (h) => h.position === handlePosition
  );
  if (handle && node.positionAbsolute) {
    // all handles are from type source, that's why we use handleBounds.source here

    let offsetX = handle.width / 2;
    let offsetY = handle.height / 2;

    // this is a tiny detail to make the markerEnd of an edge visible.
    // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
    // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
    switch (handlePosition) {
      case Position.Left:
        offsetX = 0;
        break;
      case Position.Right:
        offsetX = handle.width;
        break;
      case Position.Top:
        offsetY = 0;
        break;
      case Position.Bottom:
        offsetY = handle.height;
        break;
    }

    const x = node.positionAbsolute.x + handle.x + offsetX;
    const y = node.positionAbsolute.y + handle.y + offsetY;

    return [x, y];
  } else {
    return [0, 0];
  }
}

function getNodeCenter(node: Node): { x: number; y: number } {
  if (!node.positionAbsolute || !node.width || !node.height) {
    return {
      x: 0,
      y: 0,
    };
  }
  return {
    x: node.positionAbsolute.x + node.width / 2,
    y: node.positionAbsolute.y + node.height / 2,
  };
}

export function getEdgeParams(
  source: Node,
  target: Node
): {
  sx: number;
  sy: number;
  tx: number;
  ty: number;
  sourcePos: Position;
  targetPos: Position;
} {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

// #region CSV based build page
function parseNodeRefs(data: string, nodeNumber: string): string[] {
  if (data.includes('|')) {
    const result = data.split('|').map((item: string) => {
      if (item.includes('~')) {
        return item.split('~')[1].trim();
      } else if (item.includes('*')) {
        return item.split('*')[0].trim();
      } else {
        return undefined;
      }
    });

    if (result.includes('{')) {
      console.error('check for error', nodeNumber, data);
    }

    return result.filter(Boolean) as string[];
  } else {
    const data2 = data.replace('{', '').replace('}', '');
    if (data2.startsWith('~')) {
      console.error('check for error', nodeNumber, data);
      return [];
    }

    if (data2.includes('~')) {
      return [data2.split('~')[1]];
    } else if (data2.includes('*')) {
      return [data2.split('*')[0]];
    } else {
      return [];
    }
  }
}

export function csvFileProcessor(
  fileBuffer: WithImplicitCoercion<string>
): Promise<Array<NodeData>> {
  return new Promise((resolve) => {
    parse(Buffer.from(fileBuffer), {}, (err, data) => {
      const __nodes: Array<NodeData> = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.forEach((node: any, index: number) => {
        if (index === 0) return;

        const nextNodes = node[7] ? [node[7].toString()] : [];

        if (!node[7]) {
          if (node[19]) {
            nextNodes.push(...parseNodeRefs(node[19], node[0]));
          }
          if (node[10]) {
            nextNodes.push(...parseNodeRefs(node[10], node[0]));
          }

          if (node[20]) {
            nextNodes.push(...parseNodeRefs(node[20], node[0]));
          }
        }

        __nodes.push({
          node_instance: {
            id: node[0],
            node: {
              node_name: node[2],
              node_type: 'D',
              // Temporary node-id as w.r.t. new database, we are getting different id, this is just temp fix
              id: node[0],
            },
          },
          node_number: node[0],
          outgoing_nodes: {
            to_node_numbers: nextNodes || [],
          },
        });
      });

      __nodes.map((___node) => {
        if (
          ___node?.outgoing_nodes.to_node_numbers &&
          ___node?.outgoing_nodes.to_node_numbers.length
        ) {
          return ___node.outgoing_nodes.to_node_numbers.map((child: number) => {
            const childToUpdate = __nodes.find(
              (n) => n.node_number.toString() == child.toString()
            );

            if (childToUpdate) {
              childToUpdate.parentId = [___node.node_number];
              return child;
            } else {
              console.log('orphan?', child, ___node.node_number);
              return null;
            }
          });
        } else {
          return ___node;
        }
      });

      __nodes.map((___node) => {
        ___node.outgoing_nodes.to_node_numbers = [
          ...new Set(___node.outgoing_nodes.to_node_numbers || []),
        ];
        return ___node;
      });
      resolve(__nodes);
    });
  });
}
// #endregion CSV based build page
