export {
  edges$,
  nodes$,
  graphData$,
  selectedNode$,
  builderStore,
  setActiveNode,
  resetActiveNode,
  getActiveNode,
  handleAddNode,
  handleNodeChanges,
  handleEdgeChanges,
  handleCollapseToggle,
  handleNodeTitleChange,
  convertToDefaultNode,
  // @todo - is it right place for generateGraph?
  generateGraph,
  flushGraphDb,
} from './graph';

export type {
  MessageContents,
  Node,
  NodeData,
  Edge,
  NodeDetails,
} from './graph';

export {
  selectedNodeDetails$,
  nodeDetailsStateHistory,
  handleNodeDetailsUpdate,
  handleNodeDetailsIncomingData,
} from './node-details';

import { enableElfProdMode } from '@ngneat/elf';
import { isProduction } from '@pypestream/utils';

if (isProduction) {
  enableElfProdMode();
}
