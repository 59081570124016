import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../../components/base-element';
import '../../../components/image/image.wc';
import styles from './applet-card-image.scss?inline';

const fallback =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTI4IiBoZWlnaHQ9IjEyOCIgdmlld0JveD0iMCAwIDEyOCAxMjgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIG9wYWNpdHk9IjAuMSI+CjxyZWN0IHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiByeD0iMjgiIGZpbGw9IiMxNTFBMjUiLz4KPC9nPgo8ZyBvcGFjaXR5PSIwLjIiPgo8cmVjdCB4PSI0MCIgeT0iNDAiIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgcng9IjI0IiBmaWxsPSIjMTUxQTI1Ii8+CjwvZz4KPC9zdmc+Cg==';

@customElement('ps-applet-card-image')
export class AppletCardImageWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * The src attribute for the image.
   */
  @property({ reflect: true }) src: string = '';

  render() {
    return html`
      <div class="c-applet-card-image">
        <ps-image
          width="128"
          height="128"
          src=${this.src}
          alt=""
          fallback=${fallback}
        ></ps-image>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-applet-card-image': AppletCardImageWC;
  }
}
