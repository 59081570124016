import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import styles from './background.scss?inline';

const tagname = 'ps-background';

@customElement(tagname)
export class BackgroundWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <span class="c-background">
        <slot></slot>
      </span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [tagname]: BackgroundWC;
  }
}
