import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import styles from './inline-list.scss?inline';

@customElement('ps-inline-list')
export class InlineListWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * Defines the space between items
   */
  @property({ reflect: true }) gutter:
    | 'none'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large' = 'medium';

  /**
   * Allows inline lists to fill up all horizontal space available
   */
  @property({ reflect: true }) width: 'full' | 'auto' = 'auto';

  render() {
    return html`
      <div
        class=${classMap({
          'c-inline-list': true,
          [`c-inline-list--gap-${this.gutter}`]: this.gutter,
          [`c-inline-list--width-${this.width}`]: this.width,
        })}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-inline-list': InlineListWC;
  }
  enum PSElementTagNameMap {
    'ps-inline-list' = 'ps-inline-list',
  }
}
