import { html, unsafeCSS, PropertyValues } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, state, query } from 'lit/decorators.js';
import { ResizeController } from '@lit-labs/observers/resize-controller.js';
import { BaseElement, customElement } from '../../base-element';
import pageBodyStyles from './page-sidenav.scss?inline';

@customElement('ps-page-sidenav')
export class PageSidenavWC extends BaseElement {
  static styles = unsafeCSS(pageBodyStyles);

  @property({ type: Boolean, reflect: true }) open = false;

  @property({ reflect: true }) behavior:
    | 'pinned-on-mobile'
    | 'collapse-on-mobile'
    | 'always-collapsible' = 'always-collapsible';

  @query('.c-page-sidenav') sidenav: HTMLDivElement;

  @property()
  currentPageWidth: number = document.body.clientWidth;

  breakpoint: number = 600;

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('currentPageWidth')) {
      if (this.currentPageWidth >= this.breakpoint) {
        this.sidenav.classList.add('no-transition');
      } else {
        this.sidenav.classList.remove('no-transition');
      }
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    if (this.currentPageWidth >= 600) {
      this.sidenav.classList.add('no-transition');
    } else {
      this.sidenav.classList.remove('no-transition');
    }
  }

  private _observer = new ResizeController(this, {
    target: document.body,
    skipInitial: false,
    callback: (value) => {
      if (!value) return;
      const pageWidth = value[0]?.contentRect?.width;

      if (pageWidth) {
        this.currentPageWidth = pageWidth;
      }
    },
  });

  render() {
    return html`
      <div
        class="${classMap({
          'c-page-sidenav': true,
          'c-page-sidenav--open': this.open,
          [`c-page-sidenav--${this.behavior}`]: true,
        })}"
      >
        <ps-button
          size="large"
          class="c-page-sidenav__toggle-button"
          variant="primary"
          rounded
          @click=${() => {
            this.open = !this.open;
          }}
        >
          <ps-icon name="arrow-right"></ps-icon>
        </ps-button>

        <div class="c-page-sidenav__content">
          <div class="c-page-sidenav__links">
            <slot></slot>
          </div>
        </div>
      </div>
      <div
        part="overlay"
        class="c-page-sidenav__overlay"
        tabindex="-1"
        @click="${() => {
          this.open = false;
        }}"
        @keypress="${(e: KeyboardEvent) => {
          if (this.open && e.key === 'Enter') {
            this.open = false;
          }
        }}"
      ></div>
    `;
    // @todo: keep working on the footer UI
    // <ps-divider class="c-page-sidenav__divider"></ps-divider>
    // <div class="c-page-sidenav__footer"></div>
    // <ps-button
    //   size="large"
    //   variant="ghost"
    //   @click=${() => {
    //     this.open = true;
    //   }}
    // >
    //   <ps-icon name="arrow-right"></ps-icon>
    // </ps-button>
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-page-sidenav': PageSidenavWC;
  }
}
