/* eslint-disable lit/no-invalid-html */
/* eslint-disable lit/binding-positions */
import { unsafeCSS, PropertyValues } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './media-object.scss?inline';

@customElement('ps-media-object')
export class MediaObjectWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property({ reflect: true }) href: string;

  @property({ reflect: true }) target: '_blank' | '_self' | '_parent' | '_top' =
    '_self';

  /** Flips order of media and body slots. */
  @property({ type: Boolean, reflect: true }) reversed = false;

  private isLink() {
    return !!this.href;
  }

  render() {
    const isLink = this.isLink();
    const tag = isLink ? literal`a` : literal`div`;

    const classes = classMap({
      'c-media-object': true,
      'c-media-object--interactive': isLink,
      [`c-media-object--reversed`]: this.reversed,
    });

    return html`
      <${tag} href=${ifDefined(
        isLink ? this.href : undefined
      )} target=${ifDefined(isLink ? this.target : undefined)} rel=${ifDefined(
        isLink ? 'noreferrer' : undefined
      )} class=${classes}>
        ${
          this.slotted('media')
            ? html`<figure class="c-media-object__figure">
                <slot name="media"></slot>
              </figure>`
            : ''
        }
        <div class="c-media-object__body"><slot></slot></div>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-media-object': MediaObjectWC;
  }
}
