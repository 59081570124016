import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import '../../icon/icon.wc';
import styles from './modal-icon.scss?inline';

@customElement('ps-modal-icon')
export class ModalIconWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** Icon name. */
  @property({ reflect: true }) name: 'success' | 'error' = 'success';

  render() {
    const iconName = this.name === 'error' ? 'exclamation' : 'check';

    const classes = classMap({
      'c-modal-icon': true,
      [`c-modal-icon--${this.name}`]: this.name,
    });

    return html`<span class=${classes}
      ><ps-icon name=${iconName} size="medium"></ps-icon
    ></span>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-modal-icon': ModalIconWC;
  }
  enum PSElementTagNameMap {
    'ps-modal-icon' = 'ps-modal-icon',
  }
}
