export function flexRenderer<TProps extends object>(
  comp: ((props: TProps) => string) | string | undefined,
  props: TProps
) {
  if (!comp) {
    return '';
  }

  return typeof comp === 'function' ? comp(props) : comp;
}
