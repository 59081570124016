import * as React from 'react';
import { createComponent } from '@lit/react';
import { TabWC } from './tab.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TabProps = GenerateComponentTypeWithChildren<TabWC> & {
  onClick?: React.MouseEventHandler<TabWC>;
};

export type TabPropsWithRef = GeneratePropsWithRef<TabProps>;

export const Tab = createComponent({
  tagName: TabWC.tagname,
  elementClass: TabWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as TabPropsWithRef;
