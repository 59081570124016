import { unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import buttonStyles from './button-group.scss?inline';

@customElement('ps-button-group')
export class ButtonGroupWC extends BaseElement {
  static styles = unsafeCSS(buttonStyles);

  @property({ reflect: true }) align: 'start' | 'end' | 'center' = 'start';

  render() {
    return html`
      <div
        class="${classMap({
          'c-button-group': true,
          [`c-button-group--align-${this.align}`]: true,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-button-group': ButtonGroupWC;
  }
  enum PSElementTagNameMap {
    'ps-button-group' = 'ps-button-group',
  }
}
