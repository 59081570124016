import * as React from 'react';
import { createComponent } from '@lit/react';
import { NavExtraWC } from './nav-extra.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type NavExtraProps = GenerateComponentTypeWithChildren<NavExtraWC>;

export type NavExtraPropsWithRef = GeneratePropsWithRef<NavExtraProps>;

export const NavExtra = createComponent({
  tagName: NavExtraWC.tagname,
  elementClass: NavExtraWC,
  react: React,
}) as NavExtraPropsWithRef;
