import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './callout-title.scss?inline';

@customElement('ps-callout-title')
export class CalloutTitleWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  /** Text-align */
  @property({ reflect: true }) align: 'start' | 'center' | 'end' = 'start';

  render() {
    return html`
      <p
        class="${classMap({
          'c-callout-title': true,
          [`c-callout-title--align-${this.align}`]: this.align,
        })}"
      >
        <slot></slot>
      </p>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-callout-title': CalloutTitleWC;
  }
  enum PSElementTagNameMap {
    'ps-callout-title' = 'ps-callout-title',
  }
}
