import { Trans, useTranslation } from 'react-i18next';
import { TransKeyProp } from './types';
import React from 'react';

interface TranslationComponentProps
  extends React.PropsWithChildren<Required<TransKeyProp>> {
  values?: Record<string, unknown>;
  defaults?: string;
}

export const TranslationComponent: React.FC<TranslationComponentProps> = ({
  i18nKey,
  children,
  values,
  defaults,
}) => {
  const { t } = useTranslation();
  return (
    <Trans
      i18nKey={i18nKey}
      t={t}
      {...(values && { values })}
      {...(defaults && { defaults })}
    >
      <React.Fragment>{children}</React.Fragment>
    </Trans>
  );
};
