import moment from 'moment';

export const addLeadingZeros = (dateString: string): string => {
  const parts = dateString.split('/');
  if (parts[0] && parts[0].length === 1) parts[0] = `0${parts[0]}`;
  if (parts[1] && parts[1].length === 1) parts[1] = `0${parts[1]}`;
  return parts.join('/');
};

export const formatYear = (dateString: string): string => {
  const parts = dateString.split('/');
  if (parts.length === 3 && parts[2].length === 2) {
    const currentYear = moment().year();
    const inputYear = parseInt(parts[2], 10);
    const currentCentury = Math.floor(currentYear / 100) * 100;
    const formattedYear = inputYear + currentCentury;
    if (formattedYear > currentYear) {
      parts[2] = (formattedYear - 100).toString();
    } else {
      parts[2] = formattedYear.toString();
    }
  }
  return parts.join('/');
};

export const formatDateInput = (value: string): string => {
  const cleanedValue = value.replace(/[\s\-/]+/g, '');

  if (cleanedValue.length === 6 || cleanedValue.length === 8) {
    const month = cleanedValue.slice(0, 2);
    const day = cleanedValue.slice(2, 4);
    const year = cleanedValue.slice(4);

    const formattedDate = `${month}/${day}/${year}`;
    return addLeadingZeros(formatYear(formattedDate));
  }

  return value;
};
