import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import './text';
import { Translate } from '../base-element/mixins/translation-mixin';

@customElement('ps-text-caption')
export class TextCaptionWC extends Translate(BaseElement) {
  /** Text-align */
  @property() textAlign: 'start' | 'center' | 'end' | 'justify' = 'start';

  @property() transform: 'none' | 'uppercase' = 'none';

  /** Typography variant */
  @property() variant: 'secondary' | 'tertiary' | 'danger' = 'secondary';

  /** Truncate option */
  @property({ type: Boolean, reflect: true }) truncate?: boolean = false;

  render() {
    return html`
      <ps-text
        size="4xsmall"
        textAlign=${this.textAlign}
        variant=${this.variant}
        transform=${this.transform}
        ?truncate=${this.truncate}
        data-cy="text-caption"
      >
        <slot></slot>
      </ps-text>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text-caption': TextCaptionWC;
  }
  enum PSElementTagNameMap {
    'ps-text-caption' = 'ps-text-caption',
  }
}
