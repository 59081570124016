export const sortByLastDate =
  (reverse: boolean) => (a: Date | string, b: Date | string) => {
    return reverse
      ? Number(new Date(a)) - Number(new Date(b))
      : Number(new Date(b)) - Number(new Date(a));
  };

export const sortAlphabetically =
  (reverse: boolean) => (a: string, b: string) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    if (reverse ? nameA > nameB : nameA < nameB) {
      return -1;
    }

    if (reverse ? nameA < nameB : nameA > nameB) {
      return 1;
    }

    return 0;
  };
