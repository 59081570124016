import * as React from 'react';
import { createComponent } from '@lit/react';
import { AccordionGroupWC } from './accordion-group.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type AccordionGroupProps =
  GenerateComponentTypeWithChildren<AccordionGroupWC>;

export type AccordionGroupPropsWithRef =
  GeneratePropsWithRef<AccordionGroupProps>;

export const AccordionGroup = createComponent({
  tagName: AccordionGroupWC.tagname,
  elementClass: AccordionGroupWC,
  react: React,
}) as AccordionGroupPropsWithRef;
