import {
  Button,
  Icon,
  Spacer,
  Stack,
  TextTitle,
  TextBody,
  PageBody,
} from '@pypestream/design-system';
import { PageHeader, MicroappsSummaryTable } from '../../components';
import { TableHeading } from '../../components/microapps-summary-table/microapps-summary-table';
import { getMicroAppProps } from '../../xstate/micro-apps/helpers';
import './homepage.scss';
import {
  useMicroappsStateMatches,
  useMicroappsStateMatchesOneOf,
  useMicroappsCtxSelector,
} from '../../xstate/app.xstate';

export const Homepage: React.FC = () => {
  const loading = useMicroappsStateMatchesOneOf(['idle', 'loading']);
  const data = useMicroappsCtxSelector((ctx) => ctx.data);

  const tableHeadings: TableHeading[] = [
    {
      i18nKey: 'studio/homepage:app.name',
      children: 'App Name',
      sortingType: 'alphabetically',
    },
    {
      i18nKey: 'studio/homepage:app.status',
      children: 'Production Status',
      sortingType: 'status',
    },
    {
      i18nKey: 'studio/homepage:app.updated',
      align: 'end',
      children: 'Last updated',
      sortedByDefault: true,
      sortingType: 'lastDate',
    },
  ];

  const renderHomepage = (): JSX.Element => {
    if (!data || data.length === 0) {
      return (
        <div className="c-homepage--container">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <TextTitle i18nKey="studio/homepage:app.empty.title" size="small">
              You have no apps yet
            </TextTitle>
            <TextBody i18nKey="studio/homepage:app.empty.subtitle">
              Your apps will be displayed here
            </TextBody>
            <Button disabled i18nKey="studio/homepage:app.create">
              <Icon slot="prefix" name="plus" />
              Create App
            </Button>
          </Stack>
          <Spacer size="xlarge" />
        </div>
      );
    }
    return (
      <>
        <Stack alignItems="center" justifyContent="space-between">
          <TextTitle i18nKey="studio/homepage:title" size="small">
            All apps
          </TextTitle>
          <Button i18nKey="studio/homepage:app.create" disabled>
            <Icon slot="prefix" name="plus" />
            New App
          </Button>
        </Stack>
        <Spacer size="2xlarge" />
        <MicroappsSummaryTable
          data={getMicroAppProps(data)}
          headings={tableHeadings}
        />
      </>
    );
  };
  return (
    <>
      <PageHeader />
      <PageBody>
        {loading ? (
          <TextTitle i18nKey="studio/common:loading">Loading...</TextTitle>
        ) : (
          renderHomepage()
        )}
      </PageBody>
    </>
  );
};
