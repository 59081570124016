/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import {
  DrawerPanelWC,
  DrawerPanelToggleEvent,
  DrawerPanelOpenEvent,
} from '../drawer-panel/drawer-panel.wc';
import { BaseElement, customElement } from '../../base-element';
import drawerPanelWrapperStyles from './drawer-panel-wrapper.scss?inline';

@customElement('ps-drawer-panel-wrapper')
export class DrawerPanelWrapperWC extends BaseElement {
  static styles = unsafeCSS(drawerPanelWrapperStyles);

  @property({ type: Boolean, reflect: true }) open: boolean;

  @property({ type: Boolean, reflect: true }) expanded: boolean;

  @query('slot') defaultSlot: HTMLSlotElement;

  // eslint-disable-next-line class-methods-use-this
  // private isDrawerPanel(item: HTMLElement): boolean {
  //   return item.tagName.toLowerCase() === 'ps-drawer-panel';
  // }

  // firstUpdated() {
  //   // const styles = unsafeCSS(drawerPanelWrapperStyles);
  //   // const sheet = new CSSStyleSheet();
  //   // sheet.replaceSync(`${styles.cssText}`);
  //   // console.log(styles);
  //   // document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
  //   const whenAllDefined = Promise.allSettled([
  //     customElements.whenDefined('ps-drawer-panel'),
  //   ]).then(() => {
  //     console.log(this.getAllDrawerPanels());
  //     // console.log(this.defaultSlot.assignedElements('ps-drawer-panel'));
  //   });
  // }

  getAllDrawerPanels() {
    return [...this.defaultSlot.assignedElements({ flatten: true })].filter(
      (el: HTMLElement | Element) => {
        if (el instanceof DrawerPanelWC) return true;

        return false;
      }
    ) as DrawerPanelWC[];
  }

  render() {
    return html`
      <div
        class="${classMap({
          'c-drawer-panel-wrapper': true,
          'c-drawer-panel-wrapper--expanded': this.expanded,
          'c-drawer-panel-wrapper--open': this.open,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }

  drawerPanels: DrawerPanelWC[];

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.handleDrawerPanelExpandChanges =
      this.handleDrawerPanelExpandChanges.bind(this);
    this.handleDrawerPanelOpenChanges =
      this.handleDrawerPanelOpenChanges.bind(this);

    this.addEventListener(
      'drawer-panel-toggle',
      this.handleDrawerPanelExpandChanges
    );
    this.addEventListener(
      'drawer-panel-open',
      this.handleDrawerPanelOpenChanges
    );
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.removeEventListener(
      'drawer-panel-toggle',
      this.handleDrawerPanelExpandChanges
    );

    this.removeEventListener(
      'drawer-panel-open',
      this.handleDrawerPanelOpenChanges
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleDrawerPanelExpandChanges(event: DrawerPanelToggleEvent) {
    // console.log('handleDrawerPanelChanges', event.detail.expanded);
    this.expanded = event.detail.expanded;
  }

  // eslint-disable-next-line class-methods-use-this
  handleDrawerPanelOpenChanges(event: DrawerPanelOpenEvent) {
    // console.log('DrawerPanelOpenEvent', event.detail.open);
    this.open = event.detail.open;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-drawer-panel-wrapper': DrawerPanelWrapperWC;
  }
  enum PSElementTagNameMap {
    'ps-drawer-panel-wrapper' = 'ps-drawer-panel-wrapper',
  }
}
