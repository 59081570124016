import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import styles from './block-list.scss?inline';

@customElement('ps-block-list')
export class BlockListWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * Defines the space between items
   */
  @property({ reflect: true }) gutter:
    | 'none'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge' = 'medium';

  render() {
    return html`
      <div
        class=${classMap({
          'c-block-list': true,
          [`c-block-list--gap-${this.gutter}`]: this.gutter,
        })}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-block-list': BlockListWC;
  }
  enum PSElementTagNameMap {
    'ps-block-list' = 'ps-block-list',
  }
}
