import { html, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { customElement } from '../../base-element';
import { PopoverBaseWC } from '../../popover';
import styles from './dropdown.scss?inline';

/**
 * A basic Dropdown component
 *
 * @element ps-dropdown
 *
 * @slot - This is an unnamed (default) slot
 * @slot dropdown-top - content that appears above the default slot
 * @slot dropdown-bottom - content that appears below the default slot
 */
@customElement('ps-dropdown')
export class DropdownWC extends PopoverBaseWC {
  static styles = unsafeCSS(styles);

  @query('.c-dropdown') dropdown: HTMLElement;

  @property({ reflect: true }) 'min-width': 'auto' | 'none' = 'auto';

  /** The distance in pixels from which to offset the panel away from its anchor. */
  @property({ type: Number }) distance = 8;

  render() {
    return html`
      <div
        id="popover-container"
        part="dropdown"
        class=${classMap({
          'c-dropdown': true,
          'c-dropdown--active': this.active,
        })}
        style="transform: ${this.transform}"
        tabindex="0"
      >
        <slot name="dropdown-top"></slot>
        <slot></slot>
        <slot name="dropdown-bottom"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-dropdown': DropdownWC;
  }
  enum PSElementTagNameMap {
    'ps-dropdown' = 'ps-dropdown',
  }
}
