import * as React from 'react';
import { createComponent } from '@lit/react';
import { BackgroundWC } from './background.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type BackgroundProps = GenerateComponentTypeWithChildren<BackgroundWC>;

export type BackgroundPropsWithRef = GeneratePropsWithRef<BackgroundProps>;

export const Background = createComponent({
  tagName: BackgroundWC.tagname,
  elementClass: BackgroundWC,
  react: React,
}) as BackgroundPropsWithRef;
