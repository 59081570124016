import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../../../components/base-element';
import styles from './graph-node.scss?inline';

@customElement('ps-graph-node')
export class GraphNodeWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property() variant: 'default' | 'flush' = 'default';

  @property() status: 'valid' | 'warning' | 'error' = 'valid';

  @property() label = '';

  @property() caption = '';

  @property({ type: String }) nodeId = `42`;

  render() {
    const classes = classMap({
      'c-graph-node': true,
      [`c-graph-node--variant-${this.variant}`]: this.variant,
      [`c-graph-node--error`]: this.status === 'error',
      [`c-graph-node--warning`]: this.status === 'warning',
    });

    const statusClasses = classMap({
      'c-graph-node__status': true,
      'c-graph-node__status-pill': true,
    });

    const isDefault = this.variant === 'default';

    const label = isDefault
      ? html` <ps-text-subtitle truncate>${this.label}</ps-text-subtitle> `
      : html` <ps-text-body truncate> ${this.label} </ps-text-body> `;

    const caption = isDefault
      ? html` <ps-text-body truncate>${this.caption}</ps-text-body> `
      : html` <ps-text-caption>${this.caption}</ps-text-caption> `;

    return html`
      <div class=${classes}>
        <div class=${statusClasses}></div>

        <div class="c-graph-node__header">
          <div class="c-graph-node__label">${label}</div>
          <div class="c-graph-node__id">
            <ps-text-caption variant="tertiary">${this.nodeId}</ps-text-caption>
          </div>
        </div>

        ${caption}

        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-graph-node': GraphNodeWC;
  }
}
