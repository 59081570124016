import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import styles from './wrapper.scss?inline';

@customElement('ps-wrapper')
export class WrapperWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property({ reflect: true }) width: 'xlarge' | 'medium' | 'large' = 'medium';

  render() {
    return html`
      <div
        class="${classMap({
          'c-wrapper': true,
          [`c-wrapper--${this.width}`]: this.width,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-wrapper': WrapperWC;
  }
  enum PSElementTagNameMap {
    'ps-wrapper' = 'ps-wrapper',
  }
}
