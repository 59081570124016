import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextSubtitleWC } from './text-subtitle.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TextSubtitleProps =
  GenerateComponentTypeWithChildren<TextSubtitleWC> & TranslateInterface;

export type TextSubtitlePropsWithRef = GeneratePropsWithRef<TextSubtitleProps>;

export const TextSubtitle = createComponent({
  tagName: TextSubtitleWC.tagname,
  elementClass: TextSubtitleWC,
  react: React,
}) as TextSubtitlePropsWithRef;
