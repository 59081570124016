/* eslint-disable no-prototype-builtins */
import { useCallback, useMemo, useState } from 'react';
import { MicroAppItemProps } from '../components/microapp-item/microapp-item';
import {
  sortDataAlphabeticallyByTitleField,
  sortDataByDeployingStatus,
  sortDataByUpdatedField,
} from '../components/microapps-summary-table/helpers';

export interface SortingDetails {
  sorted: boolean;
  reverse: boolean;
}

export type SortingType = 'alphabetically' | 'status' | 'lastDate';

interface SortingState {
  alphabetically: SortingDetails;
  status: SortingDetails;
  lastDate: SortingDetails;
}

export interface SortingHandlerProps extends Pick<SortingDetails, 'reverse'> {
  type: SortingType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMicroAppItemType = (data: any[]): data is MicroAppItemProps[] => {
  const hasTitleField = data[0]?.hasOwnProperty('title');
  const hasStatusField = data[0]?.hasOwnProperty('status');
  const hasUpdatedField =
    data[0]?.hasOwnProperty('updated') && data[0] !== null;

  return hasTitleField && hasStatusField && hasUpdatedField;
};

export const useSortableData = <T>(data: T[]) => {
  const initialState = useMemo(
    () => ({
      alphabetically: {
        reverse: false,
        sorted: false,
      },
      status: {
        reverse: false,
        sorted: false,
      },
      lastDate: {
        reverse: false,
        sorted: false,
      },
    }),
    []
  );

  const [sortingState, setSortingState] = useState<SortingState>(initialState);

  const onSort = useCallback(
    ({ reverse, type }: SortingHandlerProps) => {
      setSortingState({
        ...initialState,
        [type]: {
          sorted: true,
          reverse,
        },
      });
    },
    [initialState]
  );

  const sortingDetails = {
    alphabetically: {
      reverse: sortingState.alphabetically.reverse,
      sorted:
        sortingState.alphabetically.sorted ||
        sortingState.alphabetically.reverse,
    },
    status: {
      reverse: sortingState.status.reverse,
      sorted: sortingState.status.sorted || sortingState.status.reverse,
    },
    lastDate: {
      reverse: sortingState.lastDate.reverse,
      sorted: sortingState.lastDate.sorted || sortingState.lastDate.reverse,
    },
  };

  const sortingFunction = <M extends typeof data>(microapps: M) => {
    if (isMicroAppItemType(microapps) && sortingDetails.alphabetically.sorted) {
      return sortDataAlphabeticallyByTitleField(
        microapps,
        sortingDetails.alphabetically.reverse
      );
    }

    if (isMicroAppItemType(microapps) && sortingDetails.status.sorted) {
      return sortDataByDeployingStatus(
        microapps,
        sortingDetails.status.reverse
      );
    }

    if (isMicroAppItemType(microapps) && sortingDetails.lastDate.sorted) {
      return sortDataByUpdatedField(microapps, sortingDetails.lastDate.reverse);
    }

    return microapps;
  };

  return {
    sortingDetails,
    sortedData: sortingFunction<typeof data>(data),
    onSort,
  };
};
