import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ApolloGqlProvider,
  ApolloLink,
  HttpLink,
  from,
  split,
  Operation,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';

// @TODO: update to include user-specific UUID header for GQL permission check on incoming requests
function getHeaders(): Record<string, string> {
  const headers = {
    'x-hasura-admin-secret': import.meta.env.FE_GQL_ADMIN_SECRET,
  };
  return headers;
}

// helper function to allow us to test if an incoming request is a subscription vs query / mutation
// check out this [blog post](https://www.apollographql.com/blog/backend/federation/using-subscriptions-with-your-federated-data-graph/#subscription-service-for-a-live-blog) and search for "getMainDefinition" for more info
function isRequestASubscription(op: Operation): boolean {
  const definition = getMainDefinition(op.query);
  const isSubscription =
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription';
  return isSubscription;
}

// https://www.apollographql.com/docs/react/api/link/introduction/
const getLinks = (): ApolloLink => {
  const retryLink = new RetryLink();
  const httpLink = new HttpLink({
    uri: import.meta.env.FE_GQL_ENDPOINT,
  });

  const wsLink = new WebSocketLink({
    uri: import.meta.env.FE_GQL_WSS_ENDPOINT || '',
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        const headers = getHeaders();
        return { headers };
      },
    },
  });

  return from([
    retryLink,
    setContext(async (operation, prevContext) => {
      const headers = getHeaders();
      const context = {
        ...prevContext,
        headers,
      };
      return context;
    }),
    split(
      // separately handle web sockets from the rest of the gql traffic
      isRequestASubscription,
      // use a web socket for gql subscriptions
      wsLink,
      // otherwise fall back to an https connection
      httpLink
    ),
  ]);
};

export const client = new ApolloClient({
  // check out https://www.apollographql.com/docs/react/caching/cache-configuration/ for full docs on configuring the Apollo Client cache
  cache: new InMemoryCache(),
  link: getLinks(),
});

export const ApolloProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => <ApolloGqlProvider client={client}>{children}</ApolloGqlProvider>;
