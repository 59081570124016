import { Apps_Version, GetMicroAppsQuery } from '@pypestream/api-services';
import {
  Button,
  Icon,
  Menu,
  MenuHeader,
  MenuItem,
  Dropdown,
  Divider,
  Spacer,
} from '@pypestream/design-system';
import { formatRelative, getDateOrNull } from '@pypestream/utils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  sendBuilderEvent,
  useMicroappsCtxSelector,
} from '../../../xstate/app.xstate';
import './version-dropdown.scss';

type MicroApp = NonNullable<GetMicroAppsQuery['apps_app']>[0] | undefined;

export const VersionDropdown = () => {
  const { microAppDetails, selectedAppId, selectedVersionId } =
    useMicroappsCtxSelector((ctx) => {
      const selectedMicroApp: MicroApp = ctx.data?.find(
        (app) => app.app_id === ctx.selectedAppId
      );

      return {
        selectedAppId: ctx.selectedAppId,
        selectedVersionId: ctx.selectedVersionId,
        microAppDetails: selectedMicroApp,
      };
    });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleDropdownItemClick = (version: Apps_Version) => {
    if (selectedVersionId === version.version_id) {
      return;
    }
    navigate(`/${selectedAppId}/${version.version_id}`);
    sendBuilderEvent('builder.resetToIdle');
  };

  const selectedVersion = microAppDetails?.versions?.find(
    (version) => version.version_id === selectedVersionId
  );

  const top3Versions = microAppDetails?.versions
    ?.slice(-3)
    .map((version) => version.version_id);

  const selectedVersionIsInTop3Versions = top3Versions?.includes(
    selectedVersion?.version_id
  );

  if (!selectedVersionIsInTop3Versions) {
    top3Versions?.push(selectedVersion?.version_id);
    top3Versions?.shift();
  }

  const microAppVersionsDropdown = top3Versions?.map((version) => {
    return (
      <MenuItem
        onClick={() => {
          setIsMenuOpen(false);
          const versionObj = microAppDetails?.versions.find(
            (versionObjFind) => {
              return versionObjFind.version_id === version;
            }
          );
          handleDropdownItemClick(versionObj as Apps_Version);
        }}
        key={version}
        className="c-menu-item"
        selected={selectedVersion?.version_id === version}
      >
        {
          microAppDetails?.versions.find(
            (versionDisplay) => versionDisplay.version_id === version
          )?.version_name
        }

        <div slot="suffix">
          {getDateOrNull(
            microAppDetails?.versions.find(
              (versionObj) => versionObj.version_id == version
            )?.readonly_at
          )
            ? formatRelative(
                new Date(
                  microAppDetails?.versions.find(
                    (versionObj) => versionObj.version_id == version
                  )?.readonly_at
                ),
                new Date()
              )
            : 'Draft'}
        </div>
      </MenuItem>
    );
  });

  return (
    <div>
      {microAppDetails && (
        <>
          <Button variant="ghost" id="popover-trigger">
            {microAppDetails?.app_name}
            <Icon slot="suffix" name="chevron-down"></Icon>
          </Button>
          <Dropdown trigger="popover-trigger" placement="bottom">
            <Menu>
              <MenuHeader>Recent Versions</MenuHeader>
              {microAppVersionsDropdown}
              <Spacer size="small"></Spacer>
              <Divider></Divider>
              <Spacer size="medium"></Spacer>
              <MenuItem value="option-settings" disabled>
                <Icon name="settings" slot="prefix"></Icon>
                App Settings
              </MenuItem>
            </Menu>
          </Dropdown>
        </>
      )}
    </div>
  );
};
