/* eslint-disable wc/guard-super-call */
import React, { createElement } from 'react';
import { unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { createRoot, Root } from 'react-dom/client';
import { type Moment } from 'moment/moment';
import styles from './range-datepicker.scss?inline';
import { BaseElement, customElement } from '../../base-element';
import {
  RangeDatepicker,
  RangeDatepickerPresetProps,
} from './range-datepicker';

@customElement('ps-range-datepicker')
export class RangeDatepickerWC extends BaseElement {
  static styles = unsafeCSS(styles);

  root: Root;

  @property({ type: Array })
  presets: RangeDatepickerPresetProps[];

  @property({ type: Object }) startDate?: Moment | null;

  @property({ type: Object }) endDate?: Moment | null;

  @property({ type: Boolean, attribute: 'omit-default-presets' })
  omitDefaultPresets = false;

  connectedCallback() {
    super.connectedCallback();
    if (!this.root) {
      this.root = createRoot(this.renderRoot);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // this.root.unmount();
  }

  render() {
    this.root.render(
      createElement(RangeDatepicker, {
        presets: this.presets,
        omitDefaultPresets: this.omitDefaultPresets,
        startDate: this.startDate,
        endDate: this.endDate,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-range-datepicker': RangeDatepickerWC;
  }
  enum PSElementTagNameMap {
    'ps-range-datepicker' = 'ps-range-datepicker',
  }
}
