import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './grid.scss?inline';

@customElement('ps-grid')
export class GridWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * Defines the space between cells.
   */
  @property() gutter: 'none' | 'small' | 'medium' | 'large' | 'xlarge' =
    'medium';

  /**
   * Defines the space between rows.
   */
  @property() rowGutter: 'none' | 'small' | 'medium' | 'large' = 'medium';

  /**
   * Defines the 'align-items' style property
   */
  @property({ reflect: true }) alignItems:
    | 'start'
    | 'center'
    | 'end'
    | 'stretch' = 'start';

  /**
   * Defines the 'justify-content' style property
   */
  @property({ reflect: true }) justifyContent: 'start' | 'center' | 'end' =
    'start';

  render() {
    const classes = classMap({
      'c-grid': true,
      [`c-grid--gap-${this.gutter}`]: this.gutter,
      [`c-grid--row-gap-${this.rowGutter}`]: this.rowGutter,
      [`c-grid--align-${this.alignItems}`]: this.alignItems,
      [`c-grid--justify-${this.justifyContent}`]: this.justifyContent,
    });

    return html`
      <div data-cy="grid" class=${classes}>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-grid': GridWC;
  }
  enum PSElementTagNameMap {
    'ps-grid' = 'ps-grid',
  }
}
