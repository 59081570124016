/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Page, TextTitle } from '@pypestream/design-system';
import { FC, useEffect } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from 'react-error-boundary';
import {
  useBuilderCtxSelector,
  useMicroappsCtxSelector,
} from '../../xstate/app.xstate';
import { datadogLogs } from '@datadog/browser-logs';
import { ComplexState, useCurrentState } from '../../hooks/use-current-state';
import { formatErrorMessage, logError } from './helpers';
import { useErrorTag, useResetAppContextAndGoToHome } from './hooks';
import { ErrorPageProps, ChildrenProps } from './types';

export const FormattedErrorMessage: FC<{ message: string }> = ({ message }) => {
  const { msg, jsonMsg, query } = formatErrorMessage(message);

  const jsonMessage = jsonMsg ? (
    <>
      <br />
      <br />
      {jsonMsg}
    </>
  ) : null;

  const gqlQuery = query ? (
    <>
      <br />
      <br />
      {query}
    </>
  ) : null;

  return (
    <pre
      style={{
        color: 'white',
        padding: '20px',
        background: 'black',
        whiteSpace: 'pre-wrap',
      }}
    >
      {msg}
      {jsonMessage}
      {gqlQuery}
    </pre>
  );
};

const ErrorPage: FC<ErrorPageProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Page>
      <div
        role="alert"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '1rem',
        }}
      >
        <TextTitle size="large" i18nKey="studio/common:error.title">
          Something went wrong:
        </TextTitle>
        <FormattedErrorMessage message={error.message} />
        <Button
          i18nKey="studio/common:error.button"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
      </div>
    </Page>
  );
};

const UncaughtErrorBoundary: FC<ChildrenProps> = ({ children }) => {
  const { isLocalError } = useErrorTag();
  const microappsMachineErrors = useMicroappsCtxSelector((ctx) => ctx.errors);
  const builderMachineErrors = useBuilderCtxSelector((ctx) => ctx.errors);
  const errors = !isLocalError
    ? microappsMachineErrors || builderMachineErrors
    : null;
  const { showBoundary } = useErrorBoundary();

  const handler = (e: ErrorEvent) => showBoundary(e);

  const removeErrorListener = () => {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('error', handler);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (isLocalError) {
      removeErrorListener();

      return;
    }

    window.addEventListener('error', handler);

    return () => {
      removeErrorListener();
    };
  }, [isLocalError]);

  useEffect(() => {
    if (!errors) {
      return;
    }

    showBoundary(errors);
  }, [errors]);

  return <>{children}</>;
};

export const GlobalErrorBoundary: FC<ChildrenProps> = ({ children }) => {
  const resetApp = useResetAppContextAndGoToHome();
  const currentState = useCurrentState();

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(e, i) => logError(e, i, currentState)}
      onReset={resetApp}
    >
      <UncaughtErrorBoundary>{children}</UncaughtErrorBoundary>
    </ReactErrorBoundary>
  );
};
