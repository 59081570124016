import {
  Table as TableDS,
  TableHead,
  TableRow,
  TableBody,
} from '@pypestream/design-system';
import { FC } from 'react';

interface TableProps {
  headings: JSX.Element[];
  rows: JSX.Element[];
}

export const Table: FC<TableProps> = ({ headings, rows }) => {
  return (
    <TableDS>
      <TableHead>
        <TableRow>{headings}</TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </TableDS>
  );
};
