import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../../../components/base-element';
import styles from './status-card.scss?inline';

@customElement('ps-status-card')
export class StatusCardWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property() status: 'info' | 'warning' | 'error' = 'info';

  @property() eyebrow = '';

  @property() label = '';

  @property() body = '';

  @property() footer = '';

  render() {
    const classes = classMap({
      'c-status-card': true,
      [`c-status-card--status-${this.status}`]: this.status,
    });

    return html`
      <div class=${classes}>
        <div class="c-status-card__row">
          <div class="c-status-card__content">
            ${this.eyebrow
              ? html`<ps-text-overline variant="tertiary"
                  >${this.eyebrow}</ps-text-overline
                >`
              : null}
          </div>
          ${this.slotted('actions')
            ? html`
                <div class="c-status-card__actions">
                  <slot name="actions"></slot>
                </div>
              `
            : null}
        </div>
        ${this.label
          ? html`<ps-text-subtitle size="small" truncate
              >${this.label}</ps-text-subtitle
            >`
          : null}
        ${this.body
          ? html`<div class="c-status-card__body">${this.body}</div>`
          : null}
        ${this.footer
          ? html`
              <div class="c-status-card__footer">
                <ps-text-caption variant="tertiary"
                  >${this.footer}</ps-text-caption
                >
              </div>
            `
          : null}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-status-card': StatusCardWC;
  }
}
