import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Checkbox } from '../../../checkbox';

export const CheckboxWidget = (props: WidgetProps) => {
  const { label, value, onChange, disabled } = props;

  return (
    <Checkbox
      defaultChecked={value}
      onChange={() => onChange(!value)}
      disabled={disabled}
    >
      {label}
    </Checkbox>
  );
};
