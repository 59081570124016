import { ReactiveController, ReactiveControllerHost } from 'lit';
import * as focusTrap from 'focus-trap';

export class FocusTrapController implements ReactiveController {
  private host: ReactiveControllerHost;

  private trap: focusTrap.FocusTrap;

  returnFocusOnDeactivate? = true;

  constructor(host: ReactiveControllerHost, returnFocusOnDeactivate = true) {
    this.returnFocusOnDeactivate = returnFocusOnDeactivate;
    (this.host = host).addController(this);
  }

  hostConnected() {
    const { returnFocusOnDeactivate } = this;
    // Typings here for this.host are not great but wasn't sure how to handle -- kept throwing errors
    this.trap = focusTrap.createFocusTrap(this.host as unknown as HTMLElement, {
      clickOutsideDeactivates: true,
      escapeDeactivates: true,
      setReturnFocus(nodeFocusedBeforeActivation) {
        // if returnFocusOnDeactivate isn't disabled, return back the original element
        if (returnFocusOnDeactivate) {
          return nodeFocusedBeforeActivation;
        }

        return false;
      },
      checkCanReturnFocus: () =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500);
        }),

      // initialFocus: this.host as unknown as HTMLElement,
      fallbackFocus: this.host as unknown as HTMLElement,
      tabbableOptions: {
        getShadowRoot: true,
      },
    });
  }

  hostDisconnected() {
    this.trap.deactivate();
  }

  /**
   * Activates the focus trap.
   */
  activate() {
    this.trap.activate();
  }

  /**
   * Deactivate the focus trap.
   */
  deactivate() {
    this.trap.deactivate();
  }
}
