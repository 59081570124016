import { html, unsafeCSS, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement, PSCustomEvent } from '../base-element';
import styles from './portal-destination.scss?inline';

export type UpdatePortalContentEvent = PSCustomEvent<
  // eslint-disable-next-line no-use-before-define
  PortalDestinationWC,
  {
    destination: string;
    content: Node | string | Node[];
  }
>;

function instanceOfNodes(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): data is Node[] {
  return Object.prototype.hasOwnProperty.call(data, 'length');
}

@customElement('ps-portal-destination')
export class PortalDestinationWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property() name: string;

  @property({ attribute: false }) projectedEl: HTMLElement[];

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    document.addEventListener(
      'update-portal-content',
      this.updatePortalContent,
      true
    );
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    document.removeEventListener(
      'update-portal-content',
      this.updatePortalContent,
      true
    );
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    this.emit('portal-destination-ready', {
      detail: {
        name: this.name,
      },
      // eslint-disable-next-line no-use-before-define
    }) as PortalReadyEvent;
  }

  createRenderRoot() {
    return this;
  }

  confirmDestination(e: UpdatePortalContentEvent) {
    // eslint-disable-next-line no-console
    if (!this.name) console.warn('This destination has not been named.');
    if (e.detail.destination !== this.name) return false;
    e.stopPropagation();
    return true;
  }

  updatePortalContent = (e: UpdatePortalContentEvent) => {
    if (!this.confirmDestination(e)) return;
    const slotElement = this.renderRoot?.querySelector(
      'slot[name="portal-destination"]'
    );
    if (slotElement) {
      if (instanceOfNodes(e.detail.content)) {
        slotElement.replaceChildren(...e.detail.content);
      } else {
        // slotElement.replaceChildren(e.detail.content);
        slotElement.replaceChildren(e.detail.content);
      }
    }
  };

  render() {
    return html`<slot name="portal-destination"></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-portal-destination': PortalDestinationWC;
  }
  enum PSElementTagNameMap {
    'ps-portal-destination' = 'ps-portal-destination',
  }

  interface GlobalEventHandlersEventMap {
    'update-portal-content': UpdatePortalContentEvent;
  }
}

export type PortalReadyEvent = PSCustomEvent<
  PortalDestinationWC,
  {
    name: string;
  }
>;

declare global {
  interface GlobalEventHandlersEventMap {
    'portal-destination-ready': PortalReadyEvent;
  }
}
