import * as React from 'react';
import { createComponent } from '@lit/react';
import { ModalWC } from './modal.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type ModalProps = GenerateComponentTypeWithChildren<
  Omit<ModalWC, 'close' | 'onClose'>
> & {
  // A function called when the Modal is about to close
  onClose?: (event: CustomEvent) => void;
};

export type ModalPropsWithRef = GeneratePropsWithRef<ModalProps>;

export const Modal = createComponent({
  tagName: ModalWC.tagname,
  elementClass: ModalWC,
  react: React,
  events: {
    onClose: 'close',
  },
}) as ModalPropsWithRef;
