import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../../base-element';
import '../../divider/divider.wc';
import styles from './menu-divider.scss?inline';

@customElement('ps-menu-divider')
export class MenuDividerWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html` <ps-divider data-cy="menu-divider"></ps-divider> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-menu-divider': MenuDividerWC;
  }
  enum PSElementTagNameMap {
    'ps-menu-divider' = 'ps-menu-divider',
  }
}
