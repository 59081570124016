import * as React from 'react';
import { createComponent } from '@lit/react';
import { MegaMenuWC } from './mega-menu.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../components/component-types-generics';

export type MegaMenuProps = GenerateComponentTypeWithoutChildren<MegaMenuWC>;

export type MegaMenuPropsWithRef = GeneratePropsWithRef<MegaMenuProps>;

export const MegaMenu = createComponent({
  tagName: MegaMenuWC.tagname,
  elementClass: MegaMenuWC,
  react: React,
}) as MegaMenuPropsWithRef;
