import { ToastContainer, Page } from '@pypestream/design-system';
import { Homepage, ManageLandingPage, Build } from './pages';
import { PrimaryNav, AppModal, GlobalErrorBoundary } from './components';
import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { syncXstateWithRouteParams } from './utils/sync-xstate-with-route-params';
import { routes } from './components/primary-nav/primary-nav';

import './index.scss';

const Root = () => {
  return (
    <GlobalErrorBoundary>
      <Page>
        <PrimaryNav />
        <Outlet />
        <AppModal />
        <ToastContainer />
      </Page>
    </GlobalErrorBoundary>
  );
};

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      path={routes.home}
      element={<Root />}
      loader={({ params, request }) => {
        const url = new URL(request.url);
        const searchQuery = url.searchParams;
        syncXstateWithRouteParams({
          params,
          searchQuery,
        });
        return null;
      }}
      shouldRevalidate={() => {
        return true;
      }}
    >
      <Route index element={<Homepage />} />
      <Route path="*" element={<Homepage />} />
      <Route path={':app_id'} element={<ManageLandingPage />} />
      <Route path={':app_id/settings'} element={<ManageLandingPage />} />
      <Route
        path={':app_id/settings/environment'}
        element={<ManageLandingPage />}
      />
      <Route path={':app_id/:version_id'} element={<Build />} />
      <Route path={':app_id/:version_id/preview'} element={<Build />} />
    </Route>
  )
);

export const RouterProvider = () => <ReactRouterProvider router={router} />;
