import Form, { FormProps } from '@rjsf/core';
import { IconButtonProps } from '@rjsf/utils';
import React from 'react';
import { Button } from '../buttons';
import { Icon } from '../icon';
import { templates as defaultTemplates } from './src/templates';
import { widgets as defaultWidgets } from './src/widgets';

export type { ErrorListProps, RJSFValidationError } from '@rjsf/utils';
export type JSONSchemaFormProps = FormProps & {
  ref?: FormProps['ref'];
};

function AddButton(props: IconButtonProps) {
  const { icon, iconType, onClick, disabled, ...btnProps } = props;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      size="medium"
      width="full"
      className="u-margin-top-medium"
    >
      Add Element
      <Icon name="plus" size="small" slot="suffix" />
    </Button>
  );
}

function RemoveButton(props: IconButtonProps) {
  const { icon, iconType, onClick, disabled, ...btnProps } = props;

  return (
    <Button onClick={onClick} disabled={disabled} size="small">
      <Icon name="delete" size="small" />
    </Button>
  );
}

function MoveUpButton(props: IconButtonProps) {
  const { icon, iconType, onClick, disabled, ...btnProps } = props;

  return (
    <Button onClick={onClick} disabled={disabled} size="small">
      <Icon name="arrow-up" size="small" />
    </Button>
  );
}

function MoveDownButton(props: IconButtonProps) {
  const { icon, iconType, onClick, disabled, ...btnProps } = props;

  return (
    <Button onClick={onClick} disabled={disabled} size="small">
      <Icon name="arrow-down" size="small" />
    </Button>
  );
}

export const JSONSchemaForm = React.forwardRef<
  FormProps['ref'],
  JSONSchemaFormProps
>(
  (
    {
      schema,
      uiSchema,
      validator,
      onChange,
      onSubmit,
      onError,
      templates,
      widgets,
      ...otherProps
    },
    ref
  ) => {
    let customTemplates: FormProps['templates'] = templates;
    let buttonTemplates;
    if (templates?.ButtonTemplates) {
      const { ButtonTemplates, ...restCustomTemplates } = templates;
      customTemplates = restCustomTemplates;
      buttonTemplates = templates?.ButtonTemplates;
    }
    return (
      <Form
        {...otherProps}
        schema={schema}
        uiSchema={uiSchema}
        validator={validator}
        onChange={onChange}
        onSubmit={onSubmit}
        onError={onError}
        templates={{
          ButtonTemplates: {
            AddButton,
            RemoveButton,
            MoveDownButton,
            MoveUpButton,
            ...buttonTemplates,
          },
          ...defaultTemplates,
          ...customTemplates,
        }}
        widgets={{
          ...defaultWidgets,
          ...widgets,
        }}
        ref={ref as FormProps['ref']}
      />
    );
  }
);

export const DefaultSlot = () => <slot></slot>;

export default JSONSchemaForm;
