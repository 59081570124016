import {
  Grid,
  GridCell,
  Spacer,
  Stack,
  TextTitle,
  PageBody,
  Button,
  Icon,
  IconButton,
} from '@pypestream/design-system';
import { useNavigate } from 'react-router-dom';
import {
  PageHeader,
  AppDetailsTable,
  LocalErrorBoundary,
} from '../../components';
import { DeploymentCard } from '../../components/cards';
import {
  sendMicroAppsEvent,
  useMicroappsCtxSelector,
} from '../../xstate/app.xstate';
import { getAppDeployments } from './get-app-deployments';

export const ManageLandingPage = () => {
  const navigate = useNavigate();
  const {
    microAppDetails,
    selectedAppId,
    selectedVersionId,
    draftVersionId,
    error,
    environments,
  } = useMicroappsCtxSelector((ctx) => ({
    microAppDetails: ctx.data,
    selectedAppId: ctx.selectedAppId,
    selectedVersionId: ctx.selectedVersionId,
    draftVersionId: ctx.draftVersionId,
    error: ctx.errors,
    environments: ctx.environments,
  }));

  const versionIdToOpen = selectedVersionId || draftVersionId;
  const latestVersions: Record<string, string> = {};

  const selectedMicroApp = microAppDetails?.find(
    (app) => app.app_id === selectedAppId
  );

  const latestProdVersion = latestVersions.prod;
  const appVersions = selectedMicroApp?.versions || [];
  const appsDeployment = getAppDeployments(
    appVersions,
    selectedAppId || undefined
  );

  return (
    <>
      <PageHeader>
        {!microAppDetails ? (
          <TextTitle i18nKey="studio/common:loading">Loading...</TextTitle>
        ) : (
          <>
            <Stack
              alignItems="center"
              gutter="xsmall"
              justifyContent="space-between"
              display="flex"
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"
                display="flex"
                gutter="small"
              >
                <TextTitle textAlign="center">
                  {selectedMicroApp?.app_name}
                </TextTitle>
                <IconButton name="settings" disabled />
              </Stack>
              {/* which app version should this point to? */}

              <Button
                onClick={() => navigate(`/${selectedAppId}/${versionIdToOpen}`)}
                disabled={!versionIdToOpen}
              >
                <Icon slot="prefix" size="xsmall" name="edit"></Icon>
                Edit App
              </Button>
            </Stack>
            <Spacer size="medium" />
            <Grid>
              {appsDeployment?.map((deploymentDetails) => {
                const isLatest = Boolean(
                  deploymentDetails.environment?.environment_name &&
                    deploymentDetails.version?.version_name ===
                      latestVersions[
                        deploymentDetails.environment?.environment_name
                      ]
                );

                return (
                  <GridCell
                    xsmall="12"
                    medium="6"
                    key={deploymentDetails.deployment_id}
                  >
                    <DeploymentCard
                      deploymentDetails={deploymentDetails}
                      isLatest={isLatest}
                    />
                  </GridCell>
                );
              })}
            </Grid>
          </>
        )}
      </PageHeader>

      <PageBody>
        <LocalErrorBoundary
          onReset={() => sendMicroAppsEvent('microApps.refetch')}
          error={error}
        >
          <TextTitle size="small" i18nKey="studio/manage:activity">
            Recent Activity
          </TextTitle>
          <AppDetailsTable
            microAppDetails={selectedMicroApp}
            selectedAppId={selectedAppId}
            environments={environments}
          />
        </LocalErrorBoundary>
      </PageBody>
    </>
  );
};
