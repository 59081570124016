import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import styles from './callout.scss?inline';

@customElement('ps-callout')
export class CalloutWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * Component size.
   */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  /* Theme */
  @property({ reflect: true }) theme: 'dark' | 'light' = 'dark';

  render() {
    return html`
      <div
        class="${classMap({
          'c-callout': true,
          [`c-callout--size-${this.size}`]: this.size,
          [`c-callout--theme-${this.theme}`]: this.theme,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-callout': CalloutWC;
  }
  enum PSElementTagNameMap {
    'ps-callout' = 'ps-callout',
  }
}
