import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { customElement } from '../../base-element';
import { AvatarWC } from '../../avatar';

@customElement('ps-menu-avatar')
export class MenuAvatarWC extends AvatarWC {
  @property({ attribute: false }) label = '';

  constructor() {
    super();
    this.size = 'small';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-menu-avatar': MenuAvatarWC;
  }
  enum PSElementTagNameMap {
    'ps-menu-avatar' = 'ps-menu-avatar',
  }
}
