import * as React from 'react';
import { createComponent } from '@lit/react';
import { CalloutDescriptionWC } from './callout-description.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type CalloutDescriptionProps =
  GenerateComponentTypeWithChildren<CalloutDescriptionWC>;

export type CalloutDescriptionPropsWithRef =
  GeneratePropsWithRef<CalloutDescriptionProps>;

export const CalloutDescription = createComponent({
  tagName: CalloutDescriptionWC.tagname,
  elementClass: CalloutDescriptionWC,
  react: React,
}) as CalloutDescriptionPropsWithRef;
