import { unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import styles from './tab-button.scss?inline';

import '../../icon/icon.wc';

@customElement('ps-tab-button')
export class TabButtonWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property({ reflect: true }) variant: 'dev' | 'staging' | 'prod' = 'dev';

  @property({ reflect: true, type: Boolean }) disabled = false;

  @property({ reflect: true, type: Boolean }) selected = false;

  render() {
    return html`
      <button
        class=${classMap({
          'c-tab-btn': true,
          [`c-tab-btn--${this.variant}`]: this.variant,
          'c-tab-btn--selected': this.selected,
        })}
        ?disabled=${this.disabled}
      >
        <span class="c-tab-btn__icon">
          <ps-icon name="cloud" size="xsmall"></ps-icon>
        </span>
        <span class="c-tab-btn__label">
          <slot></slot>
        </span>
      </button>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tab-button': TabButtonWC;
  }
  enum PSElementTagNameMap {
    'ps-tab-button' = 'ps-tab-button',
  }
}
