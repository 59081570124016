import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextTitleWC } from './text-title.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TextTitleProps = GenerateComponentTypeWithChildren<TextTitleWC> &
  TranslateInterface & {
    slot?: string;
    title?: string;
  };

export type TextTitlePropsWithRef = GeneratePropsWithRef<TextTitleProps>;

export const TextTitle = createComponent({
  tagName: TextTitleWC.tagname,
  elementClass: TextTitleWC,
  react: React,
}) as TextTitlePropsWithRef;
