/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { Machine, interpret, assign, Sender } from 'xstate';
import { inspect } from '@xstate/inspect';
import { sort } from '@pypestream/utils';
import { UserStatus } from '@pypestream/api-services/urql';
import { compact, uniq } from 'lodash';
import { initialize } from 'launchdarkly-js-client-sdk';
import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../../feature-flags/feature-flags.types';
import {
  DefaultFeatureFlags,
  WindowWithPypestreamGlobals,
} from '../../feature-flags/feature-flags.default';

import {
  SmartContext,
  SmartState,
  SmartEvents,
  SmartTypestates,
  Language,
  Country,
  Timezone,
} from './smart.xstate-utils';

import { hasErrors } from '../utils';

const win = window as WindowWithPypestreamGlobals;

const isValidFlag = (flagKey: any): boolean => {
  const isValid = flagKey in DefaultFeatureFlags;

  if (!isValid) {
    // @todo - Need to log this with datadog once available.
    // Also, do we need to log app version too?
    console.log('Unhandled feature flag :- ', flagKey);
  }
  return isValid;
};

// @todo: update to support overrides (for debugging) via external super admin controls
const enableStateInspector = false;
if (enableStateInspector) {
  inspect({
    url: 'https://stately.ai/viz?inspect=1',
    iframe: false,
  });
}

type UrqlGqlLimitedCandidateModule =
  typeof import('@pypestream/api-services/urql.limited-access.candidate').urqlGqlLimitedCandidate;

type UrqlGqlLimitedProdModule =
  typeof import('@pypestream/api-services/urql.limited-access.prod').urqlGqlLimitedProd;

type UrqlGqlLimitedNonProdModule =
  typeof import('@pypestream/api-services/urql.limited-access.nonprod').urqlGqlLimitedNonProd;

type UrqlGqlLimitedMainModule =
  typeof import('@pypestream/api-services/urql.limited-access.main').urqlGqlLimitedMain;

export type UrqlGqlLimitedClient = Promise<
  | UrqlGqlLimitedCandidateModule
  | UrqlGqlLimitedProdModule
  | UrqlGqlLimitedNonProdModule
  | UrqlGqlLimitedMainModule
>;

const urqlClient = async (): UrqlGqlLimitedClient => {
  let env = '';
  if (window.location.host.endsWith('pypestream.com')) {
    env = 'prod';
  } else if (window.location.host.endsWith('nonprod.pypestream.dev')) {
    env = 'nonprod';
  } else if (env === undefined) {
    env = 'candidate';
  }

  if (env === 'prod') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.prod'
    );
    return module.urqlGqlLimitedProd;
  }
  if (env === 'nonprod' || env === 'uat') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.nonprod'
    );
    return module.urqlGqlLimitedNonProd;
  }
  if (env === 'main') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.main'
    );
    return module.urqlGqlLimitedMain;
  }

  const module = await import(
    '@pypestream/api-services/urql.limited-access.candidate'
  );
  return module.urqlGqlLimitedCandidate;
};

export const smartMachine = Machine<SmartContext, SmartState, SmartEvents>({
  predictableActionArguments: true,
  preserveActionOrder: true,
  id: 'smart-components',
  context: {
    accountId: undefined,
    userInfo: undefined,
    userSettings: {},
    projects: [],
    userProjects: [],
    allProducts: [],
    userProductRoles: {},
    languages: [],
    countries: [],
    timezones: [],
  },
  type: 'parallel',
  invoke: [
    {
      id: 'initializing-feature-flags',
      src: (ctx) => async (sendEvent: Sender<SmartEvents>) => {
        if (Object.keys(DefaultFeatureFlags).length) {
          const client = initialize(
            win.LAUNCH_DARKLY_API_KEY || '',
            {
              kind: 'user',
              anonymous: true,
            },
            {
              // This is to prevent sending many events from app to launchDarkly.
              // @todo - Need to check how elegantly we can handle this as w.r.t. launch darkly docs, this might break some features on LD side. (features like, reports, ld logs and all) --> not good for AB testing.
              flushInterval: 900000,
            }
          );

          client.on('change', (updatedFlags) => {
            Object.keys(updatedFlags).forEach((updatedFlagKey) => {
              if (isValidFlag(updatedFlagKey)) {
                sendEvent({
                  type: 'featureFlags.update',
                  // isValidFlag check is making sure that this typecast is correct
                  // @todo - Ideally we should not need this typecast, need to check for proper fix
                  updatedFlag: updatedFlagKey as AvailableFeatureFlags,
                  updatedValue: updatedFlags[updatedFlagKey].current,
                });
              }
            });
          });

          return client
            .waitForInitialization()
            .then(() => {
              sendEvent({
                type: 'featureFlags.initialized',
                flags: Object.fromEntries(
                  Object.entries(client.allFlags()).filter(([key]) =>
                    isValidFlag(key)
                  )
                ) as FeatureFlagType,
              });
            })
            .catch(() => {
              sendEvent({
                type: 'featureFlags.initializedWithDefaultValues',
              });
              // @todo - Need to log this with datadog once available.
              console.log(
                'Initialized application with build-time/default-flags values.'
              );
            });
        }
        // @todo - Need to log this with datadog
        console.warn(
          "Bypassed LD-initialization as we didn't had FFs build time."
        );
        return Promise.resolve();
      },
    },
  ],
  on: {
    updateUserInfo: {
      actions: assign((_ctx, event) => {
        const { userInfo } = event;

        const newContext: SmartContext = {
          ..._ctx,
          userInfo: {
            ...userInfo,
            status: userInfo.status || UserStatus.Invited,
            email: _ctx.userInfo?.email || '',
            defaultAccount: {
              id: userInfo.accountId,
            },
          },
        };

        return newContext;
      }),
    },
    changeOrg: [
      {
        cond: (ctx, event) => !ctx.userInfo,
        actions: assign((ctx, event) => ({
          ...ctx,
          accountId: event.org,
        })),
        target: 'userInfo.loading',
      },
      {
        cond: (ctx, event) => !!ctx.userInfo,
        actions: assign((ctx, event) => ({
          ...ctx,
          accountId: event.org,
        })),
        target: ['#projects.loading', '#userProductRoles.loading'],
      },
    ],
  },
  states: {
    featureFlags: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            'featureFlags.initialized': {
              target: '#smart-components.featureFlags.loaded.withActualValue',
              actions: [
                assign((ctx, data) => {
                  ctx.featureFlags = data.flags;
                  return ctx;
                }),
              ],
            },
            'featureFlags.initializedWithDefaultValues': {
              target: '#smart-components.featureFlags.loaded.withDefaultValues',
              actions: [
                assign((ctx) => {
                  ctx.featureFlags = DefaultFeatureFlags;
                  return ctx;
                }),
              ],
            },
          },
        },
        loaded: {
          on: {
            'featureFlags.update': {
              actions: assign((ctx, data) => {
                let updatedContext = ctx;
                if (
                  ctx.featureFlags &&
                  data.updatedFlag in DefaultFeatureFlags
                ) {
                  updatedContext = {
                    ...ctx,
                    featureFlags: {
                      ...ctx.featureFlags,
                      [data.updatedFlag]: data.updatedValue,
                    },
                  };
                }
                return updatedContext;
              }),
            },
          },
          states: {
            withActualValue: {},
            withDefaultValues: {},
          },
        },
      },
    },
    userInfo: {
      initial: 'loading',
      states: {
        idle: {
          always: {
            target: 'loading',
          },
        },
        loading: {
          invoke: {
            id: 'getUserInfo',
            src: async (ctx) => {
              const { data: user, error: userInfoError } = await (
                await urqlClient()
              ).getUserInfoLimited();

              if (hasErrors(user)) {
                throw new Error(user.errors?.[0]?.message);
              }

              if (userInfoError) {
                throw new Error(
                  `Failed to fetch user info: ${userInfoError.message}`
                );
              }

              const userId = user?.admin_?.currentUser?.id;
              const email = user?.admin_?.currentUser?.email;
              const projectIdsAssignedThroughTeams = compact(
                user?.admin_?.currentUser?.assignedTeams
                  ?.map(({ assignedProjects }) =>
                    assignedProjects?.map(
                      (assignedProject) => assignedProject.id
                    )
                  )
                  .flat()
              );

              if (!userId || !email) {
                throw new Error(
                  `No user id found: ${userId} or email: ${email}`
                );
              }

              const { data: userInfo, error: userSettingsError } = await (
                await urqlClient()
              ).getUserSettingsLimited({ email });

              if (hasErrors(userInfo)) {
                throw new Error(userInfo.errors?.[0]?.message);
              }

              if (userSettingsError) {
                throw new Error(
                  `Failed to fetch user settings: ${userSettingsError.message}`
                );
              }

              return {
                userInfo: user?.admin_?.currentUser,
                userSettings: userInfo?.admin_,
                userProjects: projectIdsAssignedThroughTeams,
                defaultAccountId: user?.admin_?.currentUser?.defaultAccount?.id,
              };
            },
            onDone: {
              target: [
                'loaded',
                '#projects.loading',
                '#userProductRoles.loading',
              ],
              actions: assign((ctx, event) => {
                const {
                  userInfo,
                  userSettings,
                  userProjects,
                  defaultAccountId,
                } = event.data;

                return {
                  ...ctx,
                  userInfo,
                  userSettings,
                  userProjects: uniq([...ctx.userProjects, ...userProjects]),
                  ...(!ctx.accountId && {
                    accountId: defaultAccountId,
                  }),
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {
          on: {
            updateUser: 'updating',
          },
        },
        loadError: {},
        updating: {
          id: 'updatingUser',
          invoke: {
            src: async (
              ctx,
              event
            ): Promise<void | {
              callback: ((res: boolean) => void) | undefined;
              ctx: SmartContext;
            }> => {
              if (event.type !== 'updateUser') {
                return {
                  callback: undefined,
                  ctx,
                };
              }

              const { userInfo } = event;
              const { data, error: userUpdateError } = await (
                await urqlClient()
              ).updateUserLimited(userInfo);

              if (hasErrors(data)) {
                throw new Error(data.errors?.[0]?.message);
              }

              if (userUpdateError) {
                throw new Error(
                  `Failed to update user: ${userUpdateError.message}`
                );
              }

              const newContext: SmartContext = {
                ...ctx,
                userInfo: {
                  ...userInfo,
                  status: userInfo.status || UserStatus.Active,
                  email: ctx.userInfo?.email || '',
                  defaultAccount: {
                    id: userInfo.accountId,
                  },
                },
              };

              return { callback: event.callback, ctx: newContext };
            },
            onDone: {
              target: 'loaded',
              actions: assign((_ctx, event) => {
                const { callback, ctx } = event.data;
                callback(true);
                return ctx;
              }),
            },
            onError: {
              target: 'loaded',
            },
          },
        },
      },
    },
    projects: {
      id: 'projects',
      initial: 'idle',
      states: {
        idle: {
          always: {
            target: 'loading',
            cond: (context) =>
              context.userInfo?.id !== undefined &&
              context.accountId !== undefined,
          },
        },
        loading: {
          invoke: {
            id: 'getProjects',
            src: async (ctx) => {
              const userId = ctx.userInfo?.id;
              const { accountId } = ctx;

              if (userId && accountId) {
                const { data: projects, error: projectsError } = await (
                  await urqlClient()
                ).getProjectsLimited({
                  userId,
                  accountId,
                });

                if (projectsError) {
                  throw new Error(
                    `Failed to fetch projects: ${projectsError.message}`
                  );
                }

                return {
                  projects: projects?.admin_?.projects?.rows,
                  userProjects: compact(
                    projects?.admin_?.userProjects?.rows?.map(
                      ({ project }) => project?.id
                    )
                  ),
                };
              }

              return {
                projects: [],
                userProjects: [],
              };
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                const { projects, userProjects } = event.data;

                return {
                  ...ctx,
                  projects,
                  userProjects: uniq([...ctx.userProjects, ...userProjects]),
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
    products: {
      initial: 'idle',
      states: {
        idle: {
          always: {
            target: 'loading',
            cond: (context) => context.userInfo?.id !== undefined,
          },
        },
        loading: {
          id: 'productsLoading',
          invoke: {
            id: 'getProducts',
            src: async () => {
              const { data: products, error: productsError } = await (
                await urqlClient()
              ).getProductsLimited();

              if (productsError) {
                throw new Error(
                  `Failed to fetch products: ${productsError.message}`
                );
              }

              return {
                products: products?.admin_?.allToolProducts,
              };
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                const { products } = event.data;

                return {
                  ...ctx,
                  allProducts: products,
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
    userProductRoles: {
      id: 'userProductRoles',
      initial: 'idle',
      states: {
        idle: {
          always: {
            target: 'loading',
            cond: (context) =>
              context.userInfo?.id !== undefined &&
              context.accountId !== undefined,
          },
        },
        loading: {
          invoke: {
            id: 'getUserProductRoles',
            src: async (ctx) => {
              const userId = ctx.userInfo?.id;
              const { accountId } = ctx;

              if (userId && accountId) {
                const { data: productRoles, error: productRolesError } = await (
                  await urqlClient()
                ).getUserProductRolesLimited({
                  userId,
                  accountId,
                });

                if (productRolesError) {
                  throw new Error(
                    `Failed to fetch user product roles: ${productRolesError.message}`
                  );
                }

                return {
                  userProductRoles: productRoles?.admin_?.userProductRoles,
                };
              }

              return {
                userProductRoles: {},
              };
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                const { userProductRoles } = event.data;

                return {
                  ...ctx,
                  userProductRoles,
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
    languages: {
      id: 'languages',
      initial: 'loading',
      states: {
        loading: {
          id: 'languagesLoading',
          invoke: {
            id: 'getLanguages',
            src: async () => {
              const { data: languages, error: languagesError } = await (
                await urqlClient()
              ).getLanguagesLimited();

              if (hasErrors(languages)) {
                throw new Error(languages.errors?.[0]?.message);
              }

              if (languagesError) {
                throw new Error(
                  `Failed to fetch languages: ${languagesError.message}`
                );
              }

              return {
                locales: languages?.admin_?.locales || [],
                localizationSettingsConfig:
                  languages?.admin_?.localizationSettingsConfig,
              };
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                const { locales, localizationSettingsConfig } = event.data;
                const languages: Language[] = sort<Language>(locales)
                  .asc('name')
                  .filter(
                    ({ languageCode }) =>
                      !languageCode ||
                      (
                        localizationSettingsConfig?.user
                          .supportedLanguageCodes || []
                      ).includes(languageCode)
                  );

                return {
                  ...ctx,
                  languages,
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
    countries: {
      id: 'countries',
      initial: 'loading',
      states: {
        loading: {
          id: 'countriesLoading',
          invoke: {
            id: 'getCountries',
            src: async () => {
              const { data: countries, error: countriesError } = await (
                await urqlClient()
              ).getCountriesLimited();

              if (hasErrors(countries)) {
                throw new Error(countries.errors?.[0]?.message);
              }

              if (countriesError) {
                throw new Error(
                  `Failed to fetch countries: ${countriesError.message}`
                );
              }

              return countries?.admin_?.countries || [];
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                // as in manager.xstate
                const unsupportedCountryCodes = [
                  'RU',
                  'SA',
                  'CA',
                  'CN',
                  'AU',
                  'AE',
                ];

                const countries = sort<Country>(event.data)
                  .asc('name')
                  .filter(
                    ({ code }) => !unsupportedCountryCodes.includes(code)
                  );

                return {
                  ...ctx,
                  countries,
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
    timezones: {
      id: 'timezones',
      initial: 'loading',
      states: {
        loading: {
          id: 'timezonesLoading',
          invoke: {
            id: 'getTimezones',
            src: async () => {
              const { data: timezones, error: timezonesError } = await (
                await urqlClient()
              ).getTimeZonesLimited();

              if (hasErrors(timezones)) {
                throw new Error(timezones.errors?.[0]?.message);
              }

              if (timezonesError) {
                throw new Error(
                  `Failed to fetch timezones: ${timezonesError.message}`
                );
              }

              return timezones?.admin_?.timeZones || [];
            },
            onDone: {
              target: 'loaded',
              actions: assign((ctx, event) => {
                const timezones = sort<Timezone>(event.data).asc('label');

                return {
                  ...ctx,
                  timezones,
                };
              }),
            },
            onError: {
              target: 'loadError',
            },
          },
        },
        loaded: {},
        loadError: {},
      },
    },
  },
});

export const smartService = interpret<
  SmartContext,
  SmartState,
  SmartEvents,
  SmartTypestates,
  any
>(smartMachine, {
  devTools: enableStateInspector,
});

smartService.start();

win.pypestream = {
  smartService,
};
