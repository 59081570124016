export enum ProductName {
  Manager = 'Manager',
  ContactCenter = 'ContactCenter',
  Analytics = 'Analytics',
  Studio = 'Studio',
}

export const generateBaseURLsToTools = (): Record<ProductName, string> => {
  let baseURL = '';

  if (window.location.host.endsWith('pypestream.com')) {
    baseURL = 'pypestream.com';
  } else if (window.location.host.endsWith('pypestream.dev')) {
    const isPreview = window.location.host.split('.').length === 4;
    const env = window.location.host.split('.')[isPreview ? 2 : 1];
    if (env === 'pypestream') {
      // in case of manager.pypestream.dev
      baseURL = `candidate.pypestream.dev`;
    } else {
      baseURL = `${env}.pypestream.dev`;
    }
  } else {
    baseURL = 'candidate.pypestream.dev';
  }

  return {
    Manager: `https://manager.${baseURL}`,
    Studio: `https://studio.${baseURL}`,
    ContactCenter: `https://contactcenter.${baseURL}`,
    Analytics: `https://analytics.${baseURL}`,
  };
};
