/* eslint-disable no-console */
// Add types for window.navigation for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="navigation-api-types" />

import { PropertyValueMap, html } from 'lit';
import { state, property, customElement } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { transformProfilePicture } from '@pypestream/utils';
import { ref, Ref, createRef } from 'lit/directives/ref.js';
import { ConsentStatus, UserStatus } from '@pypestream/api-services';

import {
  CheckboxWC,
  AvatarWC,
  EntityTypes,
  UploadType,
  BaseElement,
} from '../../components';
import {
  Country,
  Language,
  SmartContext,
  Timezone,
} from '../xstate/smart.xstate-utils';
import { smartService } from '../xstate/smart.xstate';

// @todo: re-install @virtualstate/navigation (so we can use the @virtualstate/navigation/polyfill) once CC upgrades build process

interface OnboardingState {
  userId?: string;
  accountId?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  profilePhoto?: string;
  email?: string;
  authMethod?: string;
  requiredConsentStatus?: ConsentStatus;
  optionalConsentStatus?: ConsentStatus;
  currentOptionalConsentStatus?: ConsentStatus;
  status?: UserStatus;
  loading: boolean;
  country?: string;
  defaultLanguage?: string;
  defaultTimezone?: string;
}

@customElement('ps-onboarding-modal')
export class OnboardingModalWC extends BaseElement {
  context: SmartContext;

  @property() onSuccess?: (status?: UserStatus) => void;

  @property() open: boolean | 'always-opened' = false;

  // every 60 minutes
  @property({ type: Number, reflect: true, attribute: 'poll-interval' })
  pollInterval = 60 * 1000 * 60;

  @property({ type: Array }) languages: Language[] = [];

  @property({ type: Array }) countries: Country[] = [];

  @property({ type: Array }) timezones: Timezone[] = [];

  @state() private onboardingState: OnboardingState = {
    userId: undefined,
    accountId: undefined,
    firstName: undefined,
    lastName: undefined,
    jobTitle: undefined,
    profilePhoto: undefined,
    authMethod: undefined,
    email: undefined,
    loading: false,
    status: undefined,
    requiredConsentStatus: undefined,
    optionalConsentStatus: undefined,
    currentOptionalConsentStatus: undefined,
    country: undefined,
    defaultLanguage: undefined,
    defaultTimezone: undefined,
  };

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  connectedCallback() {
    this.setupListeners();

    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    // this.setupListeners();
    // } else {
    //   import('@virtualstate/navigation/polyfill').then(() => {
    //     this.setupListeners();
    //   });
    // }

    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
      this.init();
    });
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    setInterval(() => {
      this.init();
    }, this.pollInterval);
  }

  protected updated(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (changedProperties.has('env')) {
      this.init();
    }
  }

  private _mutationObserver: MutationObserver;

  setupListeners() {
    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    //   window.navigation.addEventListener('navigate', this.init);
    // } else {
    // console.error('window.navigation polyfill failed to load');
    // }
    let oldHref = document.location.href;
    this._mutationObserver = new MutationObserver((mutations) => {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href;

        this.init();
      }
    });

    this._mutationObserver.observe(
      document.querySelector('body') as HTMLElement,
      {
        childList: true,
        subtree: true,
      }
    );
  }

  tearDownListeners() {
    // console.log('tearDownListeners');

    // @todo: possibly re-enable once Contact Center build process upgraded OR remove when switching to GQL subscription
    // if (window.navigation) {
    //   window.navigation.removeEventListener('navigate', this.init);
    // } else {
    //   console.error('window.navigation polyfill failed to load');
    // }
    this._mutationObserver.disconnect();
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.tearDownListeners();
  }

  async init() {
    const { userInfo, userSettings, countries, languages, timezones } =
      this.context;

    if (userInfo && userSettings) {
      const accountId = userInfo?.defaultAccount?.id;
      const userId = userInfo?.id;
      const email = userInfo?.email;
      const profilePhoto = userInfo?.profilePhoto;

      // as in manager.xstate
      this.languages = languages;
      // as in manager.xstate
      this.countries = countries;
      this.timezones = timezones;

      this.onboardingState = {
        accountId,
        userId,
        email,
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        jobTitle: userInfo?.settings?.jobTitle,
        profilePhoto: profilePhoto
          ? transformProfilePicture(profilePhoto)
          : undefined,
        authMethod: userSettings?.getLoginInfo?.recommendedAuthMethod,
        status: userInfo?.status,
        loading: false,
        requiredConsentStatus: userInfo?.requiredConsentStatus,
        optionalConsentStatus: userInfo?.optionalConsentStatus,
        currentOptionalConsentStatus: userInfo?.optionalConsentStatus,
        country: userInfo?.settings?.country,
        defaultLanguage: userInfo?.settings?.defaultLanguage,
        defaultTimezone: userInfo?.settings?.defaultTimezone,
      };

      if (this.open === 'always-opened') {
        this.open = true;
        return;
      }

      if (
        this.onboardingState.requiredConsentStatus !== ConsentStatus.Accepted
      ) {
        this.open = true;
      }
    }
  }

  private _updateContext() {
    const userInfo = {
      id: this.onboardingState.userId || '',
      accountId: this.onboardingState.accountId || '',
      firstName: this.onboardingState.firstName || '',
      lastName: this.onboardingState.lastName || '',
      email: this.onboardingState.email || '',
      settings: {
        jobTitle: this.onboardingState.jobTitle,
        country: this.onboardingState.country,
        defaultLanguage: this.onboardingState.defaultLanguage,
        defaultTimezone: this.onboardingState.defaultTimezone,
      },
      status: UserStatus.Active,
      picture: this.onboardingState.profilePhoto,
      requiredConsentStatus: this.onboardingState.requiredConsentStatus,
      requiredConsentUpdatedAt: new Date().toISOString(),
      optionalConsentStatus: this.onboardingState.optionalConsentStatus,
      optionalConsentUpdatedAt:
        this.onboardingState.optionalConsentStatus !==
        this.onboardingState.currentOptionalConsentStatus
          ? new Date().toISOString()
          : undefined,
    };

    smartService.send({
      type: 'updateUserInfo',
      userInfo,
    });
  }

  private _onChange(
    e: Event & {
      target: { name?: string; value?: string; files: FileList | null } | null;
    }
  ) {
    if (!e.target?.name) {
      return;
    }

    this.onboardingState = {
      ...this.onboardingState,
      [e.target?.name as string]: e.target?.value,
    };

    this._updateContext();
  }

  private _onSubmit = async (
    event: Event & {
      preventDefault: () => void;
    }
  ) => {
    try {
      event?.preventDefault();

      if (
        !this.onboardingState.country ||
        !this.onboardingState.defaultLanguage ||
        !this.onboardingState.defaultTimezone
      ) {
        return;
      }

      if (!this.onboardingState.userId || !this.onboardingState.accountId) {
        return;
      }

      const userInfo = {
        id: this.onboardingState.userId,
        accountId: this.onboardingState.accountId,
        firstName: this.onboardingState.firstName || '',
        lastName: this.onboardingState.lastName || '',
        settings: {
          jobTitle: this.onboardingState.jobTitle,
          country: this.onboardingState.country,
          defaultLanguage: this.onboardingState.defaultLanguage,
          defaultTimezone: this.onboardingState.defaultTimezone,
        },
        status: UserStatus.Active,
        picture: this.onboardingState.profilePhoto,
        requiredConsentStatus: this.onboardingState.requiredConsentStatus,
        requiredConsentUpdatedAt: new Date().toISOString(),
        optionalConsentStatus:
          this.onboardingState.optionalConsentStatus !==
          this.onboardingState.currentOptionalConsentStatus
            ? this.onboardingState.optionalConsentStatus
            : undefined,
        optionalConsentUpdatedAt:
          this.onboardingState.optionalConsentStatus !==
          this.onboardingState.currentOptionalConsentStatus
            ? new Date().toISOString()
            : undefined,
      };

      await new Promise((resolve) => {
        smartService.send({
          type: 'updateUser',
          userInfo,
          callback: (res) => resolve(res),
        });
      });

      this.onSuccess?.();
      this.open = false;
    } catch (error) {
      console.error(JSON.stringify(error, null, 2));
    }
  };

  private _onRequiredStatusChange = (e: InputEvent) => {
    if (e.target instanceof CheckboxWC) {
      const nextValue =
        e.target.checked === true
          ? ConsentStatus.Accepted
          : ConsentStatus.Unknown;

      this.onboardingState = {
        ...this.onboardingState,
        requiredConsentStatus: nextValue,
      };

      this._updateContext();
    }
  };

  private _onOptionalStatusChange = () => {
    const nextValue =
      this.onboardingState.optionalConsentStatus !== ConsentStatus.Accepted
        ? ConsentStatus.Accepted
        : ConsentStatus.Unknown;

    this.onboardingState = {
      ...this.onboardingState,
      optionalConsentStatus: nextValue,
    };

    this._updateContext();
  };

  // private onCookiePolicyClick = () => {
  //   const nextValue =
  //     this.onboardingState.optionalConsentStatus !== ConsentStatus.Accepted
  //       ? ConsentStatus.Seen
  //       : this.onboardingState.optionalConsentStatus;

  //   this.onboardingState = {
  //     ...this.onboardingState,
  //     optionalConsentStatus: nextValue,
  //   };
  // };

  avatarRef: Ref<AvatarWC> = createRef();

  protected render() {
    const isGoogleProvider = this.onboardingState.authMethod
      ? this.onboardingState.authMethod === 'google-sso' ||
        this.onboardingState.authMethod === 'oidc'
      : false;

    const cta = (() => {
      if (isGoogleProvider) {
        return '';
      }

      return this.onboardingState.profilePhoto
        ? 'Change profile picture'
        : 'Select a file';
    })();

    return html`<ps-modal
      ?open=${Boolean(this.open)}
      hide-close-btn
      stay-on-click-outside
      stay-on-esc
    >
      <form @submit=${this._onSubmit}>
        <ps-text-title>Finish up your profile</ps-text-title>
        <ps-text-body variant="secondary">
          Just a couple more questions for you...
        </ps-text-body>

        <ps-spacer size="xlarge"></ps-spacer>

        <ps-block-list gutter="large">
          <ps-input
            placeholder="First Name"
            required
            name="firstName"
            value=${ifDefined(this.onboardingState.firstName)}
            ?readonly=${isGoogleProvider}
            @input=${this._onChange}
          ></ps-input>

          <ps-input
            placeholder="Last Name"
            required
            name="lastName"
            value=${ifDefined(this.onboardingState.lastName)}
            ?readonly=${isGoogleProvider}
            @input=${this._onChange}
          ></ps-input>

          <ps-upload
            account-id=${ifDefined(this.onboardingState.accountId)}
            entity-id=${ifDefined(this.onboardingState.userId)}
            entity-type=${EntityTypes.USER}
            accept="image/png, image/jpeg"
            ?readonly=${isGoogleProvider}
            type=${this.onboardingState.profilePhoto
              ? UploadType.success
              : UploadType.default}
            label="Profile Picture"
            cta=${cta}
            value=${ifDefined(this.onboardingState.profilePhoto)}
            name="profilePhoto"
            text=${isGoogleProvider
              ? 'To replace or remove, visit your Google account'
              : 'Upload a file here'}
            @change=${this._onChange}
          >
            <ps-avatar
              slot="preview"
              size="2xlarge"
              src=${ifDefined(this.onboardingState.profilePhoto)}
              label=${`${this.onboardingState.firstName || 'Firsname'} ${
                this.onboardingState.lastName || 'Lastname'
              }`}
              ${ref(this.avatarRef)}
            ></ps-avatar>
          </ps-upload>

          <ps-input
            placeholder="Job Title"
            name="jobTitle"
            value=${ifDefined(this.onboardingState.jobTitle)}
            @input=${this._onChange}
          ></ps-input>

          <ps-select
            required
            placeholder="Select Country"
            name="country"
            @input=${this._onChange}
            value=${ifDefined(this.onboardingState.country)}
          >
            ${this.countries.map(
              (country) => html`
                <ps-select-option value="${country.code}"
                  >${country.name}</ps-select-option
                >
              `
            )}
          </ps-select>

          <ps-select
            required
            placeholder="Select Language"
            name="defaultLanguage"
            @input=${this._onChange}
            value=${ifDefined(this.onboardingState.defaultLanguage)}
          >
            ${this.languages.map(
              (language) => html`
                <ps-select-option value="${language.locale}"
                  >${language.name}</ps-select-option
                >
              `
            )}
          </ps-select>

          <ps-select
            required
            placeholder="Select Timezone"
            name="defaultTimezone"
            @input=${this._onChange}
            value=${ifDefined(this.onboardingState.defaultTimezone)}
          >
            ${this.timezones.map(
              (timezone) => html`
                <ps-select-option value="${timezone.id}"
                  >${timezone.label}</ps-select-option
                >
              `
            )}
          </ps-select>
        </ps-block-list>

        <ps-spacer size="xlarge"></ps-spacer>

        <ps-stack gutter="xsmall" alignItems="center">
          <ps-checkbox
            size="small"
            name="requiredConsentStatus"
            ?checked=${this.onboardingState.requiredConsentStatus ===
            ConsentStatus.Accepted}
            @change=${this._onRequiredStatusChange}
          ></ps-checkbox>
          <ps-text-body size="small">
            Agree to
            <a
              href="https://app.termly.io/document/terms-of-service/85363fa8-fff8-4aeb-963e-cf55bbe4ef7f"
              target="_blank"
              rel="noreferrer"
              >Terms and Conditions</a
            >
            and
            <a
              href="https://app.termly.io/document/privacy-policy/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
              target="_blank"
              rel="noreferrer"
              >Privacy Policy</a
            >
          </ps-text-body>
        </ps-stack>
        <ps-stack gutter="xsmall" alignItems="center">
          <ps-checkbox
            size="small"
            name="optionalConsentStatus"
            ?checked=${this.onboardingState.optionalConsentStatus ===
            ConsentStatus.Accepted}
            @change=${this._onOptionalStatusChange}
          ></ps-checkbox>
          <ps-text-body size="small">
            Opt in to
            <a
              href="https://app.termly.io/document/cookie-policy/84d2833f-fd93-4e1e-bcf7-4b18f161aa77"
              target="_blank"
              rel="noreferrer"
              >Cookie Policy</a
            >
            to help us improve your experience
          </ps-text-body>
        </ps-stack>

        <ps-spacer size="medium"></ps-spacer>

        <ps-button
          size="large"
          width="full"
          type="submit"
          ?disabled=${this.onboardingState.requiredConsentStatus !==
            ConsentStatus.Accepted || this.onboardingState.loading}
        >
          ${this.onboardingState.loading ? 'Loading...' : 'Save Changes'}
        </ps-button>
      </form>
    </ps-modal>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-onboarding-modal': OnboardingModalWC;
  }
  enum PSElementTagNameMap {
    'ps-onboarding-modal' = 'ps-onboarding-modal',
  }
}
