import React, { FC } from 'react';
import { Spacer, TextSubtitle } from '@pypestream/design-system';
import { PuffLoader } from 'react-spinners';

export const Loader: FC<{
  relative?: boolean;
}> = ({ relative }) => (
  <div
    className={relative ? '' : 'u-full-width u-full-height u-overflow-hidden'}
    style={{
      minHeight: relative ? '100%' : 'calc(100vh - 80px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <PuffLoader color="#5956E3" size={60} speedMultiplier={2} />
    <Spacer size="small" />
    <TextSubtitle i18nKey="studio/common:loading">Loading...</TextSubtitle>
  </div>
);
