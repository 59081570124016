import {
  IconName,
  CardProps,
  Stack,
  Card,
  Icon,
  TextOverline,
} from '@pypestream/design-system';
import { FC } from 'react';

export type DrawerPanelTabCardProps = CardProps & {
  icon?: IconName;
  id?: string;
};

export const DrawerPanelTabCard: FC<DrawerPanelTabCardProps> = ({
  icon,
  disabled = false,
  children,
  onClick,
  onKeyDown,
}) => {
  return (
    <Stack gutter="xsmall" direction="column" alignItems="stretch">
      <Card
        size="small"
        disabled={disabled}
        interactive
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <Stack alignItems="center">
          {icon && <Icon name={icon} size="xlarge" />}
          <TextOverline>{children}</TextOverline>
        </Stack>
      </Card>
    </Stack>
  );
};
