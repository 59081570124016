import * as React from 'react';
import { createComponent } from '@lit/react';
import { TooltipWC } from './tooltip.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TooltipProps = GenerateComponentTypeWithChildren<TooltipWC>;

export type TooltipPropsWithRef = GeneratePropsWithRef<TooltipProps>;

export const Tooltip = createComponent({
  tagName: TooltipWC.tagname,
  elementClass: TooltipWC,
  react: React,
}) as TooltipPropsWithRef;
