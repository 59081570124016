import { getDateOrNull } from '@pypestream/utils';
import { MicroAppItemProps } from '../../components/microapp-item/microapp-item';
import { MicroApps } from './micro-apps.types';
import { GetMicroAppsQuery } from '@pypestream/api-services';
import { MicroAppsContext } from './micro-apps.xstate';

export const getMicroAppStatus = (
  data: MicroApps[0]['versions'][0],
  latestVersions: string[]
): MicroAppItemProps['status'] => {
  const isDeployed = Boolean(data.deployments.length);

  if (!isDeployed) {
    return 'live';
  }

  const isLatestVersion =
    isDeployed && latestVersions.includes(data.version_name);

  if (isLatestVersion) {
    return 'latest';
  }

  const isOutdated = isDeployed && !latestVersions.includes(data.version_name);

  if (isOutdated) {
    return 'outdated';
  }

  return 'default';
};

export const getMicroAppProps = (data: GetMicroAppsQuery['apps_app']) => {
  const result: MicroAppItemProps[] = [];

  data.forEach(({ app_id, app_name: name, versions }) => {
    // const latestVersions = Object.keys(permissions).map(
    //   (envName) => permissions[envName]?.latest_version
    // );

    result.push({
      id: app_id,
      title: name,
      // status: getMicroAppStatus(versions[0], latestVersions),
      status: 'default',

      updated: getDateOrNull(versions[0]?.updated_at),
      // userName: `${first_name} ${last_name}`,
      // userAvatar: avatar,
    });
  });

  return result;
};

export const getMicroAppDraftVersionId = (
  data: MicroAppsContext['data'],
  selectedAppId: MicroAppsContext['selectedAppId']
) =>
  data
    ?.find((app) => app.app_id === selectedAppId)
    ?.versions?.find((version) => !version.readonly_at)?.version_id;
