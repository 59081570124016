import { ReactFlowProvider } from 'reactflow';
import { FC, Fragment, PropsWithChildren, createRef, useEffect } from 'react';
import { PuffLoader } from 'react-spinners';
import {
  sendBuilderEvent,
  sendUiEvent,
  useBuilderCtxSelector,
  useBuilderStateMatches,
} from '../../xstate/app.xstate';
import { TextSubtitle, PageBody, Spacer } from '@pypestream/design-system';
import { useBuildPageData } from './use-build-page-data';
import { DrawerPanel, DrawerPanelTabs, Graph, Loader } from '../../components';
import { NodeDetails } from '../../components/node-details';

// @todo: relocate so we can reuse to in other components
function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (event.target && event.target instanceof HTMLElement) {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        }
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const BuilderContentWrapper: FC<PropsWithChildren<{ loading?: boolean }>> = ({
  children,
  loading = false,
}) => {
  const wrapperRef = createRef<HTMLDivElement>();

  const simulatingGraphLayout = useBuilderStateMatches(
    'graph.simulatingGraphLayout'
  );

  useOnClickOutside(wrapperRef, (event) => {
    sendBuilderEvent({
      type: 'builder.node.deselect',
    });
  });

  const isLoading = loading || simulatingGraphLayout;

  return (
    <div
      className="u-full-width u-overflow-hidden"
      style={{
        maxHeight: '100%',
        display: 'grid',
        gridTemplateRows: '1fr',
        justifyItems: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 80px)',
      }}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
};

const Build: FC = () => {
  const { loading, isDrawerOpen, isDrawerPanelExpanded } = useBuildPageData();

  const nodeId = useBuilderCtxSelector((ctx) => ctx.selectedNodeId);

  const toggleExpanded = () => {
    sendUiEvent({
      expanded: isDrawerPanelExpanded,
      type: 'drawer.elements.triggerToggle',
    });
  };

  const content = loading ? (
    <div style={{}}>
      <PuffLoader
        color="#5956E3"
        size={60}
        speedMultiplier={2}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'inherit',
          position: 'relative',
          width: '60px',
          height: '60px',
        }}
      />
      <Spacer size="small" />
      <TextSubtitle i18nKey="studio/common:loading">Loading...</TextSubtitle>
    </div>
  ) : (
    <>
      <DrawerPanel
        open={isDrawerOpen}
        expanded={isDrawerPanelExpanded}
        onDrawerPanelToggle={toggleExpanded}
        details={<Fragment>{(nodeId && <NodeDetails />) || null}</Fragment>}
        elements={<DrawerPanelTabs />}
      />
      <ReactFlowProvider>
        <Graph />
      </ReactFlowProvider>
    </>
  );

  return (
    <>
      <PageBody background="none" spacing={loading ? 'small' : 'none'}>
        <BuilderContentWrapper loading={loading}>
          {content}
        </BuilderContentWrapper>
      </PageBody>
    </>
  );
};

export default Build;
