/* eslint-disable lit/no-invalid-html, lit/binding-positions */
import { unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html, literal } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './text-button.scss?inline';

@customElement('ps-text-button')
export class TextButtonWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  /** The icon-button's data-testid. */
  @property({ reflect: true }) 'data-testid' = 'wc-text-button';

  /** The button's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  /** Disables the button. */
  @property({ reflect: true, type: Boolean }) disabled = false;

  /**
   * The type of button. When the type is `submit`, the button will submit the surrounding form. Note that the default
   * value is `button` instead of `submit`, which is opposite of how native `<button>` elements behave.
   */
  @property() type: 'button' | 'submit' | 'reset' = 'button';

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: string;

  /** If button rendered as an `<a>`, it is possible to set target attribute. */
  @property({ reflect: true }) target: HTMLAnchorElement['target'];

  @property({ reflect: true }) variant: 'primary' | 'secondary' = 'primary';

  render() {
    const isLink = !!this.href;
    const tag = isLink ? literal`a` : literal`button`;

    return html`
      <${tag}
        class=${classMap({
          'c-text-btn': true,
          [`c-text-btn--size-${this.size}`]: this.size,
          [`c-text-btn--variant-${this.variant}`]: this.variant,
        })}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        type=${ifDefined(isLink ? undefined : this.type)}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}
      >
        <slot></slot>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text-button': TextButtonWC;
  }
}
