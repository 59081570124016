import { ReactiveElement } from 'lit';

export class PypestreamElement extends ReactiveElement {
  static tagname: string;
}

export type Initializer = (
  element: PypestreamElement | ReactiveElement
) => void;

function isReactiveElementClass(
  clazz: Function // eslint-disable-line @typescript-eslint/ban-types
): clazz is typeof PypestreamElement {
  return typeof clazz['addInitializer' as keyof typeof clazz] === 'function';
}

export type Constructor<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
};

// From the TC39 Decorators proposal
interface ClassElement {
  kind: 'field' | 'method';
  key: PropertyKey;
  placement: 'static' | 'prototype' | 'own';
  initializer?: () => void;
  extras?: ClassElement[];
  finisher?: <T>(clazz: Constructor<T>) => undefined | Constructor<T>;
  descriptor?: PropertyDescriptor;
}

// From the TC39 Decorators proposal
interface ClassDescriptor {
  kind: 'class';
  name: string | undefined;
  elements: ClassElement[];
  finisher?: <T>(clazz: Constructor<T>) => undefined | Constructor<T>;
}

const legacyCustomElement = (
  tagName: string,
  clazz: Constructor<PypestreamElement>
) => {
  if (!window.customElements.get(tagName)) {
    window.customElements.define(tagName, clazz as CustomElementConstructor);
  }
  // Cast as any because TS doesn't recognize the return type as being a
  // subtype of the decorated class when clazz is typed as
  // `Constructor<HTMLElement>` for some reason.
  // `Constructor<HTMLElement>` is helpful to make sure the decorator is
  // applied to elements however.
  // tslint:disable-next-line:no-any

  if (!isReactiveElementClass(clazz)) {
    throw new Error(
      `@customElement may only decorate PypestreamElements. ${clazz.name} is does not implement PypestreamElement.`
    );
  }

  // eslint-disable-next-line no-param-reassign
  clazz.tagname = tagName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return clazz as any;
};

const standardCustomElement = (
  tagName: string,
  descriptor: ClassDescriptor
) => {
  const { kind, elements } = descriptor;
  return {
    kind,
    elements,
    // This callback is called once the class is otherwise fully defined
    finisher(clazz: Constructor<HTMLElement>) {
      if (!isReactiveElementClass(clazz)) {
        throw new Error(
          `@customElement may only decorate Reactive PypestreamElements. ${clazz.name} is does not implement PypestreamElement.`
        );
      }

      // eslint-disable-next-line no-param-reassign
      clazz.tagname = tagName;

      if (!customElements.get(tagName)) {
        customElements.define(tagName, clazz);
      }
    },
  };
};

/**
 * Class decorator factory that defines the decorated class as a custom element.
 *
 * ```
 * @customElement('my-element')
 * class MyElement {
 *   render() {
 *     return html``;
 *   }
 * }
 * ```
 * @category Decorator
 * @param tagName The name of the custom element to define.
 */
export const customElement =
  (tagName: string) =>
  (classOrDescriptor: Constructor<PypestreamElement> | ClassDescriptor) =>
    typeof classOrDescriptor === 'function'
      ? legacyCustomElement(tagName, classOrDescriptor)
      : standardCustomElement(tagName, classOrDescriptor);
