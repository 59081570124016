import * as React from 'react';

import {
  TestID,
  useRule,
  useStopEventPropagation,
  type RuleProps,
} from 'react-querybuilder';
import { RuleFieldForm } from './rule-field';

/**
 * Renders a `React.Fragment` containing an array of form controls for managing
 * a {@link RuleType}.
 */
const RuleComponents = React.memo(
  (r: RuleProps & ReturnType<typeof useRule>) => {
    const {
      schema: {
        controls: {
          shiftActions: ShiftActionsControlElement,
          dragHandle: DragHandleControlElement,
          cloneRuleAction: CloneRuleActionControlElement,
          lockRuleAction: LockRuleActionControlElement,
          removeRuleAction: RemoveRuleActionControlElement,
        },
      },
    } = r;

    return (
      <div className="c-query-builder-rule">
        {r.schema.enableDragAndDrop && (
          <DragHandleControlElement
            testID={TestID.dragHandle}
            ref={r.dragRef}
            level={r.path.length}
            path={r.path}
            title={r.translations.dragHandle.title}
            label={r.translations.dragHandle.label}
            className={r.classNames.dragHandle}
            disabled={r.disabled}
            context={r.context}
            validation={r.validationResult}
            schema={r.schema}
            ruleOrGroup={r.rule}
          />
        )}
        <div className="c-query-builder-rule__field">
          <RuleFieldForm {...r} />
        </div>
        {r.schema.showCloneButtons && (
          <CloneRuleActionControlElement
            testID={TestID.cloneRule}
            label={r.translations.cloneRule.label}
            title={r.translations.cloneRule.title}
            className={r.classNames.cloneRule}
            handleOnClick={r.cloneRule}
            level={r.path.length}
            path={r.path}
            disabled={r.disabled}
            context={r.context}
            validation={r.validationResult}
            ruleOrGroup={r.rule}
            schema={r.schema}
          />
        )}
        {r.schema.showLockButtons && (
          <LockRuleActionControlElement
            testID={TestID.lockRule}
            label={r.translations.lockRule.label}
            title={r.translations.lockRule.title}
            className={r.classNames.lockRule}
            handleOnClick={r.toggleLockRule}
            level={r.path.length}
            path={r.path}
            disabled={r.disabled}
            disabledTranslation={
              r.parentDisabled ? undefined : r.translations.lockRuleDisabled
            }
            context={r.context}
            validation={r.validationResult}
            ruleOrGroup={r.rule}
            schema={r.schema}
          />
        )}
        {r.schema.showShiftActions && (
          <ShiftActionsControlElement
            testID={TestID.shiftActions}
            level={r.path.length}
            path={r.path}
            titles={{
              shiftUp: r.translations.shiftActionUp.title,
              shiftDown: r.translations.shiftActionDown.title,
            }}
            labels={{
              shiftUp: r.translations.shiftActionUp.label,
              shiftDown: r.translations.shiftActionDown.label,
            }}
            className={r.classNames.shiftActions}
            disabled={r.disabled}
            shiftUp={r.shiftRuleUp}
            shiftDown={r.shiftRuleDown}
            shiftUpDisabled={r.shiftUpDisabled}
            shiftDownDisabled={r.shiftDownDisabled}
            context={r.context}
            validation={r.validationResult}
            schema={r.schema}
            ruleOrGroup={r.rule}
          />
        )}
        <RemoveRuleActionControlElement
          testID={TestID.removeRule}
          label={r.translations.removeRule.label}
          title={r.translations.removeRule.title}
          className={r.classNames.removeRule}
          handleOnClick={r.removeRule}
          level={r.path.length}
          path={r.path}
          disabled={r.disabled}
          context={r.context}
          validation={r.validationResult}
          ruleOrGroup={r.rule}
          schema={r.schema}
        />
      </div>
    );
  }
);

/**
 * Default component to display {@link RuleType} objects. This is
 * actually a small wrapper around {@link RuleComponents}.
 */
export const Rule = React.memo((props: RuleProps) => {
  const r = useRule(props);
  r.cloneRule = useStopEventPropagation(r.cloneRule);
  r.toggleLockRule = useStopEventPropagation(r.toggleLockRule);
  r.removeRule = useStopEventPropagation(r.removeRule);
  r.shiftRuleUp = useStopEventPropagation(r.shiftRuleUp);
  r.shiftRuleDown = useStopEventPropagation(r.shiftRuleDown);

  return (
    <div
      ref={r.dndRef}
      data-testid={TestID.rule}
      data-dragmonitorid={r.dragMonitorId}
      data-dropmonitorid={r.dropMonitorId}
      className={r.outerClassName}
      data-rule-id={r.id}
      data-level={r.path.length}
      data-path={JSON.stringify(r.path)}
    >
      <RuleComponents {...r} key={r.id} />
    </div>
  );
});
