import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import heroStyles from './hero.scss?inline';

@customElement('ps-hero')
export class HeroWC extends BaseElement {
  static styles = unsafeCSS(heroStyles);

  /** The button's size. */
  @property() variant: 'default' | 'editor' | 'minimal' = 'default';

  render() {
    const classes = classMap({
      'c-hero': true,
      [`c-hero--variant-${this.variant}`]: this.variant,
    });

    return html`
      <div class=${classes}>
        <div class="c-hero__inner">
          <div class="c-hero__content">
            <ps-wrapper>
              <slot></slot>
            </ps-wrapper>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-hero': HeroWC;
  }
  enum PSElementTagNameMap {
    'ps-hero' = 'ps-hero',
  }
}
