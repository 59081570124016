import { FC } from 'react';
import {
  Card,
  Icon,
  Spacer,
  Stack,
  TextBody,
  TextSubtitle,
  Tag,
} from '@pypestream/design-system';
import { getCapitalizedString, format } from '@pypestream/utils';
import { TranslationKeys } from '@pypestream/translations';
import { DeploymentDetails } from '../../pages/manage-landing-page/get-app-deployments';

interface DeploymentCardProps {
  deploymentDetails: DeploymentDetails;
  isLatest: boolean;
}

export const DeploymentCard: FC<DeploymentCardProps> = ({
  deploymentDetails,
  isLatest,
}) => {
  const tagVariant = isLatest ? 'latest' : 'outdated';
  const capitalizedTagVariant = getCapitalizedString(tagVariant);

  return (
    <Card size="xlarge" href="/google.com">
      <Icon name="open" size="small" slot="action" />
      <TextSubtitle>
        {deploymentDetails.environment?.environment_name}
      </TextSubtitle>
      <Spacer size="2xsmall" />
      <Stack>
        <TextBody size="small" variant="secondary">
          {deploymentDetails.version?.version_name}&nbsp;
          {format(new Date(deploymentDetails.deployed_at))}
        </TextBody>
        <Tag
          variant={tagVariant}
          i18nKey={capitalizedTagVariant as TranslationKeys}
        >
          {capitalizedTagVariant}
        </Tag>
      </Stack>
    </Card>
  );
};
