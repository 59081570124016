import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import styles from './nav-actions.scss?inline';

@customElement('ps-nav-actions')
export class NavActionsWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="c-nav-actions">
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-nav-actions': NavActionsWC;
  }
  enum PSElementTagNameMap {
    'ps-nav-actions' = 'ps-nav-actions',
  }
}
