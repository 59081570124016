/* eslint-disable lit/no-invalid-html */
/* eslint-disable lit/binding-positions */
import { unsafeCSS } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './tag.scss?inline';
import { Translate } from '../base-element/mixins/translation-mixin';

export enum DeployingStatuses {
  latest,
  outdated,
  live,
  primary,
  default,
  indicator,
  success,
  error,
  warning,
}

@customElement('ps-tag')
export class TagWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  /**
   * Component size.
   */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'small';

  /**
   * Component size.
   * if added new variants, please update the enum in packages/translations/generated/i18nTypes-studio.ts
   * and add new translations in packages/translations/locales/studio in each language
   */
  @property({ reflect: true }) variant: keyof typeof DeployingStatuses =
    'default';

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: HTMLAnchorElement['href'];

  /** If button rendered as an `<a>`, it is possible to set target attribute. */
  @property({ reflect: true }) target?: HTMLAnchorElement['target'];

  /** Indicates that tag is clickable */
  @property({ type: Boolean, reflect: true }) interactive = false;

  @property({ type: Boolean, reflect: true }) disabled = false;

  @property({ type: Boolean, reflect: true }) minimal = false;

  /** Makes the tag removable and shows a remove button. */
  @property({ type: Boolean }) removable = false;

  private handleRemoveClick() {
    this.emit('ps-remove');
  }

  render() {
    const isLink = !!this.href;
    const isInteractive = (isLink || this.interactive) && !this.disabled;
    let tag = literal`span`;

    if (isLink) tag = literal`a`;
    else if (this.interactive) tag = literal`button`;

    return html`
      <${tag}
        href=${ifDefined(isLink && !this.disabled ? this.href : undefined)}
        tabindex=${ifDefined(isInteractive) ? 0 : null}
        target=${ifDefined(isLink ? this.target : undefined)}
        class="${classMap({
          'c-tag': true,
          [`c-tag--size-${this.size}`]: this.size,
          [`c-tag--minimal`]: this.minimal,
          [`c-tag--variant-${this.variant}`]: this.variant,
          'c-tag--interactive': isInteractive,
        })}"
      >
        <slot></slot>
        ${
          this.removable
            ? html`
                <ps-icon-button
                  part="remove-button"
                  exportparts="base:remove-button__base"
                  name="close"
                  rounded
                  size="xsmall"
                  label="Remove"
                  class="tag__remove"
                  @click=${this.handleRemoveClick}
                  tabindex="-1"
                ></ps-icon-button>
              `
            : ''
        }
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tag': TagWC;
  }
  enum PSElementTagNameMap {
    'ps-tag' = 'ps-tag',
  }
}
