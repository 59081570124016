/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, unsafeCSS } from 'lit';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { property, query, state, eventOptions } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { onceTransitionEnd } from '@rcaferati/wac';
import { nanoid } from 'nanoid';
import { addons } from '@storybook/preview-api';
import { ClickAwayController, FocusTrapController } from '../../../internal';

import { BaseElement, customElement } from '../../base-element';
import '../../buttons/icon-button/icon-button.wc';
import { PortalDestinationWC } from '../../portal/portal-destination.wc';
import styles from './modal.scss?inline';

@customElement('ps-modal')
export class ModalWC extends BaseElement {
  onClickAway = new ClickAwayController({
    host: this,
  });

  focusTrapController = new FocusTrapController(this);

  static styles = unsafeCSS(styles);

  private parentEl: Element | null;

  @query('.c-modal') modal?: HTMLDialogElement;

  @query('.c-modal__body') modalBody: HTMLElement;

  private portalDestinationEl: HTMLElement | null;

  /** Indicates whether or not the modal is open. */
  @property({ type: Boolean, reflect: true }) open? = false;

  /** The modal's size. */
  @property({ reflect: true }) size: 'medium' | 'large' = 'medium';

  // eslint-disable-next-line lit/no-native-attributes
  @property({ reflect: true }) id = `modal-${nanoid()}`;

  @property({ type: Boolean, reflect: true, attribute: 'use-portal' })
  usePortal = true;

  @property({ type: Boolean, reflect: true, attribute: 'hide-close-btn' })
  hideCloseBtn = false;

  @property({ type: Boolean, attribute: 'stay-on-click-outside' })
  stayOnClickOutside = false;

  @property({ type: Boolean, attribute: 'stay-on-esc' }) stayOnEsc = false;

  constructor() {
    super();

    addons.getChannel().addListener('storyChanged', (foo) => {
      this._closeModal();
    });
  }

  firstUpdated() {
    this.onClickAway.element = this.modalBody;

    if (this.parentElement && !this.parentEl && this.usePortal) {
      // Keep the reference of parent element to append `this` instance of popover once portal is closed.
      this.parentEl = this.parentElement;
    }
  }

  triggerStateChange() {
    if (
      this.open === true &&
      this.currentState !== 'opened' &&
      this.currentState !== 'opening'
    ) {
      this._openModal();
    } else if (
      !this.open &&
      this.currentState !== 'closing' &&
      this.currentState !== 'closed'
    ) {
      this._closeModal();
    }
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('open')) {
      this.triggerStateChange();

      this.onClickAway.excludeElement = !this.open;
    }
  }

  @state() modalId: string;

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.focusTrapController.hostConnected();

    this.onClickAway.callback = (event) => {
      if (!this.open || !this.hasUpdated || this.stayOnClickOutside) return;

      // @todo: consider updating to include a few other elements if needed; maybe elevate config for this to the top?
      if (event.target) {
        const target = event.target as HTMLElement;
        if (target.closest('.c-tooltip') || target.closest('.c-popover'))
          return;
      }

      this._closeModal();
    };

    if (this.parentNode === document.body) this.usePortal = false;

    if (this.usePortal) {
      this.portalDestinationEl = document.querySelector(
        `${PortalDestinationWC.tagname}[name="${this.id}"]`
      );

      if (!this.portalDestinationEl) {
        const destinationEl = document.createElement(
          PortalDestinationWC.tagname
        );

        destinationEl.setAttribute('name', this.id);
        document.body.appendChild(destinationEl);
      }
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.focusTrapController?.hostDisconnected();
    this.onClickAway.hostDisconnected();
    clearAllBodyScrollLocks();

    // auto-cleanup portal destinations when modal removed
    if (this.portalDestinationEl && this.usePortal && document.body) {
      document.body.removeChild(this.portalDestinationEl);
    }
  }

  @state() currentState: 'closed' | 'closing' | 'opened' | 'opening' = 'closed';

  // eslint-disable-next-line class-methods-use-this
  @eventOptions({ passive: true })
  _handleKeyPress(e: KeyboardEvent) {
    if (e.key === 'Escape' && !this.stayOnEsc) {
      this._closeModal();
    }
  }

  render() {
    return html`
      <dialog
        class="${classMap({
          'c-modal': true,
          [`c-modal--${this.currentState}`]: true,
        })}"
      >
        <div
          class="c-modal__backdrop"
          @click=${() => {
            if (this.stayOnClickOutside) return;
            this._closeModal();
          }}
        ></div>

        <div
          class="${classMap({
            'c-modal__body': true,
            [`c-modal__body--size-${this.size}`]: this.size,
          })}"
        >
          ${!this.hideCloseBtn
            ? html`
                <div class="c-modal__close-btn">
                  <ps-icon-button
                    size="small"
                    name="close"
                    @click=${this._closeModal}
                  ></ps-icon-button>
                </div>
              `
            : ''}
          ${this.slotted('header')
            ? html`<div class="c-modal__header">
                <slot name="header"></slot>
              </div>`
            : null}

          <slot></slot>

          ${this.slotted('footer')
            ? html`<div class="c-modal__footer">
                <slot name="footer"></slot>
              </div>`
            : null}
        </div>
      </dialog>
    `;
  }

  private _openModal() {
    if (this.usePortal) {
      setTimeout(() => {
        this.emit('update-portal-content', {
          detail: {
            destination: this.id,
            content: this,
          },
        });
      }, 50);
    }

    if (this.currentState === 'closed' || this.currentState === 'closing') {
      setTimeout(() => {
        this.currentState = 'opening';
        window.addEventListener('keydown', (e) => this._handleKeyPress(e));
        disableBodyScroll(this.modalBody, {
          reserveScrollBarGap: true,
        });

        onceTransitionEnd(this.modalBody).then((event) => {
          this.focusTrapController.activate();

          this.currentState = 'opened';

          if (this.modal) {
            this.modal.open = true;
          }
        });
      }, 100);
    }
  }

  private _closeModal() {
    if (this.currentState === 'opening' || this.currentState === 'opened') {
      this.currentState = 'closing';

      setTimeout(() => {
        this.focusTrapController.deactivate();
        this.modal?.close();
        window.removeEventListener('keydown', this._handleKeyPress);
      }, 200);
    }

    onceTransitionEnd(this.modalBody).then((event) => {
      this.currentState = 'closed';
      enableBodyScroll(this.modalBody);
      this.open = false;
      this.emit('close');

      if (this.usePortal) {
        this.emit('update-portal-content', {
          detail: {
            destination: this.id,
            content: '',
          },
        });

        if (this.parentEl && !this.parentEl.contains(this)) {
          this.parentEl.append(this);
        }
      }
    });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-modal': ModalWC;
  }
  enum PSElementTagNameMap {
    'ps-modal' = 'ps-modal',
  }
}
