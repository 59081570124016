import { html } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import {
  BaseElement,
  customElement,
  SlotObserver,
} from '../../../components/base-element';
import '../../../components/stack/stack.wc';
import '../../../components/text/text-subtitle.wc';

@customElement('ps-applet-card-title')
export class AppletCardTitleWC extends SlotObserver(BaseElement, [
  '[slot]',
  '[slot="prefix"]',
  '[slot="suffix"]',
]) {
  /** Title content. */
  @property({
    reflect: true,
  })
  label: string;

  render() {
    return html`
      <ps-stack gutter="small" nowrap>
        ${this.hasSlottedContent('[slot="prefix"]')
          ? html` <slot name="prefix"></slot> `
          : null}
        <ps-text-subtitle size="small" truncate-lines="2"
          >${this.label}</ps-text-subtitle
        >
        ${this.hasSlottedContent('[slot="suffix"]')
          ? html` <slot name="suffix"></slot> `
          : null}
      </ps-stack>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-applet-card-title': AppletCardTitleWC;
  }
}
