import * as React from 'react';
import { createComponent } from '@lit/react';
import { GridCellWC } from './grid-cell.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type GridCellProps = GenerateComponentTypeWithChildren<GridCellWC>;

export type GridCellPropsWithRef = GeneratePropsWithRef<GridCellProps>;

export const GridCell = createComponent({
  tagName: GridCellWC.tagname,
  elementClass: GridCellWC,
  react: React,
}) as GridCellPropsWithRef;
