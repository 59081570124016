import * as React from 'react';
import { createComponent } from '@lit/react';
import { InlineListWC } from './inline-list.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type InlineListProps = GenerateComponentTypeWithChildren<InlineListWC>;

export type InlineListPropsWithRef = GeneratePropsWithRef<InlineListProps>;

export const InlineList = createComponent({
  tagName: InlineListWC.tagname,
  elementClass: InlineListWC,
  react: React,
}) as InlineListPropsWithRef;
