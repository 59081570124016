export type ModuleType = typeof import('./*.svg');

export const allIcons: Record<string, unknown> = import.meta.glob(
  './icons/**/*.svg',
  {
    eager: true,
  }
);

export const icons: Record<string, ModuleType> = {};

Object.keys(allIcons).forEach((icon) => {
  const simpleName = icon
    .substring(icon.lastIndexOf('/') + 1)
    .replace('.svg', '') as string;

  const value = allIcons[icon] as ModuleType;

  icons[simpleName] = value;
});
