import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextOverlineWC } from './text-overline.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TextOverlineProps =
  GenerateComponentTypeWithChildren<TextOverlineWC>;

export type TextOverlinePropsWithRef = GeneratePropsWithRef<TextOverlineProps>;

export const TextOverline = createComponent({
  tagName: TextOverlineWC.tagname,
  elementClass: TextOverlineWC,
  react: React,
}) as TextOverlinePropsWithRef;
