import * as React from 'react';
import { createComponent } from '@lit/react';
import { NavLinksWC } from './nav-links.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type NavLinksProps = GenerateComponentTypeWithChildren<NavLinksWC>;

export type NavLinksPropsWithRef = GeneratePropsWithRef<NavLinksProps>;

export const NavLinks = createComponent({
  tagName: NavLinksWC.tagname,
  elementClass: NavLinksWC,
  react: React,
}) as NavLinksPropsWithRef;
