import { unsafeCSS, html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement, watch } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import '../../icon/icon.wc';
import styles from './menu-item.scss?inline';

@customElement('ps-menu-item')
export class MenuItemWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  private cachedTextLabel: string;

  @query('slot:not([name])') defaultSlot: HTMLSlotElement;

  /** Set the item in a selected state. */
  @property({ type: Boolean, reflect: true }) selected = false;

  /** A unique value to store in the menu item. This can be used as a way to identify menu items when selected. */
  @property() value = '';

  /** Set the menu item in a disabled state, prevents selection. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Marking a MenuItem as "subtle" disables visible interactive states. @deprecated */
  @property({ type: Boolean, reflect: true })
  set subtle(val: boolean) {
    if (val) this.variant = 'subtle';
  }

  /** Set menu-item variant. */
  @property({ reflect: true }) variant: 'default' | 'subtle' | 'ghost' =
    'default';

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('role', 'menuitem');
    this.handleHostClick = this.handleHostClick.bind(this);
    this.addEventListener('click', this.handleHostClick);
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.removeEventListener('click', this.handleHostClick);
  }

  private handleHostClick(event: MouseEvent) {
    // Prevent the click event from being emitted when the button is disabled or loading
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  @watch('disabled')
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  private handleDefaultSlotChange() {
    const textLabel = this.getTextLabel();

    // Ignore the first time the label is set
    if (typeof this.cachedTextLabel === 'undefined') {
      this.cachedTextLabel = textLabel;
      return;
    }

    // When the label changes, emit a slotchange event so parent controls see it
    if (textLabel !== this.cachedTextLabel) {
      this.cachedTextLabel = textLabel;
      this.emit('slotchange', {
        bubbles: true,
        composed: false,
        cancelable: false,
      });
    }
  }

  getTextLabel() {
    if (!this.defaultSlot) {
      return '';
    }
    const nodes = this.defaultSlot.assignedNodes({ flatten: true });
    let text = '';

    [...nodes].forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        text += node.textContent;
      }
    });

    return text;
  }

  render() {
    return html`
      <div
        data-cy="menu-item"
        class=${classMap({
          'c-menu-item': true,
          'c-menu-item--disabled': this.disabled,
          'c-menu-item--selected': this.selected,
          [`c-menu-item--${this.variant}`]: this.variant,
        })}
      >
        ${this.slotted('prefix')
          ? html`<slot
              name="prefix"
              class="c-menu-item__prefix"
              data-cy="menu-item-prefix"
            >
            </slot>`
          : null}
        <span class="c-menu-item__content" data-cy="menu-item-content">
          <slot @slotchange=${this.handleDefaultSlotChange}></slot>
          <span class="c-menu-item__secondary"
            ><slot name="secondary"></slot
          ></span>
        </span>
        ${this.slotted('suffix') && !this.selected
          ? html`<slot
              name="suffix"
              class="c-menu-item__suffix"
              data-cy="menu-item-suffix"
            >
            </slot>`
          : null}
        ${this.selected
          ? html`<ps-icon
              name="check"
              size="xsmall"
              weight="bold"
              class="c-menu-item__check-icon"
              data-cy="menu-item-check-icon"
            ></ps-icon>`
          : null}
      </div>
    `;
  }
}

export type MenuItemSelectedEvent = CustomEvent<{ item: MenuItemWC }>;

declare global {
  interface HTMLElementTagNameMap {
    'ps-menu-item': MenuItemWC;
  }
  enum PSElementTagNameMap {
    'ps-menu-item' = 'ps-menu-item',
  }

  interface GlobalEventHandlersEventMap {
    'menu-item-selected': MenuItemSelectedEvent;
  }
}
