import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './breadcrumb-item.scss?inline';

const tagname = 'ps-breadcrumb-item';

@customElement(tagname)
export class BreadcrumbItemWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  /**
   * Optional URL to direct the user to when the breadcrumb item is activated. When set, a link will be rendered
   * internally. When unset, a button will be rendered instead.
   */
  @property() href?: string;

  /** Tells the browser where to open the link. Only used when `href` is set. */
  @property() target?: '_blank' | '_parent' | '_self' | '_top';

  /** The `rel` attribute to use on the link. Only used when `href` is set. */
  @property() rel = 'noreferrer noopener';

  /**
   * Tells this is a root item.
   */
  @property() root?: boolean = false;

  /**
   * The src attribute for the img element.
   */
  @property() icon?: string;

  /**
   * text inside button or a element.
   */
  @property() text?: string = '';

  render() {
    const isLink = !!this.href;

    const renderChild =
      this.root && this.icon
        ? html`
            <div class="c-breadcrumb-item__icon">
              <img
                src="${this.icon}"
                srcset="${this.icon}"
                alt="breadcrumb item img"
                referrerpolicy="no-referrer"
                data-cy="breadcrumb-item-img"
              />
            </div>
          `
        : html` <span class="c-breadcrumb-item__text">${this.text}</span>`;

    return html`
      <div class="c-breadcrumb-item" data-cy="breadcrumb-item">
        ${isLink
          ? html`
              <a
                data-cy="breadcrumb-item-label"
                class="c-breadcrumb-item__label c-breadcrumb-item__label--link"
                href="${this.href}"
                target="${ifDefined(this.target ? this.target : undefined)}"
                rel=${ifDefined(this.target ? this.rel : undefined)}
              >
                ${renderChild}
              </a>
            `
          : html`
              <button
                data-cy="breadcrumb-item-label"
                type="button"
                class="c-breadcrumb-item__label c-breadcrumb-item__label--button"
              >
                ${renderChild}
              </button>
            `}

        <span
          data-cy="breadcrumb-item-separator"
          class="c-breadcrumb-item__separator"
          aria-hidden="true"
        >
          <slot name="separator">
            <ps-icon name="chevron-right" size="xsmall"></ps-icon
          ></slot>
        </span>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [tagname]: BreadcrumbItemWC;
  }
  enum PSElementTagNameMap {
    'ps-breadcrumb-item' = 'ps-breadcrumb-item',
  }
}
