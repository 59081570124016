import * as React from 'react';
import { createComponent } from '@lit/react';
import { ButtonGroupWC } from './button-group.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type ButtonGroupProps = GenerateComponentTypeWithChildren<ButtonGroupWC>;

export type ButtonGroupPropsWithRef = GeneratePropsWithRef<ButtonGroupProps>;

export const ButtonGroup = createComponent({
  tagName: ButtonGroupWC.tagname,
  elementClass: ButtonGroupWC,
  react: React,
}) as ButtonGroupPropsWithRef;
