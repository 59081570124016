import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import tableBodyStyles from './table-body.scss?inline';

@customElement('ps-table-body')
export class TableBodyWC extends BaseElement {
  static styles = unsafeCSS(tableBodyStyles);

  render() {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-table-body': TableBodyWC;
  }
  enum PSElementTagNameMap {
    'ps-table-body' = 'ps-table-body',
  }
}
