import { Modal } from '@pypestream/design-system';
import {
  sendUiEvent,
  useUiStateMatches,
  useUserCtxSelector,
} from '../../xstate/app.xstate';

// Higher Level Modal Component
export const AppModal = () => {
  const isModalOpen = useUiStateMatches('modal.opened');
  const publishModal = useUserCtxSelector((ctx) => ctx.user?.canPublish);
  const handleModalClose = () => {
    sendUiEvent({
      type: 'modal.hasClosed',
    });
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <div>
        {publishModal ? (
          <p>You are seeing this Modal because you can Publish.</p>
        ) : null}
      </div>
    </Modal>
  );
};
