import { html, nothing, unsafeCSS } from 'lit';
import { property, queryAssignedElements, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { animate, fadeIn } from '@lit-labs/motion';
import { BaseElement, customElement } from '../../base-element';
import { AvatarWC } from '../../avatar/avatar.wc';
import styles from './avatar-group.scss?inline';

@customElement('ps-avatar-group')
export class AvatarGroupWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * The total number of avatars
   */
  @state() protected _total: number;

  /**
   * Component size.
   */
  @property({ reflect: true }) size: AvatarWC['size'] = 'medium';

  /**
   * Limit the number of avatars shown.
   */
  @property({ reflect: true, type: Number }) max: number;

  @queryAssignedElements()
  _elements!: Array<AvatarWC>;

  handleSlotChange() {
    let totalAvatars = 0;

    this._elements.filter((el) => {
      if (el.tagName.toLowerCase() === AvatarWC.tagname) {
        totalAvatars += 1;

        if (totalAvatars > this.max) {
          el.setAttribute('hidden', '');
        } else {
          el.removeAttribute('hidden');
        }

        return true;
      }

      return false;
    });

    this._total = totalAvatars;
  }

  render() {
    let overMaxValue = `+${this._total - this.max}`;

    // @todo: update so this 99 (upper limit) is a configurable value once we come up with a good name for this
    if (this._total - this.max > 99) {
      overMaxValue = `＞99`;
    }

    return html`
      <div class="c-avatar-group">
        <slot
          @slotchange=${this.handleSlotChange}
          style="display: none;"
        ></slot>
        ${this._elements.length > 0
          ? repeat(
              this._elements,
              (avatar) => avatar?.id,
              (avatar) => html`
                <ps-avatar
                  label=${avatar?.label}
                  size=${this.size}
                  src=${avatar.src || ''}
                  ?hidden=${avatar?.hidden}
                  ${animate({
                    properties: ['opacity'],
                    in: fadeIn,
                    skipInitial: true,
                  })}
                ></ps-avatar>
              `
            )
          : nothing}
        ${this._total && this.max && this._total > this.max
          ? html`
              <ps-avatar size=${this.size} class="c-avatar-group__item"
                >${overMaxValue}</ps-avatar
              >
            `
          : nothing}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-avatar-group': AvatarGroupWC;
  }
}
