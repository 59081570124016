import { html, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { formatDistanceToNowStrict } from 'date-fns';
import { BaseElement, customElement } from '../../../components/base-element';
import '../../../components/text/text-body.wc';
import '../../../components/avatar/avatar.wc';
import styles from './applet-card-avatar.scss?inline';

@customElement('ps-applet-card-avatar')
export class AppletCardAvatarWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** Avatar label attribute. */
  @property({
    reflect: true,
  })
  label?: string;

  /**
   * The src attribute for the avatar.
   */
  @property({ reflect: true }) src?: string;

  /** Date property. */
  @property({
    reflect: false,
    attribute: false,
  })
  date?: Date;

  render() {
    return html`
      <div class="c-applet-card-avatar">
        <ps-avatar
          size="small"
          label=${ifDefined(this.label)}
          src=${ifDefined(this.src)}
        ></ps-avatar>
        ${this.date
          ? html`
              <ps-text-body size="small" variant="secondary" truncate
                >${formatDistanceToNowStrict(this.date, {
                  addSuffix: true,
                })}<span class="c-applet-card-avatar__addon">
                  by</span
                ></ps-text-body
              >
            `
          : nothing}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-applet-card-avatar': AppletCardAvatarWC;
  }
}
