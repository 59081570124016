import * as React from 'react';
import { createComponent } from '@lit/react';
import { GridWC } from './grid.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type GridProps = GenerateComponentTypeWithChildren<GridWC>;

export type GridPropsWithRef = GeneratePropsWithRef<GridProps>;

export const Grid = createComponent({
  tagName: GridWC.tagname,
  elementClass: GridWC,
  react: React,
}) as GridPropsWithRef;
