import {
  TagWC,
  TableRow,
  TableCell,
  Stack,
  TextSubtitle,
  TextBody,
  Tag,
  Avatar,
} from '@pypestream/design-system';
import { format } from '@pypestream/utils';
import { FC } from 'react';
import { getIcon, getStatusLabel } from './helpers';

import './microapp-item.scss';
import { useNavigate } from 'react-router-dom';

export interface MicroAppItemProps {
  id: number;
  title: string;
  status: TagWC['variant']; // "default" | "primary" | "latest" | "outdated" | "live" | "success" | "error" | "warning";
  updated: Date | null;
  // userName: string;
  // userAvatar?: string;
}

export const MicroAppItem: FC<MicroAppItemProps> = ({
  id,
  title,
  status,
  updated,
  // userName,
  // userAvatar,
}) => {
  const navigate = useNavigate();
  const setSelectedAppId = () => {
    navigate(`/${id}`);
  };

  return (
    <TableRow
      data-app-id={id}
      tabindex={0}
      actionable
      onClick={setSelectedAppId}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.code === 'Space') {
          e.preventDefault();
          setSelectedAppId();
        }
      }}
      role="button"
      // className="c-hoverable-row"
    >
      <TableCell>
        <Stack alignItems="center">
          {getIcon(status)}
          <TextSubtitle>{title}</TextSubtitle>
        </Stack>
      </TableCell>
      <TableCell>
        <Tag variant={status} i18nKey={`studio/homepage:status.${status}`}>
          {getStatusLabel(status)}
        </Tag>
      </TableCell>
      <TableCell>
        <Stack gutter="small" alignItems="center" justifyContent="end">
          <TextBody>
            {updated && updated !== null ? format(updated as Date) : 'unknown'}
          </TextBody>
          {/* by <Avatar size="small" label={userName} src={userAvatar} /> */}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
