import * as React from 'react';
import { createComponent } from '@lit/react';
import { createPortal } from 'react-dom';
import { ExpandRecursively } from '@pypestream/shared-types';
import { PopoverWC } from './popover.wc';
import { PopoverAutoCloseEvent } from './popover-base.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PopoverEvents = {
  onAutoClose?: (event: PopoverAutoCloseEvent) => void;
};

export type PopoverProps = GenerateComponentTypeWithChildren<PopoverWC> &
  PopoverEvents;

/** @todo: eventually merge these @lit/react generics into our GenerateComponentTypeWithChildren functionality */
export type EventName<T extends Event = Event> = string & {
  __event_type: T;
};

type ReactProps<I, E> = Omit<React.HTMLAttributes<I>, keyof E>;
type ElementWithoutPropsOrEventListeners<I, E> = Omit<
  I,
  keyof E | keyof ReactProps<I, E>
>;

// Props the user is allowed to use, includes standard attributes, children,
// ref, as well as special event and element properties.
export type WebComponentProps<I, E> = Partial<
  I & ElementWithoutPropsOrEventListeners<I, E>
>;

export type ReactWebComponent<I, E> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WebComponentProps<I, E>> & React.RefAttributes<I>
>;

// use default LitElement + React component since we need actually working refs
export const Popover = createComponent({
  tagName: PopoverWC.tagname,
  elementClass: PopoverWC,
  react: React,
  events: {
    onClose: 'close',
    onAutoClose: 'popover-autoclose',
  },
}) as ReactWebComponent<PopoverProps, PopoverEvents>;

// PopoverWithReactPortal still needs work on TS typings
// export const PopoverWithReactPortal = React.forwardRef<
//   PopoverWC,
//   React.ForwardedRef<PopoverWC> & {
//     children?: React.ReactNode;
//   }
// >(({ children, ...otherProps }, ref) =>
//   createPortal(
//     <Popover {...otherProps} omitPortal ref={ref}>
//       {children}
//     </Popover>,
//     document.body
//   )
// );
