import { TableCellProps } from '@pypestream/design-system';
import { FC } from 'react';
import { ColumnTitle, MicroAppItem, Table } from '..';
import { MicroAppItemProps } from '../microapp-item/microapp-item';
import { SortingType, useSortableData } from '../../hooks/use-sortable-data';

export type TableHeading = Partial<TableCellProps> & {
  sortingType: SortingType;
  sortedByDefault?: boolean;
};

export interface MicroappsSummaryTableProps<T> {
  data: T[];
  headings: TableHeading[];
}

export const MicroappsSummaryTable: FC<
  MicroappsSummaryTableProps<MicroAppItemProps>
> = ({ data, headings }) => {
  const { sortedData, sortingDetails, onSort } =
    useSortableData<MicroAppItemProps>(data);

  const headingNodes = headings.map(
    ({ children, sortingType, ...restProps }) => (
      <ColumnTitle
        key={String(children)}
        sortingDetails={sortingDetails[sortingType]}
        sortingType={sortingType}
        variant="th"
        onSort={onSort}
        {...restProps}
      >
        {children}
      </ColumnTitle>
    )
  );

  const rowNodes = sortedData.map((props: MicroAppItemProps) => (
    <MicroAppItem key={props.id} {...props} />
  ));

  return <Table headings={headingNodes} rows={rowNodes} />;
};

export default MicroappsSummaryTable;
