import * as React from 'react';
import { createComponent } from '@lit/react';
import { MenuItemWC } from './menu-item.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type MenuItemProps = GenerateComponentTypeWithChildren<MenuItemWC> & {
  onClick?: React.MouseEventHandler<MenuItemWC>;
  id?: string;
};

export type MenuItemPropsWithRef = GeneratePropsWithRef<MenuItemProps>;

export const MenuItem = createComponent({
  tagName: MenuItemWC.tagname,
  elementClass: MenuItemWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as MenuItemPropsWithRef;
