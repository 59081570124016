import { isProduction } from '..';

interface ShowGitInfoProps {
  commitHash?: string;
  branchName?: string;
  tagName?: string;
  appName: string;
}

export const showGitInfo = ({
  appName,
  commitHash,
  branchName,
  tagName,
}: ShowGitInfoProps) => {
  if (!isProduction) return;

  console.log(
    `%c
-------------------------------------------------------
${appName} Git Info:
Commit Hash: ${commitHash}
Branch Name: ${branchName}
Tag Name: ${tagName}
-------------------------------------------------------`,
    'color: purple; font-size: larger; font-weight: bold'
  );
};
