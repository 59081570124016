import * as React from 'react';
import { createComponent } from '@lit/react';
import { SnackbarWC } from './snackbar.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type SnackbarProps = GenerateComponentTypeWithChildren<SnackbarWC>;

export type SnackbarPropsWithRef = GeneratePropsWithRef<SnackbarProps>;

export const Snackbar = createComponent({
  tagName: SnackbarWC.tagname,
  elementClass: SnackbarWC,
  react: React,
}) as SnackbarPropsWithRef;
