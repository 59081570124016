import * as React from 'react';
import { createComponent } from '@lit/react';
import { PSCustomEvent } from '../base-element';
import { ToggleWC } from './toggle.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type ToggleProps = GenerateComponentTypeWithChildren<ToggleWC> & {
  onChange?: (event: PSCustomEvent<ToggleWC, { checked: boolean }>) => void;
};

export type TogglePropsWithRef = GeneratePropsWithRef<ToggleProps>;

export const Toggle = createComponent({
  tagName: ToggleWC.tagname,
  elementClass: ToggleWC,
  react: React,
  events: {
    onChange: 'change',
  },
}) as TogglePropsWithRef;
