import { useEffect } from 'react';
import {
  useMicroappsStateMatches,
  useBuilderStateMatches,
  useUiStateMatchesOneOf,
  sendBuilderEvent,
  useMicroappsCtxSelector,
} from '../../xstate/app.xstate';

export const useBuildPageData = () => {
  const selectedVersionId = useMicroappsCtxSelector(
    (ctx) => ctx.selectedVersionId
  );
  const selectedAppId = useMicroappsCtxSelector((ctx) => ctx.selectedAppId);
  const selectedAppName =
    useMicroappsCtxSelector(
      (ctx) =>
        ctx.data?.find(({ app_id }) => app_id === ctx.selectedAppId)?.app_name
    ) || '';

  const appDetailsLoaded = useMicroappsStateMatches('loaded');
  const loading = useBuilderStateMatches('graph.loading');

  const isDrawerOpen = useUiStateMatchesOneOf([
    'drawer.opened.nodeDetails',
    'drawer.opened.elements',
  ]);

  // @todo: once tests updated, move this back to the micro-apps.xstate.ts appDetails.loaded state
  useEffect(() => {
    if (!appDetailsLoaded || !selectedVersionId) {
      return;
    }

    sendBuilderEvent({
      type: 'builder.fetchData',
      selectedVersionId: selectedVersionId,
      selectedAppId,
      selectedAppName,
    });
  }, [appDetailsLoaded, selectedVersionId]);

  return {
    loading,
    isDrawerOpen: Boolean(isDrawerOpen),
    isDrawerPanelExpanded: isDrawerOpen === 'drawer.opened.elements',
  };
};
