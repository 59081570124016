import * as React from 'react';
import { createComponent } from '@lit/react';
import { MenuDividerWC } from './menu-divider.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type MenuDividerProps =
  GenerateComponentTypeWithoutChildren<MenuDividerWC>;

export type MenuDividerPropsWithRef = GeneratePropsWithRef<MenuDividerProps>;

export const MenuDivider = createComponent({
  tagName: MenuDividerWC.tagname,
  elementClass: MenuDividerWC,
  react: React,
}) as MenuDividerPropsWithRef;
