import { html, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { nanoid } from 'nanoid';
import { onceTransitionEnd } from '@rcaferati/wac';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import { PortalDestinationWC } from '../../portal/portal-destination.wc';
import styles from './snackbar.scss?inline';

@customElement('ps-snackbar')
export class SnackbarWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  private parentEl: Element | null;

  private portalDestinationEl: HTMLElement | null;

  @query('.c-snackbar') snackbar: HTMLDivElement;

  // eslint-disable-next-line lit/no-native-attributes
  @property({ reflect: true }) id = `snackbar-${nanoid()}`;

  /** Indicates whether or not the Snackbar is open. */
  @property({ type: Boolean }) open = false;

  @state() currentState: 'closed' | 'closing' | 'opened' | 'opening' = 'closed';

  firstUpdated() {
    if (this.parentElement && !this.parentEl) {
      // Keep the reference of parent element to append `this` instance of popover once portal is closed.
      this.parentEl = this.parentElement;
    }
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    this.portalDestinationEl = document.querySelector(
      `${PortalDestinationWC.tagname}[name="${this.id}"]`
    );

    if (!this.portalDestinationEl) {
      const destinationEl = document.createElement(PortalDestinationWC.tagname);

      destinationEl.setAttribute('name', this.id);
      document.body.appendChild(destinationEl);
    }
  }

  async updated(changedProps: Map<string, unknown>) {
    super.updated(changedProps);

    if (changedProps.has('open')) {
      if (this.open) {
        setTimeout(() => {
          this.emit('update-portal-content', {
            detail: {
              destination: this.id,
              content: this,
            },
          });
        }, 50);

        setTimeout(() => {
          this.currentState = 'opening';

          onceTransitionEnd(this.snackbar).then((event) => {
            this.currentState = 'opened';
          });
        }, 100);
      } else {
        this.currentState = 'closing';
        onceTransitionEnd(this.snackbar).then((event) => {
          this.currentState = 'closed';

          // this.emit('update-portal-content', {
          //   detail: {
          //     content: '',
          //     destination: this.id,
          //   },
          // });

          // Append this instance back to parent element.
          if (this.parentEl && !this.parentEl.contains(this)) {
            this.parentEl.append(this);
          }
        });
      }
    }
  }

  render() {
    const classes = classMap({
      'c-snackbar': true,
      [`c-snackbar--${this.currentState}`]: true,
    });

    return html`
      <div
        class=${classes}
        role="alert"
        aria-hidden=${this.open ? 'false' : 'true'}
      >
        ${this.slotted('icon')
          ? html`
              <div class="c-snackbar__icon">
                <slot name="icon"></slot>
              </div>
            `
          : null}
        <div class="c-snackbar__body">
          <ps-text-subtitle size="small">
            <slot></slot>
          </ps-text-subtitle>
        </div>

        ${this.slotted('action')
          ? html` <div class="c-snackbar__action">
              <slot name="action"></slot>
            </div>`
          : null}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-snackbar': SnackbarWC;
  }
}
