import { ButtonWC } from '../../components';
import { customElement } from '../../components/base-element';

@customElement('button-with-different-default')
export class ButtonWithDifferentDefaultWC extends ButtonWC {
  constructor() {
    super();
    this._variant = 'secondary';
  }
}
