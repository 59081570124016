import * as React from 'react';
import { createComponent } from '@lit/react';
import { MediaObjectWC } from './media-object.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type MediaObjectProps = GenerateComponentTypeWithChildren<MediaObjectWC>;

export type MediaObjectPropsWithRef = GeneratePropsWithRef<MediaObjectProps>;

export const MediaObject = createComponent({
  tagName: MediaObjectWC.tagname,
  elementClass: MediaObjectWC,
  react: React,
}) as MediaObjectPropsWithRef;
