import * as React from 'react';
import { createComponent } from '@lit/react';
import { WrapperWC } from './wrapper.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type WrapperProps = GenerateComponentTypeWithChildren<WrapperWC>;

export type WrapperPropsWithRef = GeneratePropsWithRef<WrapperProps>;

export const Wrapper = createComponent({
  tagName: WrapperWC.tagname,
  elementClass: WrapperWC,
  react: React,
}) as WrapperPropsWithRef;
