import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import { AccordionItemWC } from './accordion-item.wc';
import styles from './accordion-group.scss?inline';

@customElement('ps-accordion-group')
export class AccordionGroupWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @property({ type: Boolean, reflect: true }) multiple = false;

  private handleAccordionToggle = (event: Event) => {
    const allAccordions = this.getAllAccordions();
    if (!this.multiple) {
      allAccordions.forEach((accordion) => {
        if (accordion !== event.target) {
          accordion.hide();
        }
      });
    }
  };

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('role', 'accordion');
    this.addEventListener('ps-show', this.handleAccordionToggle);
  }

  disconnectedCallback() {
    this.removeEventListener('ps-show', this.handleAccordionToggle);
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
  }

  private getAllAccordions(): AccordionItemWC[] {
    return [...this.querySelectorAll<AccordionItemWC>('ps-accordion-item')];
  }

  render() {
    return html`<div data-cy="accordion" class="c-accordion">
      <slot></slot>
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-accordion-group': AccordionGroupWC;
  }
  enum PSElementTagNameMap {
    'ps-accordion-group' = 'ps-accordion-group',
  }
}
