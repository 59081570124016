import { classMap } from 'lit/directives/class-map.js';
import { html, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import {
  animateTo,
  shimKeyframesHeightAuto,
  stopAnimations,
} from '../base-element/utilities/animate';
import {
  getAnimation,
  setDefaultAnimation,
} from '../base-element/utilities/animation-registry';
import { waitForEvent } from '../base-element/utilities/event';
import { watch } from '../base-element/directives/watch.directive';
import { IconWC } from '../icon/icon.wc';
import styles from './accordion-item.scss?inline';
import { BaseElement, customElement } from '../base-element';

/**
 * @header Accordion show a brief header and expand to show additional content.
 * @dependency ps-icon
 *
 * @slot - The Accordion's main content.
 * @slot header - The Accordion's header. Alternatively, you can use the `header` attribute.
 * @slot expand-icon - Optional expand icon to use instead of the default. Works best with `<ps-icon>`.
 * @slot collapse-icon - Optional collapse icon to use instead of the default. Works best with `<ps-icon>`.
 *
 * @event ps-show - Emitted when the Accordion opens.
 * @event ps-after-show - Emitted after the Accordion opens and all animations are complete.
 * @event ps-hide - Emitted when the Accordion closes.
 * @event ps-after-hide - Emitted after the Accordion closes and all animations are complete.
 */
@customElement('ps-accordion-item')
export class AccordionItemWC extends BaseElement {
  static styles = unsafeCSS(styles);

  static dependencies = {
    'ps-icon': IconWC,
  };

  @query('.c-accordion-item') accordion: HTMLDetailsElement;

  @query('.c-accordion-item__header') headerElement: HTMLElement;

  @query('.c-accordion-item__body') body: HTMLElement;

  @query('.c-accordion-item__expand-icon-slot') expandIconSlot: HTMLSlotElement;

  accordionObserver: MutationObserver;

  /**
   * Indicates whether or not the accordion is open. You can toggle this attribute to show and hide the accordion, or you
   * can use the `show()` and `hide()` methods and this attribute will reflect the accordion' open state.
   */
  @property({ type: Boolean, reflect: true }) open = false;

  /** The header to show in the header. If you need to display HTML, use the `header` slot instead. */
  @property() header: string;

  /** Disables the accordion so it can't be toggled. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /**
   * Defines the space within header item
   */
  @property({ reflect: true }) gap:
    | 'none'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large' = 'none';

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('role', 'accordion-item');
  }

  firstUpdated() {
    this.body.style.height = this.open ? 'auto' : '0';
    if (this.open) {
      this.accordion.open = true;
    }

    this.accordionObserver = new MutationObserver((changes) => {
      for (const change of changes) {
        if (change.type === 'attributes' && change.attributeName === 'open') {
          if (this.accordion.open) {
            this.show();
          } else {
            this.hide();
          }
        }
      }
    });
    this.accordionObserver.observe(this.accordion, { attributes: true });
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.accordionObserver?.disconnect();
  }

  private handleAccordionClick(event: MouseEvent) {
    event.preventDefault();

    if (!this.disabled) {
      if (this.open) {
        this.hide();
      } else {
        this.show();
      }
      this.headerElement.focus();
    }
  }

  private handleAccordionKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

      if (this.open) {
        this.hide();
      } else {
        this.show();
      }
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      event.preventDefault();
      this.hide();
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      event.preventDefault();
      this.show();
    }
  }

  @watch('open', { waitUntilFirstUpdate: true })
  async handleOpenChange() {
    if (this.open) {
      this.accordion.open = true;
      // Show
      const slShow = this.emit('ps-show', { cancelable: true });
      if (slShow.defaultPrevented) {
        this.open = false;
        this.accordion.open = false;
        return;
      }

      await stopAnimations(this.body);

      const { keyframes, options } = getAnimation(this, 'accordion.show', {
        dir: this.dir,
      });
      await animateTo(
        this.body,
        shimKeyframesHeightAuto(keyframes, this.body.scrollHeight),
        options
      );
      this.body.style.height = 'auto';

      this.emit('ps-after-show');
    } else {
      // Hide
      const slHide = this.emit('ps-hide', { cancelable: true });
      if (slHide.defaultPrevented) {
        this.accordion.open = true;
        this.open = true;
        return;
      }

      await stopAnimations(this.body);

      const { keyframes, options } = getAnimation(this, 'accordion.hide', {
        // dir: this.localize.dir(),
        dir: this.dir,
      });
      await animateTo(
        this.body,
        shimKeyframesHeightAuto(keyframes, this.body.scrollHeight),
        options
      );
      this.body.style.height = 'auto';

      this.accordion.open = false;
      this.emit('ps-after-hide');
    }
  }

  /** Shows the accordion. */
  async show() {
    if (this.open || this.disabled) {
      return undefined;
    }

    this.open = true;
    return waitForEvent(this, 'ps-after-show');
  }

  /** Hides the accordion */
  async hide() {
    if (!this.open || this.disabled) {
      return undefined;
    }

    this.open = false;
    return waitForEvent(this, 'ps-after-hide');
  }

  render() {
    const isRtl = this.matches(':dir(rtl)');

    return html`
      <details
        part="base"
        data-cy="accordion-item"
        class=${classMap({
          'c-accordion-item': true,
          'c-accordion-item--open': this.open,
          'c-accordion-item--disabled': this.disabled,
          'c-accordion-item--rtl': isRtl,
        })}
      >
        <summary
          part="header"
          id="header"
          class=${classMap({
            'c-accordion-item__header': true,
            [`c-accordion-item__header--gap-${this.gap}`]: this.gap,
          })}
          role="button"
          aria-expanded=${this.open ? 'true' : 'false'}
          aria-controls="content"
          aria-disabled=${this.disabled ? 'true' : 'false'}
          tabindex=${this.disabled ? '-1' : '0'}
          @click=${this.handleAccordionClick}
          @keydown=${this.handleAccordionKeyDown}
        >
          <slot name="header" part="header" class="c-accordion-item__summary"
            >${this.header}</slot
          >

          <span part="summary-icon" class="c-accordion-item__summary-icon">
            <slot name="expand-icon">
              <ps-icon size="small" name="chevron-down"></ps-icon>
            </slot>
            <slot name="collapse-icon">
              <ps-icon size="small" name="chevron-down"></ps-icon>
            </slot>
          </span>
        </summary>

        <div
          class="c-accordion-item__body"
          role="region"
          aria-labelledby="header"
        >
          <slot
            part="content"
            id="content"
            class="c-accordion-item__content"
          ></slot>
        </div>
      </details>
    `;
  }
}

setDefaultAnimation('accordion.show', {
  keyframes: [
    { height: '0', opacity: '0' },
    { height: 'auto', opacity: '1' },
  ],
  options: { duration: 250, easing: 'linear' },
});

setDefaultAnimation('accordion.hide', {
  keyframes: [
    { height: 'auto', opacity: '1' },
    { height: '0', opacity: '0' },
  ],
  options: { duration: 250, easing: 'linear' },
});
