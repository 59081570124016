import * as React from 'react';
import { createComponent } from '@lit/react';
import { BadgeWC } from './badge.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type BadgeProps = GenerateComponentTypeWithChildren<BadgeWC>;

export type BadgePropsWithRef = GeneratePropsWithRef<BadgeProps>;

export const Badge = createComponent({
  tagName: BadgeWC.tagname,
  elementClass: BadgeWC,
  react: React,
}) as BadgePropsWithRef;
