import * as React from 'react';
import { createComponent } from '@lit/react';
import { TableRowWC } from './table-row.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TableRowProps = GenerateComponentTypeWithChildren<TableRowWC> & {
  onClick?: React.MouseEventHandler<TableRowWC>;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTableRowElement>) => void;
};

export type TableRowPropsWithRef = GeneratePropsWithRef<TableRowProps>;

export const TableRow = createComponent({
  tagName: TableRowWC.tagname,
  elementClass: TableRowWC,
  react: React,
  events: {
    onClick: 'click',
    onKeyDown: 'keydown',
  },
}) as TableRowPropsWithRef;
