import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';
import {
  getCoreRowModel,
  getPaginationRowModel,
  Table,
  ColumnDef,
  Row,
  TableState,
} from '@tanstack/table-core';

import { customElement, BaseElement, PSCustomEvent } from '../../base-element';
import { watch } from '../../base-element/directives/watch.directive';
import '../../table';

import { flexRenderer } from './flex-renderer';
import { UseTableCore } from './use-table-core';

export type TableData = {
  name: string;
  email: string;
  roles: string;
  team: string;
  created: string;
};

@customElement('ps-data-table')
export class DataTableWC<T = TableData> extends BaseElement {
  @property({ type: Array }) columns: ColumnDef<T>[];

  @property({ type: Array }) data: T[];

  @property({ type: Boolean, reflect: true, attribute: 'enable-row-selection' })
  enableRowSelection = false;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'enable-multi-row-selection',
  })
  enableMultiRowSelection = false;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'enable-row-selection-ui',
  })
  enableRowSelectionUi = false;

  // exposing the Tanstack table instance allows access to internal methods (ex. reset row selection)
  table: Table<T>;

  @state() private tableState: TableState;

  @watch('tableState', {
    waitUntilFirstUpdate: true,
  })
  async handleTableStateChange() {
    await this.updateComplete;

    if (this.enableRowSelection) {
      const rowsSelected = this.table.getSelectedRowModel().rows;
      this.emit('row-selection-change', {
        detail: {
          selected: rowsSelected,
        },
      });
    }
  }

  createTable() {
    let columnsDef: ColumnDef<T>[] = this.columns;

    if (this.enableRowSelection && this.enableRowSelectionUi === true) {
      columnsDef = [
        {
          id: 'select',
          header: ({ table }) => html`
            <ps-checkbox
              value="select-all"
              ?checked=${table.getIsAllRowsSelected()}
              ?indeterminate=${table.getIsSomeRowsSelected()}
              @click=${table.getToggleAllRowsSelectedHandler()}
            >
            </ps-checkbox>
          `,
          cell: ({ row }) => html`
            <ps-checkbox
              ?disabled=${!row?.getCanSelect()}
              ?checked=${row.getIsSelected()}
              ?indeterminate=${row.getIsSomeSelected()}
              @click=${row.getToggleSelectedHandler()}
            >
            </ps-checkbox>
          `,
        },
        ...columnsDef,
      ];
    }

    this.table = UseTableCore<T>({
      state: this.tableState,
      data: this.data,
      columns: columnsDef,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      enableRowSelection: this.enableRowSelection,
      onStateChange: (updater) => {
        this.tableState =
          typeof updater === 'function'
            ? updater(this.table.getState())
            : updater;
      },
      // debugAll: true,
    });
  }

  render() {
    let columnsDef: ColumnDef<T>[] = this.columns;

    if (this.enableRowSelection && this.enableRowSelectionUi) {
      columnsDef = [
        {
          id: 'select',
          header: ({ table }) => html`
            <ps-checkbox
              value="select-all"
              ?checked=${table.getIsAllRowsSelected()}
              ?indeterminate=${table.getIsSomeRowsSelected()}
              @click=${table.getToggleAllRowsSelectedHandler()}
            >
            </ps-checkbox>
          `,
          cell: ({ row }) => html`
            <ps-checkbox
              ?disabled=${!row?.getCanSelect()}
              ?checked=${row.getIsSelected()}
              ?indeterminate=${row.getIsSomeSelected()}
              @click=${row.getToggleSelectedHandler()}
            >
            </ps-checkbox>
          `,
        },
        ...columnsDef,
      ];
    }

    this.table = UseTableCore<T>({
      state: this.tableState,
      data: this.data,
      columns: columnsDef,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      enableRowSelection: this.enableRowSelection,
      enableMultiRowSelection: this.enableMultiRowSelection,
      onStateChange: (updater) => {
        this.tableState =
          typeof updater === 'function'
            ? updater(this.table.getState())
            : updater;
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: 1000,
        },
      },
      // debugAll: true,
    });

    return html`
      <ps-table>
        <ps-table-head>
          ${this.table
            ?.getHeaderGroups()
            ?.map(
              (headerGroup) => html`
                <ps-table-row key=${headerGroup.id}>
                  ${headerGroup.headers.map(
                    (header) => html`
                      <ps-table-cell variant="th" key=${header.id}>
                        ${header.isPlaceholder
                          ? null
                          : flexRenderer(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </ps-table-cell>
                    `
                  )}
                </ps-table-row>
              `
            )}
        </ps-table-head>
        <ps-table-body>
          ${this.table?.getRowModel()?.rows.map(
            (row) => html`
              <ps-table-row
                key=${row.id}
                class=${row.getIsSelected() ? 'is-selected' : null}
                selected=${ifDefined(row.getIsSelected() ? true : null)}
                actionable
                @click=${() => {
                  row.toggleSelected();
                }}
                @keydown=${(e: KeyboardEvent) => {
                  if (e.key === 'Enter' || e.code === 'Space') {
                    e.preventDefault();
                    row.toggleSelected();
                  }
                }}
                tabindex="0"
                role="button"
              >
                ${row
                  .getVisibleCells()
                  .map(
                    (cell) => html`
                      <ps-table-cell key=${cell.id}>
                        ${flexRenderer(
                          cell.column.columnDef?.cell,
                          cell.getContext()
                        )}
                      </ps-table-cell>
                    `
                  )}
              </ps-table-row>
            `
          )}
        </ps-table-body>
      </ps-table>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-data-table': DataTableWC;
  }
  enum PSElementTagNameMap {
    'ps-data-table' = 'ps-data-table',
  }
}

export type DataTableRowSelection<T> = PSCustomEvent<
  DataTableWC<T>,
  { selected: Row<T>[] }
>;
