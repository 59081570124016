import {
  Dropdown,
  DropdownPropsWithRef,
  Popover,
  Menu,
  MenuItem,
  Button,
  Icon,
} from '@pypestream/design-system';
import { useState } from 'react';
import {
  useBuilderCtxSelector,
  useBuilderStateMatches,
} from '../../../xstate/app.xstate';
import './workflow-dropdown.scss';

export const WorkflowDropdown = () => {
  const workflow = useBuilderCtxSelector((ctx) => ctx?.workflows ?? []);
  const canShowWorkflowDropdown = useBuilderStateMatches('workflows.loaded');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [manageWorkflowOpen, setManageWorkflowOpen] = useState(false);
  const [manageWorkflowState, setManageWorkflowState] = useState(true);

  const handleDropdownItemClick = (item: string) => {
    setSelectedWorkflow(item);
    console.log(item, 'workflow dropdown click');
  };

  const workflowDropdown = workflow?.map((item, idx) => {
    return (
      <MenuItem
        onClick={() => {
          handleDropdownItemClick(item.name as string);
          setIsMenuOpen(false);
        }}
        key={item.id}
        value={item.name as string}
        className="c-menu-item"
        selected={
          selectedWorkflow === item.name ||
          (idx === 0 && selectedWorkflow === '')
        }
      >
        {item.name}
      </MenuItem>
    );
  });

  const handleManageWorkflows = () => {
    setManageWorkflowOpen(!manageWorkflowOpen);
  };

  return (
    <>
      {canShowWorkflowDropdown && (
        <div>
          <Button
            variant="ghost"
            id="workflow-trigger"
            onClick={() => {
              // setIsMenuOpen(!isMenuOpen);
              setManageWorkflowOpen(false);
            }}
          >
            {selectedWorkflow || workflow[0]?.name}
            <Icon slot="suffix" name="chevron-down"></Icon>
          </Button>
          <Popover
            width="auto"
            trigger="workflow-trigger"
            active={isMenuOpen}
            placement="bottom"
            stayOpenOnSelect
            closeOnClickOutside={false}
            closeOnEscClick
            onAutoClose={(e) => {
              const composedPath = e.detail.composedPath() as HTMLElement[];

              if (
                composedPath.some((el) =>
                  ['ps-dropdown', 'ps-menu-item'].includes(
                    el?.tagName?.toLowerCase()
                  )
                )
              ) {
                e.stopPropagation();
                return;
              } else {
                e.target.hide();
              }
            }}
            onclose={() => {
              setManageWorkflowOpen(false);
              setIsMenuOpen(false);
            }}
          >
            <Menu>
              {workflowDropdown}
              <MenuItem id="manage-workflows-trigger">
                Manage Workflows
                <span className="c-menu-item__manage-workflows" slot="suffix">
                  {manageWorkflowState ? 'On' : 'Off'}
                  <Icon slot="suffix" name="chevron-right"></Icon>
                </span>
              </MenuItem>
              <Dropdown placement="right" trigger="manage-workflows-trigger">
                <Menu>
                  <MenuItem
                    onClick={() => setManageWorkflowState(!manageWorkflowState)}
                  >
                    Toggle Manage Workflow
                  </MenuItem>
                </Menu>
              </Dropdown>

              <MenuItem disabled>Auto Layout</MenuItem>
            </Menu>
          </Popover>
        </div>
      )}
    </>
  );
};
