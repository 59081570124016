import { GetMicroAppsQuery } from '@pypestream/api-services';

type MicroAppVersions = NonNullable<
  GetMicroAppsQuery['apps_app']
>[0]['versions'];

export const getAppDeployments = (versions: MicroAppVersions, appId?: number) =>
  versions
    .map(({ deployments, version_name }) =>
      deployments
        .map(({ deployment_id, deployed_at, environment }) => ({
          deployment_id,
          deployed_at,
          version: {
            version_name,
            app_id: appId,
          },
          environment,
        }))
        .filter((deployment) => deployment.environment?.is_production)
    )
    .flat()
    .sort(
      (a, b) =>
        new Date(b.deployed_at).getTime() - new Date(a.deployed_at).getTime()
    );

export type DeploymentDetails = ReturnType<typeof getAppDeployments>[0];
