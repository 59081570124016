import * as React from 'react';
import { createComponent } from '@lit/react';
import { AuthCardWC } from './auth-card.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type AuthCardProps = GenerateComponentTypeWithChildren<AuthCardWC>;

export type AuthCardPropsWithRef = GeneratePropsWithRef<AuthCardProps>;

export const AuthCard = createComponent({
  tagName: AuthCardWC.tagname,
  elementClass: AuthCardWC,
  react: React,
}) as AuthCardPropsWithRef;
