import latestIcon from '../../assets/latest-icon.svg?url';
import outdatedIcon from '../../assets/outdated-icon.svg?url';
import notDeployedIcon from '../../assets/not-deployed-icon.svg?url';
import { MicroAppItemProps } from './microapp-item';

// TODO: temporary get icon depends on status
export const getIcon = (status: MicroAppItemProps['status']) => {
  let url = '';

  switch (status) {
    case 'live':
      url = notDeployedIcon;
      break;
    case 'outdated':
      url = outdatedIcon;
      break;
    case 'latest':
    default:
      url = latestIcon;
      break;
  }

  return <img src={url} alt="status icon" />;
};

export const getStatusLabel = (status: MicroAppItemProps['status']) => {
  switch (status) {
    case 'live':
      return 'Not Deployed';
    case 'outdated':
      return 'Outdated Version';
    case 'latest':
    default:
      return 'Latest Version';
  }
};
