import { Params } from 'react-router-dom';
import { sendMicroAppsEvent, sendBuilderEvent } from '../xstate/app.xstate';

export const syncXstateWithRouteParams = ({
  params,
  searchQuery,
}: {
  params: Params<string>;
  searchQuery: URLSearchParams;
}) => {
  const { app_id: appId, version_id: versionId } = params;
  const appIdAsNumber = Number(appId);
  const versionIdAsNumber = Number(versionId);

  if (!isNaN(appIdAsNumber)) {
    sendMicroAppsEvent({
      type: 'microApps.selectAppId',
      id: appIdAsNumber,
    });
  } else {
    sendMicroAppsEvent({
      type: 'microApps.deselectAppId',
    });
  }

  if (versionId) {
    sendMicroAppsEvent({
      type: 'microApps.selectVersionId',
      id: versionIdAsNumber,
    });
    sendBuilderEvent({
      type: 'builder.set.csvBasedGraph',
      enable: !!searchQuery.get('csvBasedGraph'),
    });
    sendBuilderEvent('builder.resetToIdle');
  } else {
    sendMicroAppsEvent({
      type: 'microApps.deselectVersionId',
    });
    sendBuilderEvent('builder.resetToIdle');
  }
};
