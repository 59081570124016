import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './callout-description.scss?inline';

@customElement('ps-callout-description')
export class CalloutDescriptionWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <p class="c-callout-description">
        <slot></slot>
      </p>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-callout-description': CalloutDescriptionWC;
  }
  enum PSElementTagNameMap {
    'ps-callout-description' = 'ps-callout-description',
  }
}
