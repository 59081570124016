/* eslint-disable @typescript-eslint/no-explicit-any */
const idSet = (window._SVG_SPRITE_IDS_ =
    window._SVG_SPRITE_IDS_ || []);
export const spriteNodeId = '__SVG_SPRITE_NODE__';
import BrowserSpriteSymbol from 'svg-baker-runtime/src/browser-symbol';
import BrowserSprite from 'svg-baker-runtime/src/browser-sprite';
const svgSpriteSheet = new BrowserSprite({
    attrs: {
        id: '__SVG_SPRITE_NODE__',
    },
});
if (!window['svgSpriteSheet']) {
    window['svgSpriteSheet'] = svgSpriteSheet;
}
export default function addSymbol({ id, viewBox, content, }) {
    if (idSet.indexOf(id) > -1 || document.getElementById(id)) {
        console.warn(`Icon #${id} was duplicately registered. It must be globally unique.`);
    }
    window['svgSpriteSheet'].add(new BrowserSpriteSymbol({
        id,
        viewBox,
        content,
    }));
}
export { BrowserSprite, BrowserSpriteSymbol };
