import * as React from 'react';
import { createComponent } from '@lit/react';
import { RadioGroupWC } from './radio-group.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type RadioGroupProps =
  GenerateComponentTypeWithChildren<RadioGroupWC> & {
    onChange?: React.ChangeEventHandler<RadioGroupWC>;
    onInput?: React.ChangeEventHandler<RadioGroupWC>;
    onInvalid?: React.ChangeEventHandler<RadioGroupWC>;
  };

export type RadioGroupPropsWithRef = GeneratePropsWithRef<RadioGroupProps>;

export const RadioGroup = createComponent({
  tagName: RadioGroupWC.tagname,
  elementClass: RadioGroupWC,
  react: React,
  events: {
    onChange: 'change',
    onInput: 'input',
    onInvalid: 'invalid',
  },
}) as RadioGroupPropsWithRef;
