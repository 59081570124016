import * as React from 'react';
import { createComponent } from '@lit/react';
import { ScrollbarWC } from './scrollbar.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type ScrollbarProps = GenerateComponentTypeWithChildren<ScrollbarWC>;

export type ScrollbarPropsWithRef = GeneratePropsWithRef<ScrollbarProps>;

export const Scrollbar = createComponent({
  tagName: ScrollbarWC.tagname,
  elementClass: ScrollbarWC,
  react: React,
}) as ScrollbarPropsWithRef;
