import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import styles from './nav-extra.scss?inline';

@customElement('ps-nav-extra')
export class NavExtraWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="c-nav-extra">
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-nav-extra': NavExtraWC;
  }
  enum PSElementTagNameMap {
    'ps-nav-extra' = 'ps-nav-extra',
  }
}
