import { getBrowserLanguage } from '../navigator/index';

/**
 * Function to return capitalized string.
 * @param inputString - input string can be combination of single or multiple words
 * @param locale - Specific locale to consider while converting character case.
 *
 * @return string - Capitalized version of input string.
 */
export const getCapitalizedString = (
  [firstCharacter, ...restCharacters]: string,
  locale: string = getBrowserLanguage()
): string => {
  let capitalizedString = '';
  if (firstCharacter === undefined) {
    return '';
  }

  capitalizedString += firstCharacter.toLocaleUpperCase(locale);

  if (!restCharacters) {
    return capitalizedString;
  }
  capitalizedString += restCharacters.join('').toLocaleLowerCase(locale);

  return capitalizedString;
};

export const removeTrailingSlash = (value: string) => {
  // using this instead of regex for improved readability
  return value.endsWith('/') ? value.slice(0, -1) : value;
};
