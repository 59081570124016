import { createComponent } from '@lit/react';
import * as React from 'react';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { PortalEntranceWC } from './portal-entrance.wc';

export type PortalEntranceProps =
  GenerateComponentTypeWithChildren<PortalEntranceWC>;

export type PortalEntrancePropsWithRef =
  GeneratePropsWithRef<PortalEntranceProps>;

export const PortalEntrance = createComponent({
  tagName: PortalEntranceWC.tagname,
  elementClass: PortalEntranceWC,
  react: React,
}) as PortalEntrancePropsWithRef;
