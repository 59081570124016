import React from 'react';
import { ObjectFieldTemplateProps } from '@rjsf/utils';

export const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) =>
  props.properties.map((element) => (
    <div
      className={`c-rjsf-object-field c-rjsf-object-field--${element.name}`}
      key={element.name}
    >
      {element.content}
    </div>
  ));
