import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../../base-element';
import pageFooterStyles from './page-footer.scss?inline';

@customElement('ps-page-footer')
export class PageFooterWC extends BaseElement {
  static styles = unsafeCSS(pageFooterStyles);

  render() {
    return html`
      <div class="c-page-footer">
        <ps-wrapper>
          <slot></slot>
        </ps-wrapper>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-page-footer': PageFooterWC;
  }
}
