import { Sender, Machine, Typestate, Interpreter, assign } from 'xstate';
// import { assign } from '@xstate/immer';
import Cookies from 'js-cookie';
import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';
import {
  GlobalAppUserEvents,
  sendUserMessage,
  SharedEvents,
} from './app.xstate-utils';
import { sendGlobalAppEvent } from './app.xstate';

export interface UserInfo {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  profilePhoto?: string;
}

export interface UserContext {
  user?: {
    userId?: string;
    email?: string;
    profilePhoto?: string;
    info?: UserInfo;
    firstName?: string;
    lastName?: string;
    permissions?: {
      canPublish?: boolean;
    };
    canPublish?: boolean;
  };
}

export interface UserState {
  states: {
    unknown: Record<string, unknown>;
    authError: Record<string, unknown>;
    loggedOut: {
      states: {
        idle: Record<string, unknown>;
        loggingIn: Record<string, unknown>;
      };
    };
    loggedIn: {
      states: {
        // loading: Record<string, unknown>;
        // loadingError: Record<string, unknown>;
        loaded: {
          states: {
            userInfo: {
              states: {
                idle: Record<string, unknown>;
                updating: Record<string, unknown>;
              };
            };
          };
        };
      };
    };
  };
}

export type UserEvents = GlobalAppUserEvents | SharedEvents;

/** Fetch user from db */
// async function loadUser({
//   userId,
//   email,
// }: {
//   userId?: string;
//   email?: string;
// }): Promise<{
//   firstName?: string;
//   lastName?: string;
//   profilePic?: string;
//   displayName?: string;
// }> {
//   const today = new Date().toISOString();
//   if (!userId) return {};
//   // throw new Error(`Load User missing userId`);
//   if (!email) throw new Error('Load User missing email');
//   try {
//     return {
//       firstName: 'Samantha',
//       lastName: 'Smith',
//       profilePic: '',
//       displayName: 'Sam',
//     };
//   } catch (e) {
//     console.error(e);
//     throw new Error(
//       `Loading User Details failed, userId probably does not exist in our DB: "${userId}""`
//     );
//   }
// }

export interface UserTypestates extends Typestate<UserContext> {
  context: UserContext;
  value: MachineStateSchemaPaths<UserState['states']>;
}

export type UserInterpreter = Interpreter<
  UserContext,
  UserState,
  UserEvents,
  UserTypestates
>;

const { createInvokablePromise, createXstateHooks: createUserXstateHooks } =
  getXstateUtils<UserContext, UserEvents, UserTypestates, UserState>();

export { createUserXstateHooks };

// const userAuthedTransition: TransitionConfig<
//   UserContext,
//   Extract<UserEvents, { type: 'user.authed' }>
// > = {
//   target: 'loggedIn',
//   cond: function hasUseId(ctx, event) {
//     console.log('userAuthedTransition');
//     if (ctx?.user?.userId) {
//       return true;
//     }
//     console.error(
//       'Attempting to transition to state "loggedIn" but the event does not contain userId',
//       { event }
//     );
//     return false;
//   },
//   actions: [
//     assign((ctx, { user }) => {
//       ctx.user = user;
//     }),
//     forwardTo(uiMachine.id),
//   ],
// };

export const userMachine = Machine<UserContext, UserState, UserEvents>({
  type: 'atomic',
  strict: true,
  id: 'user',
  initial: 'unknown',
  predictableActionArguments: true,
  preserveActionOrder: true,
  invoke: {
    id: 'userAuthObserver',
    src: (ctx, event) => (sendEvent: Sender<GlobalAppUserEvents>) => {
      // window.auth0 = auth0;
      // console.log('userAuthObserver', ctx, event);
      // const cachedUserData = Cookies.get('pypestream-user-info');
      // note #1. just a quick and dirty way to temporarily "cache" our fake user session. normally this revalidation would be handled by a service like Auth0 via a refresh token
      // note #2. the "proper" thing to do would be to do runtime validation on the incoming info before assigning it (ie. via superstruct)
      // note #3: FYI, our placeholder user Context data doesn't contain any sensitive info!)
      // if (cachedUserData !== undefined) {
      //   sendEvent({
      //     type: 'user.signingBackIn',
      //     user: JSON.parse(cachedUserData),
      //   });
      // }
      // example of how we could have a continuously running background task watching for URL query string parameters (ie. from an Auth0 log in callback) which we parse / remove to validate an OK login
      // we'd want to send out events (via the sendEvent function we have available) if, for example, we wanted to move to an error state
    },
  },
  context: {
    user: {
      canPublish: true,
    },
  },
  states: {
    unknown: {
      always: [
        {
          target: 'loggedOut',
          cond: function userNotLoggedIn(ctx) {
            return ctx?.user?.email === undefined || ctx.user.email === '';
          },
        },
      ],
      on: {
        'user.signIn': {
          actions: assign((ctx, event) => {
            console.log('setting user email...');
            if (event.email) {
              ctx.user = ctx.user || {};
              ctx.user.email = event.email;
            }
            return ctx;
          }),
        },
        'user.signOut': {
          target: 'loggedOut',
          actions: [
            assign((ctx) => {
              ctx.user = {};
              return ctx;
              // Cookies.remove('pypestream-user-info');
            }),
          ],
        },
        'user.signingBackIn': {
          target: 'loggedIn',
          actions: [
            assign((ctx, event) => {
              ctx.user = { ...ctx.user, ...event.user };
              return ctx;
            }),
          ],
        },
        'user.authError': {
          target: 'authError',
          actions: (ctx, event) => {
            sendUserMessage({
              type: 'error',
              text: event.errorMsg,
            });
          },
        },
        // 'user.authed': userAuthedTransition,
      },
    },
    authError: {
      id: 'authError',
      always: [
        {
          target: 'loggedOut',
        },
      ],
    },
    loggedOut: {
      initial: 'idle',
      entry() {
        sendGlobalAppEvent({
          type: 'user.loggedOut',
        });
      },
      on: {
        'user.signingBackIn': {
          target: 'loggedIn',
          actions: [
            assign((ctx, event) => {
              ctx.user = { ...ctx.user, ...event.user };
              return ctx;
            }),
          ],
        },
        // 'user.authed': userAuthedTransition,
      },
      states: {
        idle: {
          on: {
            'user.signIn': {
              target: 'loggingIn',
              actions: assign((ctx, { email }) => {
                console.log('loggingIn', email);
                if (ctx.user !== undefined) ctx.user.email = email;
                return ctx;
              }),
            },
          },
        },
        loggingIn: {
          invoke: createInvokablePromise({
            id: 'loggingIn',
            src: async (ctx, event): Promise<void | UserContext['user']> => {
              if (event.type === 'user.signIn') {
                if (
                  event.email === 'test@tester.com'
                  // && event.password === 'studio-2023'
                ) {
                  return {
                    ...ctx.user,
                    email: event.email,
                    userId: '28433274-bf23-435e-9c71-7ecf2e1da6a9',
                    info: {
                      displayName: 'Bob',
                      firstName: 'Robert',
                      lastName: 'Smith',
                    },
                  };
                } else {
                  throw Error('wrong username or password');
                }
              }
            },
            onDoneTarget: '#loggedIn',
            onDoneAssignContext({ ctx, data }) {
              console.log('onDoneAssignContext', data);
              if (data !== undefined) {
                ctx.user = data;
              }
            },
            onErrorTarget: '#authError',
            onErrorActions: [
              {
                type: 'sendUserMessage',
                exec(ctx, event) {
                  sendUserMessage({
                    type: 'error',
                    text: `Error logging in user: ${event.data.message}`,
                    autoClose: 3000,
                  });
                },
              },
            ],
          }),
        },
      },
    },
    loggedIn: {
      id: 'loggedIn',
      initial: 'loaded',
      entry(context) {
        sendGlobalAppEvent({
          type: 'app.sendUserMessage',
          msg: {
            text: 'logged in!!',
            type: 'success',
            autoClose: 1000,
          },
        });

        sendGlobalAppEvent({
          type: 'user.loggedIn',
        });

        // Cookies.set('pypestream-user-info', JSON.stringify(context.user));
      },
      exit: [
        assign((ctx) => {
          ctx.user = undefined;
          return ctx;
        }),
        {
          type: 'removeUserToken',
          exec: () => {
            console.log('remove user token..');
          },
        },
      ],
      on: {
        'user.signOut': {
          target: 'loggedOut',
          actions: [
            assign((ctx) => {
              ctx.user = {};
              Cookies.remove('pypestream-user-info');
              return ctx;
            }),
          ],
        },
      },
      states: {
        // @todo: add loading, error, etc for fetching logged in user's profile photo and such
        loaded: {},
      },
    },
  },
});
