import * as React from 'react';
import { createComponent } from '@lit/react';
import { AppletCardImageWC } from './applet-card-image.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type AppletCardImageProps =
  GenerateComponentTypeWithChildren<AppletCardImageWC>;

export type AppletCardImagePropsWithRef =
  GeneratePropsWithRef<AppletCardImageProps>;

export const AppletCardImage = createComponent({
  tagName: AppletCardImageWC.tagname,
  elementClass: AppletCardImageWC,
  react: React,
}) as AppletCardImagePropsWithRef;
