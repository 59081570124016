import React, { FC, useState, useEffect } from 'react';
import 'react-dates/initialize';
import {
  DayPickerRangeController,
  type FocusedInputShape,
  isSameDay,
} from 'react-dates';
import { type Moment } from 'moment/moment';
import moment from 'moment';
import { Menu, MenuItem } from '../../menu';
import { Input, InputProps } from '../../input';
import { Button, IconButton } from '../../buttons';
import { Stack } from '../../stack';
import { Icon } from '../../icon';
import { TextOverline } from '../../text';
import { Scrollbar } from '../../scrollbar';
import './range-datepicker.scss';
import { Tooltip } from '../../tooltip';
import { addLeadingZeros, formatDateInput, formatYear } from '../utils';

const INPUT_FORMAT = 'MM/DD/YYYY';
const ERROR_MESSAGE = 'Invalid date';

const renderNavPrevButton = ({
  ariaLabel,
  disabled,
  onClick,
}: {
  ariaLabel?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
}) => (
  <IconButton
    aria-label={ariaLabel}
    disabled={disabled}
    onClick={onClick}
    style={{ position: 'absolute', top: -4, left: 24 }}
    name="chevron-left"
    size="small"
  />
);

const renderNavNextButton = ({
  ariaLabel,
  disabled,
  onClick,
}: {
  ariaLabel?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
}) => (
  <IconButton
    aria-label={ariaLabel}
    disabled={disabled}
    onClick={onClick}
    style={{ position: 'absolute', top: -4, right: 18 }}
    name="chevron-right"
    size="small"
  />
);

const renderMonthText = (month: Moment) => (
  <TextOverline variant="primary" textAlign="center">
    {moment(month).format('MMMM YYYY')}
  </TextOverline>
);

export type RangeDatepickerPresetProps = {
  text: string;
  start: Moment;
  end: Moment;
  id: string;
};

const today = moment();
const yesterday = moment().subtract(1, 'day');
const defaultPresets = [
  {
    text: 'Today',
    start: today,
    end: today,
    id: 'dStart',
  },
  {
    text: 'Yesterday',
    start: yesterday,
    end: yesterday,
    id: '-1dStart',
  },
  {
    text: 'Last 24 hours',
    start: moment().subtract(1, 'day'),
    end: today,
    id: '-24h',
  },
  {
    text: 'Last 7 days',
    start: moment().subtract(6, 'day'),
    end: today,
    id: '-7d',
  },
  {
    text: 'Last 14 days',
    start: moment().subtract(13, 'day'),
    end: today,
    id: '-14d',
  },
  {
    text: 'Last 30 days',
    start: moment().subtract(29, 'day'),
    end: today,
    id: '-30d',
  },
  {
    text: 'Last 90 days',
    start: moment().subtract(89, 'day'),
    end: today,
    id: '-90d',
  },
];

interface RangeDatepickerProps {
  presets?: RangeDatepickerPresetProps[];
  omitDefaultPresets?: boolean;
  startDate?: Moment | null;
  endDate?: Moment | null;
  onDatesChange?: ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
    id?: RangeDatepickerPresetProps['id'];
  }) => void;
  onCancel?: () => void;
  onSubmit?: ({
    startDate,
    endDate,
    id,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
    id?: RangeDatepickerPresetProps['id'];
  }) => void;
}

export const RangeDatepicker: FC<RangeDatepickerProps> = ({
  presets,
  omitDefaultPresets = false,
  startDate: initialStartDate = null,
  endDate: initialEndDate = null,
  onDatesChange,
  onCancel,
  onSubmit,
}) => {
  const [startDate, setStartDate] = React.useState<Moment | null>(
    initialStartDate
  );
  const [endDate, setEndDate] = React.useState<Moment | null>(initialEndDate);
  const [filterKey, setFilterKey] = React.useState<
    RangeDatepickerPresetProps['id'] | undefined
  >();
  const [focusedInput, setFocusedInput] =
    React.useState<FocusedInputShape>('startDate');
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const [dateDifference, setDateDifference] = React.useState<number>(0);
  const [hasErrorStartDate, setHasErrorStartDate] =
    React.useState<boolean>(false);
  const [hasErrorEndDate, setHasErrorEndDate] = React.useState<boolean>(false);
  const [rawStartDate, setRawStartDate] = React.useState<string>(
    startDate ? moment(startDate).format(INPUT_FORMAT) : ''
  );
  const [rawEndDate, setRawEndDate] = useState<string>(
    endDate ? moment(endDate).format(INPUT_FORMAT) : ''
  );

  useEffect(() => {
    if (startDate && endDate) {
      setDateDifference(endDate.diff(startDate, 'days'));
    }
  }, [startDate, endDate]);

  const onChange = ({
    startDate: start,
    endDate: end,
    id,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
    id?: RangeDatepickerPresetProps['id'];
  }) => {
    setStartDate(start);
    setEndDate(end);
    setRawStartDate(start ? moment(start).format(INPUT_FORMAT) : '');
    setRawEndDate(end ? moment(end).format(INPUT_FORMAT) : '');
    setHasErrorStartDate(false);
    setHasErrorEndDate(false);
    setFilterKey(id);
    onDatesChange?.({
      startDate: start,
      endDate: end,
      ...(id && { id }),
    });
  };

  const handleStartDateChange: InputProps['onChange'] = (e) => {
    let value = addLeadingZeros(e.target.value);
    value = formatYear(value);
    value = formatDateInput(value);
    setRawStartDate(value);
    const date = moment(value, INPUT_FORMAT, true);
    if (date.isValid()) {
      setStartDate(date);
      setHasErrorStartDate(false);
      if (endDate && date.isAfter(endDate)) {
        setEndDate(moment(date).add(dateDifference, 'days'));
        setRawEndDate(
          moment(date).add(dateDifference, 'days').format(INPUT_FORMAT)
        );
      }
    } else {
      setHasErrorStartDate(true);
    }
  };

  const handleEndDateChange: InputProps['onChange'] = (e) => {
    let value = addLeadingZeros(e.target.value);
    value = formatYear(value);
    value = formatDateInput(value);
    setRawEndDate(value);
    const date = moment(value, INPUT_FORMAT, true);
    if (date.isValid()) {
      setEndDate(date);
      setHasErrorEndDate(false);
      if (startDate && date.isBefore(startDate)) {
        setStartDate(moment(date).subtract(dateDifference, 'days'));
        setRawStartDate(
          moment(date).subtract(dateDifference, 'days').format(INPUT_FORMAT)
        );
      }
    } else {
      setHasErrorEndDate(true);
    }
  };

  const renderDatePresets = () => {
    let presetsArr: RangeDatepickerPresetProps[] = [];

    if (!omitDefaultPresets) {
      presetsArr = defaultPresets;
    }

    if (presets) presetsArr = [...presetsArr, ...presets];

    if (presetsArr.length <= 0) return null;

    return (
      <div className="c-range-datepicker__presets">
        <Scrollbar>
          <Menu>
            {presetsArr?.map(({ text, start, end, id }) => {
              let isSelected = false;

              if (startDate && endDate) {
                isSelected =
                  text === selectedPreset &&
                  isSameDay(start, startDate) &&
                  isSameDay(end, endDate);
              }

              return (
                <MenuItem
                  key={text.replace(/\s+/g, '')}
                  selected={isSelected}
                  onClick={() => {
                    setSelectedPreset(text);
                    setHasErrorStartDate(false);
                    setHasErrorEndDate(false);
                    onChange({
                      startDate: start,
                      endDate: end,
                      id,
                    });
                  }}
                >
                  {text}
                </MenuItem>
              );
            })}
          </Menu>
        </Scrollbar>
      </div>
    );
  };

  const orientation = window.matchMedia('(max-width: 767px)').matches
    ? 'vertical'
    : 'horizontal';

  const isDisabledApplyButton =
    !startDate || !endDate || hasErrorStartDate || hasErrorEndDate;

  return (
    <div className="c-range-datepicker">
      {renderDatePresets()}
      <div className="c-range-datepicker__main">
        <div className="c-range-datepicker__control">
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={onChange}
            focusedInput={focusedInput}
            onFocusChange={(input) => {
              setFocusedInput(!input ? 'startDate' : input);
            }}
            withPortal={false}
            numberOfMonths={2}
            noBorder
            initialVisibleMonth={null}
            hideKeyboardShortcutsPanel
            orientation={orientation}
            renderNavPrevButton={renderNavPrevButton}
            renderNavNextButton={renderNavNextButton}
            renderMonthText={renderMonthText}
            daySize={40}
          />
        </div>
        <div className="c-range-datepicker__controls">
          <Stack
            alignItems="start"
            gutter="small"
            justifyContent="space-between"
          >
            <Stack alignItems="start" gutter="small">
              <Input
                variant="outlined"
                style={{ width: 125 }}
                placeholder="mm/dd/yyyy"
                value={rawStartDate}
                onChange={handleStartDateChange}
                hasError={hasErrorStartDate}
                helpText={hasErrorStartDate ? ERROR_MESSAGE : ''}
              />
              <Icon
                name="arrow-right"
                style={{ marginTop: 12 }}
                size="xsmall"
              />
              <Input
                variant="outlined"
                style={{ width: 125 }}
                placeholder="mm/dd/yyyy"
                value={rawEndDate}
                onChange={handleEndDateChange}
                hasError={hasErrorEndDate}
                helpText={hasErrorEndDate ? ERROR_MESSAGE : ''}
              />
            </Stack>
            <Stack
              alignItems="start"
              justifyContent="end"
              gutter="small"
              className="c-range-datepicker__actions"
            >
              <Button
                size="large"
                variant="ghost"
                onClick={() => {
                  setStartDate(initialStartDate);
                  setEndDate(initialEndDate);
                  setFilterKey(undefined);
                  setSelectedPreset(null);
                  onCancel?.();
                }}
              >
                Cancel
              </Button>
              {isDisabledApplyButton ? (
                <Tooltip content="Please fill in both dates correctly">
                  <Button size="large" disabled>
                    Apply
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  size="large"
                  onClick={() =>
                    onSubmit?.({
                      startDate,
                      endDate,
                      id: filterKey,
                    })
                  }
                >
                  Apply
                </Button>
              )}
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};
