import * as React from 'react';
import { createComponent } from '@lit/react';
import { MenuWC } from './menu.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type MenuProps = GenerateComponentTypeWithChildren<MenuWC> & {
  onSelect?: React.ChangeEventHandler<MenuWC>;
};

export type MenuPropsWithRef = GeneratePropsWithRef<MenuProps>;

export const Menu = createComponent({
  tagName: MenuWC.tagname,
  elementClass: MenuWC,
  react: React,
  events: {
    onSelect: 'select',
  },
}) as MenuPropsWithRef;
