import { createComponent } from '@lit/react';
import * as React from 'react';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import {
  PortalDestinationWC,
  UpdatePortalContentEvent,
} from './portal-destination.wc';

export type PortalDestinationProps =
  GenerateComponentTypeWithChildren<PortalDestinationWC> & {
    updatePortalContent?: (event: UpdatePortalContentEvent) => void;
  };

export type PortalDestinationPropsWithRef =
  GeneratePropsWithRef<PortalDestinationProps>;

export const PortalDestination = createComponent({
  tagName: PortalDestinationWC.tagname,
  elementClass: PortalDestinationWC,
  react: React,
  events: {
    updatePortalContent: 'update-portal-content',
  },
}) as PortalDestinationPropsWithRef;
