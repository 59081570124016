import { Tag } from '@pypestream/design-system';

import { useMicroappsCtxSelector } from '../../xstate/app.xstate';

export const VersionIndicator = () => {
  const { microAppDetails, selectedVersionId } = useMicroappsCtxSelector(
    (ctx) => ({
      selectedVersionId: ctx.selectedVersionId,
      microAppDetails: ctx.data?.find(
        ({ app_id }) => app_id === ctx.selectedAppId
      ),
    })
  );

  const indicator = true;

  return (
    <div>
      <Tag variant="indicator" minimal={indicator}>
        {
          microAppDetails?.versions.find(
            (version) => version.version_id == selectedVersionId
          )?.version_name
        }
      </Tag>
      {/* draft indicator to be added once proper logic is implemented  */}
      {/* <Tag variant="indicator" minimal={indicator}>
       draft
     </Tag> */}
    </div>
  );
};
