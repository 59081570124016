import { FormControlMixin } from '@open-wc/form-control';
import { html, unsafeCSS } from 'lit';
import { live } from 'lit-html/directives/live.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import styles from './search.scss?inline';

@customElement('ps-search')
export class SearchWC extends FormControlMixin(BaseElement) {
  static styles = unsafeCSS(styles);

  @query('.c-search__input') input: HTMLInputElement;

  @property({ reflect: true, type: String }) value = '';

  /** The search component's size. */
  @property({ reflect: true }) size: 'medium' | 'large' | 'xlarge' = 'medium';

  /** The button's variant. */
  @property({ reflect: true }) variant = 'filled' || 'outlined';

  /** Placeholder text to show as a hint when the input is empty. */
  @property({ reflect: true }) placeholder = 'Search';

  /** Error boolean */
  @property({ reflect: true, type: Boolean }) hasError = false;

  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;

  @state() hasFocus = false;

  private handleChange() {
    this.value = this.input.value;
    this.emit('change');
  }

  private handleInput() {
    this.value = this.input.value;
    this.emit('input');
  }

  private focusInput(e: PointerEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.input.focus();
    this.value = '';
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.emit('enter');
      // Not sure how to type this
      this.focusInput(e as unknown as PointerEvent);
    }
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('focus');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('blur');
  }

  render() {
    return html`
      <div
        class="${classMap({
          'c-search': true,
          [`c-search--size-${this.size}`]: this.size,
        })}"
        data-cy="search"
      >
        <ps-icon
          data-cy="search-icon"
          class="c-search__icon"
          name="search"
        ></ps-icon>
        <input
          autocomplete="on"
          class="${classMap({
            'c-search__input': true,
            [`c-search__input--variant-${this.variant}`]: this.variant,
            [`c-search__input--size-${this.size}`]: this.size,
            [`c-search__input--error`]: this.hasError,
          })}"
          placeholder=${this.placeholder}
          type="text"
          .value=${live(this.value)}
          @change=${this.handleChange}
          @keydown=${this.handleKeyDown}
          @input=${this.handleInput}
          data-cy="search-input"
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          ?autofocus=${this.autofocus}
        />
        <ps-icon-button
          @click=${this.focusInput}
          @keydown=${this.handleKeyDown}
          size="xsmall"
          name="close"
          class="c-search__clear-icon"
          data-cy="search-reset-button"
        ></ps-icon-button>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-search': SearchWC;
  }
  enum PSElementTagNameMap {
    'ps-search' = 'ps-search',
  }
}
