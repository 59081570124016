import { html, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../../components/base-element';
import '../../../components/text/text-body.wc';
import '../../../components/avatar/avatar.wc';
import styles from './applet-card-info.scss?inline';

@customElement('ps-applet-card-info')
export class AppletCardInfoWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** Avatar label attribute. */
  @property({
    reflect: true,
  })
  label?: string;

  /**
   * The src attribute for the avatar.
   */
  @property({ reflect: true }) src?: string;

  render() {
    return html`
      <div class="c-applet-card-info">
        <div class="c-applet-card-info__label">
          ${this.label
            ? html` <ps-text-subtitle size="xsmall" variant="secondary" truncate
                >${this.label}:</ps-text-subtitle
              >`
            : nothing}
        </div>
        <div class="c-applet-card-info__content">
          <ps-text-body variant="secondary" size="small" truncate-lines="2">
            <slot></slot>
          </ps-text-body>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-applet-card-info': AppletCardInfoWC;
  }
}
