import * as React from 'react';
import { createComponent } from '@lit/react';
import { SimpleSelectWC } from './simple-select.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type SimpleSelectProps =
  GenerateComponentTypeWithChildren<SimpleSelectWC> & {
    onChange?: React.ChangeEventHandler<SimpleSelectWC>;
  };

export type SimpleSelectPropsWithRef = GeneratePropsWithRef<SimpleSelectProps>;

export const SimpleSelect = createComponent({
  tagName: SimpleSelectWC.tagname,
  elementClass: SimpleSelectWC,
  react: React,
  events: {
    onChange: 'change',
  },
}) as SimpleSelectPropsWithRef;
