import * as React from 'react';
import { createComponent } from '@lit/react';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { AccordionItemWC } from './accordion-item.wc';

export type AccordionItemProps =
  GenerateComponentTypeWithChildren<AccordionItemWC>;

export type AccordionItemPropsWithRef =
  GeneratePropsWithRef<AccordionItemProps>;

export const AccordionItem = createComponent({
  tagName: AccordionItemWC.tagname,
  elementClass: AccordionItemWC,
  react: React,
}) as AccordionItemPropsWithRef;
