export enum LaunchDarklyEnvs {
  latest = 'latest',
  next = 'next',
  dev = 'dev',
}

export const launchDarklyEnvs = [
  LaunchDarklyEnvs.next,
  LaunchDarklyEnvs.latest,
  LaunchDarklyEnvs.dev,
];
