import { Configuration, FrontendApi } from '@ory/client';
export const kratosApi = new FrontendApi(
  new Configuration({
    basePath: import.meta.env.FE_KRATOS_ENDPOINT,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export { Session } from '@ory/client';
