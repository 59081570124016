import * as React from 'react';
import { createComponent } from '@lit/react';
import { TabGroupWC } from './tab-group.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TabGroupProps = GenerateComponentTypeWithChildren<TabGroupWC>;

export type TabGroupPropsWithRef = GeneratePropsWithRef<TabGroupProps>;

export const TabGroup = createComponent({
  tagName: TabGroupWC.tagname,
  elementClass: TabGroupWC,
  react: React,
}) as TabGroupPropsWithRef;
