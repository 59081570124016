/** @todo: work on eliminating the need for this entire file (ie. easier way to grab this data in a much more official way) */

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EventObject,
  State,
  StateSchema,
  TypegenDisabled,
  Typestate,
} from 'xstate';
import {
  builderService,
  globalAppService,
  microAppsService,
  uiService,
  userService,
} from '../xstate/app.xstate';
import {
  GLOBAL_APP_SUB_MACHINE_IDS,
  GlobalAppContext,
  GlobalAppEvents,
  GlobalAppStateSchema,
} from '../xstate/app.xstate-utils';
import { UiContext, UiEvents, UiState } from '../xstate/ui.xstate';
import { UserContext, UserEvents, UserState } from '../xstate/user.xstate';
import {
  BuilderEvents,
  BuilderPageContext,
  BuilderState,
} from '../xstate/builder/builder.xstate';
import {
  MicroAppsContext,
  MicroAppsState,
  MicroAppsEvents,
} from '../xstate/micro-apps/micro-apps.xstate';
import { useEffect, useState } from 'react';

interface MachineTypestatesGeneric<TMachineContext>
  extends Typestate<TMachineContext> {
  context: TMachineContext;
  value: any;
}

type MachineCurrentStateGeneric<
  TMachineContext,
  TMachineState extends StateSchema<any>,
  TMachineEvents extends EventObject,
> = State<
  TMachineContext,
  TMachineEvents,
  TMachineState,
  MachineTypestatesGeneric<TMachineContext>,
  TypegenDisabled
>;

type GlobalCurrentState = MachineCurrentStateGeneric<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents
>;

type UiCurrentState = MachineCurrentStateGeneric<UiContext, UiState, UiEvents>;

type UserCurrentState = MachineCurrentStateGeneric<
  UserContext,
  UserState,
  UserEvents
>;

type BuilderCurrentState = MachineCurrentStateGeneric<
  BuilderPageContext,
  BuilderState,
  BuilderEvents
>;

type MicroAppCurrentState = MachineCurrentStateGeneric<
  MicroAppsContext,
  MicroAppsState,
  MicroAppsEvents
>;

export interface ComplexState {
  global?: GlobalCurrentState;
  ui?: UiCurrentState;
  user?: UserCurrentState;
  builder?: BuilderCurrentState;
  microApps?: MicroAppCurrentState;
}

const services = [
  {
    name: 'global',
    service: globalAppService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.ui,
    service: uiService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.user,
    service: userService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.builder,
    service: builderService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.microApps,
    service: microAppsService,
  },
];

export const useCurrentState = () => {
  const [currentState, setCurrerntState] = useState<ComplexState | null>(null);

  useEffect(() => {
    services.forEach(({ service, name }) => {
      // @ts-ignore
      service.subscribe((state) => {
        setCurrerntState((prevState) => ({
          ...prevState,
          [name]: state,
        }));
      });
    });
  }, []);

  return currentState;
};
