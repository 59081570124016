import * as React from 'react';
import { createComponent } from '@lit/react';
import { UploadWC } from './upload.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type UploadProps = GenerateComponentTypeWithChildren<UploadWC> & {
  onChange?: React.ChangeEventHandler<UploadWC>;
};

export type UploadPropsWithRef = GeneratePropsWithRef<UploadProps>;

export const Upload = createComponent({
  tagName: UploadWC.tagname,
  elementClass: UploadWC,
  react: React,
  events: {
    onChange: 'change',
  },
}) as UploadPropsWithRef;
