import * as React from 'react';
import SchemaField from '@rjsf/core/lib/components/fields/SchemaField';
import { type RuleProps, useRule } from 'react-querybuilder';

export const RuleFieldForm = (
  ruleProps: RuleProps & ReturnType<typeof useRule>
) => (
  <SchemaField
    onChange={(formData, _es, id) => {
      if (id?.endsWith('_condition_query_options_operator')) {
        ruleProps.generateOnChangeHandler('operator')(
          formData?.options.operator
        );
      } else if (id?.includes('_condition_query_options_')) {
        const options = { ...formData?.options };
        delete options.operator;
        ruleProps.generateOnChangeHandler('value')(JSON.stringify(options));
      } else if (id?.endsWith('_pkg')) {
        ruleProps.generateOnChangeHandler('field')(formData?.pkg);
      }
    }}
    onBlur={() => {
      //
    }}
    onFocus={() => {
      //
    }}
    readonly={ruleProps.context.readonly}
    disabled={ruleProps.context.disabled}
    registry={ruleProps.context.registry}
    idSchema={ruleProps.context.idSchema}
    name={ruleProps.context.name}
    key={`${ruleProps.id}-${ruleProps.path}`}
    schema={ruleProps.context.conditionSchema}
    formData={{
      pkg: ruleProps.field,
      options: {
        operator: ruleProps.operator,
        ...JSON.parse(ruleProps.value || '{}'),
      },
    }}
    uiSchema={
      {
        // pkg: {
        //   'ui:widget': 'CustomWidgetComponent',
        // },
        // options: {
        //   operator: {
        //     'ui:widget': 'PlainTextComponent',
        //   },
        // },
      }
    }
  />
);
