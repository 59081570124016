import * as React from 'react';
import { createComponent } from '@lit/react';
import { DrawerPanelWC, DrawerPanelToggleEvent } from './drawer-panel.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type DrawerPanelProps =
  GenerateComponentTypeWithChildren<DrawerPanelWC> & {
    anchor?: 'left' | 'right';
    onDrawerPanelToggle?: (event: DrawerPanelToggleEvent) => void;
  };

export type DrawerPanelPropsWithRef = GeneratePropsWithRef<DrawerPanelProps>;

export const DrawerPanel = createComponent({
  tagName: DrawerPanelWC.tagname,
  elementClass: DrawerPanelWC,
  react: React,
  events: {
    onDrawerPanelToggle: 'drawer-panel-toggle',
  },
}) as DrawerPanelPropsWithRef;
