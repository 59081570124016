import * as React from 'react';
import { createComponent } from '@lit/react';
import { WidgetWC } from './widget.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type WidgetProps = GenerateComponentTypeWithChildren<WidgetWC>;

export type WidgetPropsWithRef = GeneratePropsWithRef<WidgetProps>;

export const Widget = createComponent({
  tagName: WidgetWC.tagname,
  elementClass: WidgetWC,
  react: React,
});
