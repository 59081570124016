import * as React from 'react';
import { createComponent } from '@lit/react';
import { NavActionsWC } from './nav-actions.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type NavActionsProps = GenerateComponentTypeWithChildren<NavActionsWC>;

export type NavActionsPropsWithRef = GeneratePropsWithRef<NavActionsProps>;

export const NavActions = createComponent({
  tagName: NavActionsWC.tagname,
  elementClass: NavActionsWC,
  react: React,
}) as NavActionsPropsWithRef;
