import { RJSFSchema } from '@rjsf/utils';
import { object, optional, string, Infer, boolean } from 'superstruct';
import {
  messageTypeSchema,
  ContentTypes,
  commonMessageContent,
} from './common';

export const WebviewContentStruct = object({
  messageType: string(),
  url: string(),
  type: string(),
  label: string(),
  inline: optional(boolean()),
  id: optional(string()),
  lastUpdated: optional(string()),
});

export type WebviewContent = Infer<typeof WebviewContentStruct>;

export const webviewContentSchema: RJSFSchema = {
  title: ContentTypes.Webview,
  type: 'object',
  properties: {
    messageType: {
      ...messageTypeSchema,
      default: ContentTypes.Webview,
    },
    type: {
      type: 'string',
      enum: ['static'],
    },
    url: {
      type: 'string',
    },
    inline: {
      type: 'boolean',
    },
    ...commonMessageContent,
  },
  required: ['messageType', 'type', 'url'],
  if: { properties: { inline: { const: false } } },
  then: {
    properties: {
      label: {
        type: 'string',
      },
    },
    required: ['label'],
  },
};
