import React from 'react';
import { FieldTemplateProps } from '@rjsf/utils';

export function CustomFieldTemplate(props: FieldTemplateProps) {
  const { classNames, help, errors, children } = props;
  return (
    <div className={`c-rjsf-field ${classNames}`}>
      {children}
      {errors}
      {help}
    </div>
  );
}
