import * as React from 'react';
import { createComponent } from '@lit/react';
import { BlockListWC } from './block-list.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type BlockListProps = GenerateComponentTypeWithChildren<BlockListWC>;

export type BlockListPropsWithRef = GeneratePropsWithRef<BlockListProps>;

export const BlockList = createComponent({
  tagName: BlockListWC.tagname,
  elementClass: BlockListWC,
  react: React,
}) as BlockListPropsWithRef;
