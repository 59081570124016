import * as React from 'react';
import { createComponent } from '@lit/react';
import { SpinnerWC } from './spinner.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type SpinnerProps = GenerateComponentTypeWithoutChildren<SpinnerWC>;

export type SpinnerPropsWithRef = GeneratePropsWithRef<SpinnerProps>;

export const Spinner = createComponent({
  tagName: SpinnerWC.tagname,
  elementClass: SpinnerWC,
  react: React,
}) as SpinnerPropsWithRef;
