/* eslint-disable wc/guard-super-call */
/* eslint-disable no-plusplus */
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { html, unsafeCSS, css } from 'lit';
import { BaseElement, customElement, watch } from '../base-element';
import styles from './tab-panel.scss?inline';

export const tagname = 'ps-tab-panel';

let id = 0;
@customElement('ps-tab-panel')
export class TabPanelWC extends BaseElement {
  static styles = css`
    ${unsafeCSS(styles)}
  `;

  private readonly attrId = ++id;

  private readonly componentId = `ps-tab-panel-${this.attrId}`;

  /** The tab panel's name. */
  @property({ reflect: true }) name = '';

  /** When true, the tab panel will be shown. */
  @property({ type: Boolean, reflect: true }) active = false;

  connectedCallback() {
    super.connectedCallback();
    this.id = this.id.length > 0 ? this.id : this.componentId;
    this.setAttribute('role', 'tabpanel');
  }

  @watch('active')
  handleActiveChange() {
    this.setAttribute('aria-hidden', this.active ? 'false' : 'true');
  }

  render() {
    return html`
      <div
        class=${classMap({
          'c-tab-panel': true,
          'c-tab-panel--active': this.active,
        })}
        tabindex="-1"
        aria-label=${this.name}
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tab-panel': TabPanelWC;
  }
  enum PSElementTagNameMap {
    'ps-tab-panel' = 'ps-tab-panel',
  }
}
