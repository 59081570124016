import { useMemo, useRef } from 'react';
import { Button, TextBody } from '@pypestream/design-system';
import ReactFlow, {
  Controls,
  ConnectionMode,
  useStore,
  ReactFlowState,
  MiniMap,
} from 'reactflow';
import { useObservable } from '@ngneat/use-observable';
import 'reactflow/dist/style.css';
import {
  selectedNode$,
  handleNodeChanges,
  handleEdgeChanges,
  nodes$,
  edges$,
} from '../../store/graph';

import { useGraphData } from './use-graph-data';
import {
  sendBuilderEvent,
  useBuilderCtxSelector,
  useBuilderStateMatches,
} from '../../xstate/app.xstate';
import {
  DefaultNode,
  CUSTOM_DEFAULT_NODE_NAME,
  CUSTOM_PLACEHOLDER_NODE_NAME,
  PlaceholderNode,
} from './custom-nodes';
import {
  PlaceholderEdge,
  CUSTOM_PLACEHOLDER_EDGE_NAME,
  CustomDefaultEdge,
  CUSTOM_DEFAULT_EDGE_NAME,
} from './custom-edges';
import './graph.scss';
import { useNavigate } from 'react-router-dom';
import { TranslationComponent } from '@pypestream/translations';

const addSelectedNodesFunc = (state: ReactFlowState) => state.addSelectedNodes;

const Graph = () => {
  const { enableCSVBasedGraphRendering, simulationProgress } =
    useBuilderCtxSelector((ctx) => ({
      enableCSVBasedGraphRendering: ctx.csvBasedGraph,
      simulationProgress: ctx.simulationProgress,
    }));
  const simulatingGraphLayout = useBuilderStateMatches(
    'graph.simulatingGraphLayout'
  );
  const navigate = useNavigate();
  const [edges] = useObservable(edges$);
  const [nodes] = useObservable(nodes$);
  const target = useRef<HTMLDivElement>(null);

  const addSelectedNodes = useStore(addSelectedNodesFunc);
  const [selectedNode] = useObservable(selectedNode$);
  const selectedNodeId = useRef('');

  const { nodeClickHandler, showMiniMap, fileUploadHandler, setCenter } =
    useGraphData(target);

  if (selectedNode?.id && selectedNode?.id !== selectedNodeId.current) {
    selectedNodeId.current = selectedNode?.id;

    setCenter(selectedNode.position.x + 50, selectedNode.position.y + 50, {
      duration: 500,
      zoom: 1.2,
    });
  }

  const nodeTypes = useMemo(
    () => ({
      [`${CUSTOM_DEFAULT_NODE_NAME}`]: DefaultNode,
      [`${CUSTOM_PLACEHOLDER_NODE_NAME}`]: PlaceholderNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      [`${CUSTOM_DEFAULT_EDGE_NAME}`]: CustomDefaultEdge,
      [`${CUSTOM_PLACEHOLDER_EDGE_NAME}`]: PlaceholderEdge,
    }),
    []
  );
  const stepEdgeStyle = sessionStorage.getItem('stepEdgeStyle');
  return (
    <>
      <div
        style={{
          right: '0.5rem',
          transform: 'translateY(0.5rem)',
          position: 'fixed',
          zIndex: 100,
          // @todo: replace with dynamic header height value
          top: '80px',
        }}
      >
        {/* Temporary support for switching between edge styles and graph data provider */}
        {/* <Button
          size="medium"
          onClick={() => {
            stepEdgeStyle
              ? sessionStorage.removeItem('stepEdgeStyle')
              : sessionStorage.setItem('stepEdgeStyle', 'true');
            window.location.reload();
          }}
        >
          Set {stepEdgeStyle ? 'Bezier' : 'smoothStep'} Edge Style
        </Button>
        {enableCSVBasedGraphRendering ? (
          <input
            type="file"
            id="csvFile"
            accept=".csv"
            placeholder="upload csvFile"
            onChange={fileUploadHandler}
          />
        ) : (
          <Button
            size="medium"
            onClick={() => {
              sendBuilderEvent({
                type: 'builder.renderCSVBasedGraph',
              });
              navigate(`?csvBasedGraph=true`);
            }}
          >
            Enable CSV Based Graph Rendering
          </Button>
        )} */}
      </div>
      {simulatingGraphLayout ? (
        <div
          // temp inline style till we get other drawer UI updates in place
          style={{
            gridRowStart: 1,
            gridColumnStart: 1,
            textAlign: 'center',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          <TextBody textAlign="center">
            <TranslationComponent
              i18nKey="studio/build:simulatingGraphProgress"
              values={{ progress: `${simulationProgress || 0}%` }}
              defaults="Graph simulation progress is {{progress}}"
            />
          </TextBody>
        </div>
      ) : (
        <ReactFlow
          className="c-graph"
          ref={target}
          nodes={nodes}
          edges={edges}
          onNodesChange={handleNodeChanges}
          onEdgesChange={handleEdgeChanges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          attributionPosition="bottom-left"
          fitView
          onInit={(reactFlowInstance) => {
            setTimeout(() => {
              reactFlowInstance.fitView({ duration: 800 });
            }, 300);
          }}
          onNodeClick={nodeClickHandler}
          onPaneClick={() => {
            sendBuilderEvent({
              type: 'builder.node.deselect',
            });
            addSelectedNodes([]);
          }}
          minZoom={0.01}
          maxZoom={Infinity}
          connectionMode={ConnectionMode.Loose}
        >
          {showMiniMap && <MiniMap pannable zoomable zoomStep={1} />}
          <Controls
            className="c-graph-controls"
            fitViewOptions={{
              duration: 800,
              padding: 24,
              maxZoom: 2,
              minZoom: 0.05,
            }}
            showInteractive={false}
            position="bottom-center"
          />
        </ReactFlow>
      )}
    </>
  );
};

export default Graph;
