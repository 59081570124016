import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../../../components/base-element';
import styles from './auth-card.scss?inline';

@customElement('ps-auth-card')
export class AuthCardWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** The card optional coverImg */
  @property() coverImgSrc?: string;

  // @todo: restore slotted header & footer containers once Next.js not using dynamic imports
  // ${this.slotted('header')
  // ? html`<slot name="header" class="c-auth-card__header"></slot>`
  // : ''}
  // <slot class="c-auth-card__main"></slot>
  // ${this.slotted('footer')
  // ? html`<slot name="footer" class="c-auth-card__footer"></slot>`
  // : ''}

  render() {
    return html`
      <div
        class=${classMap({
          'c-auth-card': true,
          'c-auth-card--has-cover-img': !!this.coverImgSrc,
        })}
      >
        <div class="c-auth-card__content">
          <slot name="header" class="c-auth-card__header"></slot>
          <slot class="c-auth-card__main"></slot>
          <slot name="footer" class="c-auth-card__footer"></slot>
        </div>
        ${this.coverImgSrc
          ? html`
              <img
                src=${this.coverImgSrc}
                width="550"
                height="600"
                alt=""
                class="c-auth-card__cover-img"
              />
            `
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-auth-card': AuthCardWC;
  }
}
