import * as React from 'react';
import { createComponent } from '@lit/react';
import { GraphNodeWC } from './graph-node.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type GraphNodeProps = GenerateComponentTypeWithChildren<GraphNodeWC> & {
  onClick?: React.MouseEventHandler<GraphNodeWC>;
};

export type GraphNodePropsWithRef = GeneratePropsWithRef<GraphNodeProps>;

export const GraphNode = createComponent({
  tagName: GraphNodeWC.tagname,
  elementClass: GraphNodeWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as GraphNodePropsWithRef;
