import * as React from 'react';
import { createComponent } from '@lit/react';
import { PageFooterWC } from './page-footer.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PageFooterProps = GenerateComponentTypeWithChildren<PageFooterWC>;
export type PageFooterPropsWithRef = GeneratePropsWithRef<PageFooterProps>;

export const PageFooter = createComponent({
  tagName: PageFooterWC.tagname,
  elementClass: PageFooterWC,
  react: React,
}) as PageFooterPropsWithRef;
