import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';

export const sentryInit = (dsn: string) => {
  // https://pypestream.atlassian.net/wiki/spaces/analytics/pages/3632398424/Analytics+Dogfooding+-+Implementation+Details#Sentry-Integration
  // const datasetId = 22; // "Product Data" dataset
  const datasetId = 21;

  try {
    if (!dsn) {
      throw new Error('Sentry DSN is not provided');
    }

    Sentry.init({
      dsn,
      environment: process.env.ANALYTICS_CAPTURE_ENVIRONMENT,
      integrations: [
        new posthog.SentryIntegration(posthog, 'pypestream', datasetId),
      ],
    });
    console.log(
      '%cSentry: service initialized successfully',
      'color: green; font-size: larger; font-weight: bold'
    );
  } catch (error) {
    console.error('Sentry initialization failed:', error);
  }
};
