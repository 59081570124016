import {
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
  useStore,
} from 'reactflow';
import { FC, useCallback } from 'react';
import './custom-default-edge.scss';
import clsx from 'clsx';
import { EdgeData } from '../../../../store/graph/graph';
import { getEdgeParams } from '../../../../utils/graph';

export const CUSTOM_DEFAULT_EDGE_NAME = 'customDefaultEdge';

type CustomDefaultEdgeProps = EdgeProps<EdgeData>;

export const CustomDefaultEdge: FC<CustomDefaultEdgeProps> = ({
  id,
  source,
  target,
  markerEnd,
  style,
  data,
}) => {
  const stepEdgeStyle = sessionStorage.getItem('stepEdgeStyle');

  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const classes = clsx(
    'react-flow__edge-path',
    'c-edge-status',
    data && `c-edge-status--${data.status}`
  );

  let edgePath;
  if (stepEdgeStyle) {
    edgePath = getSmoothStepPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
      borderRadius: 32,
      offset: -32,
    })[0];
  } else {
    edgePath = getBezierPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
    })[0];
  }

  return <path id={id} className={classes} d={edgePath} style={style} />;
};

export default CustomDefaultEdge;
