import * as React from 'react';
import { createComponent } from '@lit/react';
import { ModalIconWC } from './modal-icon.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type ModalIconProps = GenerateComponentTypeWithoutChildren<ModalIconWC>;

export type ModalIconPropsWithRef = GeneratePropsWithRef<ModalIconProps>;

export const ModalIcon = createComponent({
  tagName: ModalIconWC.tagname,
  elementClass: ModalIconWC,
  react: React,
}) as ModalIconPropsWithRef;
