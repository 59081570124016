/* eslint-disable class-methods-use-this */
/* eslint-disable lit/binding-positions */
/* eslint-disable lit/no-invalid-html */
import { unsafeCSS } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../base-element';
import cardStyles from './card.scss?inline';

@customElement('ps-card')
export class CardWC extends BaseElement {
  static styles = unsafeCSS(cardStyles);

  @property({ type: Boolean, reflect: true }) disabled = false;

  /** The cards's size. */
  @property() size: 'small' | 'medium' | 'large' | 'xlarge' = 'medium';

  /** The cards's shadow. */
  @property() shadow: 'none' | 'small' | 'medium' = 'small';

  /** The cards's variant. */
  @property() variant: 'default' | 'glass' = 'default';

  /** The cards's border. */
  @property() border: 'none' | 'default' | 'primary' = 'none';

  /** The cards's border radius. */
  @property() radius: 'medium' | 'large' = 'medium';

  /** When set, card will be rendered as an `<a>` tag. */
  @property({
    reflect: true,
  })
  href?: string;

  @property({ type: Boolean, reflect: true }) interactive = false;

  /** Text-align card content */
  @property() textAlign: 'start' | 'center' | 'end' = 'start';

  @property({ reflect: true }) display: 'block' | 'flex' = 'block';

  render() {
    const isLink = !!this.href;
    const tag = isLink ? literal`a` : literal`div`;
    const isInteractive = (isLink || this.interactive) && !this.disabled;

    const classes = classMap({
      'c-card': true,
      [`c-card--size-${this.size}`]: this.size,
      [`c-card--shadow-${this.shadow}`]: this.shadow,
      [`c-card--border-${this.border}`]: this.border,
      [`c-card--variant-${this.variant}`]: this.variant,
      [`c-card--radius-${this.radius}`]: this.radius,
      'c-card--interactive': isInteractive,
      'c-card--disabled': this.disabled,
      [`c-card--align-${this.textAlign}`]: this.textAlign,
      [`c-card--display-${this.display}`]: this.display,
    });

    return html`
      <${tag}
        class=${classes}
        href=${ifDefined(isLink && !this.disabled ? this.href : undefined)}
        tabindex=${ifDefined(isInteractive) ? 0 : null}
        data-cy="card"
        part="card-body"
      >
          <slot></slot>
        ${
          this.slotted('action')
            ? html`<span class="c-card__action">
                <slot name="action"></slot>
              </span>`
            : null
        }
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-card': CardWC;
  }
  enum PSElementTagNameMap {
    'ps-card' = 'ps-card',
  }
}
