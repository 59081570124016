import { unsafeCSS, html } from 'lit';
import { BaseElement, customElement } from '../../base-element';
import '../../text/text-overline';
import styles from './menu-header.scss?inline';

@customElement('ps-menu-header')
export class MenuHeaderWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <ps-text-overline data-cy="menu-header">
        <slot></slot>
      </ps-text-overline>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-menu-header': MenuHeaderWC;
  }
  enum PSElementTagNameMap {
    'ps-menu-header' = 'ps-menu-header',
  }
}
