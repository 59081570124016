import * as React from 'react';
import { createComponent } from '@lit/react';
import { StepperItemWC } from './stepper-item.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type StepperItemProps = GenerateComponentTypeWithChildren<StepperItemWC>;

export type StepperItemPropsWithRef = GeneratePropsWithRef<StepperItemProps>;

export const StepperItem = createComponent({
  tagName: StepperItemWC.tagname,
  elementClass: StepperItemWC,
  react: React,
}) as StepperItemPropsWithRef;
