/* eslint-disable wc/guard-super-call */
/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { html, unsafeCSS } from 'lit';
import { query, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import { TabWC, tagName as tabTagName } from './tab.wc';
import { TabPanelWC, tagname as tabPanelTagName } from './tab-panel.wc';

import tabGroupStyles from './tab-group.scss?inline';

const tagname = 'ps-tab-group';

@customElement('ps-tab-group')
export class TabGroupWC extends BaseElement {
  static styles = unsafeCSS(tabGroupStyles);

  private activeTab?: TabWC;

  private tabs: TabWC[] = [];

  private panels: TabPanelWC[] = [];

  @query('.c-tab-group') drawerPanel: HTMLElement;

  @query('.c-tab-group__tabs') slottedTabs: HTMLSlotElement;

  @query('.c-tab-group__panels') slottedPanels: HTMLSlotElement;

  @property() variant: 'default' | 'contained' | 'flush' | 'filled' = 'default';

  @property() placement: 'bottom' | 'top' = 'top';

  private getAllTabs(
    options: { includeDisabled: boolean } = { includeDisabled: true }
  ) {
    const slot =
      this.shadowRoot?.querySelector<HTMLSlotElement>('slot[name="tab"]');

    return [...(slot?.assignedElements() as TabWC[])].filter((el) =>
      options.includeDisabled
        ? el.tagName.toLowerCase() === tabTagName
        : el.tagName.toLowerCase() === tabTagName && !el.disabled
    );
  }

  connectedCallback() {
    const whenAllDefined = Promise.allSettled([
      customElements.whenDefined(tabTagName),
      customElements.whenDefined(tabPanelTagName),
    ]);

    super.connectedCallback();

    this.updateComplete.then(() => {
      this.syncTabsAndPanels();
      whenAllDefined.then(() => {
        const intersectionObserver = new IntersectionObserver(
          (entries, observer) => {
            if (entries[0].intersectionRatio > 0) {
              this.setActiveTab(this.getActiveTab() ?? this.tabs[0], {
                emitEvents: false,
              });

              observer.unobserve(entries[0].target);
            }
          }
        );
        intersectionObserver.observe(this.drawerPanel);
      });
    });
  }

  private getAllPanels() {
    const slottedPanels = this.slottedPanels?.assignedElements() || [];
    return [...slottedPanels].filter(
      (el) => el.tagName.toLowerCase() === tabPanelTagName
    ) as [TabPanelWC];
  }

  private getActiveTab() {
    return this.tabs.find((el) => el.active);
  }

  private handleClick(event: MouseEvent | KeyboardEvent) {
    const target = event.target as HTMLElement;
    const tab = target.closest(tabTagName);
    const tabGroup = tab?.closest(tagname);
    this.syncTabsAndPanels();
    // Ensure the target tab is in this tab group
    if (tabGroup !== this) {
      return;
    }

    if (tab !== null && !tab?.disabled) {
      this.setActiveTab(tab, { scrollBehavior: 'smooth' });
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      this.handleClick(event);
    }
  }

  setActiveTab(
    tab: TabWC,
    options?: { emitEvents?: boolean; scrollBehavior?: 'auto' | 'smooth' }
  ) {
    options = {
      emitEvents: true,
      scrollBehavior: 'auto',
      ...options,
    };

    if (tab !== this.activeTab && !tab.disabled) {
      const previousTab = this.activeTab;
      this.activeTab = tab;

      // Sync active tab and panel
      this.tabs.map((el) => (el.active = el === this.activeTab));
      this.panels.map((el) => (el.active = el.name === this.activeTab?.panel));

      // Emit events
      if (options.emitEvents) {
        if (previousTab) {
          this.emit('ps-tab-hide', { detail: { name: previousTab.panel } });
        }
        this.emit('ps-tab-show', { detail: { name: this.activeTab.panel } });
      }
    }
  }

  setActiveTabByPanel(panel: string) {
    const nextActiveTab = this.tabs.find((tab) => tab.panel === panel);

    if (!nextActiveTab) return;

    this.setActiveTab(nextActiveTab);
  }

  // This stores tabs and panels so we can refer to a cache instead of calling querySelectorAll() multiple times.
  private syncTabsAndPanels() {
    this.tabs = this.getAllTabs({ includeDisabled: false });
    this.panels = this.getAllPanels();
  }

  /** Shows the specified tab panel. */
  show(panel: string) {
    const tab = this.tabs.find((el) => el.panel === panel);

    if (tab) {
      this.setActiveTab(tab, { scrollBehavior: 'smooth' });
    }
  }

  render() {
    const classes = classMap({
      'c-tab-group': true,
      [`c-tab-group--variant-${this.variant}`]: this.variant,
      [`c-tab-group--placement-${this.placement}`]: this.placement,
    });

    return html`
      <div class=${classes}>
        <div class="c-tab-group__body">
          ${this.slotted('tab')
            ? html`
                <slot
                  @click=${this.handleClick}
                  @keydown=${this.handleKeyDown}
                  class="c-tab-group__tabs"
                  @slotchange=${this.syncTabsAndPanels}
                  name="tab"
                ></slot>
                <slot
                  class="c-tab-group__panels"
                  @slotchange=${this.syncTabsAndPanels}
                ></slot>
              `
            : null}
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tab-group': TabGroupWC;
  }
  enum PSElementTagNameMap {
    'ps-tab-group' = 'ps-tab-group',
  }
}
