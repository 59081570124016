import * as React from 'react';
import { createComponent } from '@lit/react';
import { CheckboxWC } from './checkbox.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type CheckboxProps = GenerateComponentTypeWithChildren<CheckboxWC> & {
  onChange?: React.ChangeEventHandler<CheckboxWC>;
};

export type CheckboxPropsWithRef = GeneratePropsWithRef<CheckboxProps>;

export const Checkbox = createComponent({
  tagName: CheckboxWC.tagname,
  elementClass: CheckboxWC,
  react: React,
  events: {
    onChange: 'change',
  },
}) as CheckboxPropsWithRef;
