import * as React from 'react';
import { createComponent } from '@lit/react';
import { DrawerPanelWrapperWC } from './drawer-panel-wrapper.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type DrawerPanelWrapperProps =
  GenerateComponentTypeWithChildren<DrawerPanelWrapperWC>;

export type DrawerPanelWrapperPropsWithRef =
  GeneratePropsWithRef<DrawerPanelWrapperProps>;

export const DrawerPanelWrapper = createComponent({
  tagName: DrawerPanelWrapperWC.tagname,
  elementClass: DrawerPanelWrapperWC,
  react: React,
}) as DrawerPanelWrapperPropsWithRef;
