import { useNavigate } from 'react-router-dom';
import {
  useGlobalAppCurrentState,
  useUiCurrentState,
  useUserCurrentState,
  useBuilderCurrentState,
  useMicroAppsCurrentState,
} from '../../xstate/app.xstate';
import { routes } from '../primary-nav/primary-nav';
import { filterErrorTags, resetAppContext } from './helpers';
import { ErrorTags } from './types';

export const useResetAppContextAndGoToHome = () => {
  const navigate = useNavigate();
  return () => {
    resetAppContext();
    navigate(routes.home);
  };
};

export const useCurrentState = () => {
  const global = useGlobalAppCurrentState();
  const ui = useUiCurrentState();
  const user = useUserCurrentState();
  const builder = useBuilderCurrentState();
  const microApps = useMicroAppsCurrentState();

  return {
    global,
    ui,
    user,
    builder,
    microApps,
  };
};

export const useErrorTag = (tags?: ErrorTags | ErrorTags[]) => {
  const userTags = filterErrorTags(tags);
  const currentState = useCurrentState();
  const statesArr = Object.values(currentState);
  const isLocalError = statesArr.some((state) => state.hasTag(ErrorTags.local));
  const isGlobalError = statesArr.some((state) =>
    state.hasTag(ErrorTags.global)
  );
  const hasUserTags = !userTags.length
    ? false
    : statesArr.some((state) =>
        userTags.every((tag) => state.hasTag(tag as string))
      );

  return {
    isLocalError,
    isGlobalError,
    hasUserTags,
  };
};
