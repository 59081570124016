/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { TreeUtils } from 'simple-tree-utils';
import { PropertyValueMap, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';

import { BaseElement, customElement } from '../../components';
import { OrgSelectorState, OrgWithChildren } from './types';
import { TreeItemWC } from '../../components/tree-item/tree-item.wc';
import { SmartContext } from '../xstate/smart.xstate-utils';
import { smartService } from '../xstate/smart.xstate';

import './universal-nav-tree-child.wc';
import '../../components/tree/tree.wc';
import '../../components/buttons/icon-button/icon-button.wc';
import '../../components/icon/icon.wc';
import '../../components/menu/src/menu.wc';

const treeUtils = new TreeUtils({
  idProp: 'id', // the key of a unique identifier for an object (source object)
  parentIdProp: 'parentId', // the key of a unique parent identifier (source object)
  childrenProp: 'children', // the key, where child nodes are stored (destination object tree)
});

@customElement('ps-universal-nav-org-selector')
export class OrgSelectorWC extends BaseElement {
  context: SmartContext;

  @property({ type: Number, attribute: 'poll-interval' })
  pollInterval = 60 * 1000 * 60;

  @property({ type: String }) org: string | undefined;

  @property() onSelectionChange: (org: string | null) => void;

  @state() private localState: OrgSelectorState = {
    orgs: [],
    orgTree: [],
  };

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
      this.init();
    });
  }

  init() {
    const { userInfo } = this.context;

    const defaultAccount = userInfo?.defaultAccount;

    if (defaultAccount) {
      const { allChildAccounts, ...orgProps } = defaultAccount;

      this.localState.orgs = [orgProps].concat(allChildAccounts || []);
    }

    const orgsWithChildren: OrgWithChildren[] = treeUtils.list2Tree(
      this.localState.orgs || []
    ) as OrgWithChildren[];

    orgsWithChildren.map((org) => {
      if ('id' in org) {
        org.selected = org?.id === this.org;
      }

      if ('children' in org === undefined) {
        org.children = [];
      }

      return org;
    }) as OrgWithChildren[];

    const maxDepth = (root: OrgWithChildren) => {
      // if root is undefined, depth is 0
      if (!root) return 0;
      // variable to store the maximum levels
      let max = 0;
      // helper function to traverse the tree
      // recursively increment the levels by one
      const dfs = (node: OrgWithChildren, levels: number) => {
        // console.log(node.name, levels);

        node.depth = levels;
        // compare levels and max to pass the maximum levels
        if (levels > max) max = levels;
        // traverse all children of the current node
        for (const child of node.children) {
          // increment the levels by one
          dfs(child, levels + 1);
        }
      };
      // when root is not null, the tree has at least one level,
      // so we pass down 1
      dfs(root, 1);

      // return the maximum levels
      return max;
    };

    maxDepth(orgsWithChildren[0]);

    this.localState = {
      ...this.localState,
      orgTree: orgsWithChildren,
    };
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    setInterval(() => {
      this.init();
    }, this.pollInterval);
  }

  render() {
    const { orgTree } = this.localState;

    return html`
      <ps-menu>
        <ps-tree
          @pl-selection-change=${({
            detail: { selection },
          }: {
            detail: { selection: TreeItemWC[] };
          }) => {
            this.onSelectionChange(selection[0]?.getAttribute('data-org-id'));
          }}
        >
          ${orgTree?.map(
            (item) => html`
              <ps-universal-nav-tree-child
                .child=${item}
                org=${ifDefined(this.org)}
              ></ps-universal-nav-tree-child>
            `
          )}
        </ps-tree>
      </ps-menu>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-universal-nav-org-selector': OrgSelectorWC;
  }
  enum PSElementTagNameMap {
    'ps-universal-nav-org-selector' = 'ps-universal-nav-org-selector',
  }
}
