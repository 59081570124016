/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, useCallback, useState } from 'react';
import get from 'lodash.get';
import {
  JSONSchemaForm,
  TextTitle,
  Text,
  RJSFValidationError,
  Button,
  ButtonGroup,
} from '@pypestream/design-system';
import validator from '@rjsf/validator-ajv6';
import { useObservable } from '@ngneat/use-observable';
import { useNodeDetailsSubscription } from '@pypestream/api-services/apollo';
import { useBuilderCtxSelector } from '../../xstate/app.xstate';
import {
  nodeDetailsSchemaRoot,
  nodeDetailsUiSchemaRoot,
} from '../../schemas/node-details-schema-root';
import {
  selectedNodeDetails$,
  handleNodeDetailsUpdate,
  handleNodeDetailsIncomingData,
  nodeDetailsStateHistory,
} from '../../store/graph';
import { Loader } from '../loader';

export const NodeDetails: FC = () => {
  const [isErrorElementVisible, setIsErrorElementVisible] =
    useState<boolean>(false);
  const { nodeId, nodeNumber, enableCSVBasedGraphRendering } =
    useBuilderCtxSelector((ctx) => ({
      nodeId: ctx.selectedNodeId,
      nodeNumber: ctx.selectedNodeNumber,
      enableCSVBasedGraphRendering: ctx.csvBasedGraph,
    }));
  const [nodeDetails] = useObservable(selectedNodeDetails$);

  const { loading, error } = useNodeDetailsSubscription({
    variables: {
      node_id: nodeId,
    },
    onData: ({ data: { data } }) => {
      if (data?.apps_node_by_pk) {
        const { __typename, ...restDetails } = data.apps_node_by_pk;
        handleNodeDetailsIncomingData({
          ...restDetails,
        });
      }
    },
    onError: ({ message }) => {
      console.log('error message', message);
      // @todo - better error handling in case of,
      // 1. response errors
      // 2. websocket issues?
      // 3. Need to add support of datadog for logging errors
    },
    skip: enableCSVBasedGraphRendering || !nodeId,
  });

  // Function to override default rendering of error messages with react-json-form
  // https://rjsf-team.github.io/react-jsonschema-form/docs/usage/validation/#custom-error-messages
  const transformErrorsHandler = useCallback(
    (formErrors: RJSFValidationError[]) =>
      formErrors.map((formError: RJSFValidationError) => {
        if (formError.property && formError.message) {
          const propertyStack = formError.property.split('.');
          if (propertyStack.length) {
            return {
              ...formError,
              message: `${propertyStack.pop()} ${formError.message}`,
            };
          }
        }
        return formError;
      }),
    []
  );

  if (!nodeId || !nodeNumber) {
    // @todo - Need to log this error with datadog once available.
    console.error(
      `nodeId and nodeNumber are mandatory to render NodeDetails panel. current nodeId - ${nodeId} current nodeNumber - ${nodeNumber} `
    );
    return null;
  }

  if (loading) {
    return <Loader relative />;
  } else if (error) {
    // @todo - Need to log this error with datadog once available + need to work on better UI/UX
    return <span>Something went wrong, please reselect node again!</span>;
  }

  return (
    <>
      {/* @todo: if we want to include this for testing, we should add a super admin-specific feature flag */}
      {/* <div id="this-element-just-for-error-boundary-demostration">
        <Button onClick={() => (nodeNumber as any)?.slice()}>
          Сall a func with an err
        </Button>
        <Button onClick={() => setIsErrorElementVisible(true)}>
          {!isErrorElementVisible
            ? 'Show el with an err'
            : (nodeNumber as any)?.trim()}
        </Button>
      </div> */}

      {/* @todo: replace div with ButtonGroup once we update to not make minWidth :host styles required */}
      <div
        style={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => nodeDetailsStateHistory.undo()}
          disabled={!nodeDetailsStateHistory.hasPast}
          size="small"
        >
          Undo
        </Button>
        <Button
          onClick={() => nodeDetailsStateHistory.redo()}
          disabled={!nodeDetailsStateHistory.hasFuture}
          size="small"
        >
          Redo
        </Button>
      </div>
      <TextTitle size="small" slot="header">
        {nodeDetails?.node_name}
      </TextTitle>
      <Text size="3xsmall" variant="tertiary" slot="header">
        {nodeNumber}
      </Text>
      <div>
        <JSONSchemaForm
          uiSchema={nodeDetailsUiSchemaRoot}
          schema={nodeDetailsSchemaRoot}
          transformErrors={transformErrorsHandler}
          validator={validator}
          showErrorList={false}
          idSeparator="/"
          // todo - need to rethink on this as this has negative
          liveValidate
          formData={{
            ...nodeDetails,
            node_number: nodeNumber,
          }}
          onChange={(formEvent, id) => {
            if (id && nodeId) {
              const keyPathForUpdate = id.split('/');
              keyPathForUpdate.shift();
              const { node_number, ...restFormData } = formEvent.formData;
              handleNodeDetailsUpdate({
                nodeId,
                keyPathForUpdate,
                formData: restFormData,
              });
            }
          }}
          // @todo need to add logging with following both handlers.
          // logging means something like datadog
          onSubmit={(data1, event) => console.log('submitted', data1, event)}
          onError={(errors) => console.log('errors', errors)}
          readonly={enableCSVBasedGraphRendering}
        >
          <Fragment />
        </JSONSchemaForm>
      </div>
    </>
  );
};
