import * as React from 'react';
import { createComponent } from '@lit/react';
import { AppletCardInfoWC } from './applet-card-info.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type AppletCardInfoProps =
  GenerateComponentTypeWithChildren<AppletCardInfoWC>;

export type AppletCardInfoPropsWithRef =
  GeneratePropsWithRef<AppletCardInfoProps>;

export const AppletCardInfo = createComponent({
  tagName: AppletCardInfoWC.tagname,
  elementClass: AppletCardInfoWC,
  react: React,
}) as AppletCardInfoPropsWithRef;
