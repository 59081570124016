import * as React from 'react';
import { createComponent } from '@lit/react';
import { TagWC } from './tag.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TagProps = GenerateComponentTypeWithChildren<TagWC> &
  TranslateInterface;

export type TagPropsWithRef = GeneratePropsWithRef<TagProps>;

export const Tag = createComponent({
  tagName: TagWC.tagname,
  elementClass: TagWC,
  react: React,
}) as TagPropsWithRef;
