import * as React from 'react';
import { createComponent } from '@lit/react';
import { CardWC } from './card.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type CardProps = GenerateComponentTypeWithChildren<CardWC> & {
  onClick?: React.MouseEventHandler<CardWC>;
  onKeyDown?: React.KeyboardEventHandler<CardWC>;
};

export type CardPropsWithRef = GeneratePropsWithRef<CardProps>;

export const Card = createComponent({
  tagName: CardWC.tagname,
  elementClass: CardWC,
  react: React,
  events: {
    onClick: 'click',
    onKeyDown: 'keydown',
  },
}) as CardPropsWithRef;
