import React, { FC } from 'react';
import { Wrapper, Hero } from '@pypestream/design-system';
import { useLocation } from 'react-router-dom';

import './page-header.scss';
import { routes } from '../primary-nav/primary-nav';

export const PageHeader: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === routes.home;

  return (
    <Hero
      variant={isHomePage ? 'editor' : 'default'}
      style={
        isHomePage
          ? {
              '--bg-gradient-start-top': '#feffff',
              '--bg-gradient-start-bottom': '#f5ffff',
              '--bg-gradient-end-top': '#f3fefe',
              '--bg-gradient-end-bottom': '#e9fefe',
            }
          : {}
      }
    >
      {children && <>{children}</>}
    </Hero>
  );
};
