import * as React from 'react';
import { createComponent } from '@lit/react';
import { ImageWC } from './image.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type ImageProps = GenerateComponentTypeWithoutChildren<ImageWC> & {
  onError?: ({
    detail: { hasError },
  }: Event & {
    detail: { hasError: boolean };
  }) => void;
};

export type ImagePropsWithRef = GeneratePropsWithRef<ImageProps>;

export const Image = createComponent({
  tagName: ImageWC.tagname,
  elementClass: ImageWC,
  react: React,
  events: {
    onError: 'onerror',
  },
}) as ImagePropsWithRef;
