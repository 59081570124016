/* eslint-disable lit-a11y/click-events-have-key-events */
import { classMap } from 'lit/directives/class-map.js';
import { property, query } from 'lit/decorators.js';
import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement, PSCustomEvent } from '../../base-element';

import '../../search/search.wc';
import '../../icon/icon.wc';

import drawerPanelStyles from './drawer-panel.scss?inline';

@customElement('ps-drawer-panel')
export class DrawerPanelWC extends BaseElement {
  static styles = unsafeCSS(drawerPanelStyles);

  @query('.c-drawer-panel') drawerPanel: HTMLElement;

  @property({ type: Boolean, reflect: true }) open = false;

  @property({ type: Boolean, reflect: true }) expanded = false;

  @property({ reflect: true }) anchor: 'right' | 'left' = 'left';

  @property({ reflect: true }) width: 'small' | 'medium' = 'medium';

  @property({ reflect: true }) type: 'elements' | 'details' = 'elements';

  toggleExpanded(event: MouseEvent | KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();

    if ('key' in event && event.key !== 'Enter') {
      return;
    }

    this.expanded = !this.expanded;
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has('open')) {
      this.emit('drawer-panel-open', {
        detail: { open: this.open },
      });
    }

    if (changedProperties.has('expanded')) {
      this.emit('drawer-panel-toggle', {
        detail: { expanded: this.expanded },
      });
    }
  }

  render() {
    return html`
      <aside
        @click="${this.type === 'elements' && !this.expanded
          ? this.toggleExpanded
          : null}"
        title=${this.type === 'elements' && !this.expanded
          ? 'Open Panel'
          : null}
        class="${classMap({
          'c-drawer-panel': true,
          'c-drawer-panel--open': this.open,
          'c-drawer-panel--expanded': this.expanded,
          [`c-drawer-panel--anchor-${this.anchor}`]: this.anchor,
          [`c-drawer-panel--width-${this.width}`]: this.width,
          [`c-drawer-panel--type-${this.type}`]: this.type,
        })}"
      >
        ${this.type === 'elements'
          ? html`
              <button
                @click="${this.toggleExpanded}"
                title=${this.expanded ? 'Collapse Panel' : 'Open Panel'}
                class="c-drawer-panel__toggle-btn"
              >
                <ps-icon
                  size="auto"
                  name=${this.expanded ? 'minus' : 'plus'}
                ></ps-icon>
              </button>
            `
          : null}
        <div class="c-drawer-panel__body">
          ${this.slotted('header')
            ? html`
                <slot class="c-drawer-panel__header" name="header"> </slot>
              `
            : null}
          <slot></slot>
        </div>
      </aside>
    `;
  }
}

export type DrawerPanelToggleEvent = PSCustomEvent<
  DrawerPanelWC,
  { expanded: boolean }
>;

export type DrawerPanelOpenEvent = PSCustomEvent<
  DrawerPanelWC,
  { open: boolean }
>;

declare global {
  interface HTMLElementTagNameMap {
    'ps-drawer-panel': DrawerPanelWC;
  }
  enum PSElementTagNameMap {
    'ps-drawer-panel' = 'ps-drawer-panel',
  }
  interface GlobalEventHandlersEventMap {
    'drawer-panel-toggle': DrawerPanelToggleEvent;
  }

  interface GlobalEventHandlersEventMap {
    'drawer-panel-open': DrawerPanelOpenEvent;
  }
}
