/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextSubtitle,
  TextCaption,
  Stack,
  Tag,
  IconButton,
} from '@pypestream/design-system';
import {
  getCapitalizedString,
  format,
  formatRelative,
  getDateOrNull,
} from '@pypestream/utils';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetMicroAppsQuery } from '@pypestream/api-services';
import { MicroAppsContext } from '../../xstate/micro-apps/micro-apps.xstate';

type MicroApps = NonNullable<GetMicroAppsQuery['apps_app']>[0] | undefined;
interface AppDetailsTableProps {
  microAppDetails: MicroApps;
  selectedAppId: MicroAppsContext['selectedAppId'];
  environments: MicroAppsContext['environments'];
}

export const AppDetailsTable: FC<AppDetailsTableProps> = ({
  microAppDetails,
  selectedAppId,
  environments,
}) => {
  const navigate = useNavigate();
  const latestVersions: Record<string, string> = {};
  const latestProdVersion = latestVersions.prod;

  const areVersionsExist = microAppDetails?.versions;

  if (!areVersionsExist) {
    return <>...</>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell variant="th" i18nKey="studio/manage:version">
            Saved Version
          </TableCell>
          {environments.map((availableEnvironment) => (
            <TableCell variant="th" key={availableEnvironment.environment_id}>
              {getCapitalizedString(availableEnvironment.environment_name)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {microAppDetails?.versions.map((version) => (
          <TableRow
            key={version.version_id}
            actionable
            onClick={() => {
              navigate(`/${selectedAppId}/${version.version_id}`);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLTableRowElement>) => {
              if (e.key === 'Enter' || e.code === 'Space') {
                e.preventDefault();
                navigate(`/${selectedAppId}/${version.version_id}`);
              }
            }}
          >
            <TableCell>
              <TextSubtitle size="small">
                {getDateOrNull(version.readonly_at)
                  ? format(new Date(version.readonly_at))
                  : 'unknown'}
              </TextSubtitle>
              <TextCaption>
                {getCapitalizedString(`${version.version_name}`)}
              </TextCaption>
            </TableCell>

            {environments.map((env) => {
              const deploymentDetails = version.deployments.find(
                (deployment) =>
                  deployment.environment?.environment_id === env.environment_id
              );

              if (!deploymentDetails) {
                return (
                  <TableCell
                    key={`${version.version_id}-cell-${env.environment_id}`}
                  >
                    <TextCaption i18nKey="studio/manage:unpublished">
                      Unpublished
                    </TextCaption>
                  </TableCell>
                );
              }

              return (
                <TableCell key={deploymentDetails.deployment_id}>
                  <Stack alignItems="center" gutter="small">
                    <TextSubtitle size="small">
                      {format(new Date(deploymentDetails.deployed_at))}
                    </TextSubtitle>
                    {/* @Todo: This functionality was in place but didn't seem to be working -- IE: Needs to be retested */}
                    {deploymentDetails.environment?.is_production && (
                      <Tag variant="live" i18nKey="studio/tag:live">
                        Live
                      </Tag>
                    )}
                    {deploymentDetails.environment?.environment_name ===
                      'prod' &&
                      version.version_name === latestProdVersion && (
                        <div>
                          <Tag variant="latest" i18nKey="studio/tag:live">
                            Live
                          </Tag>
                        </div>
                      )}
                  </Stack>
                  <TextCaption>
                    {formatRelative(
                      new Date(deploymentDetails.deployed_at),
                      new Date()
                    )}{' '}
                    by
                    {/* @Todo: Missing this part from backend -- user data */}
                    {/*
                            {getCapitalizedString(
                              `${deploymentDetails.user?.first_name}`
                            )}{' '}
                            {getCapitalizedString(
                              `${deploymentDetails.user?.last_name}`
                            )} */}
                  </TextCaption>
                </TableCell>
              );
            })}
            {/* Final Table Row */}
            <TableCell>
              <IconButton disabled name="meatballs" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AppDetailsTable;
