import { css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';

@customElement('ps-portal-entrance')
export class PortalEntranceWC extends BaseElement {
  @property({ reflect: true }) destination: string;

  projectSlot(e: Event) {
    if (!e.target) return;
    const projectionContent = (e.target as HTMLSlotElement).assignedNodes({
      flatten: true,
    });
    if (!projectionContent.length) return;
    this.dispatchEvent(
      this.emit('update-portal-content', {
        detail: {
          destination: this.destination,
          content: projectionContent,
        },
      })
    );
  }

  static get styles() {
    return [
      css`
        :host {
          display: none;
        }
      `,
    ];
  }

  render() {
    return html` <slot @slotchange=${this.projectSlot}></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-portal-entrance': PortalEntranceWC;
  }
  enum PSElementTagNameMap {
    'ps-portal-entrance' = 'ps-portal-entrance',
  }
}
