/* eslint-disable */
import { DefaultFeatureFlags as NextDefaultFeatureFlags } from './feature-flags.next';
import { DefaultFeatureFlags as LatestDefaultFeatureFlags } from './feature-flags.latest';
import { DefaultFeatureFlags as DevDefaultFeatureFlags } from './feature-flags.dev';
import { LaunchDarklyEnvs } from './feature-flags.envs';

interface PypestreamGlobal {
  smartService?: typeof import('../smart-components/xstate/smart.xstate').smartService;
}

export interface WindowWithPypestreamGlobals extends Window {
  pypestream?: PypestreamGlobal;
  LAUNCH_DARKLY_API_KEY?: string;
  LAUNCH_DARKLY_ENV?: string;
}

let DefaultFeatureFlags = DevDefaultFeatureFlags;

if (typeof window !== 'undefined') {
  const currentEnv = (window as WindowWithPypestreamGlobals).LAUNCH_DARKLY_ENV;

  if (currentEnv === LaunchDarklyEnvs.next) {
    DefaultFeatureFlags = NextDefaultFeatureFlags;
  }

  if (currentEnv === LaunchDarklyEnvs.latest) {
    DefaultFeatureFlags = LatestDefaultFeatureFlags;
  }
}

export { DefaultFeatureFlags };
