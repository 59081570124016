import * as React from 'react';
import { createComponent } from '@lit/react';
import { StatusCardWC } from './status-card.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type StatusCardProps = GenerateComponentTypeWithChildren<StatusCardWC>;

export type StatusCardPropsWithRef = GeneratePropsWithRef<StatusCardProps>;

export const StatusCard = createComponent({
  tagName: StatusCardWC.tagname,
  elementClass: StatusCardWC,
  react: React,
}) as StatusCardPropsWithRef;
