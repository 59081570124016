import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextareaWC } from './textarea.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

/** the tidy version of the Textarea component props + events */
export type TextareaPropEvents = {
  onInputCapture?: React.ChangeEventHandler<TextareaWC>;
  onKeyDownCapture?: React.KeyboardEventHandler<TextareaWC>;
  onKeyDown?: React.KeyboardEventHandler<TextareaWC>;
  onInput?: React.ChangeEventHandler<TextareaWC>;
  onInvalid?: React.ChangeEventHandler<TextareaWC>;
  onChange?: React.ChangeEventHandler<TextareaWC>;
  onBlur?: React.ChangeEventHandler<TextareaWC>;
};

export type TextareaProps = GenerateComponentTypeWithoutChildren<TextareaWC> &
  TextareaPropEvents & {
    initialValue?: string;
  };

/** @todo: need to review the prop cleanup code in component-types-generics to make sure we aren't missing functionality.
 * Testing out the new Input component seemed to indicate that (at the very least) our Ref signature might me incorrect...
 *
 * remove Types below once these shared types are reviewed
 */

// Match a prop name to a typed event callback by
// adding an Event type as an expected property on a string.
export type EventName<T extends Event = Event> = string & {
  __event_type: T;
};

type ReactProps<I, E> = Omit<React.HTMLAttributes<I>, keyof E>;
type ElementWithoutPropsOrEventListeners<I, E> = Omit<
  I,
  keyof E | keyof ReactProps<I, E>
>;

// Props the user is allowed to use, includes standard attributes, children,
// ref, as well as special event and element properties.
export type WebComponentProps<I, E> = Partial<
  I & ElementWithoutPropsOrEventListeners<I, E>
>;

export type ReactWebComponent<I, E> = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<WebComponentProps<I, E>> & React.RefAttributes<I>
>;

export const Textarea = createComponent({
  tagName: TextareaWC.tagname,
  elementClass: TextareaWC,
  react: React,
  events: {
    onInput: 'input',
    onInvalid: 'invalid',
    onChange: 'change',
    onBlur: 'blur',
    onKeyDown: 'keydown',
    onInputCapture: 'input',
    onKeyDownCapture: 'keydown',
  },
}) as ReactWebComponent<TextareaProps, TextareaPropEvents>;
