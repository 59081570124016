/* eslint-disable @typescript-eslint/no-explicit-any */
import { assign, Machine, Typestate, Interpreter, actions } from 'xstate';
import { getXstateUtils, MachineStateSchemaPaths } from '../xstate.utils';
import { sendUiEvent } from '../app.xstate';
import { gql, Workflow } from '@pypestream/api-services';
import { CUSTOM_DEFAULT_NODE_NAME } from '../../components/graph';
import {
  Node,
  generateGraph,
  handleCollapseToggle,
  setActiveNode,
  resetActiveNode,
  handleAddNode,
  NodeData,
  flushGraphDb,
  handleNodeDetailsIncomingData,
} from '../../store/graph';
import { sendUserMessage } from '../app.xstate-utils';
import { MicroAppsContext } from '../micro-apps/micro-apps.xstate';
import { NodeInstanceType, getActiveNode } from '../../store/graph/graph';
import { ErrorTags } from '../../components/error-boundary/types';

export interface BuilderPageContext {
  microAppVersionId?: MicroAppsContext['selectedVersionId'];
  simulationProgress?: number;
  workflows: Array<Workflow>;
  errors?: Error;
  selectedNodeId?: string;
  selectedNodeNumber?: number;
  csvBasedGraph?: boolean;
}

export interface BuilderState {
  states: {
    graph: {
      states: {
        idle: Record<string, unknown>;
        loading: Record<string, unknown>;
        simulatingGraphLayout: Record<string, unknown>;
        loaded: {
          states: {
            idle: Record<string, unknown>;
            selectedNode: Record<string, unknown>;
          };
        };
        csvBasedGraph: {
          states: {
            loading: Record<string, unknown>;
          };
        };
        errors: Record<string, unknown>;
      };
    };
    workflows: {
      states: {
        loading: Record<string, unknown>;
        loaded: Record<string, unknown>;
        errors: Record<string, unknown>;
      };
    };
  };
}

export type BuilderEvents =
  | {
      type: 'builder.fetchData';
      selectedVersionId: MicroAppsContext['selectedVersionId'];
      selectedAppId: MicroAppsContext['selectedAppId'];
      selectedAppName: string;
    }
  | {
      type: 'builder.resetToIdle';
    }
  | {
      type: 'builder.node.select';
      nodeId: NodeInstanceType['id'];
      nodeNumber: number;
    }
  | {
      type: 'builder.node.deselect';
    }
  | {
      type: 'builder.collapse.toggle';
      isCollapsed: boolean;
      nodeId: string;
    }
  | {
      type: 'builder.setMicroAppVersionId';
      id: BuilderPageContext['selectedNodeId'];
    }
  | {
      type: 'builder.resetMicroAppVersionId';
    }
  | {
      type: 'builder.csvBasedGraph.processedNodes';
      nodes: Array<NodeData>;
    }
  | {
      type: 'builder.renderCSVBasedGraph';
    }
  | {
      type: 'builder.set.csvBasedGraph';
      enable: boolean;
    }
  | {
      type: 'builder.set.simulation.progress';
      progress: number;
    }
  | {
      type: 'builder.set.simulation.completed';
    }
  | {
      type: 'reset.errors';
    }
  | {
      type: 'reset.context';
      data?: BuilderPageContext;
    };

export interface BuilderTypestates extends Typestate<BuilderPageContext> {
  context: BuilderPageContext;
  value: MachineStateSchemaPaths<BuilderState['states']>;
}

export type BuilderInterpreter = Interpreter<
  BuilderPageContext,
  BuilderState,
  BuilderEvents,
  BuilderTypestates,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

const { createInvokablePromise, createXstateHooks: createBuilderXstateHooks } =
  getXstateUtils<
    BuilderPageContext,
    BuilderEvents,
    BuilderTypestates,
    BuilderState
  >();

export { createBuilderXstateHooks };

const { choose } = actions; // How to utilize a condition in actions

const deselectNodeActions = choose([
  {
    cond: function canDeselectNode(ctx: BuilderPageContext) {
      return !!ctx.selectedNodeId;
    },
    actions: [
      assign((ctx) => {
        ctx.selectedNodeId = undefined;
        ctx.selectedNodeNumber = undefined;
        // Notify our store to know reset-active nodes.
        // We can avoid doing this till we save, but for now doing it along with deselect node
        sendUiEvent({
          type: 'drawer.hasClosed',
        });

        // Notify our store to know reset-active nodes.
        // We can avoid doing this till we save, but for now doing it along with deselect node
        // Note: 1st tried to use xstate's `after` syntax but found it to be tricky for this use case
        setTimeout(() => {
          resetActiveNode();
        }, 700);
        return ctx;
      }),
    ],
  },
]);

const initialBuilderContext: BuilderPageContext = {
  workflows: [],
};

export const builderMachine = Machine<
  BuilderPageContext,
  BuilderState,
  BuilderEvents
>(
  {
    context: initialBuilderContext,
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: 'builder',
    type: 'parallel',
    strict: true,
    on: {
      'reset.context': {
        actions: ['resetContext'],
        target: 'graph.idle',
      },
      'builder.resetMicroAppVersionId': {
        actions: [
          assign((ctx) => {
            flushGraphDb();
            ctx.microAppVersionId = null;
            return ctx;
          }),
        ],
        target: 'graph.idle',
      },
      'builder.set.csvBasedGraph': {
        actions: [
          assign((ctx, data) => {
            ctx.csvBasedGraph = data.enable;
            return ctx;
          }),
        ],
      },
    },
    states: {
      // To support CSV based rendering of build page.
      // @todo - This needs to be feature flagged.
      workflows: {
        states: {
          loading: {
            invoke: createInvokablePromise({
              id: 'workflows',
              src: async (_, event) => {
                if (
                  event.type !== 'builder.fetchData' ||
                  !event.selectedAppId
                ) {
                  throw new Error('No selectedAppId provided');
                }

                const workflows = await gql.getWorkflow({
                  appId: event.selectedAppId,
                });

                if (
                  !workflows.fe_proto_ ||
                  !workflows.fe_proto_.workflow.length
                ) {
                  throw new Error('No workflows found');
                }

                return {
                  workflows: workflows.fe_proto_.workflow as Workflow[],
                };
              },
              onDoneAssignContext({ data, ctx }) {
                ctx.workflows = data.workflows;
                return ctx;
              },
              onDoneTarget: '#builder.workflows.loaded',
              onErrorTarget: '#builder.workflows.errors',
            }),
          },
          loaded: {},
          errors: {},
        },
      },
      graph: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              'builder.fetchData': [
                {
                  target: [
                    '#builder.graph.loading',
                    '#builder.workflows.loading',
                  ],
                  cond: function isNewSelectedVersionId(ctx, event) {
                    return ctx.microAppVersionId !== event.selectedVersionId;
                  },
                },
              ],
            },
          },
          loading: {
            invoke: createInvokablePromise({
              id: 'loading',
              src: async (_, event) => {
                // @todo - Need to flush db by validating selectedVersion and selectedAppId instead of flushing all the time.
                flushGraphDb();
                if (
                  event.type !== 'builder.fetchData' ||
                  !event.selectedVersionId ||
                  !event.selectedAppId
                ) {
                  return;
                }

                await getGraphData({
                  selectedVersionId: event.selectedVersionId,
                  selectedAppId: event.selectedAppId,
                  selectedAppName: event.selectedAppName,
                });

                const workflows = await gql.getWorkflow({
                  appId: event.selectedAppId,
                });

                return {
                  microAppVersionId: event.selectedVersionId,
                  workflows,
                };
              },
              onDoneAssignContext({ ctx, data }) {
                ctx.microAppVersionId = data?.microAppVersionId;
                ctx.workflows = data?.workflows?.fe_proto_
                  ?.workflow as Array<Workflow>;
                return ctx;
              },
              onDoneTarget: '#builder.graph.simulatingGraphLayout',
              onErrorTarget: '#builder.graph.errors',
            }),
          },
          simulatingGraphLayout: {
            entry: () => generateGraph(),
            on: {
              'builder.set.simulation.progress': {
                actions: [
                  assign((ctx, data) => {
                    ctx.simulationProgress = data.progress;
                    return ctx;
                  }),
                ],
              },
              'builder.set.simulation.completed': '#builder.graph.loaded',
            },
          },
          loaded: {
            always: [
              {
                target: '#builder.graph.idle',
                cond: function hasMicroAppVersionId(context) {
                  console.log(context);
                  return context.microAppVersionId === undefined;
                },
                actions: [
                  assign((ctx) => {
                    ctx.selectedNodeId = undefined;
                    return ctx;
                  }),
                ],
              },
            ],
            initial: 'idle',
            on: {
              'builder.node.select': {
                target: '.selectedNode',
                actions: [
                  assign((ctx, data) => {
                    ctx.selectedNodeId = data.nodeId;
                    ctx.selectedNodeNumber = data.nodeNumber;
                    setActiveNode(data.nodeNumber);

                    if (ctx.csvBasedGraph) {
                      const nodeDetails = getActiveNode();
                      handleNodeDetailsIncomingData({
                        id: nodeDetails?.data.node_instance.node.id,
                        node_type:
                          nodeDetails?.data.node_instance.node.node_type || 'D',
                        messages: nodeDetails?.data.node_instance.node.messages,
                        node_name:
                          nodeDetails?.data.node_instance.node.node_name,
                      });
                    }
                    // Notify our store to know active nodes.
                    // testing out a slight delay (a click or two) to improve drawer animation
                    setTimeout(() => {
                      sendUiEvent({
                        type: 'drawer.nodeDetails.triggerOpen',
                      });
                    }, 16);
                    return ctx;
                  }),
                ],
                // Condition to check, whether user can select nodes
                cond: function canSelectNode(ctx, data) {
                  // Avoid selection if data.nodeId or nodeNumber is empty
                  // We need nodeNumber to identify node-entity and nodeId to have nodeDetail's subscription in place.
                  // Also avoid selecting same node-number.
                  return (
                    !!(data.nodeId && data.nodeNumber) &&
                    data.nodeNumber !== ctx.selectedNodeNumber
                  );
                },
              },
              'builder.node.deselect': {
                target: '.idle',
                actions: deselectNodeActions,
              },
              'builder.collapse.toggle': {
                actions: (context, event) =>
                  handleCollapseToggle({
                    nodeId: event.nodeId,
                    isCollapsed: event.isCollapsed,
                  }),
              },
              'builder.resetToIdle': '#builder.graph.idle',
              'builder.renderCSVBasedGraph': '#builder.graph.csvBasedGraph',
            },
            states: {
              idle: {},
              selectedNode: {
                exit: deselectNodeActions,
              },
            },
          },
          csvBasedGraph: {
            initial: 'loading',
            states: {
              loading: {
                entry: () => flushGraphDb(),
                on: {
                  'builder.csvBasedGraph.processedNodes': {
                    target: '#builder.graph.simulatingGraphLayout',
                    actions: [
                      assign((ctx, data) => {
                        data.nodes.forEach((_node) => {
                          const node: Node = {
                            type: CUSTOM_DEFAULT_NODE_NAME,
                            position: { x: 0, y: 0 },
                            id: _node.node_number.toString(),
                            data: _node,
                          };
                          handleAddNode(node);
                        });

                        return ctx;
                      }),
                    ],
                  },
                },
              },
            },
          },
          errors: {
            tags: [ErrorTags.global],
            exit: ['resetErrors'],
            on: {
              'builder.fetchData': '#builder.graph.loading',
            },
            always: [
              {
                target: '#builder.graph.idle',
                cond: function hasMicroAppVersionId(context) {
                  console.log(context);
                  return context.microAppVersionId === undefined;
                },
                actions: [
                  assign((ctx) => {
                    ctx.selectedNodeId = undefined;
                    return ctx;
                  }),
                ],
              },
            ],
          },
        },
      },
    },
  },
  {
    actions: {
      resetErrors: assign((ctx) => {
        delete ctx.errors;

        return ctx;
      }),
      resetContext: assign((ctx, event) => {
        if (event.type !== 'reset.context') {
          return ctx;
        }

        return event.data || {};
      }),
    },
  }
);

const getGraphData = async ({
  selectedVersionId,
  selectedAppId,
  selectedAppName,
}: {
  selectedVersionId: number;
  selectedAppId: number;
  selectedAppName: string;
}) => {
  return new Promise((resolve, reject) => {
    gql
      .getGraph({
        versionId: selectedVersionId,
        appId: selectedAppId,
        // appName: selectedAppName,async
      })
      .then(async (graphResponse) => {
        if (!graphResponse.apps_version.length) {
          sendUserMessage({
            type: 'error',
            text: 'This version of your app has no nodes, please return to the Manage page and select a different version.',
            autoClose: 1000,
          });
          reject();
        } else {
          graphResponse.apps_version[0].version_node_instances.forEach(
            (_node) => {
              _node.outgoing_nodes?.to_node_numbers?.forEach(
                (outgoing_node: number) => {
                  const nodeToUpdate =
                    graphResponse.apps_version[0].version_node_instances.find(
                      (n) => n.node_number == outgoing_node
                    ) as NodeData;

                  if (nodeToUpdate) {
                    nodeToUpdate.parentId = _node.node_number;
                  } else {
                    return;
                  }
                }
              );

              const node: Node = {
                type: CUSTOM_DEFAULT_NODE_NAME,
                position: { x: 0, y: 0 },
                id: _node.node_number.toString(),
                data: _node as NodeData,
              };
              handleAddNode(node);
            }
          );
          resolve(true);
        }
      })
      .catch((error) => {
        // sendUserMessage({
        //   type: 'error',
        //   text: 'Something went wrong, please refresh the page',
        //   autoClose: 1000,
        // });
        reject(error);
      });
  });
};
