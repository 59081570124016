import { RJSFSchema } from '@rjsf/utils';
import { object, string, Infer, optional } from 'superstruct';
import {
  messageTypeSchema,
  ContentTypes,
  commonMessageContent,
} from './common';

export const TextContentStruct = object({
  messageType: string(),
  message: string(),
  id: optional(string()),
  lastUpdated: optional(string()),
});

export type TextContent = Infer<typeof TextContentStruct>;

export const textContentSchema: RJSFSchema = {
  title: ContentTypes.Text,
  type: 'object',
  properties: {
    messageType: {
      ...messageTypeSchema,
      default: ContentTypes.Text,
    },
    message: {
      type: 'string',
    },
    ...commonMessageContent,
  },
  additionalProperties: false,
  required: ['messageType', 'message'],
};
