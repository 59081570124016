/* eslint-disable react-hooks/exhaustive-deps */
import { TableCellProps, TableCell, Icon } from '@pypestream/design-system';
import { FC, useEffect } from 'react';
import {
  SortingDetails,
  SortingHandlerProps,
  SortingType,
} from '../../hooks/use-sortable-data';

export type ColumnTitleProps = TableCellProps & {
  sortingDetails: SortingDetails;
  sortedByDefault?: boolean;
  sortingType: SortingType;
  onSort: (props: SortingHandlerProps) => void;
};

export const ColumnTitle: FC<ColumnTitleProps> = ({
  children,
  sortingDetails: { sorted, reverse },
  sortedByDefault,
  sortingType: type,
  onSort,
  ...restProps
}) => {
  useEffect(() => {
    if (!sortedByDefault) {
      return;
    }

    onSort({ reverse: false, type });
  }, [sortedByDefault]);

  return (
    <TableCell
      sortable
      sortActive={sorted}
      onClick={() =>
        onSort({
          reverse: !sorted ? false : !reverse,
          type,
        })
      }
      {...restProps}
      {...(sorted && { 'aria-sort': reverse ? 'descending' : 'ascending' })}
    >
      {children}
      <Icon
        size="xsmall"
        name={`chevron-${reverse ? 'up' : 'down'}`}
        slot="sorter"
      />
    </TableCell>
  );
};
