import * as React from 'react';
import { createComponent } from '@lit/react';
import { StackWC } from './stack.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type StackProps = GenerateComponentTypeWithChildren<StackWC>;

export type StackPropsWithRef = GeneratePropsWithRef<StackProps>;

export const Stack = createComponent({
  tagName: StackWC.tagname,
  elementClass: StackWC,
  react: React,
}) as StackPropsWithRef;
