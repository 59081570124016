/* eslint-disable no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import { LitElement, PropertyValueMap, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import 'element-internals-polyfill';

export interface PSCustomEvent<EventTarget = HTMLElement, Detail = never>
  extends Omit<CustomEvent<Detail>, 'target'> {
  target: EventTarget;
}

export default class ShoelaceElement extends LitElement {
  static define(
    name: string,
    elementConstructor = this,
    options: ElementDefinitionOptions = {}
  ) {
    const currentlyRegisteredConstructor = customElements.get(name) as
      | CustomElementConstructor
      | typeof BaseElement;

    if (!currentlyRegisteredConstructor) {
      customElements.define(
        name,
        class extends elementConstructor {} as unknown as CustomElementConstructor,
        options
      );
      return;
    }

    let newVersion = ' (unknown version)';
    let existingVersion = newVersion;

    if ('version' in elementConstructor && elementConstructor.version) {
      newVersion = ` v${elementConstructor.version}`;
    }

    if (
      'version' in currentlyRegisteredConstructor &&
      currentlyRegisteredConstructor.version
    ) {
      existingVersion = ` v${currentlyRegisteredConstructor.version}`;
    }

    // Need to make sure we're not working with null or empty strings before doing version comparisons.
    if (newVersion && existingVersion && newVersion === existingVersion) {
      // If versions match, we don't need to warn anyone. Carry on.
      return;
    }

    console.warn(
      `Attempted to register <${name}>${newVersion}, but <${name}>${existingVersion} has already been registered.`
    );
  }

  static dependencies: Record<string, typeof BaseElement> = {};

  constructor() {
    super();
    Object.entries(
      (this.constructor as typeof BaseElement).dependencies
    ).forEach(([name, component]) => {
      (this.constructor as typeof BaseElement).define(name, component);
    });
  }

  /** Emits a custom event with more convenient defaults. */
  emit(name: string, options?: CustomEventInit) {
    const event = new CustomEvent(name, {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail: {},
      ...options,
    });

    this.dispatchEvent(event);

    return event;
  }
}

export class BaseElement extends ShoelaceElement {
  // adds the test-id property to the base class shared by every component
  @property({ reflect: true }) 'test-id'?: string;

  slotMap = new Map();

  static tagname: keyof typeof PSElementTagNameMap;

  // eslint-disable-next-line class-methods-use-this
  assignSlotToContent(child: Element) {
    return child.getAttribute
      ? child.getAttribute('slot') || 'default'
      : 'default';
  }

  addChildToSlotMap(slot: string, child: Element) {
    if (!slot) return;

    if (!this.slotMap.has(slot)) {
      this.slotMap.set(slot, [child]);
    } else {
      this.slotMap.set(slot, [...this.slotMap.get(slot), child]);
    }
  }

  // Save a reference to the pseudoSlot content before lit-element renders
  saveSlots() {
    Array.from(this.childNodes).forEach((child) => {
      const slot = this.assignSlotToContent(child as Element);

      if (!child.textContent || child.textContent.trim().length > 0) {
        this.addChildToSlotMap(slot, child as Element);
      } else if (slot && child instanceof HTMLElement) {
        this.addChildToSlotMap(slot, child);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  update(
    changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ) {
    if (!this.hasUpdated) {
      this.saveSlots();
    }

    super.update(changedProperties);
  }

  /**
   *
   * @param slot {string} - the name of the slot to render
   * @param useShadowRoot - provides a way to bypass real slots (even when supported)
   */
  slotted(
    slot = 'default',
    useShadowRoot = true
  ): HTMLSlotElement | Node | Node[] | string | null {
    const slotContent = this.slotMap.get(slot);

    // render actual slots if Shadow DOM supported + slotted content exists
    if (this.shadowRoot && slotContent && useShadowRoot === true) {
      const realSlot = document.createElement('slot');
      if (slot !== 'default') {
        realSlot.setAttribute('name', slot);
      }
      return realSlot;
    }
    if (slotContent && slotContent.content) {
      return slotContent.content;
    }
    if (slotContent && slotContent.childNodes) {
      return Array.from(slotContent.childNodes);
    }
    if (slotContent) {
      return slotContent;
    }
    return null;
  }
}

export interface NonCheckableFormControl extends BaseElement {
  // Standard form attributes
  name: string;
  value: unknown;
  disabled?: boolean;
  defaultValue?: unknown;
  defaultChecked?: boolean;
  form?: string | HTMLFormElement | undefined;

  // Standard validation attributes
  pattern?: string;
  min?: number | string | Date;
  max?: number | string | Date;
  step?: number | 'any';
  required?: boolean;
  minlength?: number;
  maxlength?: number;

  // Proprietary validation properties (non-attributes)
  invalid: boolean;

  // when set to onSubmit, validation styles to be hidden by default until the form is first submitted
  validationMode: 'onSubmit' | 'onChange';
  // Form validation properties
  readonly validity: ValidityState;
  readonly validationMessage: string;

  // Form validation methods
  checkValidity: () => boolean;
  getForm?: () => HTMLFormElement | null;
  reportValidity: () => boolean;
  setCustomValidity: (message: string) => void;
}

export interface CheckableFormControl extends NonCheckableFormControl {
  checked?: boolean;
}

export type FormControl = NonCheckableFormControl | CheckableFormControl;
