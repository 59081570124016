import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import spacerStyles from './spacer.scss?inline';

@customElement('ps-spacer')
export class SpacerWC extends BaseElement {
  static styles = unsafeCSS(spacerStyles);

  /** The spacer's size. */
  @property({ reflect: true }) size:
    | '2xsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge' = 'medium';

  render() {
    return html`
      <div
        class="${classMap({
          'c-spacer': true,
          [`c-spacer--size-${this.size}`]: this.size,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-spacer': SpacerWC;
  }
  enum PSElementTagNameMap {
    'ps-spacer' = 'ps-spacer',
  }
}
