import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../base-element';
import './text';
import { Translate } from '../base-element/mixins/translation-mixin';

@customElement('ps-text-subtitle')
export class TextSubtitleWC extends Translate(BaseElement) {
  /** Subtitle size. */
  @property() size: 'medium' | 'small' | 'xsmall' = 'medium';

  /** Typography variant */
  @property() variant: 'primary' | 'secondary' | 'tertiary' = 'primary';

  /** Text-align */
  @property() textAlign: 'start' | 'center' | 'end' | 'justify' = 'start';

  /** Truncate option */
  @property({ type: Boolean }) truncate?: boolean = false;

  /** Number of lines before truncate */
  @property({ type: Number, reflect: true, attribute: 'truncate-lines' })
  truncateLines?: number;

  render() {
    const sizeOption =
      // eslint-disable-next-line no-nested-ternary
      this.size === 'xsmall'
        ? '3xsmall'
        : this.size === 'small'
          ? '2xsmall'
          : 'xsmall';

    return html`
      <ps-text
        size=${sizeOption}
        font-weight="medium"
        textAlign=${this.textAlign}
        variant=${this.variant}
        ?truncate=${this.truncate}
        truncate-lines=${ifDefined(this.truncateLines)}
        data-cy="text-subtitle"
      >
        <slot></slot>
      </ps-text>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text-subtitle': TextSubtitleWC;
  }
  enum PSElementTagNameMap {
    'ps-text-subtitle' = 'ps-text-subtitle',
  }
}
