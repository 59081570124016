import { unsafeCSS, html } from 'lit';
import { query } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import type { MenuItemWC, MenuItemSelectedEvent } from './menu-item.wc';
import styles from './menu.scss?inline';

@customElement('ps-menu')
export class MenuWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @query('slot') defaultSlot: HTMLSlotElement;

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('role', 'menu');
  }

  private handleClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const item = target.closest('ps-menu-item');

    if (!item || item.disabled || item.inert) {
      return;
    }

    this.emit('menu-item-selected', {
      detail: { item },
    }) as MenuItemSelectedEvent;
  }

  private handleMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (this.isMenuItem(target)) {
      this.setCurrentItem(target as MenuItemWC);
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    // Make a selection when pressing enter
    if (event.key === 'Enter') {
      const item = this.getCurrentItem();
      event.preventDefault();

      // Simulate a click to support @click handlers on menu items that also work with the keyboard
      item?.click();
    }

    // Prevent scrolling when space is pressed
    if (event.key === ' ') {
      event.preventDefault();
    }

    // Move the selection when pressing down or up
    if (['ArrowDown', 'ArrowUp', 'Home', 'End'].includes(event.key)) {
      const items = this.getAllItems();
      const activeItem = this.getCurrentItem();
      let index = activeItem ? items.indexOf(activeItem) : 0;

      if (items.length > 0) {
        event.preventDefault();

        if (event.key === 'ArrowDown') {
          index += 1;
        } else if (event.key === 'ArrowUp') {
          index -= 1;
        } else if (event.key === 'Home') {
          index = 0;
        } else if (event.key === 'End') {
          index = items.length - 1;
        }

        if (index < 0) {
          index = items.length - 1;
        }
        if (index > items.length - 1) {
          index = 0;
        }

        this.setCurrentItem(items[index]);
        items[index].focus();
      }
    }
  }

  private handleSlotChange() {
    const items = this.getAllItems();

    // Reset the roving tab index when the slotted items change
    if (items.length > 0) {
      this.setCurrentItem(items[0]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private isMenuItem(item: HTMLElement) {
    return (
      item.tagName.toLowerCase() === 'ps-menu-item' ||
      ['menuitem'].includes(item.getAttribute('role') ?? '')
    );
  }

  getAllItems() {
    return [
      ...(this.defaultSlot.assignedElements({
        flatten: true,
      }) as HTMLElement[]),
    ].filter((el: HTMLElement) => {
      if (el.inert || el.hasAttribute('disabled') || !this.isMenuItem(el)) {
        return false;
      }
      return true;
    }) as MenuItemWC[];
  }

  getCurrentItem() {
    return this.getAllItems().find((i) => i.getAttribute('tabindex') === '0');
  }

  setCurrentItem(item: MenuItemWC) {
    const items = this.getAllItems();

    // Update tab indexes
    items.forEach((i) => {
      i.setAttribute('tabindex', i === item ? '0' : '-1');
    });
  }

  render() {
    return html`
      <slot
        @slotchange=${this.handleSlotChange}
        @click=${this.handleClick}
        @keydown=${this.handleKeyDown}
        @mousedown=${this.handleMouseDown}
      ></slot>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-menu': MenuWC;
  }
  enum PSElementTagNameMap {
    'ps-menu' = 'ps-menu',
  }
}
