import {
  sortByLastDate,
  sortAlphabetically,
} from '../../utils/sorting-functions';
import { DeployingStatuses } from '@pypestream/design-system';

export const sortDataByUpdatedField = <T extends { updated: Date | null }>(
  data: T[],
  reverse = false
) =>
  data.sort((a, b) => {
    const defaultDate = new Date(1970);

    return sortByLastDate(reverse)(
      a.updated || defaultDate,
      b.updated || defaultDate
    );
  });

export const sortDataAlphabeticallyByTitleField = <T extends { title: string }>(
  data: T[],
  reverse = false
) => data.sort((a, b) => sortAlphabetically(reverse)(a.title, b.title));

export const sortDataByDeployingStatus = <
  T extends { status: keyof typeof DeployingStatuses },
>(
  data: T[],
  reverse = false
) =>
  data.sort((a, b) =>
    reverse
      ? DeployingStatuses[b.status] - DeployingStatuses[a.status]
      : DeployingStatuses[a.status] - DeployingStatuses[b.status]
  );
