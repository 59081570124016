import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import dividerStyles from './divider.scss?inline';

@customElement('ps-divider')
export class DividerWC extends BaseElement {
  static styles = unsafeCSS(dividerStyles);

  @property({ type: Boolean, reflect: true }) vertical: true;

  render() {
    return html`<hr
      class=${classMap({
        'c-divider': true,
        'c-divider--vertical': this.vertical,
      })}
    />`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-divider': DividerWC;
  }
  enum PSElementTagNameMap {
    'ps-divider' = 'ps-divider',
  }
}
