/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
// eslint-disable-next-line import/no-extraneous-dependencies
import { html, unsafeCSS, HTMLTemplateResult } from 'lit';
import { property, query, queryAssignedElements } from 'lit/decorators.js';
import { computePosition, shift, offset, arrow, flip } from '@floating-ui/dom';
import { autoUpdate } from '@pypestream/floating-ui-dom';
import { nanoid } from 'nanoid';
import { classMap } from 'lit/directives/class-map.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';
import { PageWC } from '../page';
import {
  BaseElement,
  BaseElementWithGlobalStyles,
  customElement,
} from '../base-element';
import { Translate } from '../base-element/mixins/translation-mixin';
import '../portal';
import styles from './tooltip.scss?inline';

@customElement('ps-tooltip')
export class TooltipWC extends Translate(BaseElementWithGlobalStyles) {
  @query('#_slot') _slot: HTMLElement;

  @query('#tooltip') tooltip: HTMLElement;

  tooltipRef = createRef();

  arrowRef = createRef();

  @property() portal: HTMLElement;

  @queryAssignedElements({ slot: 'content', flatten: false })
  slottedContent!: Array<Node>;

  @query('#arrow') arrowElement: HTMLElement;

  static styles = unsafeCSS(styles);

  static globalStyles = unsafeCSS(styles);

  @property() content: string;

  @property({ reflect: true }) tooltipId: string;

  @property({ reflect: true, attribute: 'aria-labelledby' })
  ariaLabeledBy: string;

  @property() renderedContent: HTMLTemplateResult;

  @property({ reflect: true }) placement: 'top' | 'bottom' | 'right' | 'left' =
    'top';

  @property({ type: Boolean, reflect: true }) open = false;

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);

    this.tooltipId = this.tooltipId || `tooltip-${nanoid()}`;
    this.ariaLabeledBy = this.tooltipId;

    this.reposition();

    this.updateComplete.then(() => {
      this.addEventListener('blur', this.hide, true);
      this.addEventListener('focus', this.show, true);
      this.addEventListener('mouseover', this.show);
      this.addEventListener('mouseleave', this.onMouseOut);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  onMouseOut(e: Event) {
    if ('toElement' in e && e.toElement !== null) {
      if (e.toElement instanceof HTMLElement === false) return;

      const renderedTooltip = this.tooltipRef.value as HTMLElement;

      if (renderedTooltip.contains(e.toElement as HTMLElement)) return;
      if (this.contains(e.toElement as HTMLElement)) return;

      this.hide();
    } else {
      this.hide();
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.removeEventListener('blur', this.hide, true);
    this.removeEventListener('focus', this.show, true);
    this.removeEventListener('mouseover', this.show);
    this.removeEventListener('mouseleave', this.onMouseOut);

    const destination = document.body.querySelector(
      `ps-portal-destination[name="${this.tooltipId}"]`
    );

    if (!this.closest('ps-portal-destination') && destination) {
      destination.remove();
    }

    this.cleanupPlacement();
  }

  firstUpdated() {
    this.reposition();

    const rootElement =
      document.querySelector('ps-page') !== null
        ? (document.querySelector('ps-page') as PageWC)
        : document.body;

    rootElement.insertAdjacentHTML(
      'beforeend',
      `<ps-portal-destination name="${this.tooltipId}"></ps-portal-destination>`
    );
  }

  // eslint-disable-next-line class-methods-use-this
  cleanupPlacement(): void {}

  // eslint-disable-next-line class-methods-use-this
  computePlacement(): void {}

  reposition() {
    if (this._slot instanceof HTMLSlotElement) {
      const child = this._slot.assignedElements({
        flatten: true,
      })[0] as HTMLElement;

      if (child && this.tooltipRef.value) {
        this.computePlacement = () => {
          computePosition(child, this.tooltipRef.value as HTMLElement, {
            placement: this.placement,
            middleware: [
              flip(),
              offset(6),
              shift({ padding: 3 }),
              arrow({ element: this.arrowRef.value as HTMLElement }),
            ],
          }).then(({ x, y, placement, middlewareData }) => {
            Object.assign((this.tooltipRef.value as HTMLElement).style, {
              left: `${x}px`,
              top: `${y}px`,
            });
            const { x: arrowX, y: arrowY } = middlewareData.arrow || {};

            const staticSide: string = {
              top: 'bottom',
              right: 'left',
              bottom: 'top',
              left: 'right',
            }[placement.split('-')[0]] as string;

            Object.assign((this.arrowRef?.value as HTMLElement).style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
              [staticSide]: '-4px',
            });
          });
        };

        this.cleanupPlacement = autoUpdate(
          child,
          this.tooltipRef.value as HTMLElement,
          this.computePlacement
        );
      }
    }
  }

  private show() {
    this.open = true;

    if (this.tooltipRef.value as HTMLElement) {
      (this.tooltipRef.value as HTMLElement).addEventListener(
        'mouseleave',
        (e) => {
          this.onMouseOut(e);
        }
      );
    }
  }

  private hide() {
    this.open = false;

    if (this.tooltipRef.value as HTMLElement) {
      (this.tooltipRef.value as HTMLElement).removeEventListener(
        'mouseleave',
        this.onMouseOut
      );
    }
  }

  render() {
    const text =
      this.content !== ''
        ? html`<div class="c-tooltip__text" data-cy="tooltip-text">
            ${this.content}
          </div>`
        : null;

    const content = this.slotted('content')
      ? html`
          <div class="c-tooltip__content">
            ${this.slotted('content', false)}
          </div>
        `
      : null;

    return html`<slot id="_slot"></slot>
      <ps-portal-entrance destination="${this.tooltipId}" .parent=${this}>
        <div
          id="${this.tooltipId}"
          class="${classMap({
            'c-tooltip--open': this.open,
            'c-tooltip': true,
          })}"
          role="tooltip"
          aria-label=${text}
          data-cy="tooltip"
          ${ref(this.tooltipRef)}
        >
          ${text} ${content}
          <div
            id="arrow"
            class="c-tooltip__arrow"
            ${ref(this.arrowRef)}
          ></div></div
      ></ps-portal-entrance>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-tooltip': TooltipWC;
  }

  enum PSElementTagNameMap {
    'ps-tooltip' = 'ps-tooltip',
  }
}
