import * as React from 'react';
import { createComponent } from '@lit/react';
import {
  NavLinkProps as NavLinkPropsReactRouter,
  useHref,
  useLinkClickHandler,
  useInRouterContext,
} from 'react-router-dom';
import { NavLogoWC } from './nav-logo.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { logos } from '../../assets/logos';

type ModifiedNavLinkProps = Omit<NavLinkPropsReactRouter, 'to' | 'children'>;

export type NavLogoProps = GenerateComponentTypeWithChildren<NavLogoWC> & {
  onClick?: React.MouseEventHandler<NavLogoWC>;
};

export type NavLogoPropsWithRef = GeneratePropsWithRef<NavLogoProps>;

export const NavLogoInner = createComponent({
  tagName: NavLogoWC.tagname,
  elementClass: NavLogoWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as NavLogoPropsWithRef;

type NavLogoRef = ModifiedNavLinkProps & {
  onClick?: React.MouseEventHandler<NavLogoWC>;
  href: NavLinkPropsReactRouter['to'];
  children?: React.ReactNode;
  condensed?: boolean;
  name?: keyof typeof logos;
};

export const NavLogoWithoutReactRouter = React.forwardRef<
  NavLogoProps,
  NavLogoRef
>(({ onClick, href, children, condensed, name, slot }, ref) => (
  <NavLogoInner
    href={href as string}
    onClick={(event: React.MouseEvent<NavLogoWC, MouseEvent>) => {
      onClick?.(event);
    }}
    ref={ref}
    condensed={condensed}
    name={name}
    slot={slot}
  >
    {children}
  </NavLogoInner>
));

export const NavLogoWithReactRouter = React.forwardRef<
  NavLogoProps,
  NavLogoRef
>(
  (
    {
      onClick,
      replace = false,
      state,
      target,
      href,
      children,
      condensed,
      name,
      slot,
    },
    ref
  ) => {
    const _href = useHref(href);
    const handleClick = useLinkClickHandler<NavLogoWC>(href, {
      replace,
      state,
      target,
    });

    return (
      <NavLogoInner
        href={_href}
        onClick={(event: React.MouseEvent<NavLogoWC, MouseEvent>) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        condensed={condensed}
        name={name}
        slot={slot}
      >
        {children}
      </NavLogoInner>
    );
  }
);

// react router integration added to the default NavLogo component exported
export const NavLogo = React.forwardRef<NavLogoProps, NavLogoRef>(
  (props, ref) => {
    const { onClick, href, children, condensed, name, slot } = props;
    const useReactRouter = useInRouterContext();

    if (useReactRouter) {
      return (
        <NavLogoWithReactRouter
          onClick={onClick}
          href={href}
          ref={ref}
          condensed={condensed}
          name={name}
          slot={slot}
        >
          {children}
        </NavLogoWithReactRouter>
      );
    }

    return (
      <NavLogoWithoutReactRouter
        onClick={onClick}
        href={href}
        ref={ref}
        condensed={condensed}
        name={name}
        slot={slot}
      >
        {children}
      </NavLogoWithoutReactRouter>
    );
  }
);
