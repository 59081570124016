import * as React from 'react';
import { createComponent } from '@lit/react';
import { CalloutTitleWC } from './callout-title.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type CalloutTitleProps =
  GenerateComponentTypeWithChildren<CalloutTitleWC>;

export type CalloutTitlePropsWithRef = GeneratePropsWithRef<CalloutTitleProps>;

export const CalloutTitle = createComponent({
  tagName: CalloutTitleWC.tagname,
  elementClass: CalloutTitleWC,
  react: React,
}) as CalloutTitlePropsWithRef;
