import { createStore } from '@ngneat/elf';
import {
  withEntities,
  updateEntities,
  selectAllEntities,
  entitiesPropsFactory,
  getAllEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId,
  resetActiveId,
  getActiveEntity,
  upsertEntities,
  deleteAllEntities,
  getAllEntitiesApply,
} from '@ngneat/elf-entities';
import { shareReplay } from 'rxjs/operators';
import {
  Edge as ReactFlowEdge,
  EdgeChange,
  Node as ReactFlowNode,
  NodeChange,
  NodeProps,
} from 'reactflow';
import { enablePatches } from 'immer';

import { GetGraphQuery } from '@pypestream/api-services';
import { i18n } from '@pypestream/translations';

import {
  DocumentContent,
  ImageContent,
  TextContent,
  VideoContent,
  WebviewContent,
} from '../../schemas/contents';
import {
  CUSTOM_DEFAULT_NODE_NAME,
  CUSTOM_DEFAULT_EDGE_NAME,
} from '../../components/graph';
import { getEdgeId } from '../../utils/graph';
import GraphWorker from '../../worker/graph?worker';
import { sendBuilderEvent } from '../../xstate/app.xstate';
import { SimNodeType } from '../../worker/graph';
import { NodeDetails } from '../../components/node-details';
import { flushNodeDetailsDb } from './node-details';

enablePatches();

const graphWorkerInstance = new GraphWorker();
export type MessageContents = (
  | TextContent
  | ImageContent
  | VideoContent
  | DocumentContent
  | WebviewContent
)[];

// @todo: review and see if we can simplify this gnarly structure below
// Do not export, if needed, we probably need to rename following interfaces,
// Node, NodeData, NodeInstanceType and NodeDetails
export interface NodeDetails
  // Omit jsonb typeof contents and inject typed contents
  extends Omit<
    NonNullable<
      GetGraphQuery['apps_version'][0]['version_node_instances'][0]
    >['node_instance']['node'],
    'messages' | '__typename'
  > {
  messages?: MessageContents;
}

export interface NodeInstanceType
  extends Omit<
    NonNullable<
      GetGraphQuery['apps_version']
    >[0]['version_node_instances'][0]['node_instance'],
    'node'
  > {
  node: NodeDetails;
}

export interface NodeData
  extends Omit<
    NonNullable<GetGraphQuery['apps_version'][0]['version_node_instances'][0]>,
    'node_instance' | 'outgoing_nodes'
  > {
  parentId?: number | number[] | undefined;
  node_instance: NodeInstanceType;
  outgoing_nodes: {
    to_node_numbers: number[] | [];
  };
}

export interface EdgeData {
  status: 'warning' | 'error' | 'valid';
}

export type Node = ReactFlowNode<NodeData>;

export type Edge = ReactFlowEdge<EdgeData>;

const { edgeEntitiesRef, withEdgeEntities } = entitiesPropsFactory('edge');

export const builderStore = createStore(
  { name: 'builder' },
  withEntities<Node>(),
  withEdgeEntities<Edge>(),
  withActiveId()
);

export const nodes$ = builderStore.pipe(
  selectAllEntities(),
  shareReplay({ refCount: true })
);

export const edges$ = builderStore.pipe(
  selectAllEntities({ ref: edgeEntitiesRef }),
  shareReplay({ refCount: true })
);

// This is not being used currently as observable not working as expected if we use combined entities
export const graphData$ = builderStore.combine({
  nodes: builderStore.pipe(selectAllEntities()),
  edges: builderStore.pipe(selectAllEntities({ ref: edgeEntitiesRef })),
});

export const selectedNode$ = builderStore.pipe(selectActiveEntity());

export const getActiveNode = (): Node | undefined =>
  builderStore.query(getActiveEntity());

export const setActiveNode = (nodeNumber: number): void => {
  // Flush node-details elf store as in when we change the active node.
  // @todo - Need to keep this data persistent with ELF store for quick re-renders for future rendering's of details panel
  flushNodeDetailsDb();
  builderStore.update(setActiveId(nodeNumber));
};

export const resetActiveNode = (): void => builderStore.update(resetActiveId());

export const handleAddNode = (node: Node): void => {
  if (node?.data?.node_number) {
    builderStore.update(upsertEntities(node));
    if (node.data.outgoing_nodes?.to_node_numbers) {
      handleAddEdge(
        node.data.outgoing_nodes.to_node_numbers.map((child) => ({
          id: getEdgeId(node.data.node_number, child),
          source: node.id,
          target: `${child}`,
          hidden: false,
          type: CUSTOM_DEFAULT_EDGE_NAME,
          data: { status: 'valid' }, // @todo status placeholder - add status methods
          sourceHandle: 'a',
          targetHandle: 'c',
        }))
      );
    }
  } else {
    if (!node.data.node_number) {
      return;
    }
    builderStore.update(upsertEntities(node));
    if (node.data.outgoing_nodes?.to_node_numbers) {
      handleAddEdge(
        [...new Set(node.data.outgoing_nodes.to_node_numbers)]
          .filter((___node) => ___node)
          .map((child) => ({
            id: getEdgeId(node.data.node_number, child),
            source: node.id,
            target: child.toString(),
            hidden: false,
            type: CUSTOM_DEFAULT_EDGE_NAME,
            data: { status: 'valid' },
            sourceHandle: 'a',
            targetHandle: 'c',
          }))
      );
    }
  }
};

export const handleAddEdge = (edge: Edge | Edge[]): void => {
  builderStore.update(upsertEntities(edge, { ref: edgeEntitiesRef }));
};

export const flushGraphDb = () => {
  builderStore.update(deleteAllEntities());
  builderStore.update(deleteAllEntities({ ref: edgeEntitiesRef }));
  flushNodeDetailsDb();
};

// #region graph-rendering POC code
let updateGraphLayoutTimeoutId: NodeJS.Timeout;
const between = (number: number, min: number, max: number) => {
  return number >= min && number <= max;
};
// #endregion graph-rendering POC code

export const handleNodeChanges = (nodeChanges: NodeChange[]): void => {
  nodeChanges.forEach((nodeChange) => {
    switch (nodeChange.type) {
      case 'position':
        if (nodeChange.position) {
          builderStore.update(
            updateEntities(nodeChange.id, {
              position: nodeChange.position,
            })
          );

          // Temporary code till we decide on final graph rendering approach.
          clearTimeout(updateGraphLayoutTimeoutId);
          updateGraphLayoutTimeoutId = setTimeout(() => {
            if (nodeChange.position) {
              const minX = nodeChange.position.x - 1600;
              const maxX = nodeChange.position.x + 1600;
              const minY = nodeChange.position.y - 900;
              const maxY = nodeChange.position.y + 900;

              const allNodes = builderStore.query(
                getAllEntitiesApply({
                  filterEntity: (node) =>
                    between(node.position.x, minX, maxX) &&
                    between(node.position.y, minY, maxY),
                })
              );

              const draggedNode: SimNodeType | undefined = allNodes.find(
                (node) => node.id === nodeChange.id
              );

              if (draggedNode) {
                draggedNode.fx = nodeChange.position.x;
                draggedNode.fy = nodeChange.position.y;
              }

              graphWorkerInstance.postMessage({
                type: 'update',
                nodes: allNodes,
              });
            }
          }, 300);
        }

        break;
      default:
        break;
    }
  });
};

export const handleEdgeChanges = (edgeChanges: EdgeChange[]): void => {
  console.log('handleEdgeChanges ', edgeChanges);
  // edgeChanges.forEach((edgeChange) => {
  // Handling for edge updates
  // switch (edgeChange.type) {
  // }
  // });
};

export const handleCollapseToggle = ({
  nodeId,
  isCollapsed,
}: {
  nodeId: string;
  isCollapsed: boolean;
}): void => {
  // Disabled this feature as we need to check with design team for expectation with Graph.
  // if (treeHolder) {
  //   const descendantsToUpdate = treeHolder
  //     .find((node) => node.id === nodeId)
  //     ?.descendants();
  //   if (descendantsToUpdate?.length) {
  //     builderStore.update(
  //       updateEntities(
  //         descendantsToUpdate.map((node) => node.data.id),
  //         (entity) => ({
  //           ...entity,
  //           hidden: entity.id === nodeId ? false : isCollapsed,
  //           data: {
  //             ...entity.data,
  //             collapsed: isCollapsed,
  //           },
  //         })
  //       )
  //     );
  //     builderStore.update(
  //       updateEntities(
  //         descendantsToUpdate.reduce((accumulator, currentValue) => {
  //           const nodeData = (currentValue.data as Node).data;
  //           if (nodeData.children) {
  //             const ids =
  //               nodeData.children.map((child) =>
  //                 getEdgeId(currentValue.data.id, child)
  //               ) || [];
  //             return accumulator.concat(ids);
  //           }
  //           return accumulator;
  //         }, [] as string[]),
  //         {
  //           hidden: isCollapsed,
  //         },
  //         {
  //           ref: edgeEntitiesRef,
  //         }
  //       )
  //     );
  //   }
  // }
};

export const handleNodeTitleChange = ({
  nodeNumber,
  updateTitle,
}: {
  nodeNumber: string;
  updateTitle: string;
}): void => {
  builderStore.update(
    updateEntities(nodeNumber, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        node_instance: {
          ...entity.data.node_instance,
          node: {
            ...entity.data.node_instance.node,
            node_name: updateTitle,
          },
        },
      },
    }))
  );
};

export const handleNodeStatusChange = ({
  nodeId,
  status,
}: {
  nodeId: string;
  status: string;
}): void => {
  builderStore.update(
    updateEntities(nodeId, (entity) => ({
      ...entity,
      data: {
        ...entity.data,
        status,
      },
    }))
  );
};

const xPosCalc = 95;
export const convertToDefaultNode = (node: NodeProps<NodeData>): void => {
  const t = i18n.getFixedT(i18n.language);
  builderStore.update(
    updateEntities(node.id, {
      type: CUSTOM_DEFAULT_NODE_NAME,
      // @todo - How to handle this elegantly?
      position: { x: node.xPos - xPosCalc, y: node.yPos },
      data: {
        ...node.data,
        children: [],
        // @todo - Need to fix nested element-wise i18n translations.
        // Currently we rely on global i18n to detect lang
        label: t('build:addNewNode'),
      },
    })
  );

  if (node.data.parentId && Array.isArray(node.data.parentId)) {
    node.data.parentId.forEach((id: number) => {
      builderStore.update(
        updateEntities(
          // @todo - This id creation should be a single function to avoid issues?
          getEdgeId(id, node.id),
          {
            type: CUSTOM_DEFAULT_EDGE_NAME,
            data: {
              status: 'warning',
            },
          },
          { ref: edgeEntitiesRef }
        )
      );
    });
  }

  // const treeNode = treeHolder.find((_node) => _node.id === node.id);
  // if (treeNode) {
  //   createPlaceHolderNode(treeNode);
  // }
};

// Function which creates placeholder node and update treeHolder to maintain tree structure.
// const createPlaceHolderNode = (parentNode: HierarchyPointNode<Node>): void => {
//   const nodeData: NodeData = parentNode.data.data;
//   // @todo - Is it ideal? as this might generate same 6-digit random number.
//   // Probability of happening that is low but worth checking better way of handling this.
//   const placeHolderNodeNumber = getRandomNumber();

//   // @todo - Need to refactor this as base object for Node/Edge should come from single place.
//   const placeHolderNode: Node = {
//     type: CUSTOM_PLACEHOLDER_NODE_NAME,
//     position: {
//       x: parentNode.x + xPosCalc,
//       y: parentNode.y + 128 * 1.39,
//     },
//     id: placeHolderNodeNumber.toString(),
//     data: {
//       label: '',
//       number: placeHolderNodeNumber.toString(),
//       type: 'D',
//       children: [],
//       collapsed: false,
//       parentId: [],
//     },
//   };

//   // Add placeholder node to store.
//   builderStore.update(upsertEntities(placeHolderNode));

//   // Add edge to store.
//   // handleAddEdge({
//   //   id: getEdgeId(nodeData.number, placeHolderNodeNumber),
//   //   source: nodeData.number.toString(),
//   //   target: placeHolderNodeNumber.toString(),
//   //   hidden: false,
//   //   type: CUSTOM_PLACEHOLDER_EDGE_NAME,
//   // });

//   // Update parent node store to reflect newly added child elements.
//   if (parentNode.id) {
//     builderStore.update(
//       updateEntities(parentNode.id, (entity) => ({
//         ...entity,
//         data: {
//           ...entity.data,
//           children: [`${placeHolderNodeNumber}`],
//         },
//       }))
//     );
//   }

//   // Add newNode to treeHolder to maintain tree structure.
//   const newNode = hierarchy<Node>(placeHolderNode) as HierarchyPointNode<Node>;
//   newNode.parent = parentNode;
//   newNode.x = placeHolderNode.position.x - xPosCalc;
//   newNode.y = placeHolderNode.position.y;
//   // Overwrite few readonly properties as we created hierarchy node manually
//   (newNode.depth as number) = parentNode.depth + 1;
//   (newNode.height as number) = parentNode.height - 1;
//   (newNode.id as string) = placeHolderNode.id;

//   // Update parent node as this will mutate treeNode to have all updates with local tree instance.
//   if (!parentNode.children || !parentNode.data.data.children) {
//     parentNode.children = [];
//     parentNode.data.data.children = [];
//   }
//   parentNode.children.push(newNode);
//   parentNode.data.data.children.push(placeHolderNode.id);
// };

// @todo - Is it right place for generateGraph?
export const generateGraph = (linkStrength = 0.5) => {
  const allNodes = builderStore.query(getAllEntities());
  const allEdges = builderStore.query(getAllEntities({ ref: edgeEntitiesRef }));

  graphWorkerInstance.addEventListener('message', (event) => {
    switch (event.data?.kind) {
      case 'simulation_update':
        sendBuilderEvent({
          type: 'builder.set.simulation.progress',
          progress: event.data.progress,
        });
        break;
      case 'simulation_result':
        event.data.nodes?.forEach((node: SimNodeType) => {
          if (node.id && node.x && node.y) {
            builderStore.update(
              updateEntities(node.id, {
                position: {
                  x: node.x,
                  y: node.y,
                },
              })
            );
          }
        });
        sendBuilderEvent({
          type: 'builder.set.simulation.completed',
        });
        break;
      default:
        break;
    }
  });

  const { width, height } = document.body.getBoundingClientRect();
  graphWorkerInstance.postMessage({
    nodes: allNodes,
    links: allEdges,
    width,
    height,
  });
};
