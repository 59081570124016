import { default as en } from 'date-fns/locale/en-US';
import { default as ar } from 'date-fns/locale/ar';
import { default as hi } from 'date-fns/locale/hi';
import { default as zh } from 'date-fns/locale/zh-CN';
import { default as ru } from 'date-fns/locale/ru';

// @TODO - Need to fix lazy loading of date-fns resources.
const dateFnsLocales: { [keys: string]: Locale } = {
  en,
  ar,
  hi,
  zh,
  ru,
};

export { dateFnsLocales };
