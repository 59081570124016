import * as React from 'react';
import { createComponent } from '@lit/react';
import { FormControlInterface } from '@open-wc/form-control';
import { TabButtonWC } from './tab-button.wc';
import { FormControlController } from '../../base-element';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';
import { TranslateInterface } from '../../base-element/mixins/translation-mixin';

type OmittedTabButtonWC = Omit<
  TabButtonWC,
  keyof InstanceType<typeof FormControlController> | keyof FormControlInterface
>;

export type TabButtonProps =
  GenerateComponentTypeWithChildren<OmittedTabButtonWC> &
    TranslateInterface & {
      onClick?: React.MouseEventHandler<TabButtonWC | HTMLButtonElement>;
      id?: string;
    };

export type TabButtonPropsWithRef = GeneratePropsWithRef<TabButtonProps>;

export const TabButton = createComponent({
  tagName: TabButtonWC.tagname,
  elementClass: TabButtonWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as TabButtonPropsWithRef;
