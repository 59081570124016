import * as React from 'react';
import { createComponent } from '@lit/react';
import { TableHeadWC } from './table-head.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TableHeadProps = GenerateComponentTypeWithChildren<TableHeadWC>;

export type TableHeadPropsWithRef = GeneratePropsWithRef<TableHeadProps>;

export const TableHead = createComponent({
  tagName: TableHeadWC.tagname,
  elementClass: TableHeadWC,
  react: React,
}) as TableHeadPropsWithRef;
