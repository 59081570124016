import { unsafeCSS } from 'lit';
import 'construct-style-sheets-polyfill';
import style from '../styles/global-styles/global-styles.scss?inline';

const styles = unsafeCSS(style);

const sheet = new CSSStyleSheet();
sheet.replaceSync(`${styles}`);
document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];

export {};
