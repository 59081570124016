import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../base-element';
import styles from './breadcrumb.scss?inline';
import type { BreadcrumbLink } from './breadcrumb';
import './breadcrumb-item';

@customElement('ps-breadcrumb')
export class BreadcrumbWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * The label to use for the breadcrumb control. This will not be shown on the screen, but it will be announced by
   * screen readers and other assistive devices to provide more context for users.
   */
  @property() label = '';

  /**
   * Links
   */
  @property() links: BreadcrumbLink[] = [];

  render() {
    return html`
      <nav class="c-breadcrumb" aria-label=${this.label}>
        ${this.links.map(
          (link: BreadcrumbLink) =>
            html`<ps-breadcrumb-item
              href="${ifDefined(link.href ? link.href : undefined)}"
              target="${ifDefined(link.target ? link.target : undefined)}"
              root="${ifDefined(link.root ? link.root : undefined)}"
              icon="${ifDefined(link.icon ? link.icon : undefined)}"
              text="${ifDefined(link.text ? link.text : undefined)}"
            ></ps-breadcrumb-item>`
        )}
      </nav>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-breadcrumb': BreadcrumbWC;
  }
  enum PSElementTagNameMap {
    'ps-breadcrumb' = 'ps-breadcrumb',
  }
}
