import * as React from 'react';
import { createComponent } from '@lit/react';
import { AppletCardTitleWC } from './applet-card-title.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type AppletCardTitleProps =
  GenerateComponentTypeWithChildren<AppletCardTitleWC>;

export type AppletCardTitlePropsWithRef =
  GeneratePropsWithRef<AppletCardTitleProps>;

export const AppletCardTitle = createComponent({
  tagName: AppletCardTitleWC.tagname,
  elementClass: AppletCardTitleWC,
  react: React,
}) as AppletCardTitlePropsWithRef;
