import {
  Machine,
  Typestate,
  Interpreter,
  Sender,
  assign,
  actions,
} from 'xstate';
const { escalate } = actions;
import { getXstateUtils, MachineStateSchemaPaths } from '../xstate.utils';
import { urqlGql } from '@pypestream/api-services/urql';
import {
  gql,
  GetMicroAppsQuery,
  GetAppsEnvironmentQuery,
} from '@pypestream/api-services';
import { sendUserMessage } from '../app.xstate-utils';
import { ErrorTags } from '../../components/error-boundary/types';
import { getMicroAppDraftVersionId } from './helpers';

// TODO: refactor the name of this xstate machine

export interface MicroAppsContext {
  data: undefined | NonNullable<GetMicroAppsQuery['apps_app']>;
  customerId: number | null;
  errors?: Error;
  selectedAppId: number | null;
  selectedVersionId: number | null;
  draftVersionId: string | undefined;

  unsubscribeFromMicroAppsSubscription: () => void;
  environments: GetAppsEnvironmentQuery['apps_environment'];
}

export interface MicroAppsState {
  states: {
    refetching: Record<string, unknown>;
    idle: Record<string, unknown>;
    loading: Record<string, unknown>;
    loaded: {
      states: {
        listenForAppUpdates: Record<string, unknown>;
        appManagement: {
          states: {
            waitingToSelectApp: Record<string, unknown>;
            waitingToSelectAppVersion: Record<string, unknown>;
            appVersionSelected: Record<string, unknown>;
          };
        };
      };
    };
    error: Record<string, unknown>;
  };
}

export type MicroAppsEvents =
  | {
      type: 'microApps.refetch';
    }
  | {
      type: 'microApps.updateData';
      data: GetMicroAppsQuery['apps_app'];
    }
  | {
      type: 'microApps.error';
      error: Error;
    }
  // disabling these unused events for now
  // | {
  //     type: 'microApps.addMicroapp';
  //   }
  // | {
  //     type: 'microApps.deleteMicroapp';
  //   }
  | {
      type: 'microApps.selectAppId';
      id: MicroAppsContext['selectedAppId'];
    }
  | {
      type: 'microApps.setCustomerId';
      id: MicroAppsContext['customerId'];
    }
  // | {
  //     type: 'microApps.unassignCustomerId';
  //   }
  | {
      type: 'microApps.deselectAppId';
    }
  | {
      type: 'microApps.selectVersionId';
      id: MicroAppsContext['selectedVersionId'];
    }
  | {
      type: 'microApps.deselectVersionId';
    }
  | {
      type: 'reset.errors';
    }
  | {
      type: 'reset.context';
      data: MicroAppsContext;
    };

export interface MicroAppsTypeStates extends Typestate<MicroAppsContext> {
  context: MicroAppsContext;
  value: MachineStateSchemaPaths<MicroAppsState['states']>;
}

export type MicroAppsInterpreter = Interpreter<
  MicroAppsContext,
  MicroAppsState,
  MicroAppsEvents,
  MicroAppsTypeStates,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

const {
  createInvokablePromise,
  createXstateHooks: createMicroappsXstateHooks,
} = getXstateUtils<
  MicroAppsContext,
  MicroAppsEvents,
  MicroAppsTypeStates,
  MicroAppsState
>();

export { createMicroappsXstateHooks };

export const microAppsInitialContext: MicroAppsContext = {
  data: [],
  selectedAppId: null,
  selectedVersionId: null,
  draftVersionId: undefined,
  customerId: 1,
  environments: [],
  unsubscribeFromMicroAppsSubscription: () => {
    console.log('unsubscribed');
  },
};

export const microAppsMachine = Machine<
  MicroAppsContext,
  MicroAppsState,
  MicroAppsEvents
>(
  {
    context: microAppsInitialContext,
    preserveActionOrder: true,
    predictableActionArguments: true,
    id: 'microApps',
    initial: 'idle',
    strict: true,
    invoke: createInvokablePromise({
      src: async ({ customerId }) => {
        if (customerId === null) {
          return [];
        }

        const data = await gql.getAppsEnvironment({
          customerId: customerId,
        });

        return data.apps_environment;
      },
      onDoneAssignContext({ ctx, data }) {
        ctx.environments = data || [];
      },
    }),
    on: {
      'reset.context': {
        actions: ['resetContext'],
        target: 'idle',
      },
      'microApps.selectAppId': [
        {
          actions: ['selectAppId'],
          cond: (context) => context.customerId !== null,
        },
      ],
      'microApps.updateData': [
        {
          actions: assign((ctx, event) => {
            if (event.type === 'microApps.updateData') {
              ctx.data = event.data;
            }
            return ctx;
          }),
        },
      ],
      'microApps.setCustomerId': [
        {
          actions: assign((ctx, event) => {
            if (event.type === 'microApps.setCustomerId') {
              ctx.customerId = event.id;
            }
            return ctx;
          }),
          cond: (ctx, event) =>
            event.type === 'microApps.setCustomerId' &&
            typeof event.id === 'number' &&
            !Number.isNaN(event.id),
          target: 'refetching',
        },
        {
          actions: assign((ctx) => {
            ctx.customerId = null;
            return ctx;
          }),
          target: 'idle',
        },
      ],
      'microApps.selectVersionId': {
        actions: ['selectVersionId'],
        cond: (context, event) => event.id !== context.selectedVersionId,
      },
      'microApps.deselectVersionId': {
        actions: ['deselectVersionId'],
        cond: (context) => Boolean(context.selectedVersionId),
      },
      'microApps.deselectAppId': {
        actions: ['deselectAppId'],
        cond: (context) => Boolean(context.selectedAppId),
      },
    },
    states: {
      refetching: {
        id: 'refetching',
        always: [
          {
            target: 'idle',
            cond: (context, event) =>
              context.customerId !== undefined && context.customerId !== null,
          },
          {
            target: 'idle',
            actions: [assign({ data: (context) => (context.data = []) })],
          },
        ],
      },
      idle: {
        entry: [assign({ data: (context) => (context.data = []) })],
        always: [
          {
            target: 'loading',
            cond: (context, event) =>
              context.customerId !== undefined && context.customerId !== null,
          },
        ],
      },
      loading: {
        invoke: createInvokablePromise({
          id: 'loading',
          src: async (context, event) => {
            if (context.customerId === null) {
              throw Error('need a customerId in order to fetch micro apps!');
            }

            const { data, error } = await urqlGql
              .getMicroApps({
                customerId: context.customerId,
              })
              .toPromise();

            if (error) escalate(error);

            return data;
          },
          onDoneAssignContext({ ctx, data }) {
            ctx.data = data?.apps_app || [];
            ctx.draftVersionId = getMicroAppDraftVersionId(
              data?.apps_app,
              ctx.selectedAppId
            );
          },
          onDoneTarget: 'loaded',
          onErrorTarget: 'error',
          onErrorActions: [
            {
              type: 'sendUserMessage',
              exec(_, event) {
                sendUserMessage({
                  type: 'error',
                  text: `3 Error fetching microapps: ${JSON.stringify(
                    event.data
                  )}`,
                  autoClose: 3000,
                });
              },
            },
          ],
        }),
      },
      loaded: {
        id: 'loaded',
        type: 'parallel',
        states: {
          listenForAppUpdates: {
            invoke: {
              src: (context) => (sendEvent: Sender<MicroAppsEvents>) => {
                // @todo: should return Promise
                new Promise((resolve, reject) => {
                  if (context.customerId === null) {
                    throw Error(
                      'need a customerId in order to subscribe to micro apps updates'
                    );
                  }

                  const { unsubscribe } = urqlGql
                    .getMicroAppsSubscription({
                      // microappId: context?.selectedAppId,
                      customerId: context.customerId,
                      // customerId: 'g',
                    })
                    .subscribe(({ data, error }) => {
                      const _error = error;
                      // error || Math.floor(Math.random() * 2) > 0
                      //   ? {
                      //       message: 'Test error in subscription Promise',
                      //     }
                      //   : false;

                      if (_error) {
                        // @todo: reject instead of throw new Error
                        reject(_error);
                        // throw new Error(JSON.stringify(error));
                        // console.log('error', error);
                        // unsubscribe();
                        // raise(error);
                      } else {
                        console.log(
                          'incoming data from listenForAppUpdates subscription',
                          data?.apps_app
                        );
                        // @todo: resolve instead of sendEvent?
                        // return resolve(data?.apps_app || []);

                        sendEvent({
                          type: 'microApps.updateData',
                          data: data?.apps_app || [],
                        });
                      }
                    });

                  context.unsubscribeFromMicroAppsSubscription = () => {
                    console.log(
                      'unsubscribed from getMicroAppsSubscription',
                      context.customerId
                    );
                    unsubscribe();
                  };
                });
              },
              // @todo: receive resolved data from DB
              onDone: {
                actions: assign((context, event) => {
                  const { data } = event;

                  return {
                    ...context,
                    data,
                    draftVersionId: getMicroAppDraftVersionId(
                      data,
                      context.selectedAppId
                    ),
                  };
                }),
              },
              onError: {
                // @todo: I think it should be global microApps error
                // target: '#microApps.loaded.appDetails.error',
                target: '#microApps.error',
                actions: assign((ctx, event) => {
                  console.log('error!', event);
                  ctx.unsubscribeFromMicroAppsSubscription();
                  sendUserMessage({
                    type: 'error',
                    text: `GraphQL subscription error!`,
                    autoClose: 3000,
                  });
                  ctx.errors = event.data;
                  return ctx;
                }),
              },
            },
            exit(context, data) {
              console.log('onexit', data);
              context.unsubscribeFromMicroAppsSubscription();
              return;
            },
          },
          appManagement: {
            initial: 'waitingToSelectApp',
            states: {
              waitingToSelectApp: {
                id: 'waitingToSelectApp',
                always: [
                  {
                    target: 'waitingToSelectAppVersion',
                    cond: 'hasAppId',
                  },
                ],
              },
              waitingToSelectAppVersion: {
                id: 'waitingToSelectAppVersion',
                always: [
                  {
                    target: 'appVersionSelected',
                    cond: 'hasAppVersion',
                  },
                ],
              },
              appVersionSelected: {
                id: 'appVersionSelected',
                always: [
                  {
                    target: 'waitingToSelectApp',
                    cond: function hasAppSelected(ctx) {
                      return ctx.selectedAppId === null;
                    },
                  },
                  {
                    target: 'waitingToSelectAppVersion',
                    cond: function hasAppVersionSelected(ctx) {
                      return ctx.selectedVersionId === null;
                    },
                  },
                ],
              },
            },
          },
        },
      },
      error: {
        tags: [ErrorTags.global],
        on: {
          'microApps.refetch': 'refetching',
        },
        exit: ['resetErrors'],
      },
    },
  },
  {
    actions: {
      selectAppId: assign((ctx, event) => {
        console.log('selectAppId action');
        if (event.type !== 'microApps.selectAppId') {
          return ctx;
        }

        const draftVersionId = getMicroAppDraftVersionId(ctx.data, event.id);

        return {
          ...ctx,
          selectedAppId: event.id,
          draftVersionId,
        };
      }),
      deselectAppId: assign((ctx) => {
        ctx.selectedAppId = null;
        return ctx;
      }),
      selectVersionId: assign((ctx, event) => {
        if (event.type === 'microApps.selectVersionId') {
          ctx.selectedVersionId = event.id;
        }

        return ctx;
      }),
      deselectVersionId: assign((ctx) => {
        if (ctx.selectedVersionId) {
          ctx.selectedVersionId = null;
        }
        // disabled here for now. now firing off in the syncXstateWithRouteParams util
        // sendBuilderEvent('builder.resetToIdle');
        return ctx;
      }),
      resetErrors: assign((ctx) => {
        delete ctx.errors;

        return ctx;
      }),
      resetContext: assign((ctx, event) => {
        if (event.type !== 'reset.context') {
          return ctx;
        }

        return event.data;
      }),
    },
    guards: {
      hasAppId: (context) => context.selectedAppId !== null,
      hasAppVersion: (context) => context.selectedVersionId !== null,
    },
  }
);
