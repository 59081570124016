import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Select, SelectOption } from '../../../select';

export const SelectWidget = (props: WidgetProps) => {
  const { value, required, onChange, options, name } = props;

  return (
    <Select
      value={value}
      required={required}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      size={name === 'operator' ? 'medium' : 'small'}
      style={{ maxWidth: name === 'operator' ? '100px' : '275px' }}
    >
      {options.enumOptions?.map(({ label: optLabel, value: optValue }) => (
        <SelectOption value={optValue} key={optValue}>
          {optLabel}
        </SelectOption>
      ))}
    </Select>
  );
};
