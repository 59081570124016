import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './nav-link.scss?inline';
import { Translate } from '../base-element/mixins/translation-mixin';

@customElement('ps-nav-link')
export class NavLinkWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  /** Image width. */
  @property({ reflect: true }) href: string;

  @property({ reflect: true, type: Boolean }) active?: boolean = false;

  @property({ reflect: true, type: Boolean }) disabled?: boolean = false;

  @property({ reflect: true }) target: HTMLAnchorElement['target'];

  render() {
    const classes = classMap({
      'c-nav-link': true,
      'c-nav-link--active': !!this.active,
      'c-nav-link--disabled': !!this.disabled,
    });

    // TODO: use a similar templating pattern as what we're doing in Button
    if (this.href)
      return html`
        <a href="${this.href}" class=${classes} target=${this.target}>
          ${this.slotted('default')}
        </a>
      `;

    return html`
      <button class=${classes} target=${this.target}>
        ${this.slotted('default')}
      </button>
    `;
  }
}
