import * as React from 'react';
import { createComponent } from '@lit/react';
import { StepperWC } from './stepper.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

// export type StepperProps = GenerateComponentTypeWithChildren<StepperWC>;

// export type StepperPropsWithRef = GeneratePropsWithRef<StepperProps>;

export const Stepper = createComponent<StepperWC>({
  tagName: StepperWC.tagname,
  elementClass: StepperWC,
  react: React,
});
