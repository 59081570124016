/* eslint-disable lit/no-invalid-html, lit/binding-positions */
import { PropertyValueMap, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html, literal } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElementWithGlobalStyles, customElement } from '../../base-element';
import styles from './icon-button.scss?inline';
import globalStyles from './icon-button.vars.scss?inline';

import type { Icons } from '../../icon/icon.types';

// auto-import the Icon component if not yet pulled in
import '../../icon/icon.wc';

@customElement('ps-icon-button')
export class IconButtonWC extends BaseElementWithGlobalStyles {
  static styles = unsafeCSS(styles);

  static globalStyles = unsafeCSS(globalStyles);

  /** The button's size. */
  @property({ reflect: true }) size: 'xsmall' | 'small' | 'medium' | 'large' =
    'medium';

  /** The button's variant. */
  @property({ reflect: true }) variant: 'primary' | 'secondary' | 'tertiary' =
    'primary';

  /** Set button shape to round. */
  @property({ reflect: true, type: Boolean }) rounded = false;

  /** Disables the button. */
  @property({ reflect: true, type: Boolean }) disabled = false;

  /**
   * The type of button. When the type is `submit`, the button will submit the surrounding form. Note that the default
   * value is `button` instead of `submit`, which is opposite of how native `<button>` elements behave.
   */
  @property() type: 'button' | 'submit' | 'reset' = 'button';

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: string;

  /** Icon name. */
  @property({ reflect: true }) name: keyof typeof Icons;

  /** If button rendered as an `<a>`, it is possible to set target attribute. */
  @property({ reflect: true }) target: HTMLAnchorElement['target'];

  firstUpdated(
    _changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(_changedProperties);
    this.emit('load');
  }

  render() {
    const isLink = !!this.href;
    const tag = isLink ? literal`a` : literal`button`;

    let iconSize = ['xsmall', 'small'].includes(this.size) ? 'xsmall' : 'small';

    if (this.name === 'meatballs') iconSize = 'medium';

    /* eslint-disable lit/no-invalid-html, lit/binding-positions */
    return html`
      <${tag}
        class=${classMap({
          'c-icon-btn': true,
          [`c-icon-btn--size-${this.size}`]: this.size,
          [`c-icon-btn--${this.variant}`]: this.variant,
          'c-icon-btn--rounded': this.rounded,
        })}
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        type=${ifDefined(isLink ? undefined : this.type)}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}

      >
        <span class="c-icon-btn__icon">
          ${
            this.name
              ? html`<ps-icon
                  name=${this.name}
                  size=${iconSize}
                  exportparts="icon: icon-button-icon"
                ></ps-icon>`
              : html`<slot></slot>`
          }
        </span>
      </${tag}>
    `;
    /* eslint-enable lit/no-invalid-html, lit/binding-positions */
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-icon-button': IconButtonWC;
  }
  enum PSElementTagNameMap {
    'ps-icon-button' = 'ps-icon-button',
  }
}
