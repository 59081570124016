import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import tableHeadStyles from './table-head.scss?inline';

@customElement('ps-table-head')
export class TableHeadWC extends BaseElement {
  static styles = unsafeCSS(tableHeadStyles);

  render() {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-table-head': TableHeadWC;
  }
  enum PSElementTagNameMap {
    'ps-table-head' = 'ps-table-head',
  }
}
