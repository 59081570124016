import { classMap } from 'lit/directives/class-map.js';
import { html, unsafeCSS } from 'lit';
import { live } from 'lit/directives/live.js';
import { property, query, state } from 'lit/decorators.js';
import { FormControlMixin } from '@open-wc/form-control';
import styles from './toggle.scss?inline';
import {
  BaseElement,
  customElement,
  CheckableFormControl,
  FormControl,
} from '../base-element';

@customElement('ps-toggle')
export class ToggleWC
  extends FormControlMixin(BaseElement)
  implements CheckableFormControl
{
  static styles = unsafeCSS(styles);

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  get form() {
    return this.internals.form;
  }

  @query('input[type="checkbox"]') input: HTMLInputElement;

  @state() invalid = false;

  /** The name of the toggle, submitted as a name/value pair with form data. */
  @property() name = '';

  /** The current value of the toggle, submitted as a name/value pair with form data. */
  @property() value: string;

  /** The toggle's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  /** Disables the toggle. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Draws the toggle in a checked state. */
  @property({ type: Boolean, reflect: true }) checked = false;

  /** The toggle's data-testid. */
  @property({ reflect: true }) 'data-testid' = 'wc-toggle';

  firstUpdated() {
    this.invalid = !this.input.checkValidity();
  }

  /** Checks for validity but does not show a validation message. Returns true when valid and false when invalid. */
  checkValidity() {
    return this.input.checkValidity();
  }

  /** Checks for validity and shows a validation message if the control is invalid. */
  reportValidity() {
    return this.input.reportValidity();
  }

  /**
   * Sets a custom validation message. The value provided will be shown to the user when the form is submitted. To clear
   * the custom validation message, call this method with an empty string.
   */
  setCustomValidity(message: string) {
    this.input.setCustomValidity(message);
    this.invalid = !this.input.checkValidity();
  }

  handleClick() {
    this.checked = !this.checked;
    this.emit('change', {
      detail: { checked: this.checked },
    });
  }

  render() {
    return html`
      <label
        class=${classMap({
          'c-toggle': true,
          'c-toggle--checked': this.checked,
          'c-toggle--disabled': this.disabled,
        })}
      >
        <input
          class="c-toggle__input"
          type="checkbox"
          name=${this.name}
          .value=${this.value}
          .checked=${live(this.checked)}
          .disabled=${this.disabled}
          aria-checked=${this.checked ? 'true' : 'false'}
          @click=${this.handleClick}
        />

        <span class="c-toggle__control">
          <span class="c-toggle__thumb"></span>
        </span>
        ${this.slotted() ? html` <slot class="c-toggle__label"></slot> ` : null}
      </label>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-toggle': ToggleWC;
  }
}
