import { ReactiveController, ReactiveControllerHost } from 'lit';

export class ClickAwayController implements ReactiveController {
  private host: ReactiveControllerHost;

  element?: HTMLElement;

  callback?: (event: MouseEvent) => void;

  excludeElement?: HTMLElement | boolean;

  _onMouseDown(event: MouseEvent) {
    if (!this.element || !this.callback) return;

    const path = event.composedPath();

    if (typeof this.excludeElement === 'boolean') {
      if (this.excludeElement) return;
    } else if (this.excludeElement && path.includes(this.excludeElement)) {
      return;
    }

    if (!path.includes(this.element)) {
      this.callback(event);
    }
  }

  constructor({
    host,
    element,
    callback,
    excludeElement,
  }: {
    host: ReactiveControllerHost;
    element?: HTMLElement;
    callback?: (event: MouseEvent) => void;
    excludeElement?: HTMLElement | boolean;
  }) {
    (this.host = host).addController(this);

    this.element = element;
    this.callback = callback;
    this.excludeElement = excludeElement;

    this._onMouseDown = this._onMouseDown.bind(this);
  }

  hostConnected() {
    document.addEventListener('mousedown', this._onMouseDown);
  }

  hostDisconnected() {
    document.removeEventListener('mousedown', this._onMouseDown);
  }
}
