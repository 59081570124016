/* eslint-disable no-nested-ternary */
/* eslint-disable wc/guard-super-call */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { css, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { nanoid } from 'nanoid';

import { BaseElement, customElement, watch } from '../base-element';
import { ClickAwayController } from '../../internal';

interface FieldValue extends Record<string, unknown> {}

type AssignedElement = Element & {
  name: string;
  value: string;
  valueId?: string;
  hasError?: boolean;
  checked?: boolean;
};

export type AutoSaveReturnValue = Pick<
  AssignedElement,
  'name' | 'value' | 'valueId' | 'checked'
>;

export enum AutoSaveState {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

interface FieldStateIcons {
  variant: 'indicator' | 'success' | 'error';
  name: 'dot' | 'check' | 'close';
  label: AutoSaveState;
}

@customElement('ps-auto-save')
export class AutoSaveWC extends BaseElement {
  @property({ type: String, reflect: true, attribute: 'id' })
  autosaveId: string;

  @property({ type: String, attribute: 'field-state' }) fieldState:
    | AutoSaveState
    | undefined;

  @watch('fieldState')
  fieldStateChanged() {
    this._lastFieldState = this.fieldState
      ? this.fieldState
      : this._lastFieldState;
  }

  @state() private _history: FieldValue[] = [];

  @state() private _redoStack: FieldValue[] = [];

  @state() private _focused = false;

  private _lastFieldState: AutoSaveState | undefined;

  private _onClickAway = new ClickAwayController({
    host: this,
  });

  private _value: FieldValue | undefined;

  private _lastSavedData: FieldValue | null = null;

  private _isAutoSaveStarted: boolean = false;

  private _saveInterval: number | null = null;

  private _debounceDelay = 500;

  static styles = css`
    .c-autosave {
      position: relative;
    }

    .c-autosave__controls {
      position: absolute;
      bottom: 100%;
      right: 0;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
      padding: 8px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow:
        0px 1px 4px 0px rgba(115, 109, 157, 0.1),
        0px 0.5px 1.5px 0px rgba(115, 109, 157, 0.14);
      transition: opacity 150ms ease;
      opacity: 0;
      margin-bottom: 8px;
      visibility: hidden;
    }

    .c-autosave__controls--visible {
      opacity: 1;
      visibility: visible;
    }
  `;

  connectedCallback() {
    super.connectedCallback();

    const idAttribute = this.getAttribute('id');

    if (idAttribute && idAttribute !== null && !this.autosaveId) {
      this.autosaveId = idAttribute;
    } else if (!this.autosaveId) {
      this.autosaveId = `autosave-${nanoid()}`;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._clearAutoSaveInterval();
  }

  private _clickOutsideHandler = (e: Event) => {
    if (!this._lastSavedData) {
      return;
    }

    this._onBlur(e);
  };

  firstUpdated() {
    this._onClickAway.element =
      document.querySelector(`#${this.autosaveId}`) || undefined;
    this._onClickAway.callback = (e) => this._clickOutsideHandler(e);
  }

  private _getAssignedElement() {
    const slot = this.shadowRoot!.querySelector('slot');
    const assignedElement = slot!.assignedElements()[0] as AssignedElement;

    if (!assignedElement) {
      throw new Error('No assigned elements found');
    }

    return assignedElement;
  }

  private _assignedElementHasError() {
    const assignedElement = this._getAssignedElement();

    return Boolean(assignedElement.hasError);
  }

  private _onValueChanged() {
    this.emit('value-changed', { detail: { value: this._value } });
  }

  private _onValueSaved() {
    if (this._assignedElementHasError()) {
      console.log('invalid value, not saving');
      return;
    }

    this.emit('value-saved', { detail: { value: this._value } });
  }

  private _onSlotChange() {
    const assignedElement = this._getAssignedElement();

    assignedElement.addEventListener('input', this._onInput.bind(this));
    assignedElement.addEventListener('change', this._onInput.bind(this));
    assignedElement.addEventListener('focus', this._onFocus.bind(this));

    const { name, value, valueId, checked } = assignedElement;

    this._value = {
      ...this._value,
      name,
      value,
      valueId,
      checked,
    };

    if (this._value) {
      this._history.push({ ...this._value });
    }
  }

  private _onFocus() {
    this._focused = true;
  }

  private _onInput(event: Event) {
    this._startAutoSaveInterval();

    const target = event.target as AssignedElement;

    const name = target.name as keyof FieldValue;
    const { value, valueId, checked } = target;

    this._value = {
      ...this._value,
      name,
      value,
      valueId,
      checked,
    };

    this._onValueChanged();
  }

  private _onBlur(event: Event) {
    this._focused = false;

    if (!event.isTrusted) {
      return;
    }

    if (this._saveInterval) {
      clearTimeout(this._saveInterval);
      this._saveInterval = null;
      this._isAutoSaveStarted = false;
    }

    this._saveValue();
  }

  private _saveValue() {
    if (this._assignedElementHasError()) {
      console.log('invalid value, not saving');
      return;
    }

    if (
      this._value &&
      !this._areValuesEqual(this._value, this._lastSavedData)
    ) {
      console.log('Auto-saving data...', this._value);
      this._onValueSaved();

      if (
        !this._areValuesEqual(
          this._value,
          this._history[this._history.length - 1]
        )
      ) {
        this._history.push({ ...this._value });
      }

      this._lastSavedData = { ...this._value };
      this.requestUpdate();
    }
  }

  private _areValuesEqual(
    data1: FieldValue | null,
    data2: FieldValue | null
  ): boolean {
    return JSON.stringify(data1) === JSON.stringify(data2);
  }

  private _startAutoSaveInterval() {
    if (!this._isAutoSaveStarted) {
      this._isAutoSaveStarted = true;
    } else {
      return;
    }

    this._clearAutoSaveInterval();
    this._saveInterval = window.setInterval(() => {
      if (this._value) {
        this._saveValue();
      }
    }, this._debounceDelay);
  }

  private _clearAutoSaveInterval() {
    if (this._saveInterval) {
      clearInterval(this._saveInterval);
      this._saveInterval = null;
    }
  }

  private _undo(e: CustomEvent) {
    if (this._history.length > 1) {
      const lastState = this._history.pop();

      if (lastState) {
        this._redoStack.push(lastState);
      }

      this._value = { ...this._history[this._history.length - 1] };
      this.requestUpdate();
      this._onValueChanged();
    }
  }

  private _redo(e: CustomEvent) {
    if (this._redoStack.length > 0) {
      const nextState = this._redoStack.pop();

      if (nextState) {
        this._history.push(nextState);
        this._value = { ...nextState };
        this._onValueChanged();
        this.requestUpdate();
      }
    }
  }

  private _reset(e: CustomEvent) {
    this._value = { ...this._history[0] };
    this._history = [{ ...this._value }];
    this._redoStack = [];
    this._onValueChanged();
    this.requestUpdate();
  }

  render() {
    const stateIconValues: FieldStateIcons | null =
      this._lastFieldState === AutoSaveState.loading
        ? { variant: 'indicator', name: 'dot', label: AutoSaveState.loading }
        : this._lastFieldState === AutoSaveState.success
          ? { variant: 'success', name: 'check', label: AutoSaveState.success }
          : this._lastFieldState === AutoSaveState.error
            ? { variant: 'error', name: 'close', label: AutoSaveState.error }
            : null;

    const stateIcon = this._lastFieldState
      ? html`<ps-tag variant=${ifDefined(stateIconValues?.variant)} minimal>
          <ps-icon name=${ifDefined(stateIconValues?.name)}></ps-icon>
          ${ifDefined(stateIconValues?.label)}
        </ps-tag>`
      : null;

    return html`
      <div id=${this.autosaveId} class="c-autosave">
        <slot @slotchange=${this._onSlotChange}></slot>
        <div
          class=${`c-autosave__controls ${this._focused && this._lastFieldState ? 'c-autosave__controls--visible' : ''}`}
        >
          <ps-inline-list gutter="small">
            <span>${stateIcon}</span>
            <ps-icon-button
              name="undo"
              @click=${this._undo}
              ?disabled=${this._history.length <= 1 ||
              this._lastFieldState === AutoSaveState.loading}
            ></ps-icon-button>
            <ps-icon-button
              name="redo"
              @click=${this._redo}
              ?disabled=${this._redoStack.length === 0 ||
              this._lastFieldState === AutoSaveState.loading}
            ></ps-icon-button>
            <ps-icon-button
              name="refresh"
              @click=${() => this._onValueSaved()}
              ?disabled=${this._lastFieldState !== AutoSaveState.error}
            ></ps-icon-button>
            <ps-icon-button
              name="clear"
              @click=${this._reset}
              ?disabled=${this._history.length <= 1}
            ></ps-icon-button>
          </ps-inline-list>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-auto-save': AutoSaveWC;
  }
  enum PSElementTagNameMap {
    'ps-auto-save' = 'ps-auto-save',
  }
}
