import * as React from 'react';
import { createComponent } from '@lit/react';
import { MenuHeaderWC } from './menu-header.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type MenuHeaderProps = GenerateComponentTypeWithChildren<MenuHeaderWC>;

export type MenuHeaderPropsWithRef = GeneratePropsWithRef<
  MenuHeaderProps & {
    slot?: string;
  }
>;

export const MenuHeader = createComponent({
  tagName: MenuHeaderWC.tagname,
  elementClass: MenuHeaderWC,
  react: React,
}) as MenuHeaderPropsWithRef;
