import { PropertyValueMap, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { customElement, BaseElementWithGlobalStyles } from '../../base-element';
import '../../scrollbar/scrollbar.wc';
import pageStyles from './page.scss?inline';
// import pageBodyGlobalStyles from './page.global.scss?inline';

@customElement('ps-page')
export class PageWC extends BaseElementWithGlobalStyles {
  static styles = unsafeCSS(pageStyles);

  // static globalStyles = unsafeCSS(pageBodyGlobalStyles);

  @property({ reflect: true, type: Boolean, attribute: 'with-sidebar' })
  withSidebar = false;

  @property({ reflect: true, attribute: 'sidebar-position' })
  sidebarPosition: 'start' | 'end' = 'end';

  @property({ reflect: true, type: Boolean, attribute: 'with-side-nav' })
  withSidenav = false;

  @property({ reflect: true })
  background: 'default' | 'alt' | 'secondary' = 'default';

  handleSlotChange(slot: HTMLSlotElement, e: Event) {
    const assignedNodes = slot.assignedNodes();

    const hasSidebar = assignedNodes?.find(
      (node) =>
        node instanceof HTMLElement && node.classList.contains('js-sidebar')
    );

    if (hasSidebar) {
      this.withSidebar = true;

      const isStartPosition = assignedNodes?.find(
        (node) =>
          node instanceof HTMLElement &&
          node.classList.contains('js-sidebar-start')
      );

      if (isStartPosition) this.sidebarPosition = 'start';
    } else {
      this.withSidebar = false;
    }

    const hasSidenav = assignedNodes?.find(
      (node) =>
        node instanceof HTMLElement && node.tagName === 'PS-PAGE-SIDENAV'
    );

    if (hasSidenav) {
      this.withSidenav = true;
    } else {
      this.withSidenav = false;
    }
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(_changedProperties);

    const defaultSlot = this.renderRoot?.querySelector(
      'slot:not([name])'
    ) as HTMLSlotElement;
    defaultSlot?.addEventListener('slotchange', (e) => {
      this.handleSlotChange(defaultSlot, e);
    });
  }

  render() {
    return html`
      <div
        class="${classMap({
          'c-page': true,
          [`c-page--background-${this.background}`]: this.background,
          [`c-page--with-sidebar`]: this.withSidebar,
          [`c-page--with-sidebar-start`]: this.sidebarPosition === 'start',
          [`c-page--with-sidenav`]: this.withSidenav,
        })}"
      >
        <div
          class="${classMap({
            'c-page__background': true,
            [`c-page__background--${this.background}`]: this.background,
          })}"
        ></div>
        <slot name="nav"></slot>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-page': PageWC;
  }
}
