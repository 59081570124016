import { html, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../base-element';
import imageStyles from './image.scss?inline';

@customElement('ps-image')
export class ImageWC extends BaseElement {
  static styles = unsafeCSS(imageStyles);

  /** Image width. */
  @property({ reflect: true }) width?: string;

  /** Image height. */
  @property({ reflect: true }) height?: string;

  /** Alt tag for image. */
  @property({ reflect: true }) alt = '';

  /** Image src. */
  @property({ reflect: true }) src = '';

  /**
   * Load failure fault-tolerant src
   */
  @property({ type: String }) fallback?: string = '';

  /** Image srcset. */
  @property({ reflect: true }) srcset = '';

  /** Image sizes. */
  @property({ reflect: true }) sizes = 'auto';

  /** Image lazyload. */
  @property({ reflect: true, type: Boolean }) lazyload = true;

  @property({ type: Boolean }) private _hasError = false;

  @property({ reflect: true }) 'data-cy': string;

  @property({ reflect: true }) referrerpolicy:
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'origin'
    | 'origin-when-cross-origin'
    | 'same-origin'
    | 'strict-origin'
    | 'strict-origin-when-cross-origin'
    | 'unsafe-url' = 'no-referrer';

  private _onError() {
    if (this.fallback) {
      this.src = this.fallback;
    } else {
      this.src = '';
    }

    this._hasError = true;
    this.emit('onerror', { detail: { hasError: this._hasError } });
  }

  render() {
    return html`
      <img
        class="c-image"
        width="${ifDefined(this.width)}"
        height="${ifDefined(this.height)}"
        alt="${this.alt}"
        src="${this.src}"
        srcset="${this.srcset}"
        sizes="${this.sizes}"
        loading="${this.lazyload ? 'lazy' : 'eager'}"
        referrerpolicy="${this.referrerpolicy}"
        @error="${this._onError}"
      />
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-image': ImageWC;
  }
  enum PSElementTagNameMap {
    'ps-image' = 'ps-image',
  }
}
