import * as React from 'react';
import { createComponent } from '@lit/react';
import { NavLinkWC } from './nav-link.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type NavLinkProps = GenerateComponentTypeWithChildren<NavLinkWC> &
  TranslateInterface & {
    onClick?: React.MouseEventHandler<NavLinkWC>;
  };

export type NavLinkPropsWithRef = GeneratePropsWithRef<NavLinkProps>;

export const NavLink = createComponent({
  tagName: NavLinkWC.tagname,
  elementClass: NavLinkWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as NavLinkPropsWithRef;
