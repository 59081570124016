import { useCallback, MouseEvent, RefObject, useRef, ChangeEvent } from 'react';
import { useReactFlow } from 'reactflow';
import useResizeObserver, {
  UseResizeObserverCallback,
} from '@react-hook/resize-observer';
import {
  sendBuilderEvent,
  useBuilderStateMatches,
  useUiCtxSelector,
  useUiOnEvent,
} from '../../xstate/app.xstate';

import { Node } from '../../store/graph';
import { csvFileProcessor } from '../../utils/graph';

export const useGraphData = (target: RefObject<HTMLDivElement>) => {
  const showMiniMap =
    useUiCtxSelector((ctx) => ctx.windowWidthName) !== 'small';
  const csvBasedGraphState = useBuilderStateMatches('graph.csvBasedGraph');

  const { fitView, setViewport, setCenter } = useReactFlow();

  const nodeClickHandler = useCallback((event: MouseEvent, node: Node) => {
    // event.stopPropagation();
    sendBuilderEvent({
      type: 'builder.node.select',
      nodeId: node.data.node_instance.id,
      nodeNumber: node.data.node_number,
    });
  }, []);

  // TO support CSV based graph rendering
  const fileUploadHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // To support multiple csv file upload one by one
      if (!csvBasedGraphState) {
        sendBuilderEvent({
          type: 'builder.renderCSVBasedGraph',
        });
      }
      if (event.target.files?.length) {
        const reader = new FileReader();

        reader.addEventListener('load', (readerEvent) => {
          if (readerEvent && readerEvent.target && readerEvent.target.result) {
            csvFileProcessor(readerEvent.target.result as string).then(
              (nodes) => {
                sendBuilderEvent({
                  type: 'builder.csvBasedGraph.processedNodes',
                  nodes: nodes,
                });
              }
            );
          }
        });

        if (event.target.files[0]) {
          reader.readAsText(event.target.files[0]);
        }
      }
    },
    [csvBasedGraphState]
  );

  const delayResize = useRef(false);

  const viewResizeHandler: UseResizeObserverCallback = useCallback(
    (entry, observer) => {
      if (delayResize.current) {
        fitView({ duration: 200 });
      } else {
        fitView({ duration: 0 });
      }
    },
    [fitView]
  );

  useUiOnEvent({
    type: 'drawer.nodeDetails.triggerOpen',
    onEvent: (event) => {
      delayResize.current = false;
    },
  });

  useUiOnEvent({
    type: 'drawer.hasClosed',
    onEvent: (event) => {
      setTimeout(() => {
        delayResize.current = true;
      }, 200);
    },
  });

  /**
   * @todo: temporarily disabled resize observer - remove if not be needed anymore!
   */
  // useResizeObserver(target, viewResizeHandler);

  return {
    setCenter,
    fitView,
    nodeClickHandler,
    fileUploadHandler,
    showMiniMap,
    setViewport,
  };
};
