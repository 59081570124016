import * as React from 'react';
import { createComponent } from '@lit/react';
import { FormControlInterface } from '@open-wc/form-control';
import { ButtonWC } from './button.wc';
import { FormControlController } from '../../base-element';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';
import { TranslateInterface } from '../../base-element/mixins/translation-mixin';

/** besides generating a React wrapped version of the Button web component, this file
 * also demonstrates how, if / when it makes sense, we can manually curate the
 * exposed React props that are shown in Intellisense */

// type _ButtonProps = React.PropsWithChildren<
//   Omit<
//     ButtonWC,
//     | keyof InstanceType<typeof HTMLElement>
//     | keyof InstanceType<typeof FormControlController>
//     | keyof FormControlInterface
//     | keyof InstanceType<typeof BaseElement>
//     | 'internals'
//   >
// > & {
//   onClick?: React.MouseEventHandler<ButtonWC>;
// };

/**
 * note: any required props would need to get manually added (as being required) -- the
 * Partial generic means any -- "technically internally required" by not _actually_
 * externally required -- props aren't flagged as being required in React. */
// export type ButtonProps = Partial<Expand<_ButtonProps>>;

// const ReactWCButton = createComponent({
//   tagName: ButtonWC.tagname,
//   elementClass: ButtonWC,
//   react: React,
//   events: {
//     onClick: 'click',
//   },
// });

// export type ButtonPropsWithRef = React.ForwardRefExoticComponent<
//   React.PropsWithoutRef<Expand<Partial<ButtonProps>>> &
//     React.RefAttributes<Expand<ButtonProps>>
// >;

// export const Button: ButtonPropsWithRef = ReactWCButton as ButtonPropsWithRef;

type OmittedButtonWC = Omit<
  ButtonWC,
  keyof InstanceType<typeof FormControlController> | keyof FormControlInterface
>;

export type ButtonProps = GenerateComponentTypeWithChildren<OmittedButtonWC> &
  TranslateInterface & {
    onClick?: React.MouseEventHandler<ButtonWC | HTMLButtonElement>;
    id?: string;
  };

export type ButtonPropsWithRef = GeneratePropsWithRef<ButtonProps>;

export const Button = createComponent({
  tagName: ButtonWC.tagname,
  elementClass: ButtonWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as ButtonPropsWithRef;
