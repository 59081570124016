import * as React from 'react';
import { createComponent } from '@lit/react';
import { IconWC } from './icon.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type IconProps = GenerateComponentTypeWithoutChildren<IconWC>;

export type IconPropsWithRef = GeneratePropsWithRef<IconProps>;

export const Icon = createComponent({
  tagName: IconWC.tagname,
  elementClass: IconWC,
  react: React,
}) as IconPropsWithRef;
