import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import styles from './stack.scss?inline';

@customElement('ps-stack')
export class StackWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /**
   * Defines the space between items
   */
  @property() gutter:
    | 'none'
    | '2xsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large' = 'medium';

  @property({ reflect: true }) direction: 'row' | 'column' = 'row';

  /**
   * Defines the display property
   */
  @property({ reflect: true }) display: 'flex' | 'inline-flex' = 'flex';

  /**
   * Defines the 'align-items' style property
   */
  @property({ reflect: true }) alignItems:
    | 'start'
    | 'center'
    | 'end'
    | 'stretch'
    | 'baseline' = 'start';

  /**
   * Defines the 'justify-content' style property
   */
  @property({ reflect: true }) justifyContent:
    | 'start'
    | 'center'
    | 'end'
    | 'space-around'
    | 'space-between' = 'start';

  /**
   * Don't break items into multiple lines
   */
  @property({ type: Boolean, reflect: true }) nowrap = false;

  render() {
    return html`
      <div
        class="${classMap({
          'c-stack': true,
          'c-stack--nowrap': this.nowrap,
          [`c-stack--display-${this.display}`]: this.display,
          [`c-stack--gap-${this.gutter}`]: this.gutter,
          [`c-stack--align-${this.alignItems}`]: this.alignItems,
          [`c-stack--justify-${this.justifyContent}`]: this.justifyContent,
          [`c-stack--direction-${this.direction}`]: this.direction,
        })}"
      >
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-stack': StackWC;
  }
  enum PSElementTagNameMap {
    'ps-stack' = 'ps-stack',
  }
}
