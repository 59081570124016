import {
  Icon,
  Menu,
  MenuItem,
  Dropdown,
  Avatar,
  MenuDivider,
  IconButton,
  // Toggle,
  Stack,
  TextBody,
  TextCaption,
  PopoverProps,
  Popover,
} from '@pypestream/design-system';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { kratosApi } from '../../../kratos.api';
import {
  sendUiEvent,
  sendUserEvent,
  useMicroappsCtxSelector,
  useUiStateMatches,
  useUserCtxSelector,
  sendMicroAppsEvent,
} from '../../../xstate/app.xstate';

export const ProfileDropdown = () => {
  const [logout, setLogout] = useState<string>('');
  useEffect(() => {
    kratosApi.createBrowserLogoutFlow().then(({ data }) => {
      setLogout(`${data.logout_url}&return_to=${window.location.href}`);
    });
  }, []);

  const isProfileDropdownOpen = useUiStateMatches('profile.opened');

  const { user } = useUserCtxSelector((ctx) => ({
    user: ctx.user,
  }));

  const navigate = useNavigate();

  const { microAppDetails, selectedAppId, selectedVersionId, customerId } =
    useMicroappsCtxSelector((ctx) => ({
      selectedAppId: ctx.selectedAppId,
      selectedVersionId: ctx.selectedVersionId,
      microAppDetails: ctx.data?.find(
        ({ app_id }) => app_id === ctx.selectedAppId
      ),
      customerId: ctx.customerId,
    }));

  const userDropdownRef = useRef<PopoverProps>(null);

  return (
    <>
      <IconButton id="profile-dropdown" rounded>
        <Avatar
          src={user?.profilePhoto}
          label={`${user?.firstName || 'Firsname'} ${
            user?.lastName || 'Lastname'
          }`}
        />
      </IconButton>
      <Popover
        ref={userDropdownRef}
        trigger="profile-dropdown"
        // active={isProfileDropdownOpen}
        placement="bottom-end"
        width="auto"
        // onclose={() => {
        //   sendUiEvent('userProfile.close');
        // }}
      >
        <Menu>
          <MenuItem>
            <Stack alignItems="center">
              <Avatar src={user?.profilePhoto} size="large"></Avatar>
              <div>
                <TextBody>
                  {user?.firstName} {user?.lastName}
                </TextBody>
                <TextCaption variant="tertiary">{user?.email}</TextCaption>
              </div>
            </Stack>
          </MenuItem>
          <MenuItem subtle>
            <select
              style={{
                width: '100%',
                cursor: 'pointer',
              }}
              onChange={(e) => {
                sendMicroAppsEvent({
                  type: 'microApps.setCustomerId',
                  id: e.target.value ? parseInt(e.target.value, 10) : null,
                });

                if (userDropdownRef.current) {
                  userDropdownRef.current.active = false;
                }
              }}
            >
              <option value={undefined} selected={!customerId}>
                No Customer ID
              </option>
              <option value="1" selected={customerId === 1}>
                Customer #1
              </option>
              <option value="3" selected={customerId === 3}>
                Customer #3
              </option>
            </select>
          </MenuItem>
          <MenuDivider />
          {/* @TODO: To be added with dark mode implementation */}
          {/* <MenuItem value="option-settings" disabled>
            <Icon name="dark-mode" slot="prefix"></Icon>
            {/* <Icon slot="suffix" name="info"></Icon> */}
          {/* Dark Mode */}
          {/* <Toggle slot="suffix" size="small"></Toggle> */}
          {/* </MenuItem> */}
          {microAppDetails?.app_name && (
            // @Todo -- Should include all available apps in a dropdown currently just the selected app,
            // it won't be useful on homepage ?? We can use useHomePage() with data
            // Also icon should auto change based on the app
            <MenuItem
              onClick={() => {
                if (userDropdownRef.current) {
                  userDropdownRef.current.active = false;
                }
                navigate(`/${selectedAppId}`);
              }}
            >
              <Icon name="profile" slot="prefix"></Icon>
              {microAppDetails?.app_name}
              {/* @TODO: To be added when nested dropdown is ready */}
              {/* <Icon name="chevron-down" size="xsmall" slot="suffix"></Icon> */}
            </MenuItem>
          )}
          <MenuItem disabled>Preferences</MenuItem>
          <MenuItem
            i18nKey="studio/navigation:extra.signout"
            onClick={() => {
              sendUserEvent({
                type: 'user.signOut',
              });
              window.location.href = logout;
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </Popover>
    </>
  );
};
