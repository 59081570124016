import { html, unsafeCSS, PropertyValueMap } from 'lit';
import { property, query } from 'lit/decorators.js';
import SimpleBar from 'simplebar';
import { SimpleBarOptions } from 'simplebar-core';
import { ResizeController } from '@lit-labs/observers/resize-controller.js';
import debounce from 'lodash.debounce';
import { BaseElement, customElement } from '../base-element';
import styles from './scrollbar.scss?inline';

SimpleBar.removeObserver();

@customElement('ps-scrollbar')
export class ScrollbarWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @query('.c-scrollbar') scrollbar: HTMLElement;

  simplebar: SimpleBar;

  @property({ reflect: true, attribute: 'force-visible', type: Boolean })
  forceVisible: SimpleBarOptions['forceVisible'] = false;

  @property({ reflect: true, attribute: 'disable-auto-hide', type: Boolean })
  disableAutoHide: SimpleBarOptions['autoHide'] = false;

  // eslint-disable-next-line class-methods-use-this
  recalculate(v: ResizeObserverEntry[]) {
    if (this._recalculate !== undefined) {
      this._recalculate();
    }
  }

  public _recalculate?: () => void = undefined;

  private _observer = new ResizeController(this, {
    skipInitial: true,
    callback: debounce(this.recalculate, 20),
  });

  firstUpdated(
    _changedProperties:
      | PropertyValueMap<Record<string, unknown>>
      | Map<PropertyKey, unknown>
  ) {
    super.firstUpdated(_changedProperties);
    this.simplebar = new SimpleBar(this.scrollbar, {
      classNames: {
        scrollable: 'c-scrollbar--scrollable',
      },
      forceVisible: this.forceVisible,
      autoHide: !this.disableAutoHide,
    });

    this._recalculate = this.simplebar.recalculate;
  }

  render() {
    return html`
      <div class="c-scrollbar" data-cy="scrollbar">
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-scrollbar': ScrollbarWC;
  }
}
