import { RJSFSchema } from '@rjsf/utils';
import { object, string, Infer, optional } from 'superstruct';

import {
  ContentTypes,
  commonMessageContent,
  messageTypeSchema,
} from './common';

export const DocumentContentStruct = object({
  messageType: string(),
  url: string(),
  type: string(),
  id: optional(string()),
  lastUpdated: optional(string()),
});

export type DocumentContent = Infer<typeof DocumentContentStruct>;

export const documentContentSchema: RJSFSchema = {
  title: ContentTypes.Document,
  type: 'object',
  properties: {
    messageType: {
      ...messageTypeSchema,
      default: ContentTypes.Document,
    },
    type: {
      type: 'string',
      enum: ['static'],
      default: 'static',
    },
    url: {
      type: 'string',
    },
    ...commonMessageContent,
  },
  required: ['messageType', 'type', 'url'],
};
