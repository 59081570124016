import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { decisionNode } from './nodes/decision';

export const nodeDetailsSchemaRoot: RJSFSchema = {
  type: 'object',
  properties: {
    node_number: {
      type: 'integer',
      readOnly: true,
    },
    node_type: {
      type: 'string',
      readOnly: true,
    },
    node_name: {
      type: 'string',
      title: 'Field Title - ',
    },
    messages: decisionNode,
  },
  required: ['node_number', 'node_type', 'node_name'],
  additionalProperties: false,
};

export const nodeDetailsUiSchemaRoot: UiSchema = {
  messages: {
    items: {
      message: {
        'ui:widget': 'textarea',
        'ui:options': {
          widget: 'textarea',
          rows: 2,
        },
      },
      id: { 'ui:widget': 'hidden' },
      lastUpdated: { 'ui:widget': 'hidden' },
    },
  },
};
