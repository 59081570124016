import * as React from 'react';
import { createComponent } from '@lit/react';
import { HeroWC } from './hero.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export interface HeroCSSVars extends React.CSSProperties {
  '--bg-gradient-start-top'?: string;
  '--bg-gradient-start-bottom'?: string;
  '--bg-gradient-end-top'?: string;
  '--bg-gradient-end-bottom'?: string;
}

export type HeroProps = GenerateComponentTypeWithChildren<HeroWC> & {
  style?: HeroCSSVars;
};

export type HeroPropsWithRef = GeneratePropsWithRef<HeroProps>;

export const Hero = createComponent({
  tagName: HeroWC.tagname,
  elementClass: HeroWC,
  react: React,
}) as HeroPropsWithRef;
