import * as React from 'react';
import { createComponent } from '@lit/react';
import { SelectOptionWC } from './select-option.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type SelectOptionProps =
  GenerateComponentTypeWithChildren<SelectOptionWC>;

export type SelectOptionPropsWithRef = GeneratePropsWithRef<SelectOptionProps>;

export const SelectOption = createComponent({
  tagName: SelectOptionWC.tagname,
  elementClass: SelectOptionWC,
  react: React,
}) as SelectOptionPropsWithRef;
