import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import {
  BaseElement,
  customElement,
  SlotObserver,
} from '../../../components/base-element';
import '../../../components/icon/icon.wc';
import styles from './result.scss?inline';

@customElement('ps-result')
export class ResultWC extends SlotObserver(BaseElement, [
  '[slot]',
  '[slot="footer"]',
]) {
  static styles = unsafeCSS(styles);

  /**
   * Result status, decide icons and colors
   */
  @property() status: 'info' | 'success' | 'error' = 'info';

  /** Set text-align for result content */
  @property({ reflect: true }) align: 'start' | 'center' = 'start';

  /**
   * Set component label
   */
  @property() label = '';

  /**
   * Set component body text
   */
  @property() body = '';

  render() {
    const classes = classMap({
      'c-result': true,
      [`c-result--status-${this.status}`]: this.status,
      [`c-result--align-${this.align}`]: this.align,
    });

    const iconName = this.status === 'error' ? 'exclamation' : 'check';

    const iconClasses = classMap({
      'c-result__icon': true,
      [`c-result__icon--${this.status}`]: this.status,
    });

    const label = this.label
      ? html`
          <ps-text-title size="small" textAlign=${this.align}
            >${this.label}</ps-text-title
          >
          <ps-spacer size="small"></ps-spacer>
        `
      : null;

    const body = this.body
      ? html`<ps-text-body variant="secondary" textAlign=${this.align}
          >${this.body}</ps-text-body
        >`
      : null;

    return html`
      <div class=${classes}>
        <div class=${iconClasses}>
          <ps-icon name=${iconName} size="medium"></ps-icon>
        </div>
        ${label} ${body}
        <slot></slot>
        ${this.hasSlottedContent('[slot="footer"]')
          ? html`
              <div class="c-result__footer">
                <slot name="footer"></slot>
              </div>
            `
          : null}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-result': ResultWC;
  }
}
