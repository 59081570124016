import * as React from 'react';
import { createComponent } from '@lit/react';
import { TreeItemWC } from './tree-item.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TreeItemProps = GenerateComponentTypeWithChildren<TreeItemWC> & {
  onClick?: React.MouseEventHandler<TreeItemWC>;
};

export type TreeItemPropsWithRef = GeneratePropsWithRef<TreeItemProps>;

export const TreeItem = createComponent({
  tagName: TreeItemWC.tagname,
  elementClass: TreeItemWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as TreeItemPropsWithRef;
