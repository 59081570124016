import { createStore, select, withProps } from '@ngneat/elf';
import { v5 as uuidv5, v4 as uuidv4 } from 'uuid';
import set from 'lodash.set';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import { dropRight, size, last } from 'lodash';
import { produce } from 'immer';
import { compare, applyPatch, deepClone } from 'fast-json-patch';
import { stateHistory } from '@ngneat/elf-state-history';

import { gql } from '@pypestream/api-services';
import { NodeDetailsSubscription } from '@pypestream/api-services/apollo';

import { isValidMessageContent } from '../../utils/graph';
import {
  NodeDetails,
  MessageContents,
  getActiveNode,
  handleNodeTitleChange,
} from './graph';

// Randomly generated UUID for namespace to generate ids from string combinations.
// We are using it as a encryption,
// we are generating string based id and we use them further with diffing algorithm.
// These ids are unique to browser tabs to differentiate sessions
const UUID_NAMESPACE = uuidv4();
let nodeDetailsChangeUniqueIds: string[] = [];

// local variable to hold unsupported message content to send it back to server with auto saving
// This is needed to avoid data loss for unsupported message-content.
let unSupportedMessageContents: Array<{
  unSupportedMessageContent: NonNullable<
    NodeDetailsSubscription['apps_node_by_pk']
  >['messages'];
  contentIndex: number;
}> = [];

// #region Elf related methods/observables
const nodeDetailsStore = createStore(
  { name: 'nodeDetails' },
  withProps<NodeDetails>({
    id: 'TempId',
    node_type: 'D',
  })
);

export const nodeDetailsStateHistory = stateHistory(nodeDetailsStore, {
  maxAge: 1000,
});

export const selectedNodeDetails$ = nodeDetailsStore.pipe();

selectedNodeDetails$
  .pipe(select((nodeDetails) => nodeDetails?.node_name))
  .subscribe((nodeName) => {
    if (
      nodeName &&
      (nodeDetailsStateHistory.hasPast || nodeDetailsStateHistory.hasFuture)
    ) {
      debouncedSaveTitle({ nodeName });

      // Added timeout to guarantee title update on node title of rendered graph.
      setTimeout(() => {
        const activeNode = getActiveNode();
        if (activeNode && nodeName) {
          handleNodeTitleChange({
            nodeNumber: activeNode.data.node_number,
            updateTitle: nodeName,
          });
        }
      });
    }
  });

selectedNodeDetails$
  .pipe(select((nodeDetails) => nodeDetails?.messages))
  .subscribe((messages) => {
    if (
      messages &&
      (nodeDetailsStateHistory.hasPast || nodeDetailsStateHistory.hasFuture)
    ) {
      debouncedSaveContent({ messages });
    }
  });
// #endregion Elf related methods/observables

// #region helper methods to manipulate state
export const flushNodeDetailsDb = () => {
  nodeDetailsStore.reset();
  nodeDetailsStateHistory.clear();
  nodeDetailsChangeUniqueIds = [];
  unSupportedMessageContents = [];
};

export const handleNodeDetailsIncomingData = (
  rawIncomingNodeDetails: NodeDetails
): void => {
  const currentNodeDetails: NodeDetails = nodeDetailsStore.getValue();
  let injectedMessageIds = false;

  // Filtered out unsupported messages.
  const incomingProcessedMessageContent =
    rawIncomingNodeDetails?.messages?.reduce(
      (
        processedMsgs: MessageContents,
        currentMsg: NonNullable<
          NodeDetailsSubscription['apps_node_by_pk']
        >['messages'],
        currentIndex: number
      ) => {
        if (isValidMessageContent(currentMsg)) {
          if (!currentMsg.id) {
            currentMsg.id = uuidv4();
            injectedMessageIds = true;
          }
          processedMsgs.push(
            sortObjects(currentMsg) as MessageContents[number]
          );
        } else {
          // @todo - Better error handling
          const unSupportMessageIdx = unSupportedMessageContents.findIndex(
            (unSupportedMessage) =>
              unSupportedMessage.unSupportedMessageContent.id === currentMsg.id
          );

          // If we have local version of un supported message content, then update it with respect to incoming data to avoid data-loss.
          if (unSupportMessageIdx >= 0) {
            unSupportedMessageContents[
              unSupportMessageIdx
            ].unSupportedMessageContent = currentMsg;
          } else {
            // If we don't have local copy of un supported message then inject that in local variable and push it back to server with debouncedSaveContent call
            unSupportedMessageContents.push({
              contentIndex: currentIndex,
              unSupportedMessageContent: currentMsg,
            });

            // @todo - Need to save this log with datadog once available.
            console.error(
              '**** Unknown type of message content ****',
              currentMsg
            );
          }
        }
        return processedMsgs;
      },
      []
    );

  // Formalize incomingNodeDetails object
  const incomingNodeDetails: NodeDetails = {
    id: rawIncomingNodeDetails.id,
    messages: incomingProcessedMessageContent,
    node_name: rawIncomingNodeDetails.node_name,
    node_type: rawIncomingNodeDetails.node_type,
  };

  // Generate checksum for incoming changes -- needed for diffing algorithm
  const uniqueIdForIncomingData = uuidv5(
    JSON.stringify(sortObjects(incomingNodeDetails) as MessageContents[number]),
    UUID_NAMESPACE
  );

  // If checksum is not present in local stack then apply those changes as a incoming changes.
  if (!nodeDetailsChangeUniqueIds.includes(uniqueIdForIncomingData)) {
    // Generate diff between incoming and current local changes.
    const diffs = compare(currentNodeDetails, incomingNodeDetails);

    let nextState: NodeDetails = currentNodeDetails;

    if (diffs.length) {
      // Apply those diffs to merge incoming changes
      nextState = applyPatch<NodeDetails>(
        deepClone(currentNodeDetails),
        deepClone(diffs)
      ).newDocument;
    }

    // Pull unsaved local messages.
    const localUnsavedMessageContent = currentNodeDetails.messages?.filter(
      (currentMsg) => !isValidMessageContent(currentMsg)
    );

    if (localUnsavedMessageContent && localUnsavedMessageContent.length) {
      nextState = produce(nextState, (draft) => {
        draft.messages = draft.messages?.concat(
          localUnsavedMessageContent as MessageContents
        );
        return draft;
      });
    }

    if (diffs.length) {
      // Update local state with new changes
      nodeDetailsStore.update((state) => produce(nextState, (draft) => draft));
    }

    // Save content messages if we have injected new ids to any messages.
    if (injectedMessageIds && nextState.messages) {
      debouncedSaveContent({ messages: nextState.messages });
    }
  }
};

const clearNodeDetailsStateRedoStack = () => {
  if (!nodeDetailsStateHistory.hasFuture) {
    return;
  }

  nodeDetailsStateHistory.clear((history) => ({
    ...history,
    future: [],
  }));
};

export const handleNodeDetailsUpdate = ({
  formData,
  keyPathForUpdate,
}: {
  nodeId: string;
  keyPathForUpdate: string[];
  formData: NodeDetails;
}): void => {
  formData.messages?.forEach((msg) => {
    if (!msg.id) {
      msg.id = uuidv4();
    }
  });
  // Maintain checksum for updated changes -- needed for diffing algorithm
  nodeDetailsChangeUniqueIds.push(
    uuidv5(
      JSON.stringify(
        sortObjects({
          ...formData,
          messages: formData.messages
            ?.filter((_msg) => isValidMessageContent(_msg))
            ?.map((msg) => sortObjects(msg)) as MessageContents,
        })
      ),
      UUID_NAMESPACE
    )
  );

  /**
   * Clear out exiting message data if messageType changes.
   * @todo -
   * 1. How we can removed hardcoded messageType key
   * 2. How we can scale this pattern?
   */
  if (size(keyPathForUpdate) && last(keyPathForUpdate) === 'messageType') {
    nodeDetailsStore.update(
      produce((draft) => {
        const pathWithoutKey = dropRight(keyPathForUpdate);
        const context: {
          messageType: MessageContents[number]['messageType'];
          id: MessageContents[number]['id'];
        } = {
          messageType: get(formData, keyPathForUpdate),
          id: get(formData, [...pathWithoutKey, 'id']),
        };
        return set(draft, pathWithoutKey, context);
      })
    );
  }

  nodeDetailsStore.update(
    produce((draft) =>
      set(draft, keyPathForUpdate, get(formData, keyPathForUpdate))
    )
  );

  clearNodeDetailsStateRedoStack();
};

const sortObjects = (object: NodeDetails | MessageContents[number]) =>
  Object.fromEntries(Object.entries(object).sort());

// #endregion helper methods to manipulate state

// #region backend service calls
const debouncedSaveTitle = debounce(
  ({ nodeName }: { nodeName: NonNullable<NodeDetails['node_name']> }) => {
    // better way? if we add node_id to select then subscribe not working as expected
    const nodeId = nodeDetailsStore.getValue().id;
    gql.updateNodeTitle({
      nodeId,
      title: nodeName,
    });
  },
  300
);

const debouncedSaveContent = debounce(
  ({ messages }: { messages: MessageContents }) => {
    const nodeId = nodeDetailsStore.getValue().id;
    const { messageToSave, messagesWithErrors } = messages.reduce(
      (accumulator, current) => {
        if (isValidMessageContent(current)) {
          accumulator.messageToSave.push(current);
        } else {
          accumulator.messagesWithErrors.push(current);
        }
        return accumulator;
      },
      {
        messageToSave: [],
        messagesWithErrors: [],
      } as {
        messageToSave: MessageContents;
        messagesWithErrors: MessageContents;
      }
    );

    unSupportedMessageContents.forEach((msg) => {
      // Inject local-unsupported message back to the same index
      messageToSave.splice(msg.contentIndex, 0, {
        ...msg.unSupportedMessageContent,
      });
    });

    gql.updateNodeMessages({
      nodeId,
      messages: messageToSave,
    });
  },
  300
);
// #endregion backend service calls;
