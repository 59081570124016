/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  interpret,
  Machine,
  State,
  Interpreter,
  forwardTo,
  assign,
} from 'xstate';
import { inspect } from '@xstate/inspect';
import { initialize } from 'launchdarkly-js-client-sdk';
import { WindowWithPypestreamGlobals } from './global';
import {
  userMachine,
  UserInterpreter,
  createUserXstateHooks,
  UserContext,
  UserEvents,
  UserState,
  UserTypestates,
} from './user.xstate';
import {
  GlobalAppContext,
  GlobalAppEvents,
  GlobalAppStateSchema,
  GlobalAppTypestates,
  globalAppActions,
  appActivities,
  sendUserMessage,
  createAppXstateHooks,
  GLOBAL_APP_SUB_MACHINE_IDS,
} from './app.xstate-utils';
import {
  createUiXstateHooks,
  UiInterpreter,
  uiMachine,
  UiContext,
  UiState,
  UiEvents,
  UiTypestates,
} from './ui.xstate';
import {
  createBuilderXstateHooks,
  BuilderInterpreter,
  builderMachine,
  BuilderEvents,
  BuilderPageContext,
  BuilderState,
  BuilderTypestates,
} from './builder/builder.xstate';
import { isProduction } from '@pypestream/utils';
import {
  createMicroappsXstateHooks,
  MicroAppsContext,
  MicroAppsEvents,
  MicroAppsInterpreter,
  microAppsMachine,
  MicroAppsState,
  MicroAppsTypeStates,
} from './micro-apps/micro-apps.xstate';
import { AvailableFeatureFlags, FeatureFlagType } from '../feature-flags.types';
import { DefaultFeatureFlags } from '../feature-flags.default';
import { isValidFlag } from '../utils/feature-flags';

const isUnitTesting = false;

/**
 * Note: the placeholder action is the minimum thing needed for an event for it to be registered.
 * This is sometimes used for stubbing out future events, or if something else is listening for those events
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const placeholderAction = (): void => {};

export type { GlobalAppContext, GlobalAppEvents };

const win = window as WindowWithPypestreamGlobals;

// @todo: update to support overrides (for debugging) via external super admin controls
const enableStateInspector = !isProduction;
if (enableStateInspector) {
  inspect({
    url: 'https://statecharts.io/inspect',
    iframe: false,
  });
}

export const globalAppMachine = Machine<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents
>(
  {
    predictableActionArguments: true,
    id: 'app',
    context: {
      pageTolerances: {
        isHomePage: false,
        isManagePage: false,
        isBuilderPage: false,
      },
    },
    strict: true,
    type: 'parallel',
    invoke: [
      {
        id: 'initializing-feature-flags',
        src: (ctx) => {
          if (Object.keys(DefaultFeatureFlags).length) {
            const client = initialize(
              LAUNCH_DARKLY_API_KEY || '',
              {
                kind: 'user',
                anonymous: true,
              },
              {
                // This is to prevent sending many events from app to launchDarkly.
                // @todo - Need to check how elegantly we can handle this as w.r.t. launch darkly docs, this might break some features on LD side. (features like, reports, ld logs and all) --> not good for AB testing.
                flushInterval: 900000,
              }
            );

            client.on('change', (updatedFlags) => {
              Object.keys(updatedFlags).forEach((updatedFlagKey) => {
                if (isValidFlag(updatedFlagKey)) {
                  sendGlobalAppEvent({
                    type: 'featureFlags.update',
                    // isValidFlag check is making sure that this typecast is correct
                    // @todo - Ideally we should not need this typecast, need to check for proper fix
                    updatedFlag: updatedFlagKey as AvailableFeatureFlags,
                    updatedValue: updatedFlags[updatedFlagKey].current,
                  });
                }
              });
            });

            return client
              .waitForInitialization()
              .then(() => {
                sendGlobalAppEvent({
                  type: 'featureFlags.initialized',
                  flags: Object.fromEntries(
                    Object.entries(client.allFlags()).filter(([key]) =>
                      isValidFlag(key)
                    )
                  ) as FeatureFlagType,
                });
              })
              .catch(() => {
                sendGlobalAppEvent({
                  type: 'featureFlags.initializedWithDefaultValues',
                });
                // @todo - Need to log this with datadog once available.
                console.log(
                  'Initialized application with build-time/default-flags values.'
                );
              });
          }
          // @todo - Need to log this with datadog
          console.warn(
            "Bypassed LD-initialization as we didn't had FFs build time."
          );
          return Promise.resolve();
        },
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.ui,
        src: uiMachine,
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.user,
        src: userMachine,
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.builder,
        src: builderMachine,
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.microApps,
        src: microAppsMachine,
      },
    ],
    states: {
      accounts: {},
      apps: {},
      featureFlags: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              'featureFlags.initialized': {
                target: '#app.featureFlags.loaded.withActualValue',
                actions: [
                  assign((ctx, data) => {
                    ctx.featureFlags = data.flags;
                    return ctx;
                  }),
                ],
              },
              'featureFlags.initializedWithDefaultValues': {
                target: '#app.featureFlags.loaded.withDefaultValues',
                actions: [
                  assign((ctx) => {
                    ctx.featureFlags = DefaultFeatureFlags;
                    return ctx;
                  }),
                ],
              },
            },
          },
          loaded: {
            on: {
              'featureFlags.update': {
                actions: assign((ctx, data) => {
                  if (
                    ctx.featureFlags &&
                    data.updatedFlag in DefaultFeatureFlags
                  ) {
                    ctx.featureFlags[data.updatedFlag] = data.updatedValue;
                  }
                  return ctx;
                }),
              },
            },
            states: {
              withActualValue: {},
              withDefaultValues: {},
            },
          },
        },
      },
    },
    on: {
      // globally allow any part of the system to send a Toast message
      'app.sendUserMessage': {
        actions: (ctx, event) => sendUserMessage(event.msg),
      },

      // request the system to sign in / sign out
      'user.signIn': {
        actions: forwardTo(userMachine.id),
      },
      'user.signOut': {
        actions: forwardTo(userMachine.id),
      },

      // let the rest of the system know once we've successfully signed in / signed out
      'user.loggedIn': {
        actions: forwardTo(uiMachine.id),
      },
      'user.loggedOut': {
        actions: forwardTo(uiMachine.id),
      },

      'user.setProfilePhoto': {
        actions: forwardTo(userMachine.id),
      },
      'app.log': {
        actions: [(ctx, event) => console.log(event.msg)],
      },

      noop: {
        actions: placeholderAction,
      },
    },
  },
  {
    activities: Object.fromEntries(
      Object.values(appActivities).map(({ id, exec }) => [id, exec])
    ),
    actions: Object.fromEntries(
      Object.values(globalAppActions).map(({ id, exec }) => [id, exec])
    ),
  }
);

export const globalAppService: GlobalAppServiceInterpreter = interpret<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents,
  GlobalAppTypestates,
  any
>(globalAppMachine, {
  devTools: enableStateInspector,
});

export const { send: sendGlobalAppEvent } = globalAppService;

export type GlobalAppServiceInterpreter = Interpreter<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents,
  GlobalAppTypestates,
  any
>;

function log({
  state,
  name,
}: {
  name: string;
  state:
    | State<
        GlobalAppContext,
        GlobalAppEvents,
        GlobalAppStateSchema,
        GlobalAppTypestates,
        any
      >
    | State<UiContext, UiEvents, UiState, UiTypestates, any>
    | State<UserContext, UserEvents, UserState, UserTypestates, any>
    | State<
        BuilderPageContext,
        BuilderEvents,
        BuilderState,
        BuilderTypestates,
        any
      >
    | State<
        MicroAppsContext,
        MicroAppsEvents,
        MicroAppsState,
        MicroAppsTypeStates,
        any
      >;
}): void {
  if (isUnitTesting) return;

  console.debug(
    `[${name}]: "${state.event.type}" Xstate Event`,
    isProduction ? state.event : state // log less in prod
  );
}
globalAppService.onTransition((state) => log({ state, name: 'app' }));

globalAppService.start();

win.pypestream = {
  globalAppService,
};

export const uiService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.ui
) as UiInterpreter;

export const userService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.user
) as UserInterpreter;

export const builderService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.builder
) as BuilderInterpreter;

export const microAppsService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.microApps
) as MicroAppsInterpreter;

interface Service {
  name: GLOBAL_APP_SUB_MACHINE_IDS;
  service:
    | UserInterpreter
    | UiInterpreter
    | BuilderInterpreter
    | MicroAppsInterpreter;
}

if (!uiService) {
  throw new Error('Missing XState uiService');
}

const services: Service[] = [
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.user,
    service: userService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.ui,
    service: uiService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.builder,
    service: builderService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.microApps,
    service: microAppsService,
  },
];

services.forEach(({ name, service }) => {
  if (!service) {
    throw new Error(`Missing XState ${name}Service`);
  }
});

uiService.subscribe((state) =>
  log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.ui })
);

userService.subscribe((state) =>
  log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.user })
);

builderService.subscribe((state) =>
  log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.builder })
);

microAppsService.subscribe((state) =>
  log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.microApps })
);

export const { send: sendUserEvent } = userService;
export const { send: sendUiEvent } = uiService;
export const { send: sendBuilderEvent } = builderService;
export const { send: sendMicroAppsEvent } = microAppsService;

export const {
  useStateMatches: useGlobalAppStateMatches,
  useStateMatchesOneOf: useGlobalAppStateMatchesOneOf,
  useCtxSelector: useGlobalAppCtxSelector,
  useOnEvent: useGlobalAppOnEvent,
  useIsEventAllowed: useIsGlobalAppEventAllowed,
  waitForEvents: waitForGlobalAppEvents,
  useCurrentState: useGlobalAppCurrentState,
} = createAppXstateHooks(globalAppService);

export const {
  useCtxSelector: useUiCtxSelector,
  useStateMatches: useUiStateMatches,
  useStateMatchesOneOf: useUiStateMatchesOneOf,
  useOnEvent: useUiOnEvent,
  useIsEventAllowed: useIsUiEventAllowed,
  useCurrentState: useUiCurrentState,
} = createUiXstateHooks(uiService);

export const {
  useCtxSelector: useUserCtxSelector,
  useStateMatches: useUserStateMatches,
  useStateMatchesOneOf: useUserStateMatchesOneOf,
  useOnEvent: useOnUserEvent,
  useIsEventAllowed: useIsUserEventAllowed,
  useCurrentState: useUserCurrentState,
} = createUserXstateHooks(userService);

export const {
  useCtxSelector: useBuilderCtxSelector,
  useStateMatches: useBuilderStateMatches,
  useStateMatchesOneOf: useBuilderStateMatchesOneOf,
  useOnEvent: useBuilderOnEvent,
  useIsEventAllowed: useIsBuilderEventAllowed,
  useCurrentState: useBuilderCurrentState,
} = createBuilderXstateHooks(builderService);

export const {
  useCtxSelector: useMicroappsCtxSelector,
  useStateMatches: useMicroappsStateMatches,
  useStateMatchesOneOf: useMicroappsStateMatchesOneOf,
  useOnEvent: useMicroappsOnEvent,
  useIsEventAllowed: useIsMicroappsEventAllowed,
  useCurrentState: useMicroAppsCurrentState,
} = createMicroappsXstateHooks(microAppsService);

export function useAppCanEdit(): boolean {
  return useGlobalAppStateMatches('apps.selected');
}

/**
 * This is just an example of how to create an async function that sends an event, waits for the response event and resolves promise with it.
 */
async function getAppClientData(): Promise<void | { data: unknown }> {
  const event = await waitForGlobalAppEvents({
    eventToSend: {
      type: 'app.sendUserMessage',
      msg: {
        text: 'send toast when log comes through',
      },
    },
    events: ['app.log'],
  });

  if (event.type === 'app.log') {
    const { msg } = event;
    console.log('msg', msg);
  }
}

/**
 * Analytics that require site, user, client data
 */
(() => {
  /**
   * Subscribe to xstate machines (ex. appService and appClientDataService)
   * Once app ready, can be used to fire off events, analytics, etc
   */
  globalAppService.subscribe((globalAppState) => {
    if (globalAppState.matches('user.loggedIn.loaded.userInfo')) {
      globalAppService.subscribe((state) => {
        if (state.matches('apps.selected')) {
          console.log(
            'example of sending analytics when a specific app was selected'
          );
          // const { site } = state.context;
          // const { siteId, appClientMeta } = site;
          // const { user } = appService.state.context;
          // analytics, etc
        }
      });
    }
  });
})();
