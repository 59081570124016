import { Stack, Tab, TabGroup, TabPanel } from '@pypestream/design-system';
import { FC, Fragment } from 'react';
import {
  DrawerPanelTabCard,
  DrawerPanelTabCardProps,
} from './drawer-panel-card';

// @todo - This is a temporary interface, we need to update this after

interface TabContent {
  panel: string;
  title: string;
  cards: DrawerPanelTabCardProps[];
  disabled?: boolean;
}

const tabsContent: TabContent[] = [
  {
    panel: 'Content',
    title: 'Content',
    cards: [
      {
        id: '1',
        icon: 'elements-text',
        children: 'Text',
      },
      {
        id: '2',
        icon: 'elements-link',
        children: 'Link',
      },
      {
        id: '3',
        icon: 'elements-image',
        children: 'Image',
      },
    ],
  },
  {
    panel: 'Interactions',
    title: 'Interactions',
    cards: [
      {
        id: '1',
        icon: 'elements-link',
        children: 'Link',
      },
    ],
  },
  {
    panel: 'Functions',
    title: 'Functions',
    disabled: true,
    cards: [
      {
        id: '1',
        icon: 'elements-image',
        children: 'Image',
      },
    ],
  },
];

export const DrawerPanelTabs: FC = () => {
  return (
    <TabGroup>
      {tabsContent.map(({ panel, title, disabled, cards }) => (
        <Fragment key={panel}>
          <Tab slot="tab" panel={panel} disabled={disabled}>
            {title}
          </Tab>
          <TabPanel name={panel}>
            <Stack gutter="xsmall" direction="column" alignItems="stretch">
              {cards.map(({ id, icon, children, onClick, onKeyDown }) => (
                <DrawerPanelTabCard
                  disabled
                  key={id}
                  icon={icon}
                  onClick={onClick}
                  onKeyDown={onKeyDown}
                >
                  {children}
                </DrawerPanelTabCard>
              ))}
            </Stack>
          </TabPanel>
        </Fragment>
      ))}
    </TabGroup>
  );
};

export default DrawerPanelTabs;
