import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextWC } from './text.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TextProps = GenerateComponentTypeWithChildren<TextWC>;

export type TextPropsWithRef = GeneratePropsWithRef<TextProps>;

export const Text = createComponent({
  tagName: TextWC.tagname,
  elementClass: TextWC,
  react: React,
});
