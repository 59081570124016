import * as React from 'react';
import { createComponent } from '@lit/react';
import { TabPanelWC } from './tab-panel.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TabPanelProps = GenerateComponentTypeWithChildren<TabPanelWC>;

export type TabPanelPropsWithRef = GeneratePropsWithRef<TabPanelProps>;

export const TabPanel = createComponent({
  tagName: TabPanelWC.tagname,
  elementClass: TabPanelWC,
  react: React,
}) as TabPanelPropsWithRef;
