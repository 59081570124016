import * as React from 'react';
import { createComponent } from '@lit/react';
import { SpacerWC } from './spacer.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type SpacerProps = GenerateComponentTypeWithChildren<SpacerWC>;

export type SpacerPropsWithRef = GeneratePropsWithRef<SpacerProps>;

export const Spacer = createComponent({
  tagName: SpacerWC.tagname,
  elementClass: SpacerWC,
  react: React,
}) as SpacerPropsWithRef;
