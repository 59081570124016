import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextCaptionWC } from './text-caption.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TextCaptionProps =
  GenerateComponentTypeWithChildren<TextCaptionWC> & TranslateInterface;

export type TextCaptionPropsWithRef = GeneratePropsWithRef<TextCaptionProps>;

export const TextCaption = createComponent({
  tagName: TextCaptionWC.tagname,
  elementClass: TextCaptionWC,
  react: React,
}) as TextCaptionPropsWithRef;
