import { RJSFSchema } from '@rjsf/utils';
import { textContentSchema } from '../contents/text';
import { imageContentSchema } from '../contents/image';
import { videoContentSchema } from '../contents/video';
import { webviewContentSchema } from '../contents/webview';
import { documentContentSchema } from '../contents/document';
import { ContentTypes } from '../contents/common';

export const decisionNode: RJSFSchema = {
  title: 'Decision Node Schema',
  id: 'decision_node.json/decision_node',
  type: 'array',
  items: {
    allOf: [
      {
        if: {
          properties: {
            messageType: { const: ContentTypes.Text },
          },
        },
        then: textContentSchema,
      },
      {
        if: {
          properties: {
            messageType: { const: ContentTypes.Image },
          },
        },
        then: imageContentSchema,
      },
      {
        if: {
          properties: {
            messageType: { const: ContentTypes.Document },
          },
        },
        then: documentContentSchema,
      },
      {
        if: {
          properties: {
            messageType: { const: ContentTypes.Video },
          },
        },
        then: videoContentSchema,
      },
      {
        title: ContentTypes.Webview,
        if: {
          properties: {
            messageType: { const: ContentTypes.Webview },
          },
        },
        then: webviewContentSchema,
      },
    ],
  },
  required: ['messages'],
  additionalProperties: false,
};
