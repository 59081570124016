import { html, unsafeCSS, PropertyValues } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, state } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import { Translate } from '../base-element/mixins/translation-mixin';
import styles from './badge.scss?inline';

@customElement('ps-badge')
export class BadgeWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  @property({ reflect: true, type: Number }) overflowCount = 99;

  /**
   * The count attribute for the badge element.
   */
  @property({ reflect: true, type: Number }) count: number;

  @state()
  protected _formatedCount: string | number;

  @state()
  protected hasOverflowCount: boolean;

  async willUpdate(changedProperties: PropertyValues<this>) {
    super.willUpdate(changedProperties);

    // only need to check changed properties for an expensive computation.
    if (changedProperties.has('count')) {
      const roundedCount = Math.round(this.count);

      if (roundedCount > this.overflowCount) {
        this._formatedCount = `${this.overflowCount}+`;
        this.hasOverflowCount = true;
      } else {
        this._formatedCount = roundedCount;
        this.hasOverflowCount = false;
      }
    }
  }

  render() {
    return html`
      <span class="c-badge">
        <slot></slot>
        <span
          class="c-badge__label ${this.hasOverflowCount
            ? 'c-badge__label--overflow'
            : ''}
                ${this.count <= 0 || !this.count
            ? 'c-badge__label--hidden'
            : ''}"
        >
          ${this.count > 0 ? this._formatedCount : ' '}
        </span>
      </span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-badge': BadgeWC;
  }
  enum PSElementTagNameMap {
    'ps-badge' = 'ps-badge',
  }
}
