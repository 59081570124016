import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import tableRowStyles from './table-row.scss?inline';

@customElement('ps-table-row')
export class TableRowWC extends BaseElement {
  static styles = unsafeCSS(tableRowStyles);

  tabindex?: number;

  @property({ reflect: true }) role: string | null;

  @property({ reflect: true }) actionable: boolean;

  @property({ reflect: true, type: Boolean }) selected: boolean;

  render() {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-table-row': TableRowWC;
  }
  enum PSElementTagNameMap {
    'ps-table-row' = 'ps-table-row',
  }
}
