import React from 'react';
import ReactDOM from 'react-dom/client';
import { TextTitle } from '@pypestream/design-system';
import { init as initI18n, LanguageScopes } from '@pypestream/translations';
import { sentryInit, showGitInfo } from '@pypestream/utils';
import { RouterProvider } from './router';
import { ApolloProvider } from './apollo-provider';
import { AuthProvider } from './global-provider';
import { datadogInit } from './datadog-init';
import './index.scss';

showGitInfo({
  appName: 'Studio',
  commitHash: process.env.GIT_COMMIT_HASH,
  branchName: process.env.GIT_BRANCH_NAME,
  tagName: process.env.GIT_TAG_NAME,
});

let container: HTMLElement | null = null;

document.addEventListener('DOMContentLoaded', async function () {
  if (!container) {
    container = document.getElementById('root') as HTMLElement;
    const root = ReactDOM.createRoot(container);
    await initI18n(LanguageScopes.studio);
    datadogInit();
    const studioSentryDSN = import.meta.env.FE_STUDIO_SENTRY_DSN;
    sentryInit(studioSentryDSN);

    root.render(
      <React.StrictMode>
        <React.Suspense
          fallback={
            <TextTitle i18nKey="studio/common:loading">Loading ...</TextTitle>
          }
        >
          <ApolloProvider>
            <AuthProvider>
              <RouterProvider />
            </AuthProvider>
          </ApolloProvider>
        </React.Suspense>
      </React.StrictMode>
    );
  }
});
