import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { BaseElement, customElement } from '../base-element';

import './text';

@customElement('ps-text-overline')
export class TextOverlineWC extends BaseElement {
  /** Typography variant */
  @property() variant: 'primary' | 'secondary' | 'tertiary' = 'secondary';

  @property() transform: 'none' | 'uppercase' = 'uppercase';

  @property({ reflect: true, type: Boolean }) truncate = false;

  @property({ reflect: true }) textAlign:
    | 'start'
    | 'center'
    | 'end'
    | 'justify' = 'start';

  render() {
    return html`
      <ps-text
        size="5xsmall"
        font-weight="medium"
        variant=${this.variant}
        transform="${this.transform}"
        textAlign=${this.textAlign}
        truncate="${ifDefined(this.truncate ? this.truncate : undefined)}"
        data-cy="text-overline"
      >
        <slot></slot>
      </ps-text>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text-overline': TextOverlineWC;
  }
  enum PSElementTagNameMap {
    'ps-text-overline' = 'ps-text-overline',
  }
}
