import * as React from 'react';
import { createComponent } from '@lit/react';
import { SliderWC } from './slider.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type SliderProps = GenerateComponentTypeWithChildren<SliderWC>;

export type SliderPropsWithRef = GeneratePropsWithRef<SliderProps>;

export const Slider = createComponent({
  tagName: SliderWC.tagname,
  elementClass: SliderWC,
  react: React,
}) as SliderPropsWithRef;
