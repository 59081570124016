import * as React from 'react';
import { createComponent } from '@lit/react';
import { TableBodyWC } from './table-body.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TableBodyProps = GenerateComponentTypeWithChildren<TableBodyWC>;

export type TableBodyPropsWithRef = GeneratePropsWithRef<TableBodyProps>;

export const TableBody = createComponent({
  tagName: TableBodyWC.tagname,
  elementClass: TableBodyWC,
  react: React,
}) as TableBodyPropsWithRef;
