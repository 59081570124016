import * as React from 'react';
import { createComponent } from '@lit/react';
import { PageBodyWC } from './page-body.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PageBodyProps = GenerateComponentTypeWithChildren<PageBodyWC>;
export type PageBodyPropsWithRef = GeneratePropsWithRef<PageBodyProps>;

export const PageBody = createComponent({
  tagName: PageBodyWC.tagname,
  elementClass: PageBodyWC,
  react: React,
}) as PageBodyPropsWithRef;
