import { RJSFSchema } from '@rjsf/utils';
import { object, optional, string, Infer } from 'superstruct';
import {
  ContentTypes,
  commonMessageContent,
  messageTypeSchema,
} from './common';

export const ImageContentStruct = object({
  messageType: string(),
  url: string(),
  alt_text: optional(string()),
  type: optional(string()),
  id: optional(string()),
  lastUpdated: optional(string()),
});

export type ImageContent = Infer<typeof ImageContentStruct>;

export const imageContentSchema: RJSFSchema = {
  title: ContentTypes.Image,
  type: 'object',
  properties: {
    messageType: {
      ...messageTypeSchema,
      default: ContentTypes.Image,
    },
    type: {
      type: 'string',
      enum: ['static'],
      default: 'static',
    },
    url: {
      type: 'string',
    },
    alt_text: {
      type: 'string',
    },
    ...commonMessageContent,
  },
  required: ['messageType', 'url'],
};
