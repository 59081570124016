import * as React from 'react';
import { createComponent } from '@lit/react';
import { CalloutWC } from './callout.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type CalloutProps = GenerateComponentTypeWithChildren<CalloutWC>;

export type CalloutPropsWithRef = GeneratePropsWithRef<CalloutProps>;

export const Callout = createComponent({
  tagName: CalloutWC.tagname,
  elementClass: CalloutWC,
  react: React,
}) as CalloutPropsWithRef;
