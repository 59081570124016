import * as React from 'react';
import { createComponent } from '@lit/react';
import { BreadcrumbWC } from './breadcrumb.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type BreadcrumbProps = GenerateComponentTypeWithChildren<BreadcrumbWC>;

export type BreadcrumbPropsWithRef = GeneratePropsWithRef<BreadcrumbProps>;

export type BreadcrumbLink = {
  text?: string;
  root?: boolean;
  icon?: string;
  href?: string;
  target?: string;
};

export const Breadcrumb = createComponent({
  tagName: BreadcrumbWC.tagname,
  elementClass: BreadcrumbWC,
  react: React,
}) as BreadcrumbPropsWithRef;
