import React, { FC, useCallback } from 'react';
import { Position, useReactFlow, Handle } from 'reactflow';
import { IconButton, GraphNode } from '@pypestream/design-system';
import { sendBuilderEvent } from '../../../../xstate/app.xstate';
import { Node } from '../../../../store/graph';
import './default-node.scss';

export const CUSTOM_DEFAULT_NODE_NAME = 'CustomDefaultNode';

export const DefaultNode: FC<{
  data: Node['data'];
}> = ({ data }) => {
  const { fitView } = useReactFlow();

  // const toggleCollapse = useCallback(
  //   (event: React.MouseEvent<Element, MouseEvent>) => {
  //     event.stopPropagation();
  //     sendBuilderEvent({
  //       type: 'builder.collapse.toggle',
  //       nodeId: data.node_number,
  //       isCollapsed: !data.collapsed,
  //     });
  //     /**
  //      * wait at least one clock cycle (one tick) before triggering
  //      * React Flow's auto-layout. workaround to slight delay before
  //      * notes are collapsed + UI redrawn
  //      */
  //     setTimeout(() => {
  //       fitView({ padding: 0.1, duration: 500 });
  //     }, 0);
  //   },
  //   [data.collapsed, data.number, fitView]
  // );

  return (
    <GraphNode
      status="valid"
      label={data.node_instance?.node.node_name || ''}
      nodeId={data.node_number}
    >
      {data.parentId && <Handle type="target" position={Position.Top} />}
      <Handle type="source" position={Position.Top} id="a" />
      <Handle type="source" position={Position.Right} id="b" />
      <Handle type="source" position={Position.Bottom} id="c" />
      <Handle type="source" position={Position.Left} id="d" />
      {/* Disabling collapse/expand feature for now till we decide expectation for the same */}
      {/* {data.children?.length ? (
        <span className="c-graph-node__toggle">
          <IconButton
            onClick={toggleCollapse}
            name={data.collapsed ? 'plus' : 'minus'}
            variant="secondary"
            size="xsmall"
          />
        </span>
      ) : (
        ''
      )} */}
    </GraphNode>
  );
};
