import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import '../../text';
import styles from './spinner.scss?inline';

@customElement('ps-spinner')
export class SpinnerWC extends BaseElement {
  static styles = unsafeCSS(styles);

  /** Set full page mode. */
  @property({ reflect: true, type: Boolean }) fullpage?: boolean;

  render() {
    return html`<div
      class="${classMap({
        'c-spinner': true,
        'c-spinner--fullpage': !!this.fullpage,
      })}"
    >
      <div class="c-spinner__spinner">
        <span class="c-spinner__circle"></span>
        <span class="c-spinner__circle"></span>
      </div>
      ${this.slotted()
        ? html`
            <ps-text-subtitle textAlign="center"
              ><slot></slot
            ></ps-text-subtitle>
          `
        : null}
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-spinner': SpinnerWC;
  }
}
