import { FC } from 'react';
import { Position, NodeProps, Handle } from 'reactflow';
import { TextSubtitle } from '@pypestream/design-system';
import { NodeData, convertToDefaultNode } from '../../../../store/graph';
import './placeholder-node.scss';

export const CUSTOM_PLACEHOLDER_NODE_NAME = 'CustomPlaceholderNode';

export const PlaceholderNode: FC<NodeProps<NodeData>> = (nodeData) => {
  return (
    <>
      <div
        onClick={() => convertToDefaultNode(nodeData)}
        className="c-node c-node--placeholder"
        title="Click to add a node"
      >
        <TextSubtitle i18nKey="studio/build:addNewNode">
          Add new Node
        </TextSubtitle>
        <Handle
          className="c-node--handle"
          type="target"
          position={Position.Top}
          isConnectable={false}
        />
      </div>
    </>
  );
};
