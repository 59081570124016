import * as React from 'react';
import { createComponent } from '@lit/react';
import { TextBodyWC } from './text-body.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';

export type TextBodyProps = GenerateComponentTypeWithChildren<TextBodyWC> &
  TranslateInterface;

export type TextBodyPropsWithRef = GeneratePropsWithRef<TextBodyProps>;

export const TextBody = createComponent({
  tagName: TextBodyWC.tagname,
  elementClass: TextBodyWC,
  react: React,
}) as TextBodyPropsWithRef;
