import * as React from 'react';
import { createComponent } from '@lit/react';
import { OnboardingModalWC } from './onboarding-modal.wc';
import {
  GenerateComponentTypeWithoutChildren,
  GeneratePropsWithRef,
} from '../../components/component-types-generics';

export type OnboardingModalProps =
  GenerateComponentTypeWithoutChildren<OnboardingModalWC>;

export type OnboardingModalPropsWithRef =
  GeneratePropsWithRef<OnboardingModalProps>;

export const OnboardingModal = createComponent({
  tagName: 'ps-onboarding-modal',
  elementClass: OnboardingModalWC,
  react: React,
}) as OnboardingModalPropsWithRef;
