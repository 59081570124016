import * as React from 'react';
import { createComponent } from '@lit/react';
import { AvatarGroupWC } from './avatar-group.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type AvatarGroupProps = GenerateComponentTypeWithChildren<AvatarGroupWC>;

export type AvatarGroupPropsWithRef = GeneratePropsWithRef<AvatarGroupProps>;

export const AvatarGroup = createComponent({
  tagName: AvatarGroupWC.tagname,
  elementClass: AvatarGroupWC,
  react: React,
}) as AvatarGroupPropsWithRef;
