import * as React from 'react';
import { createComponent } from '@lit/react';
import { PageWC } from './page.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PageProps = GenerateComponentTypeWithChildren<PageWC>;
export type PagePropsWithRef = GeneratePropsWithRef<PageProps>;

export const Page = createComponent({
  tagName: PageWC.tagname,
  elementClass: PageWC,
  react: React,
}) as PagePropsWithRef;
