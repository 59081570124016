import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../../../components/base-element';
import styles from './auth.scss?inline';

@customElement('ps-auth')
export class AuthWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="c-auth">
        <div class="c-auth__logo">
          <ps-text size="medium" font-weight="medium">pypestream</ps-text>
        </div>
        <div class="c-auth__inner">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-auth': AuthWC;
  }
}
