import { ButtonWC } from '../../components';
import { customElement } from '../../components/base-element';

const AllVariants = ['primary', 'secondary'] as const;
type VariantTuple = typeof AllVariants;
type ButtonWithoutGhostVariation = VariantTuple[number];

function isVariant(value: string): value is ButtonWithoutGhostVariation {
  return AllVariants.includes(value as ButtonWithoutGhostVariation);
}

@customElement('button-without-ghost')
export class ButtonWithoutGhostWC extends ButtonWC {
  override _variant: ButtonWithoutGhostVariation = 'primary';

  set variant(newVal: ButtonWithoutGhostVariation) {
    if (isVariant(newVal)) {
      if (newVal === this._variant) return;

      const oldVal = this._variant;
      this._variant = newVal;
      this.requestUpdate('variant', oldVal);
    }
  }

  get variant() {
    return this._variant;
  }
}
