import * as React from 'react';
import { createComponent } from '@lit/react';
import { AvatarWC } from './avatar.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type AvatarProps = GenerateComponentTypeWithChildren<AvatarWC>;

export type AvatarPropsWithRef = GeneratePropsWithRef<AvatarProps>;

export const Avatar = createComponent({
  tagName: AvatarWC.tagname,
  elementClass: AvatarWC,
  react: React,
  events: {
    onError: 'onerror',
  },
}) as AvatarPropsWithRef;
