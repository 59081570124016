import * as React from 'react';
import { createComponent } from '@lit/react';
import { DropdownWC } from './dropdown.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type DropdownProps = GenerateComponentTypeWithChildren<
  InstanceType<typeof DropdownWC>
>;

export type DropdownPropsWithRef = GeneratePropsWithRef<
  DropdownProps & {
    slot?: string;
  }
>;

export const Dropdown = createComponent({
  tagName: DropdownWC.tagname,
  elementClass: DropdownWC,
  react: React,
  events: {
    onClose: 'close',
  },
}) as DropdownPropsWithRef;
