import * as React from 'react';
import { createComponent } from '@lit/react';
import { FormControlInterface } from '@open-wc/form-control';
import { IconButtonWC } from './icon-button.wc';
import { FormControlController } from '../../base-element';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

/** besides generating a React wrapped version of the Button web component, this file
 * also demonstrates how, if / when it makes sense, we can manually curate the
 * exposed React props that are shown in Intellisense */

// type _IconButtonProps = Omit<
//   IconButtonWC,
//   | keyof InstanceType<typeof HTMLElement>
//   | keyof InstanceType<typeof BaseElement>
//   | 'name'
// > & {
//   onClick?: React.MouseEventHandler<IconButtonWC>;
// };

/**
 * note: any required props would need to get manually added (as being required) -- the
 * Partial generic means any -- "technically internally required" by not _actually_
 * externally required -- props aren't flagged as being required in React. */
// export type IconButtonProps = Partial<Expand<_IconButtonProps>> & {
//   name: IconButtonWC['name'];
// };

// export type IconButtonPropsWithRef = React.ForwardRefExoticComponent<
//   React.PropsWithoutRef<IconButtonProps> &
//     React.RefAttributes<Expand<IconButtonProps>>
// >;

type OmittedButtonWC = Omit<
  IconButtonWC,
  keyof InstanceType<typeof FormControlController> | keyof FormControlInterface
>;

export type IconButtonProps =
  GenerateComponentTypeWithChildren<OmittedButtonWC> & {
    onClick?: React.MouseEventHandler<IconButtonWC>;
  };

export type IconButtonPropsWithRef = GeneratePropsWithRef<IconButtonProps>;

export const IconButton = createComponent({
  tagName: IconButtonWC.tagname,
  elementClass: IconButtonWC,
  react: React,
  events: {
    onClick: 'click',
  },
}) as IconButtonPropsWithRef;
