import * as React from 'react';
import { createComponent } from '@lit/react';
import { TableWC } from './table.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type TableProps = GenerateComponentTypeWithChildren<TableWC>;

export type TablePropsWithRef = GeneratePropsWithRef<TableProps>;

export const Table = createComponent({
  tagName: TableWC.tagname,
  elementClass: TableWC,
  react: React,
}) as TablePropsWithRef;
