import * as React from 'react';
import { createComponent } from '@lit/react';
import { SelectWC } from './select.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type SelectProps = GenerateComponentTypeWithChildren<SelectWC> & {
  onChange?: React.ChangeEventHandler<SelectWC>;
};

export type SelectPropsWithRef = GeneratePropsWithRef<SelectProps>;

export const Select = createComponent({
  tagName: SelectWC.tagname,
  elementClass: SelectWC,
  react: React,
  events: {
    onChange: 'change',
  },
}) as SelectPropsWithRef;
