import { RJSFSchema } from '@rjsf/utils';
import { object, optional, string, Infer } from 'superstruct';
import {
  messageTypeSchema,
  ContentTypes,
  commonMessageContent,
} from './common';

export const VideoContentStruct = object({
  messageType: string(),
  url: string(),
  alt_text: optional(string()),
  type: string(),
  id: optional(string()),
  lastUpdated: optional(string()),
});

export type VideoContent = Infer<typeof VideoContentStruct>;

export const videoContentSchema: RJSFSchema = {
  title: ContentTypes.Video,
  type: 'object',
  properties: {
    messageType: {
      ...messageTypeSchema,
      default: ContentTypes.Video,
    },
    type: {
      type: 'string',
      enum: ['static'],
      default: 'static',
    },
    url: {
      type: 'string',
      format: 'data-url',
    },
    alt_text: {
      type: 'string',
    },
    ...commonMessageContent,
  },
  required: ['messageType', 'type', 'url'],
};
