import { FC } from 'react';
import {
  DrawerPanel as PSDrawerPanel,
  DrawerPanelWC,
  DrawerPanelProps as PSDrawerPanelProps,
  DrawerPanelWrapper,
} from '@pypestream/design-system';

type DrawerPanelProps = PSDrawerPanelProps & {
  elements: JSX.Element;
  details: JSX.Element;
  onDrawerPanelToggle: () => void;
};

export const DrawerPanel: FC<DrawerPanelProps> = ({
  open,
  anchor = 'left',
  expanded,
  elements,
  details,
  onDrawerPanelToggle,
}) => {
  return (
    <DrawerPanelWrapper>
      <PSDrawerPanel
        anchor={anchor}
        type="elements"
        open={open}
        expanded={expanded}
        onDrawerPanelToggle={onDrawerPanelToggle}
      >
        {elements}
      </PSDrawerPanel>
      <PSDrawerPanel type="details" open={open} expanded={expanded}>
        <span style={{ display: 'none' }} slot="header" />
        {details}
      </PSDrawerPanel>
    </DrawerPanelWrapper>
  );
};
