import { BaseElement, customElement } from '../base-element';

@customElement('ps-buffer')
export class BufferWC extends BaseElement {
  value = '';

  internalValue: string;

  private _isUserTyping = false;

  private _debounceTimer?: number;

  updated(changedProperties: Map<string | number | symbol, unknown>) {
    if (changedProperties.has('value') && !this._isUserTyping) {
      this.internalValue = this.value;
      this.requestUpdate();
    }
  }

  onInput() {
    this._isUserTyping = true;
    this.internalValue = this.value;

    this._userTypingDebounce();
  }

  private _userTypingDebounce() {
    if (this._debounceTimer) {
      clearTimeout(this._debounceTimer);
    }

    this._debounceTimer = window.setTimeout(() => {
      this._isUserTyping = false;
    }, 500);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-buffer': BufferWC;
  }
  enum PSElementTagNameMap {
    'ps-buffer' = 'ps-buffer',
  }
}
