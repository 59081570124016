/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useRef } from 'react';
import {
  Nav,
  NavLogo,
  NavLinks,
  NavLink,
  NavLinkWC,
  NavLinkProps,
  NavExtra,
  NavActions,
  Button,
} from '@pypestream/design-system';
import {
  NavLinkProps as NavLinkPropsReactRouter,
  useHref,
  useLinkClickHandler,
} from 'react-router-dom';
import {
  sendUiEvent,
  useMicroappsCtxSelector,
  useMicroappsStateMatches,
  useUserCtxSelector,
} from '../../xstate/app.xstate';
import { TransKeyProp } from '@pypestream/translations';
import './primary-nav-link.scss';
import './primary-nav.scss';
import { VersionDropdown } from '../dropdowns/version-dropdown';
import { WorkflowDropdown } from '../dropdowns/workflow-dropdown';
import { VersionIndicator } from '../indicator';
import { ProfileDropdown } from '../dropdowns/profile-dropdown';

export const routes = {
  home: '/',
};

// demonstrates a light react router wrapper around our design system's NavLink component
export const NavLinkWrapper = React.forwardRef<
  NavLinkProps,
  TransKeyProp &
    NavLinkPropsReactRouter & {
      onClick?: React.MouseEventHandler<NavLinkWC>;
      children?: React.ReactNode;
      isActive?: boolean;
      disabled?: boolean;
    }
>(
  (
    {
      onClick,
      replace = false,
      state,
      target,
      to,
      children,
      i18nKey,
      isActive = false,
      disabled = false,
    },
    ref
  ) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler<NavLinkWC>(to, {
      replace,
      state,
      target,
    });

    return (
      <NavLink
        active={isActive}
        href={href}
        onClick={(event: React.MouseEvent<NavLinkWC, MouseEvent>) => {
          onClick?.(event);
          if (!event.defaultPrevented && !disabled) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
        i18nKey={i18nKey}
        disabled={disabled}
      >
        {children}
      </NavLink>
    );
  }
);

export const PrimaryNav: FC = () => {
  const { microAppDetails, selectedAppId, selectedVersionId, draftVersionId } =
    useMicroappsCtxSelector((ctx) => ({
      selectedAppId: ctx.selectedAppId,
      selectedVersionId: ctx.selectedVersionId,
      draftVersionId: ctx.draftVersionId,
      microAppDetails: ctx.data?.find(
        ({ app_id }) => app_id === ctx.selectedAppId
      ),
    }));

  const canShowNaActions = useMicroappsStateMatches(
    'loaded.appManagement.waitingToSelectAppVersion'
  );
  const versionIdToOpen = selectedVersionId || draftVersionId;
  const canPublish = useUserCtxSelector((ctx) => ctx.user?.canPublish);
  const isBuildPage = useMicroappsStateMatches(
    'loaded.appManagement.appVersionSelected'
  );

  const handleModalOpen = () => {
    sendUiEvent({
      type: 'modal.publish.triggerOpen',
    });
  };

  return (
    <Nav slot="nav" sticky={isBuildPage ? true : undefined}>
      <NavLogo href={routes.home} condensed={isBuildPage}>
        Studio
      </NavLogo>

      <NavLinks>
        <div
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
        >
          {selectedVersionId && <VersionDropdown />}
        </div>
        <div
          style={{
            display: 'grid',
            alignContent: 'center',
          }}
        >
          {microAppDetails && selectedVersionId && <VersionIndicator />}
        </div>

        {canShowNaActions && (
          <>
            <NavLinkWrapper
              to={`/${selectedAppId}`}
              isActive={!isBuildPage}
              i18nKey="studio/navigation:main.manage"
            >
              Manage
            </NavLinkWrapper>
            <NavLinkWrapper
              to={`/${selectedAppId}/${versionIdToOpen}`}
              isActive={isBuildPage}
              i18nKey="studio/navigation:main.build"
              disabled={!versionIdToOpen}
            >
              Build
            </NavLinkWrapper>
          </>
        )}
      </NavLinks>

      <div
        style={{
          display: 'grid',
          justifyContent: 'center',
        }}
      >
        {selectedVersionId && <WorkflowDropdown />}
      </div>

      {canShowNaActions && isBuildPage && (
        <NavExtra>
          <Button
            i18nKey="studio/navigation:extra.preview"
            variant="secondary"
            disabled={!canPublish}
          >
            Preview
          </Button>
          <Button
            onClick={handleModalOpen}
            i18nKey="studio/navigation:extra.publish"
            disabled={!canPublish}
          >
            Publish Changes
          </Button>
        </NavExtra>
      )}

      <NavActions>
        <ProfileDropdown />
      </NavActions>
    </Nav>
  );
};
