import * as React from 'react';
import { createComponent } from '@lit/react';
import { PageSidenavWC } from './page-sidenav.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type PageSidenavProps = GenerateComponentTypeWithChildren<PageSidenavWC>;
export type PageSidenavPropsWithRef = GeneratePropsWithRef<PageSidenavWC>;

export const PageSidenav = createComponent({
  tagName: PageSidenavWC.tagname,
  elementClass: PageSidenavWC,
  react: React,
});
