import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { customElement } from '../../base-element';
import { PopoverBaseWC } from './popover-base.wc';
import styles from './popover.scss?inline';

@customElement('ps-popover')
export class PopoverWC extends PopoverBaseWC {
  static styles = unsafeCSS(styles);

  /**
   * Popover size
   */
  @property({ reflect: true }) size: 'none' | 'large' = 'large';

  constructor() {
    super();
    this.distance = 8;
    this.skidding = 8;
    this.hasArrow = true;
  }

  render() {
    return html`
      <div
        id="popover-container"
        class=${classMap({
          'c-popover': true,
          'c-popover--active': this.active,
          [`c-popover--${this.width}-width`]: this.width,
          [`c-popover--size-${this.size}`]: this.size,
        })}
        style="transform: ${this.transform}"
      >
        <slot class="c-popover__body"></slot>
        <div class="c-popover__arrow" id="popover-arrow"></div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-popover': PopoverWC;
  }
  enum PSElementTagNameMap {
    'ps-popover' = 'ps-popover',
  }
}
