import {
  CSSResultGroup,
  CSSResultOrNative,
  CSSResult,
  supportsAdoptingStyleSheets,
  CSSResultArray,
} from 'lit';
import { BaseElement } from './base-element';

export class BaseElementWithGlobalStyles extends BaseElement {
  /**
   * Array of global styles  to the element. The styles should be defined
   * using the [[`css`]] tag function or via constructible stylesheets.
   */
  static globalStyles?: CSSResultGroup;

  static _globalStyles: Array<CSSResultOrNative | CSSResult> | undefined;

  /**
   * Returns the array of global styles to
   * Override this method to integrate into a style management system.
   *
   */
  static getGlobalStyles(): CSSResultGroup | undefined {
    return this.globalStyles;
  }

  static _getUniqueStyles() {
    // Only gather / process styles ONCE per class!
    // eslint-disable-next-line no-prototype-builtins
    if (this.hasOwnProperty('_globalStyles')) {
      return;
    }
    // Take care not to call `this.getStyles()` multiple times since this
    // generates new CSSResults each time.
    const userStyles = this.getGlobalStyles();

    if (Array.isArray(userStyles)) {
      // De-duplicate styles preserving the _last_ instance in the set.
      // This is a performance optimization to avoid duplicated styles that can
      // occur especially when composing via subclassing.
      // The last item is kept to try to preserve the cascade order with the
      // assumption that it's most important that last added styles override
      // previous styles.
      const addStyles = (
        styles: CSSResultArray,
        set: Set<CSSResultOrNative>
      ): Set<CSSResultOrNative> =>
        styles.reduceRight(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (set: Set<CSSResultOrNative>, s) =>
            Array.isArray(s) ? addStyles(s, set) : (set.add(s), set),
          set
        );
      const set = addStyles(userStyles, new Set<CSSResultOrNative>());
      const styles: CSSResultOrNative[] = [];
      set.forEach((v) => styles.unshift(v));
      this._globalStyles = styles;
    } else {
      this._globalStyles = userStyles === undefined ? [] : [userStyles];
    }
  }

  connectedCallback() {
    super.connectedCallback();
    (this.constructor as typeof BaseElementWithGlobalStyles)._getUniqueStyles();
    this.adoptGlobalStyles();
  }

  /**
   * Auto-injects global styles (ex. CSS custom property definitions) to the main page
   */
  adoptGlobalStyles() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const globalStyles = (
      this.constructor as typeof BaseElementWithGlobalStyles
    )._globalStyles!;

    // return early
    if (globalStyles.length === 0) {
      return;
    }

    if (supportsAdoptingStyleSheets) {
      const sheets: CSSStyleSheet[] = [];

      globalStyles.map((s) => {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(s.toString());
        sheets.push(sheet);
        return s;
      });
      document.adoptedStyleSheets = [...document.adoptedStyleSheets, ...sheets];
    }
  }
}
