import React from 'react';
import {
  format as formatDateFns,
  formatRelative as formatRelativeDateFns,
  isValid as isDateValid,
} from 'date-fns';
import { Translation } from 'react-i18next';
import { i18n, dateFnsLocales } from '@pypestream/translations';

enum DateFormats {
  // Need to fix localization for `at` key word.
  'default' = "MM/dd/yyyy 'at' hh:mm aa",
}

export { isDateValid };

export const format = (
  date: Date | number,
  dateFormat: DateFormats = DateFormats.default,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): JSX.Element => {
  return (
    <Translation>
      {() =>
        formatDateFns(date, dateFormat, {
          ...options,
          ...(!options?.locale && {
            locale: dateFnsLocales[i18n.language],
          }),
        })
      }
    </Translation>
  );
};

export const formatRelative = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }
): JSX.Element => {
  return (
    <Translation>
      {() =>
        formatRelativeDateFns(date, baseDate, {
          ...options,
          ...(!options?.locale && {
            locale: dateFnsLocales[i18n.language],
          }),
        })
      }
    </Translation>
  );
};

export const getDateOrNull = (value: Date | string | null): Date | null => {
  if (value !== null && isDateValid(new Date(value))) {
    return new Date(value);
  }

  return null;
};
