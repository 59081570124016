import * as React from 'react';
import { createComponent } from '@lit/react';
import { UniversalUserDropdownWC } from './universal-user-dropdown.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../components/component-types-generics';

export type UniversalUserDropdownProps =
  GenerateComponentTypeWithChildren<UniversalUserDropdownWC> & {
    onLogout?: () => void;
    onPreferencesClick?: () => void;
    onSelectionChange?: ({
      detail: { selection },
    }: {
      detail: { selection: string | undefined };
    }) => void;
  };

export type UniversalUserDropdownPropsWithRef =
  GeneratePropsWithRef<UniversalUserDropdownProps>;

export const UniversalUserDropdown = createComponent({
  tagName: UniversalUserDropdownWC.tagname,
  elementClass: UniversalUserDropdownWC,
  events: {
    onLogout: 'logout',
    onPreferencesClick: 'preferences-click',
    onSelectionChange: 'selection-change',
  },
  react: React,
}) as UniversalUserDropdownPropsWithRef;
