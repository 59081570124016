import React, { useEffect, useState } from 'react';
import {
  globalAppService,
  sendUserEvent,
  useUserStateMatches,
  // sendGlobalAppEvent,
} from './xstate/app.xstate';
import { kratosApi, Session } from './kratos.api';

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [session, setSession] = useState<Session>();
  const isLoggedOut = useUserStateMatches('loggedOut');

  globalAppService.start();

  useEffect(() => {
    kratosApi
      .toSession()
      .then(({ data }) => {
        if (isLoggedOut) {
          sendUserEvent({
            type: 'user.signingBackIn',
            user: {
              email: data.identity?.traits.email,
              firstName: data.identity?.traits.firstName,
              lastName: data.identity?.traits.lastName,
              profilePhoto: data.identity?.traits.picture,
            },
          });
        }

        setSession(data);
      })
      .catch(() => {
        const returnTo = window.location.href;
        const authApp: string = import.meta.env.FE_AUTH_URL;
        // will replace fallback URL once code is on next branch
        // @todo: include way to use local auth running
        let url = new URL('https://login.pypestream.dev/');
        if (authApp !== undefined && authApp !== '') {
          url = new URL(authApp?.replaceAll('"', ''));
        }
        url.searchParams.append('return_to', returnTo);
        window.location.href = url.toJSON();
      });
  }, [isLoggedOut]);

  if (!session) {
    return <h1>Loading...</h1>;
  }
  // todo: do we need this?
  const splittedPath = window.location.href.split('?');
  window.history.pushState('authenticated', '', splittedPath[0]);
  return <>{children}</>;
};
