/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentType, FC, useEffect } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from 'react-error-boundary';
import { ChildrenProps, ErrorPageProps, ErrorTags } from './types';
import { useErrorTag, useResetAppContextAndGoToHome } from './hooks';
import { useCurrentState } from '../../hooks/use-current-state';
import { TextTitle, Button } from '@pypestream/design-system';
import { FormattedErrorMessage } from './global-error-boundary';
import { filterErrorTags, logError } from './helpers';

interface LocalErrorBoundaryProps extends ChildrenProps {
  fallbackComponent?: ComponentType<FallbackProps>;
  error?: Error;
  errorTags?: ErrorTags | ErrorTags[];
  onReset: () => void;
}

export const LocalErrorPage: FC<ErrorPageProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const resetApp = useResetAppContextAndGoToHome();

  return (
    <div
      role="alert"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '1rem',
      }}
    >
      <TextTitle size="small" i18nKey="studio/common:error.title">
        Something went wrong:
      </TextTitle>
      <FormattedErrorMessage message={error.message} />
      <div>
        <Button
          i18nKey="studio/common:error.button"
          onClick={resetErrorBoundary}
        >
          Try again
        </Button>
        <Button onClick={resetApp}>Reset App</Button>
      </div>
    </div>
  );
};

export const LocalErrorBoundary: FC<LocalErrorBoundaryProps> = ({
  errorTags,
  children,
  fallbackComponent = LocalErrorPage,
  error,
  onReset,
}) => {
  const ErrorCatcher = () => {
    const { showBoundary } = useErrorBoundary();
    const { isLocalError, hasUserTags } = useErrorTag(errorTags);

    const isSuitableErrorType = filterErrorTags(errorTags).length
      ? isLocalError && hasUserTags
      : isLocalError;

    useEffect(() => {
      if (!error || !isSuitableErrorType) {
        return;
      }

      showBoundary(error);
    }, [error, isSuitableErrorType]);

    return <>{children}</>;
  };

  const currentState = useCurrentState();

  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onError={(e, i) => logError(e, i, currentState)}
      onReset={onReset}
    >
      <ErrorCatcher />
    </ReactErrorBoundary>
  );
};
