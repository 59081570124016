import * as React from 'react';
import { createComponent } from '@lit/react';
import { ResultWC } from './result.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type ResultProps = GenerateComponentTypeWithChildren<ResultWC>;

export type ResultPropsWithRef = GeneratePropsWithRef<ResultProps>;

export const Result = createComponent({
  tagName: ResultWC.tagname,
  elementClass: ResultWC,
  react: React,
}) as ResultPropsWithRef;
