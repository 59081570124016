import * as React from 'react';
import { createComponent } from '@lit/react';
import { AutoSaveWC } from './autosave.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type AutoSaveProps = GenerateComponentTypeWithChildren<
  InstanceType<typeof AutoSaveWC>
>;

export type AutoSavePropsWithRef = GeneratePropsWithRef<
  AutoSaveProps & {
    onValueChanged: (event: CustomEvent) => void;
    onValueSaved: (event: CustomEvent) => void;
  }
>;

export const AutoSave = createComponent({
  tagName: AutoSaveWC.tagname,
  elementClass: AutoSaveWC,
  react: React,
  events: {
    onValueChanged: 'value-changed',
    onValueSaved: 'value-saved',
  },
}) as AutoSavePropsWithRef;
