import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import drawerStyles from './drawer.scss?inline';

@customElement('ps-drawer')
export class DrawerWC extends BaseElement {
  static styles = unsafeCSS(drawerStyles);

  /** Disables the button. */
  @property({ reflect: true, type: Boolean }) open?: boolean = false;

  /** The drawer's anchor. */
  @property({ reflect: true }) anchor: 'right' | 'left' = 'right';

  render() {
    return html`
      <aside
        class="${classMap({
          'c-drawer': true,
          'c-drawer--open': !!this.open,
          [`c-drawer--anchor-${this.anchor}`]: this.anchor,
        })}"
      >
        <slot></slot>
      </aside>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-drawer': DrawerWC;
  }
  enum PSElementTagNameMap {
    'ps-drawer' = 'ps-drawer',
  }
}
