import * as React from 'react';
import { createComponent } from '@lit/react';
import { AppletCardAvatarWC } from './applet-card-avatar.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';

export type AppletCardAvatarProps =
  GenerateComponentTypeWithChildren<AppletCardAvatarWC>;

export type AppletCardAvatarPropsWithRef =
  GeneratePropsWithRef<AppletCardAvatarProps>;

export const AppletCardAvatar = createComponent({
  tagName: AppletCardAvatarWC.tagname,
  elementClass: AppletCardAvatarWC,
  react: React,
}) as AppletCardAvatarPropsWithRef;
