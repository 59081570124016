/* eslint-disable lit/no-invalid-html */
/* eslint-disable lit/binding-positions */
import { unsafeCSS } from 'lit';
import { html, unsafeStatic } from 'lit/static-html.js';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { BaseElement, customElement } from '../base-element';
import { Translate } from '../base-element/mixins/translation-mixin';
import textStyles from './text.scss?inline';

@customElement('ps-text')
export class TextWC extends Translate(BaseElement) {
  static styles = unsafeCSS(textStyles);

  /** HTML tag */
  @property() tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' = 'p';

  /** Typography variant */
  @property() variant: 'primary' | 'secondary' | 'tertiary' | 'danger' =
    'primary';

  @property() transform: 'none' | 'uppercase' = 'none';

  /** Font-size option */
  @property() size:
    | '5xsmall'
    | '4xsmall'
    | '3xsmall'
    | '2xsmall'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge' = 'xsmall';

  /** Font-weight option */
  @property({
    reflect: true,
    attribute: 'font-weight',
  })
  fontWeight: 'light' | 'normal' | 'medium' = 'normal';

  /** Text-align */
  @property({ reflect: true }) textAlign:
    | 'start'
    | 'center'
    | 'end'
    | 'justify' = 'start';

  /** Display option */
  @property({ reflect: true }) display: 'flex' | 'inline-flex' = 'flex';

  /** Truncate option */
  @property({ type: Boolean, reflect: true }) truncate?: boolean = false;

  /** Number of lines before truncate */
  @property({ type: Number, reflect: true, attribute: 'truncate-lines' })
  truncateLines?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static Textname: any;

  render() {
    const classes = {
      'c-typography': true,
      [`c-typography--variant-${this.variant}`]: this.variant,
      [`c-typography--${this.transform}`]: this.transform !== 'none',
      [`c-typography--size-${this.size}`]: this.size,
      [`c-typography--weight-${this.fontWeight}`]: this.fontWeight,
      [`c-typography--align-${this.textAlign}`]: this.textAlign,
      [`c-typography--${this.display}`]: this.display,
      'c-typography--truncate': !!this.truncate && !this.truncateLines,
      'c-typography--truncate-multiline': !!this.truncateLines,
    };

    const tag = unsafeStatic(this.tag);

    let styles = styleMap({});

    if (this.truncateLines) {
      styles = styleMap({
        '--c-text-truncate-lines': this.truncateLines,
      });
    }

    return html`
      <${tag}
        data-cy="text"
        class=${classMap(classes)}
        data-cy="text"
        style=${styles}
      >
        <slot></slot>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text': TextWC;
  }
  enum PSElementTagNameMap {
    'ps-text' = 'ps-text',
  }
}
